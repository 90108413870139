import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'paperless-terms-content',
  templateUrl: './paperless-terms-content.component.html',
  styleUrls: ['./paperless-terms-content.component.scss']
})

export class PaperlessTermsContentComponent implements OnInit {
    @Input() customerName = '';
    @Input() emailAddress = '';
    @Input() paperlessTermsAndConditions: string;

    constructor() { }

    ngOnInit() {}

}