import { Component, OnInit, Input } from '@angular/core';

import Endpoints from 'src/app/utilities/constants/endpoints';
import { environment as env } from 'src/environments/environment';

import { RoutingService } from 'src/app/utilities/services/routing-service/routing.service';

@Component({
  selector: 'app-actions-menu',
  templateUrl: './actions-menu.component.html',
  styleUrls: ['./actions-menu.component.scss']
})
export class ActionsMenuComponent implements OnInit {

  @Input() actionsMenuHeapID: string;
  @Input() isDropUp: boolean = false;
  @Input() actionsMenuItems: ActionsMenuItem[] = [];

  imgLoc = env.imgLoc;

  constructor(private routingService: RoutingService) { }

  ngOnInit() { }

  navigateToItemLink(actionItem: ActionsMenuItem) {
    if (actionItem.linkTab) {
      this.routingService.navigateTo(actionItem.link, actionItem.linkTab);
    } else if (actionItem.isLegacy) {
      this.routingService.navigateTo(actionItem.link);
    } else {
      if (typeof actionItem.link === 'function') {
        actionItem.link = actionItem.link(actionItem.linkProperty)
      }

      this.routingService.routeTo(actionItem.link);
    }
  }
}

export class ActionsMenuItem {
  label: string;
  isBPSLink: boolean;
  heapID: string;
  link?: any;
  isLegacy?: boolean;
  linkTab?: string;
  linkProperty?: string;
  BPSLinkType?: string;
}