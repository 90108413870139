import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';

import { BillingAccountStatuses } from 'src/app/utilities/constants/billingAccountStatuses';
import { PAYMENT_STATUS_CODES } from 'src/app/utilities/constants/payment';
import { PaymentMethods } from 'src/app/utilities/constants/paymentMethods';
import CAMStorage from 'src/app/utilities/constants/CAMStorage';
import Endpoints from 'src/app/utilities/constants/endpoints';
import NotificationMessageId from 'src/app/utilities/constants/notificationMessageId';

import { AccountOptionsPaymentMethod } from 'src/app/utilities/models/billingAccountOptions';
import { AdjustBillResponse, AdjustBill } from 'src/app/utilities/models/postponePayment';
import { SecureKeyResponse } from 'src/app/utilities/models/creditCard';
import Billing from 'src/app/utilities/models/billing';
import BillingHistoryItem from 'src/app/utilities/models/billingHistoryItem';
import BillingSchedule from 'src/app/utilities/models/billingSchedule';
import BillingStatements, { BillingPolicies } from 'src/app/utilities/models/billingStatements';
import Notification from 'src/app/utilities/models/notification';

import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { RequestCache } from 'src/app/utilities/interceptors/request-cache.service';

import BillingMethods from 'src/app/utilities/methods/billing.methods';
import Policy from '../../models/policy';
import { PolicyService } from '../policy-service/policy.service';

@Injectable({
  providedIn: 'root'
})

export class BillingService {
  accountOptionsObs: Observable<BillingAccountOptionsResponse> = null;
  billingAccounts: Billing[] = null;
  billingHistoryObs: Observable<BillingHistoryResponse> = null;
  protected billingHistoryUnsubscribe: Subject<void> = new Subject<void>();
  billingHistory: BillingHistoryItem[] = null;
  billingHistoryRq: any;
  billingObs: Observable<BillingResponse> = null;
  billingSchedule: BillingSchedule[] = [];
  billingScheduleObs: Observable<BillingScheduleResponse> = null;
  billingStatements: BillingStatements = null;
  billingStatementsObs: Observable<BillingStatements> = null;
  mapPolicyNumber = '';
  secureKeyObs: Observable<SecureKeyResponse> = null;
  updateAccountOptionsObs: Observable<AccountOptionsPaymentMethod> = null;
  updateAdjustCurrentBillDateObs: Observable<any> = null;

  private httpOptions = {
    withCredentials: true
  };

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private requestCache: RequestCache,
    private location: Location,
    private policyService: PolicyService
  ) {
    this.billingObs = this.http.get<BillingResponse>(Endpoints.api.getBilling, { withCredentials: true }).pipe(share());
    this.secureKeyObs = this.http.get<SecureKeyResponse>(Endpoints.api.getSecureKey, { withCredentials: true }).pipe(share());
  }

  // Name: getBilling
  // Purpose: Sets response and billing accounts from api/billing call to app memory objects
  // Params: none
  getBilling(): Promise<Billing[]> {
    const errorHeapId = 'MMA-View-NotificationSystemError|GetBilling';
    const path = this.location.path();

    if (CAMStorage.getItemInStorage(CAMStorage.storageKeys.hasBillingChanged) &&
      CAMStorage.getItemInStorage(CAMStorage.storageKeys.hasBillingChanged) === 'true') {
      this.requestCache.cacheBust(Endpoints.api.getBilling);
    }

    return new Promise((resolve, reject) => {
      this.billingObs.subscribe({
        next: async (response) => {
          if (response && response.status && response.status.code === 0) {
            this.billingAccounts = response.result;
            this.mapPolicyNumber = this.getAccountPolicyNumberFromBillingAccounts(this.billingAccounts);

            // set policy nickname on billing account list
            const allPolicies = await this.policyService.getAllPolicies();
            BillingMethods.verifiedAndUpdateBillingNicknames(this.billingAccounts, allPolicies.policies);

            CAMStorage.setItemInStorage(CAMStorage.storageKeys.hasBillingChanged, false);
            resolve(this.billingAccounts);
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  getAccountPolicyNumberFromBillingAccounts(billingAccounts: Billing[]): string {
    const activeBillingAccounts: Billing[] = this.filterActiveBillingAccounts(billingAccounts);
    const payableBillingAccounts: Billing[] = this.filterStandardPayBillingAccounts(activeBillingAccounts);
    const accountWithPolicyNumber = payableBillingAccounts.find((account: Billing) => !!account.policyNumber);

    return (accountWithPolicyNumber) ? accountWithPolicyNumber.policyNumber : '';
  }

  buildBillinghistoryRqs() {
    const activeBillingAccounts: Billing[] = this.billingAccounts.filter((account: Billing) => !account.isInactiveOrCanceledAccount);
    const billingHistoryRq = {
      billingHistoryQueries: []
    };

    activeBillingAccounts.forEach((account: Billing) => {
      let accountNumber = account.number;

      if (account.isPayByMortgagee) {
        accountNumber = BillingMethods.getPolicyNumberFromBillingAccount(account);
      }

      billingHistoryRq.billingHistoryQueries.push(
        {
          billingAccountNumber: accountNumber,
          contextGUID: account.contextGUID,
          requestGUID: account.requestGUID
        }
      );
    });

    return billingHistoryRq;
  }

  getBillingHistory(): Promise<BillingHistoryItem[]> {
    let promise: BillingHistoryItem[];

    return new Promise((resolve, reject) => {
      this.billingObs.subscribe({
        next: async (response) => {
          if (response && response.status.code === 0) {
            this.billingAccounts = response.result;
            this.billingHistoryRq = this.buildBillinghistoryRqs();
            promise = await this.postToBillingHistoryService();
            resolve(promise);
          }
        },
        error: (error) => reject({ error: error })
      });
    });
  }

  postToBillingHistoryService(): Promise<BillingHistoryItem[]> {
    const errorHeapId = 'MMA-View-BillingHistorySystemError|GetBilling';
    const path = this.location.path();
    if (this.billingHistoryObs === null) {
      this.billingHistoryObs = this.http.post<BillingHistoryResponse>(Endpoints.api.getBillingHistory, this.billingHistoryRq, this.httpOptions)
        .pipe(share(), takeUntil(this.billingHistoryUnsubscribe));
    }

    return new Promise((resolve, reject) => {
      this.billingHistoryObs.subscribe({
        next: (response) => {
          if (response && response.status.code === 0) {
            this.billingHistory = response.result;
            resolve(this.billingHistory);
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  ngForceUnsubscribe(obs: string): void {
    const unsubscribeFrom: Subject<void> = this[obs + 'Unsubscribe'];
    if (unsubscribeFrom) {
      unsubscribeFrom.next();
      unsubscribeFrom.complete();
    }
  }

  private setAccountOptionsObservable(billingAccounts: Array<Billing>) {
    const accountOptionsReq = {
      billingUserPolicies: [],
      requestGuid: ''
    };

    this.accountOptionsObs = null;

    if (billingAccounts && billingAccounts.length && billingAccounts[0].requestGUID && billingAccounts[0].policyNumber) {
      const billingAccount: Billing = billingAccounts[0];
      const accountOptionsEndpoint: string = Endpoints.api.getAccountOptions(billingAccount.policyNumber);

      accountOptionsReq.requestGuid = billingAccount.requestGUID;
      accountOptionsReq.billingUserPolicies.push({
        policyNumber: billingAccount.policyNumber,
        contextGuid: billingAccount.contextGUID,
        billingAccountNumber: billingAccount.number
      });

      this.accountOptionsObs = this.http.post<BillingAccountOptionsResponse>(accountOptionsEndpoint, accountOptionsReq, this.httpOptions);
    }
  }

  getAccountOptions(billingAccounts: Array<Billing>): Promise<Array<AccountOptionsPaymentMethod>> {
    const errorHeapId = 'MMA-View-NotificationSystemError|GetAccountOptions';
    const path = this.location.path();

    return new Promise((resolve, reject) => {
      this.setAccountOptionsObservable(billingAccounts);

      if (this.accountOptionsObs) {
        this.accountOptionsObs.subscribe({
          next: (response: BillingAccountOptionsResponse) => {
            const hasResponse: boolean = !!(response && response.billingAccountsPaymentMethods && response.billingAccountsPaymentMethods.length);
            const isSuccessful: boolean = hasResponse && response.status && response.status.code !== PAYMENT_STATUS_CODES.systemError;

            if (isSuccessful) {
              const billingAccountOptions: AccountOptionsPaymentMethod[] = response.billingAccountsPaymentMethods;

              resolve(billingAccountOptions);
            } else {
              this.commonService.setServiceFailureAlert(errorHeapId, path);
              this.requestCache.cacheBust(Endpoints.api.getAccountOptions(billingAccounts[0].policyNumber))
              reject();
            }
          },
          error: (error) => {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject({ error: error });
          }
        });
      } else {
        this.commonService.setServiceFailureAlert(errorHeapId, path);
        reject();
      }
    });
  }

  updateAccountOptions(accountOptions: AccountOptionsPaymentMethod): Promise<AccountOptionsPaymentMethod> {
    const errorHeapId = 'MMA-View-NotificationSystemError|UpdateAccountOptions';
    const path = this.location.path();

    this.updateAccountOptionsObs = this.http.post<AccountOptionsPaymentMethod>(Endpoints.api.updateAccountOptions, accountOptions, this.httpOptions);

    return new Promise((resolve, reject) => {
      this.updateAccountOptionsObs.subscribe({
        next: (response) => {
          const updateAccountOptionsRes: AccountOptionsPaymentMethod = response;

          this.requestCache.cacheBust(Endpoints.api.updateAccountOptions);

          if (updateAccountOptionsRes) {
            resolve(updateAccountOptionsRes);
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject();
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  updateAdjustCurrentBillDate(accountOptions: AdjustBill): Promise<AdjustBillResponse> {
    const errorHeapId = 'MMA-View-NotificationSystemError|UpdateAdjustCurrentBillDate';
    const path = this.location.path();

    this.updateAdjustCurrentBillDateObs = this.http.post<AdjustBillResponse>(Endpoints.api.updateAdjustCurrentBillDate, accountOptions, this.httpOptions);

    return new Promise((resolve, reject) => {
      this.updateAdjustCurrentBillDateObs.subscribe({
        next: async (response) => {
          const updateAdjustCurrentBillDateRs: AdjustBillResponse = response;

          if (updateAdjustCurrentBillDateRs) {
            const billingAccount: Billing = this.billingAccounts.find((ba) => ba.policyNumber === accountOptions.baseAdjustPolicyNumber);
            this.requestCache.cacheBust(Endpoints.api.getAccountOptions(billingAccount.policyNumber));
            this.requestCache.cacheBust(Endpoints.api.getBillingSchedule(accountOptions.baseAdjustPolicyNumber));
            await this.getBillingSchedule(accountOptions.baseAdjustPolicyNumber);
            await this.getAccountOptions([billingAccount]);
            resolve(updateAdjustCurrentBillDateRs);
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject();
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });

  }

  filterActiveBillingAccounts(billingAccounts: Billing[]): Billing[] {
    return billingAccounts.filter((billingAccount: Billing) => {
      return (
        billingAccount.accountStatus.toUpperCase() !== BillingAccountStatuses.NOT_FOUND &&
        (!billingAccount.isInactiveOrCanceledAccount || billingAccount.isInactiveOrCanceledAccount && billingAccount.totalBalanceRemainingAmount > 0)
      );
    });
  }

  getBillingSchedule(policyNumber: string): Promise<BillingSchedule[]> {
    const errorHeapId = 'MMA-View-NotificationSystemError|GetBillingSchedule';
    const path = this.location.path();

    const billingSchedRq = {
      policyNumber: policyNumber
    };
    this.billingScheduleObs = this.http.post<BillingScheduleResponse>(Endpoints.api.getBillingSchedule(policyNumber), billingSchedRq, this.httpOptions);

    return new Promise((resolve, reject) => {
      this.billingScheduleObs.subscribe({
        next: (response) => {
          if (response && response.status.code === 0) {
            this.billingSchedule = response.result;
            resolve(this.billingSchedule);
          } else {
            reject();
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  getSecureKey(): Promise<SecureKeyResponse> {
    const errorHeapId = 'MMA-View-NotificationSystemError|GetSecureKey';
    const path = this.location.path();

    this.requestCache.cacheBust(Endpoints.api.getSecureKey);

    return new Promise((resolve, reject) => {
      this.secureKeyObs.subscribe({
        next: (response) => {
          if (response && response.status) {
            resolve(response);
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject();
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  getBillStatements(billingPolicies: BillingPolicies): Promise<BillingStatements> {
    const errorHeapId = 'MMA-View-NotificationSystemError|getBillStatements';
    const path = this.location.path();

    return new Promise((resolve, reject) => {
      this.http.post<BillingStatements>(Endpoints.api.getBillingStatements, billingPolicies, this.httpOptions)
        .subscribe({
          next: (response) => {
            if (response && response.status) {
              this.billingStatements = response;
              resolve(this.billingStatements);
            } else {
              this.commonService.setServiceFailureAlert(errorHeapId, path);
              reject();
            }
          },
          error: (error) => {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject({ error: error });
          }
        });
    });
  }

  filterStandardPayBillingAccounts(billingAccounts: Billing[]): Billing[] {
    return billingAccounts.filter((billingAccount: Billing) => {
      return (
        billingAccount.paymentMethod.toUpperCase() === PaymentMethods.REGULAR ||
        billingAccount.paymentMethod.toUpperCase() === PaymentMethods.AUTOMATIC_DEDUCTION ||
        billingAccount.paymentMethod.toUpperCase() === PaymentMethods.RECURRING_CREDIT_CARD ||
        (billingAccount.isPayByMortgagee && billingAccount.totalBalanceRemainingAmount > 0)
      );
    });
  }

  isPaymentDue(billingAcct?: Billing): boolean {
    let billingAccts: Billing[] = [];

    if (billingAcct) {
      billingAccts.push(billingAcct);
    } else {
      billingAccts = this.billingAccounts;
    }

    billingAccts = billingAccts.filter((acct: Billing) => {
      return (
        acct.accountStatus &&
        acct.accountStatus.toUpperCase() !== BillingAccountStatuses.NOT_FOUND &&
        !acct.isInactiveOrCanceledAccount &&
        !acct.isPayrollDeduction &&
        acct.totalBalanceRemainingAmount > 0 &&
        acct.nextPaymentDate !== null &&
        acct.amountDue > 0
      );
    });

    return billingAccts.length > 0;
  }

  getBillingAccountByPolicyNumber(billingAccounts: Billing[], searchPolicyNumber: string): Billing {
    let billingAccount: Billing = null;

    billingAccount = billingAccounts.find((account: Billing) =>
      account.policyNumber === searchPolicyNumber ||
      (account.policyList && account.policyList.length && account.policyList.some((policy: Policy) => policy.number === searchPolicyNumber))
    );

    return billingAccount;
  }
}

export function mortgageIsBilledToUser(notification: Notification) {
  return notification.messageId === NotificationMessageId.MTP_BILLED_TO_USER;
}

// Purpose: Maps to response from get api/billing call
class BillingResponse {
  result: Billing[];
  status: {
    code: number;
  };
}

class BillingHistoryResponse {
  result: BillingHistoryItem[];
  status: {
    code: number;
  };
}

class BillingScheduleResponse {
  result: BillingSchedule[];
  status: {
    code: number;
  };
}

class BillingAccountOptionsResponse {
  billingAccountsPaymentMethods: Array<AccountOptionsPaymentMethod>;
  requestGuid: string;
  status: {
    additionalDetails: string,
    code: number,
    description: string
  };
}