<div [ngClass]="[isDropUp ? 'dropup' : 'dropdown']" class="actions-menu-component">
  <a class="btn actions-title-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [attr.data-heap-id]="actionsMenuHeapID">
    More actions<app-icon class="" name="more" size="32" color="active"></app-icon>
  </a>
  <ul class="dropdown-menu actions-menu" [ngClass]="{'align-top-left': isDropUp}">
    <li class="actions-menu-item" *ngFor="let item of actionsMenuItems">
      <a class="actions-menu-item-link" (click)="navigateToItemLink(item)" (keyup.enter)="navigateToItemLink(item)" [attr.data-heap-id]="item.heapID" tabindex="0">
        {{ item.label }}
      </a>
    </li>
  </ul>
</div>