export enum HTTP_STATUS_CODES {
    ok = 200,
    multiStatus = 207,
    iMUsed = 226,
    badRequest = 400,
    unauthorized = 401,
    forbidden = 403,
    notFound = 404,
    internalServerError = 500,
    serviceUnavailable = 503
  }
  