export const PolicyAPICodes: { success: number, mainframeIssue: number, serverError: number } = {
    success: 0,
    mainframeIssue: 100,
    serverError: 500
};

export const VINAPICodes: {success: number, mainframeIssue: number, invalidVin: number, serverError: number, timedOut: number} = {
    success: 0,
    mainframeIssue: 100,
    invalidVin: 400,
    serverError: 500,
    timedOut: 504
}