import { Component, OnInit } from '@angular/core';
import { AuthUserService } from 'src/app/authentication/services/auth-user/auth-user.service';
import { RoutingService } from 'src/app/utilities/services/routing-service/routing.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(
    private authUserService: AuthUserService,
    private routingService: RoutingService
  ) { }

  ngOnInit(): void {
    let routeTo = '/homepage'; // default route
    let params = {};

    try {
      let deepLink = window.localStorage.getItem('deepLink');

      if (deepLink) {
        // if a deep link exists from login, handle the code/state params
        this.authUserService.handleRedirectCallback();
        
        if (deepLink !== '/') { // check deep link isn't app root
          routeTo = deepLink;
        }
        
        let deepLinkParamsString = window.localStorage.getItem('deepLinkParams');
        
        if (deepLinkParamsString)
          params = JSON.parse('{"' + deepLinkParamsString.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key === "" ? value:decodeURIComponent(value) });
        
        window.localStorage.removeItem('deepLink');
        window.localStorage.removeItem('deepLinkParams');
      }
    } catch(err) {
      // continue regardless of error
    } finally {
      this.routingService.routeTo(routeTo, {queryParams : params});
    }
  }

}
