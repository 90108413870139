<label id="{{id}}-label" class="input-component sco-label sco-label-textInput" for="{{id}}"
       [ngClass]="{'is-focused':focus, 'is-populated':populated, 'is-error':error, 'is-disabled': isDisabled !== null, 'is-helpText': blnhelp || blnHelpMulti}">
  <span class="sco-label-visual" aria-hidden="true">{{label}}</span>
  <span class="sco-label-a11y">{{label}}</span>
  <input type="{{type}}" id="{{id}}Input" name="{{name}}" [attr.aria-label]="name" (input)="onChange($event.target.value)" (blur)="onBlur()"
         class="sco-input sco-input-textInput" (focus)="focus=true;onFocus();" (focusout)="focus=false"
         [attr.maxlength]="maxLength" [(ngModel)]="value" [attr.data-value]="ADAValue" mask='{{maskPattern}}' [attr.disabled]="isDisabled" tabindex="0"
         role="textbox" (keypress)="onKeyPress($event)" step="any" [attr.aria-required]="fieldValidation && fieldValidation.required" />
  <div class="sco-messageGroup" *ngIf="error">
    <div id="text-error" class="text-error">
      <span class="sco-message sco-message--error">{{validationMsg}}</span>
    </div>
  </div>
  <div class="sco-messageGroup">
    <div id="text-help" class="text-help" *ngIf="!blnHelpMulti && blnhelp">
      <span class="sco-message" [ngClass]="helpTextStyle + '-style'">
        <app-icon *ngIf="helpTextStyle" class="stylized-help-icon" [name]="helpTextStyle" [color]="'active'" size="16"></app-icon>
        <span>{{helpText}}</span>
      </span>
    </div>
  </div>
  <div id="multilineHelp" class="multilineHelp" *ngIf="blnHelpMulti && !blnhelp">
    <ul class="sco-list sco-list--unordered">
      <li *ngFor="let text of helpTextMulti" class="sco-list-item">{{text}}</li>
    </ul>
  </div>
  <div class="optional" *ngIf="showOptional && !(fieldValidation && fieldValidation.required)">
    <span class="sco-message">Optional</span>
  </div>
</label>
