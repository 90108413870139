<span class="search-pipe" [ngClass]="!isSearchEnabled ? 'hide' : ''">|</span>
<div [ngClass]="!isSearchEnabled ? 'hide' : ''" id="content-container" class="search-component">
  <form class="search-form" (ngSubmit)="onSearchClick()" role="search">
    <mat-form-field class="search-full-width">
      <input id="search-input"
          type="text"
          placeholder="What would you like to do?"
          aria-label="Search"
          aria-describedby="search-autocomplete-results"
          aria-autocomplete="list"
          aria-invalid="false"
          aria-required="false"
          matInput
          (input)="onChange($event.target.value)"
          [formControl]="searchControl"
          [matAutocomplete]="auto"
          /> 
      
      <mat-autocomplete id="search-autocomplete-results" #auto="matAutocomplete"> 
        <!-- <mat-option class="search-results" *ngFor="let result of pageResults | async">
            <a [routerLink]='result.pageUrl' (click)='sendToSlack(input, results.pageResults.length + results.faqResults.length, result.title)'>{{result.title}}</a>
        </mat-option> -->
        <div *ngIf="faqResults | async as faq" class="">FAQ</div>
        <mat-option class="faq-results" *ngFor="let faq of faqResults | async" [value]="faq.keyParam">
          <a (click)="resetSearch()" [routerLink]="faqPath" [queryParams]="{open: faq.keyParam}" >{{faq.title}}</a>
          <!-- (click)='sendToSlack(input, results.pageResults.length + results.faqResults.length, faq.title)' -->
        </mat-option>
      </mat-autocomplete>
      <button class="search-button" type="submit" aria-label="Submit search">
        <app-icon class="" [name]="'search'" [size]="16"></app-icon>
      </button>
    </mat-form-field>
  </form>
</div>
