import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { RequestCache } from 'src/app/utilities/interceptors/request-cache.service';
import EmailResponse, { EmailFormData, EmailRequest, EmailTemplate } from '../../models/email';
import Endpoints from 'src/app/utilities/constants/endpoints';
import { AuthUser } from '../../models/authUser';
import { EmailTemplatId } from '../../constants/email';
import { AccountService } from '../account-service/account.service';


@Injectable({
  providedIn: 'root'
})
export class EmailService {
  emailObs: Observable<EmailServiceResponse> = null;
  customerEmailObs: Observable<CustomerEmailResponse> = null;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    }),
    withCredentials: true
  };

  httpOptionsSimple = {
    withCredentials: true
  };

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private location: Location,
    private requestCache: RequestCache,
    private accountService: AccountService
  ) { }

  async sendCustomerEmail(templateId: string, email: string, lob?: string): Promise<boolean | void> {
    const errorHeapId = 'MMA-View-NotificationSystemError|SendCustomerEmail';
    const path = this.location.path();
    const emailTemplate = await this.buildEmailTemplate(templateId, email, lob);

    const isEmailCustomerLaunched: boolean = this.commonService.features?.email_customer_enabled;
    const endpoint: string = isEmailCustomerLaunched ? Endpoints.cloudApi.postCustomerEmail : Endpoints.api.sendCustomerEmail;
    this.requestCache.cacheBust(endpoint); // endpoint supports attachments

    this.customerEmailObs = this.http.post<CustomerEmailResponse>(endpoint, emailTemplate, this.httpOptionsSimple);

    return new Promise((resolve, reject) => {
      this.customerEmailObs.subscribe({
        next: (response) => {

          if (!isEmailCustomerLaunched) {
            if (response && response.result && response.status.code === 0) {
              resolve(response.result === "Email sent");
            } else {
              this.commonService.setServiceFailureAlert(errorHeapId, path);
              reject({ error: 'Non-zero status code' });
            }
          } else {
            resolve();
          }          
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  private async buildEmailTemplate(templateId: string, email: string, lob: string): Promise<EmailTemplate> {
    const emailTemplate = new EmailTemplate(email);
    emailTemplate.templateId = templateId;
    let currentUser: AuthUser = this.accountService.getAuthUser();

    switch(emailTemplate.templateId) {
      case EmailTemplatId.CHANGE_EMAIL_NOTIFY:
        emailTemplate.emailFormData.push(new EmailFormData('LOB', (lob) ? lob.toLocaleLowerCase() : 'lineofbusiness'));
        emailTemplate.emailFormData.push(new EmailFormData('firstName', (currentUser.firstName) ? currentUser.firstName : 'Valued customer'));
        break;
      case EmailTemplatId.CREATE_PW:
        // Set email template with all the info for create password email
        // Password link will be set in the EmailSenderController

        break;
      case EmailTemplatId.ACCOUNT_EXISTS_NOTIFY:
        // set email template with info needed for account already exists email
        break;
      default:
        break;
    }

    return emailTemplate;
  }

  sendBasicEmail(email: EmailTemplate): Promise<EmailResponse|void> {
    const errorHeapId = 'MMA-View-NotificationSystemError|SendBasicEmail';
    const path = this.location.path();
    const isEmailBasicLaunched: boolean = this.commonService.features?.camApiEmailBasicLaunched;
    const endpoint: string = isEmailBasicLaunched ? Endpoints.api.postBasicEmail : Endpoints.api.sendEmailBasic;

    this.requestCache.cacheBust(endpoint); // endpoint supports attachments
  
    this.emailObs = this.http.post<EmailServiceResponse>(endpoint, email, this.httpOptionsSimple);
    return new Promise((resolve, reject) => {
      this.emailObs.subscribe({
        next: (response: EmailServiceResponse) => {

          if (!isEmailBasicLaunched) {
            if (response && response.result && response.status.code === 202) {
              const email: EmailResponse = response.result;
              resolve(email);
            } else {
              this.commonService.setServiceFailureAlert(errorHeapId, path);
              reject({ error: 'Non-zero status code' });
            }
          } else {
            resolve();
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

}

class EmailServiceResponse {
  result: EmailResponse;
  status: {
    code: number;
  };
}

class CustomerEmailResponse {
  result: string;
  status: {
    code: number;
  };
}
