import { User } from "@auth0/auth0-angular";

export class AuthUser {
    email: string;
    isEmailVerified: boolean;    
    firstName: string;
    lastName: string;
    phoneNumber: string;
    policyNumbers: string[];
    masterPartyId: string;
    name: string;
    nickname: string;
    pictureUrl: string;
    sub: string;
    userId: string;
    lastUpdatedDate: string; 

    constructor(user: User) {
        if (user) {
            this.email = user.email;
            this.isEmailVerified = user.email_verified;
            this.firstName = user.given_name;
            this.lastName = user.family_name;
            this.name = user.name;
            this.phoneNumber = user["https://safeco.com/phone_number"] ? user["https://safeco.com/phone_number"] : '';
            this.policyNumbers = user["https://safeco.com/policies"] ? user["https://safeco.com/policies"] : [];
            this.masterPartyId = user["https://safeco.com/mp"] ? user["https://safeco.com/mp"] : '';
            this.nickname = user.nickname;
            this.pictureUrl = user.picture;
            this.sub = user.sub;
            this.userId = user.sub.slice(6);
            this.lastUpdatedDate = user.updated_at;
        }
    }
}