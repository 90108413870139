<div *ngIf="!dataLoaded" class="agent-title">Agent information will appear here momentarily...</div>
<div *ngIf="dataLoaded && primaryAgency">
  <div class="agent-title" *ngIf="!hideAgentTitle">
    <div *ngIf="useYourAgentTitle; else defaultTitle">Your agent</div>
    <ng-template #defaultTitle>
      <div>My agent</div>
    </ng-template>
  </div>
  <div class="agency-name">{{primaryAgency.agencyName | customTitleCase}}</div>
  <div class="contact-method">
    <span *ngIf="isGoldAgency" class="phone" itemprop="telephone" [ngClass]="{'sms-display' : displaySMS}">
      <span *ngIf="displaySMS" class="call-label agent-desktop">Call</span>
      <a class="agent-link" href="tel:+{{ goldAgencyPhoneNumber | phoneNumber }}">
        <app-icon name="phone" [ngClass]="{'agent-mobile' : displaySMS}"></app-icon>
        <span class="agent-mobile">Call</span>
        <span class="agent-desktop">{{ goldAgencyPhoneNumber | phoneNumber }}</span>
      </a>
    </span>
    <span *ngIf="isGoldPlusAgency" class="phone" itemprop="telephone">
      <span *ngIf="displaySMS" class="agent-desktop">Call</span>
      <a class="agent-link" href="tel:+{{ goldPlusAgencyPhoneNumber | phoneNumber }}">
        <app-icon name="phone" [ngClass]="{'agent-mobile' : displaySMS}"></app-icon><span
          class="agent-mobile">Call</span><span
          class="agent-desktop">{{ goldPlusAgencyPhoneNumber | phoneNumber }}</span>
      </a>
    </span>
    <span *ngIf="primaryAgency.phoneNumber && !(isGoldPlusAgency || isGoldAgency)" class="phone" itemprop="telephone">
      <a class="agent-link" href="tel:+{{ primaryAgency.phoneNumber | phoneNumber }}">
        <app-icon name="phone"></app-icon><span class="agent-mobile">Call</span><span
          class="agent-desktop">{{ primaryAgency.phoneNumber | phoneNumber }}</span>
      </a>
    </span>
    <span *ngIf="!(isGoldAgency || isGoldPlusAgency) && primaryAgency.emailAddress" class="email" itemprop="email">
      <a class="agent-link" href="mailto:{{ primaryAgency.emailAddress }}">
        <app-icon name="email"></app-icon><span class="agent-mobile">Email</span><span
          class="agent-desktop">Email</span>
      </a>
    </span>
    <span *ngIf="displaySMS" class="sms">
      <div *ngIf="isMobileDevice" class="sms-display">
        <span class="text-label agent-desktop">Text</span>
        <a *ngIf="isMobileDevice" id="AgentCard_TextGold" class="agent-link" href="{{ goldAgencySMSLink }}">
          <app-icon name="tablet" class="agent-mobile"></app-icon>
          <span class="agent-mobile">Text</span>
          <span class="agent-desktop">{{ goldAgencySMSNumber | phoneNumber }}</span>
        </a>
      </div>
      <div *ngIf="!isMobileDevice" class="sms-display">
        <span class="text-label agent-desktop">Text</span>
        <app-icon name="tablet" class="agent-mobile"></app-icon>
        <span>{{ goldAgencySMSNumber | phoneNumber }}</span>
      </div>
    </span>
  </div>
</div>