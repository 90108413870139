import { AfterViewInit, ElementRef, ViewChild, Component, Input, HostListener } from '@angular/core';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { ModalService } from 'src/app/utilities/services/modal-service/modal.service';
import { MileageEstimatorTableData } from 'src/app/utilities/constants/policyChange';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements AfterViewInit {
  @Input() direction: string = 'top'; // 'top' or 'bottom'
  @Input() id: string;
  @Input() label?: string;
  @Input() name: string;
  @Input() iconName?: string = 'info';
  @Input() overlayWidth: number = 200;
  @Input() shiftLeft?: number;
  @Input() shiftRight?: number;
  @Input() text: string;

  containerWidth: number;
  isBrowserIE: boolean = false;
  isMobileDevice: boolean = false;
  initialContainerWidth: number;
  initialShiftLeft: number;
  initialShiftRight: number;
  initialOverlayWidth: number;
  isTooltipActive: boolean = false;
  MAX_CONTAINER_WIDTH: number = 490;
  mileageEstimatorTableData = MileageEstimatorTableData;
  tooltipTextWidth: number;
  windowWidth: number;
  resizeTooltip: any;

  @ViewChild('sharedTooltip', { static: true })
  sharedTooltip: ElementRef;

  @ViewChild('tooltipText', { static: true })
  tooltipText: ElementRef;

  constructor(
    private commonService: CommonService,
    private modalService: ModalService
  ) { }

  ngAfterViewInit() {
    this.initialOverlayWidth = this.overlayWidth;
    this.initialContainerWidth = this.sharedTooltip.nativeElement.offsetWidth;
    this.tooltipTextWidth = this.tooltipText.nativeElement.offsetWidth;
    this.checkBrowser();
    this.checkMobile();

    this.initialShiftLeft = this.shiftLeft;
    this.initialShiftRight = this.shiftRight;

    this.resizeTooltipForIE();

    if (this.isMobileDevice) {
      this.resizeOverlay();
    }
  }

  /* Resize tooltip overlay when window width is smaller than the full container width on event end*/
  @HostListener('window:resize', ['$event'])
  onResize($event) {
    clearTimeout(this.resizeTooltip);
    this.resizeTooltip = setTimeout(() => {
      this.windowWidth = $event.target.innerWidth;

      if (this.windowWidth < this.MAX_CONTAINER_WIDTH) {
        this.resizeOverlay();
      } else {
        this.restoreOriginalOverlay();
      }
    }, 500);
  }

  checkMobile() {
    if (this.commonService.isMobileDevice()) {
      this.isMobileDevice = true;
    }
  }

  resizeOverlay() {
    this.containerWidth = this.sharedTooltip.nativeElement.offsetWidth;
    this.overlayWidth = this.containerWidth;
    this.shiftRight = 0;
    this.shiftLeft = 0;

    if (this.isBrowserIE) {
      this.shiftRight = -(this.tooltipTextWidth);
    }
  }

  resizeTooltipForIE() {
    if (this.isBrowserIE) {
      this.shiftLeft = 0;
      this.shiftRight = 0;
      this.shiftRight = this.initialShiftRight - this.tooltipTextWidth;
    }
  }

  restoreOriginalOverlay() {
    this.overlayWidth = this.initialOverlayWidth;
    this.shiftRight = this.initialShiftRight;
    this.shiftLeft = this.initialShiftLeft;
    this.resizeTooltipForIE();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick() {
    this.isTooltipActive = false;
  }

  checkBrowser() {
    const browser = this.commonService.getUserAgent();
    if (browser === "TRIDENT" || browser === "MSIE") {
      this.isBrowserIE = true;
    }
  }

  toggleTooltip($event) {
    $event.stopPropagation();
    this.isTooltipActive = !this.isTooltipActive;
  }

  onTab($event) {
    $event.stopPropagation();
  }

  contactAgent(action: boolean): void {
    this.modalService.contactAgent(action);
  }
}
