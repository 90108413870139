import { Component, OnInit, Input } from '@angular/core';
import { Cookies } from 'src/app/utilities/services/cookies-service/cookies.service';
import { HeaderTypes } from 'src/app/utilities/constants/statusIndicator';
import Policy from 'src/app/utilities/models/policy';
import PolicyMethods from 'src/app/utilities/methods/policy.methods';
import { IndicatorPages } from 'src/app/utilities/constants/statusIndicator';
import { Features } from 'src/app/utilities/models/features';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import BillingMethods from 'src/app/utilities/methods/billing.methods';
import CAMStorage from 'src/app/utilities/constants/CAMStorage';
import { LOB } from 'src/app/utilities/constants/linesOfBusiness';
import PolicyChangeMethods from 'src/app/utilities/methods/policyChange.methods';


@Component({
  selector: 'app-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss']
})
export class StatusIndicatorComponent implements OnInit {
  @Input() policy: Policy;
  @Input() indicatorPage: string;
  @Input() isPolicyDocumentRenewal?: boolean = false;
  @Input() renewalIdCardsAvailable?: boolean = false;

  features: Features;
  headerType = '';
  headerTypes = HeaderTypes;
  pendingVehicles: any[]; 
  pendingLandlords: any[];
  showEndorsementStatus: boolean = false;
  showRenewalFlag: boolean = false;
  isDocCenter: boolean = false;

  constructor(
    private commonService: CommonService,
    private cookies: Cookies
  ) { }

  ngOnInit() {
    this.initializeStatusIndicator();
  }

  ngOnChanges() {
    this.initializeStatusIndicator();
  }

  async initializeStatusIndicator() {
    this.features = await this.commonService.getFeatures();
    this.isDocCenter = (this.indicatorPage === IndicatorPages.DOC_CENTER_ID_CARD || this.indicatorPage === IndicatorPages.DOC_CENTER_RENEWAL_DOC);
    this.setHeaderDisplay();
    this.initializePendingEndorsementStatus();
  }

  initializePendingEndorsementStatus(): void {
    switch(this.policy.lineOfBusiness) {
      case LOB.Home:
        this.getPolicyPendingLandlordEndorsements();
        break;
      case LOB.Auto:
        this.getPolicyPendingVehicleEndorsements();
        break;
      default:
        break;
    }
  }

  getPolicyPendingVehicleEndorsements(): void {
    const allPendingVehicles = CAMStorage.getItemInStorage(CAMStorage.storageKeys.pendingVehicles, true);

    if (allPendingVehicles && allPendingVehicles.length) {
      this.pendingVehicles = allPendingVehicles.filter((pending) => this.policy.number === pending.policyNumber);
    }
  }

  getPolicyPendingLandlordEndorsements(): void {
    if (this.policy.isRenter) {
      const allPendingLandlords = CAMStorage.getItemInStorage(CAMStorage.storageKeys.pendingLandlords, true);

      if (allPendingLandlords && allPendingLandlords.length) {
        this.pendingLandlords = allPendingLandlords.filter((pending) => this.policy.number === pending.policyNumber);
      }
    } 
  }

  setHeaderDisplay() {
    this.showEndorsementStatus = false;
    this.showRenewalFlag = false;

    if (this.policy) {
      //Endorsement status takes priority over renewal flag
      if (this.policy.isRecentEndorsement || PolicyChangeMethods.hasPendingEndorsement(this.policy)) {
         this.showEndorsementStatus = true;
      } else if (this.isRenewal(this.policy)) {
        this.showRenewalFlag = true;
      }

      // Show both treatments on docs hub
      if (this.isDocCenter && this.showEndorsementStatus && this.isRenewal(this.policy)) {
        this.showRenewalFlag = true;
      }
    }
  }

  isRenewal(policy: Policy): boolean {
    const renewalCookie = this.cookies.getRenewalCookie();
    let isRenewal = false;
    let isDocRenewal = false;
    let isPolicyRenewal = false;

    if (this.isDocCenter) {
      if (this.renewalIdCardsAvailable || this.isPolicyDocumentRenewal) {
        isDocRenewal = true
      }
    } else if (BillingMethods.isLOBRenewalBillingEligible(policy.lineOfBusiness)) {
      if (PolicyMethods.isRenewalView(policy) || (renewalCookie && policy.isMarketingRenewal)) {
        isPolicyRenewal = true;
      }
    }

    isRenewal = isDocRenewal || isPolicyRenewal;

    return isRenewal;
  }
}
