import { Component, OnInit, Input } from '@angular/core';
import Policy from 'src/app/utilities/models/policy';
import DateMethods from 'src/app/utilities/methods/date.methods';
import { IndicatorPages } from 'src/app/utilities/constants/statusIndicator';
import { Features } from 'src/app/utilities/models/features';
import { LOB } from 'src/app/utilities/constants/linesOfBusiness';
import PolicyChangeMethods from 'src/app/utilities/methods/policyChange.methods';

@Component({
  selector: 'app-endorsement-status',
  templateUrl: './endorsement-status.component.html',
  styleUrls: ['./endorsement-status.component.scss']
})
export class EndorsementStatusComponent implements OnInit {
  @Input() policy: Policy;
  @Input() type?: string;
  @Input() pendingVehicles?: any;
  @Input() pendingLandlords?: any;

  showProcessedEndorsement: boolean = false; 
  showPendingEndorsement: boolean = false;

  constructor() { }

  lastProcessedEndorsementDate: Date;

  ngOnInit() {
    this.initializeEndorsementStatus();
  }

  ngOnChanges() {
    this.initializeEndorsementStatus();
  }

  initializeEndorsementStatus() {
    this.showPendingEndorsement = false; 
    this.showProcessedEndorsement = false; 


    if (this.policy) {
      if (PolicyChangeMethods.hasPendingEndorsement(this.policy)) {
        this.showPendingEndorsement = true; 
      } else if (this.policy.lastProcessedEndorsementDate) {
        const lastProcessedEndorsementDate = new Date(this.policy.lastProcessedEndorsementDate);

        if (lastProcessedEndorsementDate && !DateMethods.isDateMinValue(lastProcessedEndorsementDate)) {
          this.lastProcessedEndorsementDate = lastProcessedEndorsementDate;
          this.showProcessedEndorsement = true;
        }
      }
    }
  }

  buildPendingEndorsementForDisplay(): string {
    let pendingEndorsement = "";

    switch (this.policy.lineOfBusiness) {
      case LOB.Home:
        pendingEndorsement = this.buildPendingLandlordDisplay();
        break;
      case LOB.Auto:
        pendingEndorsement = this.buildPendingVehiclesDisplay();  
        break;
      default:
        break;
    }

    return pendingEndorsement;
  }

  buildPendingVehiclesDisplay(): string {
    let pendingDisplay = "Update in progress: ";

    if (this.pendingVehicles && this.pendingVehicles.length) {

      for (let i = 0; i < this.pendingVehicles.length; i++) {
        const vehicle = this.pendingVehicles[i];
        pendingDisplay += `${vehicle.year} ${vehicle.make} ${vehicle.model.toUpperCase()}`;

        if (i < this.pendingVehicles.length - 1) {
          pendingDisplay += ", ";
        }
      } 
    }

    return pendingDisplay;
  }

  buildPendingLandlordDisplay(): string {
    let pendingLandlordDisplay = " update in progress: ";

    if (this.pendingLandlords && this.pendingLandlords.length) {
      pendingLandlordDisplay = `${this.pendingLandlords[0].changeType} ` + pendingLandlordDisplay   

      this.pendingLandlords.forEach((landlords,index,array) => {
        if (index === array.length - 1) {
          pendingLandlordDisplay += `${landlords.landlord}`;
        } else {
          pendingLandlordDisplay += `${landlords.landlord}, `;
        }
      });
    }

    return pendingLandlordDisplay;
  }
}
