import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppOverlayContainer extends OverlayContainer {

  _createContainer(): void {
    const container = document.createElement('div');
    container.classList.add('app-overlay-container');

    document.querySelector('#content-container').appendChild(container);
    this._containerElement = container;
  }
}
