export const crossSellDisclaimer = { 
    HOME_DEFAULT: 'Savings based on an internal analysis of the weighted average discount Safeco monoline auto customers received when adding a Safeco homeowner’s policy during January 2018 to January 2019. Savings assumes fully covered auto with Safeco when adding a homeowner’s policy. For the purposes of the savings analysis, “fully covered” is defined as at least one vehicle in a household that carries bodily injury and/or comprehensive and collision coverage. Discount and actual savings may vary by state, circumstance and coverage level. Savings does not apply in all states.',
    AUTO_DEFAULT: 'Savings based on an internal analysis of the weighted average discount Safeco home customers received when adding a Safeco auto policy during January 2018 to January 2019. Discount and actual savings may vary by state, circumstance and coverage level.  Savings does not apply in all states.',
    GENERIC_DEFAULT: 'Insurance is offered by Safeco Insurance Company of America and/or its affiliates, with a principal place of business at 175 Berkeley Street, Boston, MA 02116. © 2024 Liberty Mutual Insurance',
    GOLD_PHONE: '877-782-0455',
    GOLD_PLUS_PHONE: '800-578-6701'
};

export const crossSellTileHeader = {
    AUTO: (offerSavings: number) => `You could put $${offerSavings} back in your pocket`,
    EARTHQUAKE: 'Be unshakable',
    HOME: (offerSavings: number) => `You could put $${offerSavings} back in your pocket`,
    MOTORCYCLE: 'Ride more. Pay less.',
    RENTERS: 'Insure a lot for just a little',
    UMBRELLA: 'Put your pocket change to good use',
    WATERCRAFT: 'Coverage that won\'t dry out your wallet'
};

export const crossSellTileBody = {
    AUTO: 'People like you are paying less for auto insurance.',
    EARTHQUAKE: 'Earthquake coverage helps you stay on solid ground.',
    HOME: 'People like you are paying less for home insurance.',
    MOTORCYCLE: 'Safeco motorcycle coverage helps keep you on the road.',
    RENTERS: 'Safeco renters coverage helps protect your stuff.',
    UMBRELLA: 'Umbrella coverage starts at just $1 a day.',
    WATERCRAFT: 'Safeco watercraft insurance helps keep you afloat.'
};

export const crossSellModalHeader = {
    AUTO: `Let's talk savings`,
    EARTHQUAKE: `Let's talk earthquake insurance`,
    HOME: `Let's talk savings`,
    MOTORCYCLE: `Let's talk savings`,
    RENTERS: `Let's talk savings`,
    UMBRELLA: `Let's talk umbrella insurance`,
    WATERCRAFT: `Let's talk savings`
};

export const crossSellModalBody = {
    AUTO: (sourcePolicyLob: string, offerLob: string, offerSavings: number) => `You already have Safeco ${sourcePolicyLob} coverage. Adding ${offerLob} could save you $${offerSavings}.`,
    EARTHQUAKE: 'An earthquake could really knock you off your feet. Get covered with Safeco.',
    HOME: (sourcePolicyLob: string, offerLob: string, offerSavings: number) => `You already have Safeco ${sourcePolicyLob} coverage. Adding ${offerLob} could save you $${offerSavings}.`,
    MOTORCYCLE: 'You already have Safeco coverage. Add motorcycle and ride off with a multi-policy discount.',
    RENTERS: 'You already have Safeco coverage. Adding renters costs just a few dollars a month more.',
    UMBRELLA: 'A bigger safety net in an uncertain world is a lot more affordable than you might think — starting at just $1 a day.',
    WATERCRAFT: 'You already have Safeco coverage. Add watercraft and sail off with a multi-policy discount.'
};
