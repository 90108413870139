@for (alert of alerts; track alert) 
{
<div class="app-alerts {{alert.type}}-type" [attr.data-heap-id]="alert.heapId" role="alert">
  <lmds-notification
    alert="{{alert.noTitleCase ? (alert.title) : (alert.title | titlecase )}}"
    highlightType="{{alert.highlightType}}"
    [inline]="alert.inline"
    [isDismissible]="alert.dismissable"
  >
    <span class="message-text">
      @for (text of alert.messages; track text) 
      {
      <span>
        <app-link-paragraph [text]="text"></app-link-paragraph>
      </span>
      }
    </span>
  </lmds-notification>
</div>
}