import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';

import { environment } from 'src/environments/environment';

import { crossSellTileHeader, crossSellTileBody } from 'src/app/utilities/constants/crossSellDisclaimer';
import { CrossSellLobId, isVehicularLOB } from 'src/app/utilities/constants/linesOfBusiness';
import Endpoints from 'src/app/utilities/constants/endpoints';

import { WebAnalytics } from 'src/app/utilities/models/webAnalytics';
import Agency from 'src/app/utilities/models/agency';
import CrossSellOffer from 'src/app/utilities/models/crossSellOffer';
import Policy from 'src/app/utilities/models/policy';
import PolicyDocument, { DocumentPolicies } from 'src/app/utilities/models/policyDocument';
import PolicyMethods from 'src/app/utilities/methods/policy.methods';

import { AnalyticsService } from 'src/app/utilities/services/analytics-service/analytics.service';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { Cookies } from 'src/app/utilities/services/cookies-service/cookies.service';
import { DiscountsService } from 'src/app/utilities/services/discounts-service/discounts.service';
import { ModalService } from 'src/app/utilities/services/modal-service/modal.service'
import { PolicyService } from 'src/app/utilities/services/policy-service/policy.service';
import { RoutingService } from 'src/app/utilities/services/routing-service/routing.service';
import DocumentMeta from 'src/app/utilities/models/documentMeta';
import { DocumentService } from 'src/app/utilities/services/document-service/document.service';
import DocumentMethods from 'src/app/utilities/methods/document.methods';
import { Features } from 'src/app/utilities/models/features';
import BillingMethods from 'src/app/utilities/methods/billing.methods';
import { ModalNames } from 'src/app/utilities/constants/modalNames';
import OverrideExperience from 'src/app/utilities/models/overrideExperience';
import CAMStorage from 'src/app/utilities/constants/CAMStorage';

export enum InfoCardType {
  autopaySignup = 'autopaySignup',
  billingSms = 'billingSms',
  crossSell = 'crossSell',
  customerAppreciation = 'customerAppreciation',
  mobileAppPromotion = 'mobileAppPromotion',
  multiPolicyDiscount = 'multiPolicyDiscount',
  newBusiness = 'newBusiness',
  paperlessBilling = 'paperlessBilling',
  policyError = 'policyError',
  policyLoading = 'policyLoading',
  renewal = 'renewal',
  renewalDocLoading = 'renewalDocLoading',
  renewalError = 'renewalError'
}

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent implements OnInit {
  @Input() infoCardName: InfoCardType[] = [];
  @Output() setDisplayOverlaySpinner = new EventEmitter<boolean>();

  imgLoc = environment.imgLoc;
  infoCardType = InfoCardType;
  url = Endpoints.url;

  primaryAgency: Agency = null;
  infoCardDisplayed: string = '';
  offerLoaded = false;
  policyLOB: string = '';
  renewalStartDate: string = '';
  multiplePolicies: boolean = false;
  webAnalytics: WebAnalytics = {
    singleMultiRenewal: 'None'
  };

  errorRetrievingPolicy: boolean = false;
  renewalPolicyUrl: string = '';
  renewalCookie: string = null;
  renewalMeta: DocumentMeta[] = [];
  features: Features;
  renewalPolicyDocuments: PolicyDocument = null;
  recentRenewalDocument: DocumentPolicies = null;
  override: OverrideExperience;

  constructor(
    protected policyService: PolicyService,
    protected commonService: CommonService,
    protected documentService: DocumentService,
    protected modalService: ModalService,
    protected routingService: RoutingService,
    protected location: Location,
    protected cookies: Cookies,
    protected analyticsService: AnalyticsService,
    protected discountsService: DiscountsService,
  ) {
    this.override = new OverrideExperience();
  }

  ngOnInit() {
    this.setInfoCard(this.infoCardName[0]);
  }

  setInfoCard(infoCardName: string) {
    this.infoCardDisplayed = this.override.infoCard || infoCardName;
  }
}

@Component({
  selector: 'info-card-renewal',
  templateUrl: './templates/renewal/renewal-swoosh.component.html',
  styleUrls: ['./templates/renewal/renewal-swoosh.component.scss']
})
export class InfoCardRenewalComponent extends InfoCardComponent implements OnInit {

  ngOnInit() {
    this.renewalCookie = this.cookies.getRenewalCookie();
    this.initializeInfoRenewalCard();
  }

  async initializeInfoRenewalCard() {
    try {
      this.features = await this.commonService.getFeatures();

      if (this.renewalCookie) {
        // original method returned pre-renewal policies at T-60
        const allMarketingRenewalPolicies = await this.policyService.getMarketingRenewalPolicies();
        const srcPolicies: Policy[] = PolicyMethods.getMarketingPreRenewalPolicies(allMarketingRenewalPolicies);
        await this.processRenewalCard(srcPolicies);
      } else { // if no renewal cookie, proceed with checking 30 days
        const allRenewalPolicies = await this.policyService.getRenewalPolicies();
        const renewalPolicies: Policy[] = PolicyMethods.getPreRenewalPolicies(allRenewalPolicies);
        await this.processRenewalCard(renewalPolicies);
      }
    } catch (err) {
      // continue regardless of error
    }
  }

  async processRenewalCard(policies: Policy[]) {
    try {
      const allPolicies: Policy[] = await this.policyService.getPolicies();

      this.getRenewalPolicyDetails(policies);  
      this.renewalMeta = await this.documentService.getPolicyDocuments(allPolicies);
      this.renewalMeta = this.renewalMeta.filter((meta) => BillingMethods.isSamePolicyNumbers(meta.primaryKey, policies[0].number) && DocumentMethods.isRenewalDocument(meta));

      if (this.renewalMeta.length) {
        this.trackRenewalAnalytics(policies);
        this.analyticsService.trackAnalytics('LP_infoTile_onLoad_renewal');
      } else {
        this.setInfoCard(this.infoCardType['renewalError']);
        this.analyticsService.trackAnalytics('MMA-View-NotificationIssue-LP-RenewalDocs');
      }
    } catch (err) {
      // continue regardless of error
    }
  }

  getRenewalPolicyDetails(policies: Policy[]) {
    if (policies.length > 1) {
      this.multiplePolicies = true;
    } else {
      this.policyLOB = policies[0].policyType.toLowerCase();
      this.setRenewalDate(policies[0]);
    }
  }

  // Sets the renewal date to be displayed
  setRenewalDate(policy: Policy) {
    if (this.renewalCookie && policy.marketingRenewalEffectiveDate) {
      this.renewalStartDate = this.convertDate(policy.marketingRenewalEffectiveDate);
    } else {
      if (policy.renewalEffectiveDate) {
        this.renewalStartDate = this.convertDate(policy.renewalEffectiveDate);
      }
    }
  }

  convertDate(renewalDate: string, forDataLayer?: boolean): string {
    const date = new Date(renewalDate);
    if (forDataLayer) {
      return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }); // mm/dd/yyyy format
    } else {
      return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    }
  }

  trackRenewalAnalytics(policies: Policy[]) {
    let heapId: string;

    if (policies.length > 1) {
      heapId = 'MMA-VIEW-MULTIPOLICY|RENEWAL';
    } else {
      heapId = 'MMA-VIEW-SINGLEPOLICY|RENEWAL';
    }

    if (heapId) {
      this.analyticsService.trackAnalytics(heapId);
    }

    const renewalDate = this.convertDate(policies[0].termEndDate, true);
    this.webAnalytics.policyRenewalDate = renewalDate;
  }

  // Gets renewal doc and sets recentRenewalDocument value to pass into openRenewalPolicyDocument
  getRenewalPolicyDocuments() {
    this.setInfoCard(this.infoCardType['renewalDocLoading']);

    this.openDocumentMeta(this.renewalMeta[0]);

    this.analyticsService.trackAnalytics('LP_SingleRenewal_DocOpen');
    
    this.setInfoCard(this.infoCardType['renewal']);
  }

  async openDocumentMeta(documentMeta: DocumentMeta) {
    this.setDisplayOverlaySpinner.emit(true);
    try {
      await this.documentService.openDocument(documentMeta, documentMeta.primaryKey);
    } catch (e) {
      // console.log(e);
    } finally {
      this.setDisplayOverlaySpinner.emit(false);
    }
  }
}

@Component({
  selector: 'info-card-autopay-signup',
  templateUrl: './templates/autopay-signup/autopay-signup.component.html',
  styleUrls: ['./templates/autopay-signup/autopay-signup.component.scss']
})
export class InfoCardAutoPaySignupComponent extends InfoCardComponent {
  ngOnInit() {
    this.analyticsService.trackAnalytics('LP_infoTile_onLoad_autopay');
  }
}

@Component({
  selector: 'info-card-billing-sms',
  templateUrl: './templates/billing-sms/billing-sms.component.html',
  styleUrls: ['./templates/billing-sms/billing-sms.component.scss']
})
export class InfoCardBillingSmsComponent extends InfoCardComponent {
  ngOnInit() {
    this.analyticsService.trackAnalytics('LP_infoTile_onLoad_billingsms');
  }
}

@Component({
  selector: 'info-card-paperless-billing',
  templateUrl: './templates/paperless-billing/paperless-billing.component.html',
  styleUrls: ['./templates/paperless-billing/paperless-billing.component.scss']
})
export class InfoCardPaperlessBillingComponent extends InfoCardComponent {
  ngOnInit() {
    this.analyticsService.trackAnalytics('LP_infoTile_onLoad_paperless');
  }
}

@Component({
  selector: 'info-card-multi-policy-discount',
  templateUrl: './templates/multi-policy-discount/multi-policy-discount.component.html',
  styleUrls: ['./templates/multi-policy-discount/multi-policy-discount.component.scss']
})
export class InfoCardMultiPolicyDiscountComponent extends InfoCardComponent {
  ngOnInit() {
    this.analyticsService.trackAnalytics('LP_infoTile_onLoad_multiPolicyDiscount');
  }
}

@Component({
  selector: 'info-card-cross-sell',
  templateUrl: './templates/cross-sell/cross-sell.component.html',
  styleUrls: ['./templates/cross-sell/cross-sell.component.scss']
})
export class InfoCardCrossSellComponent extends InfoCardComponent {
  offer: CrossSellOffer = new CrossSellOffer();
  offerStorage: CrossSellOffer;
  offerSavings: number;
  offerLob: string;
  offerHeader: string;
  offerBody: string;

  ngOnInit() {
    this.getCrossSellOffer(this.override?.infoCardLob?.toLowerCase());
  }

  async getCrossSellOffer(offerOverride?: string) {
    this.offer = offerOverride ? { ...this.offer, savings: parseInt((Math.random() * 100).toString()), lobOffer: offerOverride, opportunityId: CrossSellLobId[offerOverride] } : CAMStorage.getItemInStorage(CAMStorage.storageKeys.crossSellStorage, true);

    if (this.offer) {
      this.offerSavings = this.offer.savings;
      this.offerLob = this.offer.lobOffer.toLowerCase();
      this.setCrossSellInfoCardContent(this.offer);
      this.offerLoaded = true;
    }
    this.analyticsService.trackAnalytics('LP_infoTile_onLoad_crossSell');
  }

  setCrossSellInfoCardContent(offer: CrossSellOffer) {
    switch (offer.opportunityId) {
      case CrossSellLobId.auto:
        this.offerHeader = crossSellTileHeader.AUTO(this.offerSavings);
        this.offerBody = crossSellTileBody.AUTO;
        break;
      case CrossSellLobId.earthquake:
        this.offerHeader = crossSellTileHeader.EARTHQUAKE;
        this.offerBody = crossSellTileBody.EARTHQUAKE;
        break;
      case CrossSellLobId.watercraft: // boat vs watercraft - test
        this.offerHeader = crossSellTileHeader.WATERCRAFT;
        this.offerBody = crossSellTileBody.WATERCRAFT;
        this.offerLob = 'boat';
        break;
      case CrossSellLobId.home:
        this.offerHeader = crossSellTileHeader.HOME(this.offerSavings);
        this.offerBody = crossSellTileBody.HOME;
        break;
      case CrossSellLobId.motorcycle:
        this.offerHeader = crossSellTileHeader.MOTORCYCLE;
        this.offerBody = crossSellTileBody.MOTORCYCLE;
        break;
      case CrossSellLobId.renters:
        this.offerHeader = crossSellTileHeader.RENTERS;
        this.offerBody = crossSellTileBody.RENTERS;
        break;
      case CrossSellLobId.umbrella:
        this.offerHeader = crossSellTileHeader.UMBRELLA;
        this.offerBody = crossSellTileBody.UMBRELLA;
        break;
      default:
        break;
    }
  }

  openCrossSellModal(): void {
    try {
      this.modalService.openModal(ModalNames.CROSS_SELL);
      this.modalService.setModalLoaded();
      this.analyticsService.trackAnalytics('LP_popUp_onClick_crossSell');
    } catch (err) {
      console.log(err);
    }
  }

}

@Component({
  selector: 'info-card-new-business',
  templateUrl: './templates/new-business/new-business.component.html',
  styleUrls: ['./templates/new-business/new-business.component.scss']
})
export class InfoCardNewBusinessComponent extends InfoCardComponent {
  policies: Policy[];

  ngOnInit() {
    this.getCustomerPolicies();
  }

  async getCustomerPolicies() {
    this.setInfoCard(this.infoCardType['policyLoading']);
    try {
      const allPolicies = await this.policyService.getAllPolicies();
      this.policies = this.policyService.filterExistingPolicies(allPolicies.policies);
      this.setInfoCard(this.infoCardType['newBusiness']);
      this.analyticsService.trackAnalytics('LP_infoTile_onLoad_newBusiness');
    } catch (err) {
      this.errorRetrievingPolicy = true;
      this.setInfoCard(this.infoCardType['policyError']);
      console.log(err);
    }
  }

  getTempIDLink() {
    try {
      const policyNumber: string = this.policies[0].number;
      const tempIDLink = this.url.getTemporaryIdCard(policyNumber);
      this.routingService.openDocument(tempIDLink, policyNumber);
    } catch (err) {
      console.log(err);
    }
  }
}

@Component({
  selector: 'info-card-customer-appreciation',
  templateUrl: './templates/customer-appreciation/customer-appreciation.component.html',
  styleUrls: ['./templates/customer-appreciation/customer-appreciation.component.scss']
})
export class InfoCardCustomerAppreciationComponent extends InfoCardComponent {
  ngOnInit() {
    this.getAgencyInfo();
    this.analyticsService.trackAnalytics('LP_infoTile_onLoad_customerAppreciation');
  }

  async getAgencyInfo() {
    this.primaryAgency = await this.policyService.getPrimaryAgency();
  }
}

@Component({
  selector: 'info-card-mobile-app-promotion',
  templateUrl: './templates/mobile-app-promotion/mobile-app-promotion.component.html',
  styleUrls: ['./templates/mobile-app-promotion/mobile-app-promotion.component.scss']
})
export class InfoCardMobileAppPromotionComponent extends InfoCardComponent {
  appleAppStoreLink: string = Endpoints.url.mobileAppleAppHPPromo;
  googleAppStoreLink: string = Endpoints.url.mobileGoogleAppHPPromo;
  isAndroid: boolean = false;
  isApple: boolean = false;
  isAuto: boolean = false;
  desktopHeapId: string = 'Homepage_infoCard_txt_learnHowToDownloadMobileApp|';
  mobileAndroidHeapId: string = 'Homepage_infoCard_imgLink_downloadAndroidMobileApp|';
  mobileAppleHeapId: string = 'Homepage_infoCard_imgLink_downloadAppleMobileApp|';

  ngOnInit() {
    this.configureAppPromo();
  }

  async configureAppPromo(): Promise<void> {
    this.setOS();
    await this.checkLOB();
  }

  setOS(): void {
    const androidRegex: RegExp = /android/;
    const appleRegex: RegExp = /ipad|iphone|ipod/;
    let userAgent: string = navigator.userAgent || navigator.vendor;
    userAgent = userAgent ? userAgent.toLowerCase() : '';

    if (androidRegex.test(userAgent)) {
      this.isAndroid = true;
    } else if (appleRegex.test(userAgent)) {
      this.isApple = true;
    }
  }

  async checkLOB(): Promise<void> {
    try {
      const policies: Policy[] = await this.policyService.getPolicies();

      if (policies && policies.length) {
        this.isAuto = policies.some((policy: Policy) => isVehicularLOB(policy.lineOfBusiness));
      }
    } catch (ex) {
      this.isAuto = false;
    } finally {
      this.setHeapIds();
    }
  }

  setHeapIds(): void {
    const VEHICULAR_ID: string = 'Vehicular';
    const NON_VEHICULAR_ID: string = 'NonVehicular';
    let selectedId: string = NON_VEHICULAR_ID;

    if (this.isAuto) {
      selectedId = VEHICULAR_ID;
    }

    this.desktopHeapId += selectedId;
    this.mobileAndroidHeapId += selectedId;
    this.mobileAppleHeapId += selectedId;
  }
}
