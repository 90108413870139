import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

import { CommonService } from '../common-service/common.service';
import Endpoints from 'src/app/utilities/constants/endpoints';
import { Features } from '../../models/features';
import { PolicyService } from '../policy-service/policy.service';
import { AnalyticsService } from '../analytics-service/analytics.service';
import Policy from '../../models/policy';
import CAMStorage from '../../constants/CAMStorage';
import Agency from '../../models/agency';
import { AuthUser } from '../../models/authUser';
import { AccountService } from '../account-service/account.service';
import { environment } from 'src/environments/environment';
import PolicyMethods from '../../methods/policy.methods';

declare global {
  interface Window { sm: any; sendChatUiData: any; }
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  isGold: boolean;
  liveChat: LiveChat;
  salemoveApi: any;
  userAgent: string;
  pageStart: string;
  proactiveChatTimeout: any;
  env = environment;

  constructor(
    private commonService: CommonService,
    private accountService: AccountService,
    private policyService: PolicyService,
    private analyticsService: AnalyticsService,
    private http: HttpClient,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  async initialize() {
    try {
      const features: Features = await this.commonService.getFeatures();
      const policies: Policy[] = await this.policyService.getPolicies();
      const primaryAgency: Agency = await this.policyService.getPrimaryAgency();
      const authUser: AuthUser = this.accountService.getAuthUser();

      this.isGold = await this.policyService.isGoldPlusOrGoldAgency();
      this.userAgent = this.commonService.getUserAgent() + ': ' + navigator.userAgent;

      if (features.chatLaunched && policies && policies.length && primaryAgency) {
        this.liveChat = new LiveChat(policies, this.location, primaryAgency, authUser);
        this.checkForExistingSession();

        if (this.liveChat.accessToken) {
          this.getBearerToken(features.chatApiToken);
        }

        await this.loadChat(features.chatUrl);
        await this.setupChatApi();

        this.router.events.pipe(
          filter(event => event instanceof NavigationEnd)
        ).subscribe(async (event: NavigationEnd) => {
          if (this.liveChat.initialized) {
            this.updateSession();
          }
        });
        
        this.liveChat.initialized = true;
      }
    } catch (err) {
      // continue regardless of error
    }
  }

  public isChatAvailable(): boolean {
    let isChatAvailable: boolean = false;

    if (this.salemoveApi && this.liveChat && this.liveChat.chatAvailable) {
      isChatAvailable = true;
    }

    return isChatAvailable;
  }

  public isWithinChatOperatingHours(): boolean {
    const PST_HOUR_DIFFERENCE: number = 8; // Hour difference between UTC and PST
    const OPERATING_HOURS_BEGIN: number = 6; // 6am PST
    const OPERATING_HOURS_END: number = 17; // 5pm PST

    const UTCHour: number = new Date().getUTCHours();
    const currentPSTHour: number = UTCHour - PST_HOUR_DIFFERENCE;
    const isWithinHours: boolean = !!(currentPSTHour >= OPERATING_HOURS_BEGIN && currentPSTHour < OPERATING_HOURS_END);

    return isWithinHours;
  }

  public async initiateOmniChat(): Promise<void> {
    try {
      await this.loadOmniChat();
    
      const policies: Policy[] = await this.policyService.getPolicies();
      const authUser: AuthUser = this.accountService.getAuthUser();
      this.isGold = await this.policyService.isGoldPlusOrGoldAgency();
      this.userAgent = this.commonService.getUserAgent() + ': ' + navigator.userAgent;
  
      await this.updateOmniChatUi(authUser, policies);
    } catch (error) {
      console.log(error);
    }
  }

  private async loadOmniChat(): Promise<string> {
    return this.commonService.loadScript(this.env.omniChatScript);
  }

  private async updateOmniChatUi(authUser: AuthUser, policies: Policy[]) {
    if (window.sendChatUiData && typeof window.sendChatUiData !== 'undefined' && authUser) {
      window.sendChatUiData({
        userAgent: this.userAgent,
        policyNumbers: PolicyMethods.buildPolicyStringArray(policies),
        consumerName: `${authUser.firstName} ${authUser.lastName}`,
        email: authUser.email,
        partyId: authUser.masterPartyId, 
        goldStatus: this.isGold ? 'gold' : 'non-gold'
      })
    }
  }

  private checkForExistingSession() {
    this.route.queryParams.subscribe(params => {
      this.liveChat.accessToken = params['__sm.accessToken'] || '';
    });
    if (this.liveChat.accessToken) {
      CAMStorage.setItemInStorage(CAMStorage.storageKeys.chatSession, this.liveChat.accessToken);
    }
  }

  private getBearerToken(token: string) {
    const heapBearerFailureId = 'MMA_Chat_BearerTokenFailure';
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Accept': 'application/vnd.salemove.v1+json',
          'Content-Type': 'application/json'
        }),
      withCredentials: false
    };
    const ApiToken = JSON.stringify({
      "api_token": token
    });

    return new Promise((resolve) => {
      this.http.post(Endpoints.api.getGliaBearerToken, ApiToken, { ...httpOptions, responseType: 'text' }).subscribe({
        next: (response) => {
          const obj = JSON.parse(response);
          if (obj.token) {
            this.liveChat.bearerToken = obj.token;
            resolve(true);
          } else {
            this.analyticsService.trackAnalytics(heapBearerFailureId);
            resolve(true);
          }
        },
        error: (error) => {
          this.analyticsService.trackAnalytics(heapBearerFailureId);
          resolve(true);
        }
      })
    });
  }

  private async getExistingPageStart() {
    const heapPageStartRetrievalFailureId = 'MMA_Chat_BearerTokenFailure';
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Accept': 'application/vnd.salemove.v1+json',
          'Authorization': 'Bearer ' + this.liveChat.bearerToken,
        }),
      withCredentials: false
    };

    return new Promise((resolve) => {
      this.http.get(Endpoints.api.getGliaVisitorInformation(this.liveChat.siteID, this.liveChat.visitorID), { ...httpOptions, responseType: 'text' }).subscribe({
        next: (response) => {
          const obj = JSON.parse(response);
          if (obj.custom_attributes && obj.custom_attributes['Page Start']) {
            this.pageStart = obj.custom_attributes['Page Start'];
            resolve(true);
          } else {
            this.analyticsService.trackAnalytics(heapPageStartRetrievalFailureId);
            resolve(true);
          }
        },
        error: (error) => {
          this.analyticsService.trackAnalytics(heapPageStartRetrievalFailureId);
          resolve(true);
        }
      });
    });
  }

  private async loadChat(chatUrl: string) {
    if (this.liveChat.accessToken) {
      chatUrl += '?session_context=' + this.liveChat.accessToken;
    }
    try {
      return this.commonService.loadScript(chatUrl);
    } catch (error) {
      this.analyticsService.trackAnalytics('MMA_Script_LoadFailure|Chat');
    }
    
  }

  private async setupChatApi() {
    const api = await window.sm.getApi({ version: 'v1' });
    this.salemoveApi = api;

    // this.salemoveApi.config.siteLogo.url = env.serverUrl + env.imgLoc.substr(1) + '/safeco_medallion.svg';

    // Preserve the existing Page Start from a cross domain session
    if (this.liveChat.accessToken) {
      this.liveChat.siteID = await this.salemoveApi.getSiteId();
      this.liveChat.visitorID = await this.salemoveApi.getVisitorId();
      await this.getExistingPageStart();
    }

    // Initial information sent to in order to provide glia business rules with data for display properties
    this.salemoveApi.updateInformation({
      name: this.liveChat.fullName,
      email: this.liveChat.email,
      phone: this.liveChat.phone,
      customAttributes: {
        'Current Page': this.location.path(),
        'Current Policy': '',
        'Gold Agency': this.isGold ? 'Yes' : 'No',
        'Page Category': 'Customer Account Manager',
        'Page Start': this.pageStart ? this.pageStart : '',
        'Policy List': this.liveChat.policyList,
        'Policy LOBs': this.liveChat.policyLobList,
        'Agent Number': this.liveChat.agentNumber,
        'Agency Stat Code': this.liveChat.agencyStatCode,
        'State': this.liveChat.state,
        'User Agent': this.userAgent
      }
    });

    // Checks glia business rules if chat is visible to user and sends analytics once per session.
    this.salemoveApi.overseer.addUnbufferedEventListener(this.salemoveApi.overseer.EVENTS.REACTIVE_ENABLE, () => {
      if (!this.liveChat.chatAvailable) {
        this.liveChat.chatAvailable = true;
        this.analyticsService.trackAnalytics('MMA_Chat_Available');
      }
    });

    // Captures the current page once they start a chat session
    this.salemoveApi.addEventListener(this.salemoveApi.EVENTS.ENGAGEMENT_START, () => {
      this.liveChat.pageName = this.location.path();
      this.liveChat.sessionEnabled = true;
      this.liveChat.accessToken = this.salemoveApi.getSessionContext();
      CAMStorage.setItemInStorage(CAMStorage.storageKeys.chatSession, this.liveChat.accessToken);

      this.salemoveApi.updateInformation({
        customAttributesUpdateMethod: 'merge',
        customAttributes: {
          'Page Start': this.pageStart ? this.pageStart : this.liveChat.pageName
        }
      });

      this.analyticsService.trackAnalytics('MMA_Chat_SessionStarted');
    });

    // Detects connection changes to the backend API
    this.salemoveApi.addEventListener(this.salemoveApi.EVENTS.CONNECTION_STATUS_UPDATE, (connectionStatus: any) => {
      console.log(connectionStatus);
      if (!connectionStatus.connected) {
        this.analyticsService.trackAnalytics('MMA_Chat_ConnectionFailure');
      }
    });

    // Handles any process we need once session is ended
    this.salemoveApi.addEventListener(this.salemoveApi.EVENTS.ENGAGEMENT_END, () => {
      this.liveChat.sessionEnabled = false;
      this.liveChat.accessToken = '';
      CAMStorage.removeItemInStorage(CAMStorage.storageKeys.chatSession);

      this.salemoveApi.updateInformation({
        customAttributesUpdateMethod: 'merge',
        customAttributes: {
          'Page Start': ''
        }
      });

      this.analyticsService.trackAnalytics('MMA_Chat_SessionEnded');
    });
  }

  private updateSession() {
    // Updates glia attributes on each page change 
    this.salemoveApi.updateInformation({
      customAttributesUpdateMethod: 'merge',
      customAttributes: {
        'Current Page': this.location.path(),
        'Current Policy': ''
      }
    });
  }

  getSessionContext(): string {
    let sessionContext = '';
    if (this.liveChat && this.liveChat.sessionEnabled && this.liveChat.accessToken) {
      sessionContext = '?__sm.accessToken=' + this.liveChat.accessToken;
    }
    return sessionContext;
  }

  async enableProactiveChat(timeoutDuration: number = 30000, displayErrorMessage: boolean = false): Promise<void> {
    const errorHeapId = 'MMA-View-NotificationSystemError|EnableProactiveChat';
    const path = this.location.path();

    return new Promise((resolve) => {
      this.proactiveChatTimeout = setTimeout(async () => {
        try {
          await this.salemoveApi.queueForEngagement('text', null);
  
          this.analyticsService.trackAnalytics('MMA_Chat_ProactiveSessionEngaged');
        } catch (err) {
          this.showFailedToQueueView(err);
  
          if (displayErrorMessage) {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
          }
        } finally {
          clearTimeout(this.proactiveChatTimeout);
          resolve();
        }
      }, timeoutDuration);
    });
  }

  disableProactiveChat() {
    clearTimeout(this.proactiveChatTimeout);
  }

  private showFailedToQueueView(err) {
    this.analyticsService.trackAnalytics('MMA_Chat_ProactiveQueueFailed');
  }

}

class LiveChat {
  initialized: boolean;
  sessionEnabled: boolean;
  accessToken: string;
  chatAvailable: boolean;
  username: string;
  fullName: string;
  email: string;
  phone: string;
  currentPolicy: string;
  policyList: string;
  policyLobList: string;
  agentNumber: string;
  agencyStatCode: string;
  state: string;
  pageName: string;
  validationErrors: string;
  logInfoFn: any;
  logErrorFn: any;
  siteID: string;
  visitorID: string;
  bearerToken: string;

  constructor(policies: Policy[], location: Location, agency: Agency, authUser: AuthUser) {
    this.initialized = false;
    this.sessionEnabled = false;
    this.accessToken = '';
    this.chatAvailable = false;
    this.username = authUser.userId;
    this.fullName = authUser.firstName + ' ' + authUser.lastName;
    this.email = authUser.email;
    this.phone = authUser.phoneNumber;
    this.currentPolicy = '';
    this.policyList = this.generateStringList(policies, 'number');
    this.policyLobList = this.generateStringList(policies, 'policyType');
    this.agentNumber = agency.agentNumber;
    this.agencyStatCode = agency.agencyStatCode;
    this.state = // userProfile.state; TODO
    this.pageName = location.path();
    this.validationErrors = null;
    this.logInfoFn = () => { };
    this.logErrorFn = () => { };
    this.siteID = '';
    this.bearerToken = '';
    this.visitorID = '';
  }

  private generateStringList(policies: Policy[], property: string): string {
    const stringArray: string[] = [];
    policies.forEach((policy) => {
      stringArray.push(' ' + policy[property]);
    });
    return stringArray.toString().substr(1);
  }
}
