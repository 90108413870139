import { Component, OnInit, OnChanges, Input, ViewChild, ElementRef, Renderer2, SimpleChanges } from '@angular/core';
import ProgressWayFinderNode from 'src/app/utilities/models/ProgressWayFinderNode';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnChanges {
  @Input() numScreens: number;
  @Input() currentScreen: number;
  @Input() nodes: ProgressWayFinderNode[];
  @ViewChild('progressFill', { static: true }) progressElement: ElementRef;
  progress: number;
  progressIncrement: number;
  progressBarRadius: number = 32;          // corresponds to diameter in css   

  constructor() { }

  ngAfterViewInit() {
    this.progressIncrement = 100 / this.numScreens;
    this.resetProgress();
    if (this.currentScreen > 1) {
      this.setProgress();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.currentScreen && changes.currentScreen.currentValue === 1) {
      this.resetProgress();
    } else {
      this.setProgress();
    }
  }

  setProgress() {
    if (this.progress <= 100) {
      this.progress = (this.currentScreen / this.numScreens) * 100;
      this.progressElement.nativeElement.style.strokeDashoffset = this.calculateProgress();
    }
  }

  resetProgress() {
    this.progress = this.progressIncrement;
    this.progressElement.nativeElement.style.strokeDashoffset = this.calculateProgress();
  }

  // Create string for CSS stroke-dashoffset attribute to fill radial progress bar
  private calculateProgress() {
    // Calculated by circumference of the circle * progress percentage
    let newProgress = (2 * Math.PI * this.progressBarRadius) * (this.progress / 100);
    return newProgress.toString();
  }
}
