import { DocumentCodesMeta, DocumentTypesMeta } from 'src/app/utilities/constants/documentTypes';
import { getLOBGroupType, LOBName } from 'src/app/utilities/constants/linesOfBusiness';

import DocumentMeta from 'src/app/utilities/models/documentMeta';
import Policy from 'src/app/utilities/models/policy';

import PolicyMethods from 'src/app/utilities/methods/policy.methods';

export default class DocumentMethods {

    public static getPolicyNumbersForPolicyDocs(policies: Policy[]): string {
        let strPolicies: string = '';

        if (policies && policies.length) {
            const docPolicies: Policy[] = policies.filter((policy: Policy) => 
                !policy.isCanceled ||
                !PolicyMethods.isCanceledOverAYear(policy)
            );
    
            const polNumList: string[] = PolicyMethods.buildPolicyStringArray(docPolicies);
            
            strPolicies = polNumList && polNumList.length ? polNumList.toString() : '';
        }
        
        return strPolicies;
    }

    public static getIDCards(documents: DocumentMeta[]): DocumentMeta[] {
        let idCards: DocumentMeta[] = [];

        if (documents && documents.length) {
            idCards = documents.filter((docMeta: DocumentMeta) => this.isIDCard(docMeta));
        }

        return idCards;
    }

    public static getPolicyDocuments(documents: DocumentMeta[]): DocumentMeta[] {
        let policyDocs: DocumentMeta[] = [];

        if (documents && documents.length) {
            policyDocs = documents.filter((docMeta: DocumentMeta) => this.isPolicyDocument(docMeta));
        }

        return policyDocs;
    }

    public static getNonPayCancelDocuments(documents: DocumentMeta[]): DocumentMeta[] {
        let nonPayCancelDocs: DocumentMeta[] = [];
        if (documents && documents.length) {
            nonPayCancelDocs = documents.filter((docMeta: DocumentMeta) => this.isNonPayCancellationDocument(docMeta));
        }
        return nonPayCancelDocs;
    }

    public static getProofOfInsurance(documents: DocumentMeta[]): DocumentMeta {
        if (documents && documents.length) {
            const certDoc = documents.find((docMeta: DocumentMeta) => this.isProofOfInsurance(docMeta));
            return certDoc;
        }
    }

    public static isIDCard(documentMeta: DocumentMeta): boolean {
        let isIDCard: boolean = false;

        if (documentMeta && documentMeta.documentType === DocumentTypesMeta.POLICY && documentMeta.documentTypeCode === DocumentCodesMeta.ID_CARDS) {
            isIDCard = true;
        }

        return isIDCard;
    }

    public static isPolicyDocument(documentMeta: DocumentMeta): boolean {
        let isPolicyDocument: boolean = false;

        if (documentMeta && documentMeta.documentType === DocumentTypesMeta.POLICY && documentMeta.documentTypeCode !== DocumentCodesMeta.ID_CARDS) {
            isPolicyDocument = true;
        }

        return isPolicyDocument;
    }

    public static isProofOfInsurance(documentMeta: DocumentMeta): boolean {
        let isProofOfInsurance: boolean = false;
       
        if (documentMeta && documentMeta.documentType === DocumentTypesMeta.POLICY && documentMeta.documentTypeCode === DocumentCodesMeta.PROOF_OF_INSURANCE) {
            isProofOfInsurance = true;
        }

        return isProofOfInsurance;
    }

    public static isPolicyProofOfInsuranceEligible(policy: Policy) {
        let isProofOfInsuranceEligible: boolean = false;

        if (policy && policy.lineOfBusiness) {
            const lobGroup: string = getLOBGroupType(policy.lineOfBusiness);

            if (lobGroup === LOBName.home || lobGroup === LOBName.auto) {
                isProofOfInsuranceEligible = true;
            }
        }

        return isProofOfInsuranceEligible;
    }

    public static isRenewalDocument(documentMeta: DocumentMeta): boolean {
        let isRenewalDocument: boolean = false;

        if (documentMeta && documentMeta.documentType === DocumentTypesMeta.POLICY && documentMeta.documentTypeCode === DocumentCodesMeta.RENEWAL) {
            isRenewalDocument = true;
        }

        return isRenewalDocument;
    }

    public static isAnyDocumentRenewal(documents: DocumentMeta[]): boolean {
        let hasRenewalDocument: boolean = false;

        if (documents) {
            hasRenewalDocument = documents.some((docMeta: DocumentMeta) => this.isRenewalDocument(docMeta));
        }

        return hasRenewalDocument;
    }

    public static isNonPayCancellationDocument(documentMeta: DocumentMeta): boolean {
        let isNonPayCancellationDocument: boolean = false;
        if (documentMeta && documentMeta.documentType === DocumentTypesMeta.BILLING && documentMeta.documentTypeCode === DocumentCodesMeta.NON_PAY_CANCELLATION) {
            isNonPayCancellationDocument = true;
        }
        return isNonPayCancellationDocument;
    }

    private static isCurrentTermDocument(documentDate: string, termBeginDate: string): boolean {
        let isCurrentTermDocument: boolean = false;

        if (documentDate && termBeginDate) {
            isCurrentTermDocument = new Date(documentDate) >= new Date(termBeginDate);
        }

        return isCurrentTermDocument;
  }

  public static getVAHomeownersDocVersion(policy: Policy): string {
    let version = '';

    //rating state is VA, status is 1 (active), type is Home 
    if (policy.ratingStateAlpha === 'VA' && policy.statusCode === '1' && policy.policyType === 'Home' && policy.isRenter === false) {

      const dtDec30_2023 = '2023-12-30T00:00:00';
      const dtAug31_2024 = '2024-08-31T00:00:00';
      const dtJul20_2024 = '2024-07-20T00:00:00';
      const dtJul19_2024 = '2024-07-19T00:00:00';
      const safecoIllinois = 'Safeco Insurance Company of Illinois';
      const insIllinois = 'Insurance Company of Illinois';
      const americanEconomy = 'American Economy Insurance Company';

      // Version 1      
      if (
        policy.companyName === safecoIllinois &&
        policy.isCondo === false &&
        policy.termBeginDate > dtDec30_2023 &&
        policy.termBeginDate < dtAug31_2024) {
        version = 'v1';
      }

      // Version 2
      else if (
        policy.companyName === insIllinois &&
        policy.isCondo === true &&
        policy.termBeginDate > dtDec30_2023 &&
        policy.termBeginDate < dtAug31_2024)
      {
        version = 'v2';
      } 
  
      // Version 3
      else if (
         policy.companyName === americanEconomy &&
         policy.isCondo === true &&
         ((policy.termBeginDate > dtDec30_2023 &&
           policy.termBeginDate < dtJul20_2024)
           ||
           (policy.termBeginDate > dtJul19_2024 &&
             policy.termBeginDate < dtAug31_2024 &&
             policy.inceptionDate !== policy.termBeginDate
           ))
        )
        {
          version = 'v3';
        }

  
      // Version 4
      else if (
       policy.companyName === americanEconomy &&
       policy.isCondo === false &&
       ((policy.termBeginDate > dtDec30_2023 &&
         policy.termBeginDate < dtJul20_2024)
         ||
         (policy.termBeginDate > dtJul19_2024 &&
           policy.termBeginDate < dtAug31_2024 &&
           policy.inceptionDate !== policy.termBeginDate
         ))
        )
        {
          version = 'v4';
        } 
    }

    return version;
  }

  public static setVAHomeDocumentMeta(documentType: string, policyNumber: string, termBeginDate: string, linkPrefix: string): any {
    let dm = new Object as DocumentMeta; 

    switch (documentType) {  //double check prod
      case 'v1':        
        dm.contentUrl = linkPrefix + 'SICI-HMR.pdf'; 
        break;
      case 'v2':
        dm.contentUrl = linkPrefix + 'ICI-Condo.pdf';
        break;
      case 'v3':
        dm.contentUrl = linkPrefix + 'AEIC-Condo.pdf';
        break;
      case 'v4':
        dm.contentUrl = linkPrefix + 'AEIC-HMR2.pdf';
        break;
      default:
        break;
    }

    dm.documentDisplayName = 'Policy language updates';
    dm.documentDescription = 'This includes updated language for some of the forms associated with your home or condo policy. Reference the forms listed on your declarations page and review updated language of those coverage forms within this document.';
    dm.primaryKey = policyNumber;
    dm.receivedPassDate = termBeginDate;
    dm.documentDate = termBeginDate;
    dm.documentTypeDescription = 'vaHome';
    dm.documentType = 'vaHome';

    return dm as any;
  }

  public static isDuplicateMeta(vaHomeDocMeta: any, allPolicyDocuments: DocumentMeta[]): boolean {
    for (let i = 0; i < allPolicyDocuments.length; i++) {
        if (allPolicyDocuments[i].contentUrl === vaHomeDocMeta.contentUrl) {
            return true;
        }
    }
    return false;
  }
}
