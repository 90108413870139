import { Injectable } from '@angular/core';

import Billing from 'src/app/utilities/models/billing';
import { Features } from 'src/app/utilities/models/features';
import Policy from 'src/app/utilities/models/policy';

import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { BillingService } from 'src/app/utilities/services/billing-service/billing.service';
import { PolicyService } from 'src/app/utilities/services/policy-service/policy.service';
import { BillingAccountStatuses } from '../../constants/billingAccountStatuses';
import { CrossSellService } from '../cross-sell-service/cross.sell.service';
import CrossSellOffer, { CrossSellPolicy } from '../../models/crossSellOffer';
import PolicyMethods from '../../methods/policy.methods';
import BillingMethods from '../../methods/billing.methods';

@Injectable({
    providedIn: 'root'
})

export class UXService {

    policies: Policy[] = [];
    features: Features = new Features();
    newBusinessPolicies: Policy[] = [];
    billingAccounts: Billing[] = [];
    newBillingAccounts: Billing[] = [];

    constructor(
        private billingService: BillingService,
        private commonService: CommonService,
        private policyService: PolicyService,
        private crossSellService: CrossSellService
    ) { }

    async isNewBusinessPolicy() {

        this.policies = await this.policyService.getPolicies();
        this.billingAccounts = await this.billingService.getBilling();

        this.newBusinessPolicies = this.policies.filter((policy: Policy) => this.isAccountEffectiveDateRecent(policy.inceptionEffectiveDate));
        this.newBillingAccounts = this.billingAccounts.filter((billingAccount: Billing) => billingAccount.accountStatus.toUpperCase() === BillingAccountStatuses.NOT_FOUND);

        this.newBusinessPolicies = this.newBusinessPolicies.filter((policy: Policy) => this.newBillingAccounts.some((billingAccount: Billing) => policy.number === billingAccount.policyNumber));

        if (this.billingAccounts.length === 1 && this.newBusinessPolicies.length === 1) {
            return true;
        }

        return false;
    }

    isAccountEffectiveDateRecent(date: string): boolean {
        const effectiveRange = 5;
        const today = new Date();
        const policyDate = new Date(date);
        policyDate.setDate(policyDate.getDate() + effectiveRange);
        return today <= policyDate;
    }

    async removeCanceledPoliciesWithNoBalance(allPolicies: Policy[]): Promise<Policy[]> {
        let canceledPolicies: Policy[] = PolicyMethods.getCanceledPolicies(allPolicies);
        let activePolicies: Policy[] = PolicyMethods.getActivePolicies(allPolicies, true);
        let billingAccounts: Billing[] = [];

        this.features = await this.commonService.getFeatures();

        if (canceledPolicies && canceledPolicies.length) {
            try {
                billingAccounts = await this.billingService.getBilling();
            } catch (ex) {
                // continue regardless of error
            } finally {
                if (billingAccounts && billingAccounts.length) {
                    const canceledPoliciesWithBalance: Policy[] = 
                        canceledPolicies.filter((canceledPolicy: Policy) => this.doesCanceledPolicyHaveABalance(billingAccounts, canceledPolicy));
                    activePolicies = activePolicies.concat(canceledPoliciesWithBalance);
                }
            }
        }

        if(this.features && this.features.canceledPolicyDocsLaunched) {
            activePolicies = PolicyMethods.removeCanceledPoliciesOverAyear(activePolicies);
        }
        
        return activePolicies;
    }

    doesCanceledPolicyHaveABalance(billingAccounts: Billing[], policy: Policy): boolean {
        const associatedBillingAccount: Billing = billingAccounts.find((account: Billing) => account.number === policy.paymentAccount);

        if (associatedBillingAccount && associatedBillingAccount.totalBalanceRemainingAmount > 0) {
            const accountPolicy = associatedBillingAccount.policyList.find((accountPol: Policy) => BillingMethods.formatPolicyNumber(accountPol.number) === policy.number && accountPol.isCanceled);
            if (accountPolicy && accountPolicy.balanceAmount > 0) {
                return true;
            }
        }

        return false;
    }

    isTempIdAvailable(state: string): boolean {
        const statesNotAvailable: string[] = ['NY'];
        state = state ? state.toUpperCase() : '';

        if (statesNotAvailable.some((noTempIdState: string) => state === noTempIdState)) {
            return false;
        }

        return true;
    }

    filterActiveAndInactiveWithBalanceAccounts(billingAccounts: Billing[]): Billing[] {
        let activeAndInactiveWithBalanceAccounts: Billing[] = [];

        if (billingAccounts && billingAccounts.length) {
            activeAndInactiveWithBalanceAccounts = billingAccounts.filter((account: Billing) => !account.isInactiveOrCanceledAccount || (account.isInactiveOrCanceledAccount && account.totalBalanceRemainingAmount > 0));
        }

        return activeAndInactiveWithBalanceAccounts;
    }

    filterAllActiveBillingAccounts(billingAccounts: Billing[]): Billing[] {

        let allActiveAccounts: Billing[] = [];

        if (billingAccounts && billingAccounts.length) {
            allActiveAccounts = billingAccounts.filter((account: Billing) => !account.isInactiveOrCanceledAccount);
        }

        return allActiveAccounts;
    }

    atLeastOneBillingAccountIsAutopay(billingAccounts: Billing[]): boolean {

        let atLeastOneExists: boolean = false;

        if (billingAccounts && billingAccounts.length) {
            atLeastOneExists = billingAccounts.some((account: Billing) => account.isAutoPay);
        }

        return atLeastOneExists;
    }

    atLeastOneBillingAccountIsPayrollDeduction(billingAccounts: Billing[]): boolean {

        let atLeastOneExists: boolean = false;

        if (billingAccounts && billingAccounts.length) {
            atLeastOneExists = billingAccounts.some((account: Billing) => account.isPayrollDeduction);
        }

        return atLeastOneExists;
    }

    atLeastOneBillingAccountIsPayByMortgagee(billingAccounts: Billing[]): boolean {

        let atLeastOneExists: boolean = false;

        if (billingAccounts && billingAccounts.length) {
            atLeastOneExists = billingAccounts.some((account: Billing) => account.isPayByMortgagee);
        }

        return atLeastOneExists;
    }
}
