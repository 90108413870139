export class WebAnalytics {
  agentGold?: string;
  billingAccountNums?: string;
  billingMultiSingleMAP?: string;
  billingPaperless?: string;
  billingPaymentMethod?: string;
  countNotifications?: number;
  customerCookie?: string;
  customerUserID?: string;
  experimentName?: string;
  policyCorrectOfferType?: string;
  policyCount?: number;
  policyCountEsign?: number;
  policyCountEsignLiveInitial?: number;
  policyLineOfBusiness?: string;
  policyNumbers?: string;
  policyNumbersEsignWarning?: string;
  policiesInRenewal?: string;
  policyRatingStates?: string;
  policyRenewalDate?: string;
  masterPartyId?: string;
  singleMultiRenewal?: string;
  subSection?: string;
  subSubSection?: string;
  subSubSubSection?: string;
  successfulLogin?: string;
  testActiveTest?: string;
  testCampaignName?: string;
  testRecipeName?: string;
  variantName?: string;
  wayfinderCheckmarks?: string;
}

export class DataLayerAnalytics {
  channel?: string;
  cntofnotifications?: number;
  experimentName?: string;
  policiesInRenewal?: string;
  subsection?: string;
  subsub?: string;
  subsubsub?: string;
  successfullogin?: string;
  variantName?: string;
  wayfindercheckmarks?: string;
  "A-gold"?: string; // y or n
  "B-billingaccountnums"?: string;
  "B-multisingleMAP"?: string; //s or m
  "B-paperless"?: string;
  "B-paymentmethod"?: string;
  "C-cookie"?: string; //y or n
  "C-userid"?: string;
  "P-cntofesignpol"?: number;
  "P-cntpolsonpage"?: number;
  "P-correctoffertype"?: string;
  "P-policynumswarning"?: string;
  "P-eSignLInitialCntPol"?: number;
  "P-lineofbusiness"?: string;
  "P-policynums"?: string;
  "P-policyRenewalDate"?: string;
  "P-ratingstates"?: string;
  "P-singleMultiRenewal"?: string; //s or m or none
  "T-activeTest"?: string; //y or n
  "T-campaignName"?: string;
  "T-recipeName"?: string;

  constructor(webAnalytics: WebAnalytics) {
    this.channel = "CAM";
    this.cntofnotifications = webAnalytics.countNotifications;
    this.experimentName = webAnalytics.experimentName;
    this.policiesInRenewal = webAnalytics.policiesInRenewal;
    this.subsection = webAnalytics.subSection;
    this.subsub = webAnalytics.subSubSection;
    this.subsubsub = webAnalytics.subSubSubSection;
    this.successfullogin = webAnalytics.successfulLogin;
    this.variantName = webAnalytics.variantName;
    this.wayfindercheckmarks = webAnalytics.wayfinderCheckmarks;
    this["A-gold"] = webAnalytics.agentGold;
    this["B-billingaccountnums"] = webAnalytics.billingAccountNums;
    this["B-multisingleMAP"] = webAnalytics.billingMultiSingleMAP;
    this["B-paperless"] = webAnalytics.billingPaperless;
    this["B-paymentmethod"] = webAnalytics.billingPaymentMethod;
    this["C-cookie"] = webAnalytics.customerCookie;
    this["C-userid"] = webAnalytics.customerUserID;
    this["P-cntofesignpol"] = webAnalytics.policyCountEsign;
    this["P-cntpolsonpage"] = webAnalytics.policyCount;
    this["P-correctoffertype"] = webAnalytics.policyCorrectOfferType;
    this["P-policynumswarning"] = webAnalytics.policyNumbersEsignWarning;
    this["P-eSignLInitialCntPol"] = webAnalytics.policyCountEsignLiveInitial;
    this["P-lineofbusiness"] = webAnalytics.policyLineOfBusiness;
    this["P-policynums"] = webAnalytics.policyNumbers;
    this["P-policyRenewalDate"] = webAnalytics.policyRenewalDate;
    this["P-ratingstates"] = webAnalytics.policyRatingStates;
    this["P-singleMultiRenewal"] = webAnalytics.singleMultiRenewal;
    this["T-activeTest"] = webAnalytics.testActiveTest;
    this["T-campaignName"] = webAnalytics.testCampaignName;
    this["T-recipeName"] = webAnalytics.testRecipeName;
  }
}