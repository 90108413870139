<footer class="app-footer" *ngIf="footer">

  <div class="footer-light">
    <div class="sco-container">
      <span class="link" *ngFor="let link of footer.links">
        <a href="{{link.url}}" id="{{link.id}}" target="_blank">{{link.text}}</a> <span class="pipe">|</span>
      </span>
      <span class="link" *ngIf="isCpraChoiceLaunched">
          <app-svg-icon [svgImage]="footer.cpraLink.id"></app-svg-icon>
          <a class="display-inline" href="{{footer.cpraLink.url}}" id="{{footer.cpraLink.id}}" target="_blank">{{footer.cpraLink.text}}</a>
          <span class="pipe">|</span>
      </span>
      <span class="link">        
        <a class="display-inline" [routerLink]="" (click)="openTealiumUrl(footer.doNotSellLink.url)" id="{{footer.doNotSellLink.id}}">{{footer.doNotSellLink.text}}</a>
      </span> 
    </div>
  </div>
  <div class="footer-dark">
    <div class="sco-container">
      <div class="safeco-logo">
        <a href="https://www.safeco.com">
          <app-logo isVertical="true" color="inverse"></app-logo>
        </a>
      </div>
      <div class="copyright">
        <div>
          <span>{{footer.disclaimer}}</span>
        </div>
        <div>
          <span>{{footer.address1}}</span>, <span>{{footer.address2}}</span>
        </div>
      </div>
    </div>
  </div>
      
</footer>
