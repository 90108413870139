export enum PAYMENT_STATUS_CODES {
    successful = 0,
    hasAtLeastOneError = 5,
    cardOverLimit = 9,
    cardError = 10,
    expiredCard = 19,
    duplicatePayment = 20,
    systemError = 30,
    invalidRoutingNumber = 40,
    invalidBankAccountNumber = 42,
}

export const PaymentStatusMessages: any = {
    successful: 'Ok',
    hasAtLeastOneError: 'At least one account processing has an error',
    cardOverLimit: 'Your card has been declined',
    cardError: 'Credit card error',
    expiredCard: 'Your card has an expired date',
    duplicatePayment: 'Duplicate payment',
    systemError: 'System error',
    invalidRoutingNumber: 'Bank routing number is invalid',
    invalidBankAccountNumber: 'Bank account number is invalid',
};

export const PaymentFlow = {
    HYDRA_MAKE_A_PAYMENT: "HydraMakeAPayment",
    PWOL: "PayWithoutLogin",
    SCHEDULE_PAYMENT: "SchedulePayment",
};

export const PaymentSource = {
    HYDRA_ADYEN: "hydra-adyen"
};

export enum DELETE_SAVED_CREDIT_CARD_STATUS_CODES {
    successful = 0,
    deleteSystemError = 30
}

export const DeleteSavedCreditCardStatusMessages: any = {
    successful: 'Ok',
    deleteSystemError: 'Your card information could not be cleared at this time. Please try again later.'
};

export enum PAYMENT_INFO_STATUS_CODES {
    success = 0,
    billInfoForCCProfileFailed = 135
}


export enum SCHEDULE_MAKE_PAYMENT_STATUS_CODES {
    success = 200,
    partialFailure = 207,
    allPaymentFailure = 226
}

export enum SCHEDULE_MAKE_PAYMENT_BILLING_SERVICE_CODES {
    internalError = "500"
}

export const SCHEDULE_MAKE_PAYMENT_STATUS: any = {
    submitted:"submitted",
    success:"success",
    failed:"failed",
    scheduled:"scheduled",
    error: "error"
};

export const ScheduleMakePaymentHistoryItem: any ={
    message: "Scheduled payment successfully processed",
    creditCardPaymentType: "Credit Card Payment"
};