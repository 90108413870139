import { Component, Input, Inject, AfterViewInit, Output, EventEmitter, OnChanges, OnInit, SimpleChanges, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() id: string;
  @Input() title: string;
  @Input() heapId?: string;
  @Input() hideBorder?: boolean = false;
  @Input() hideExpandMargin?: boolean = false;
  @Input() titleIcon?: string = '';
  @Input() initializeExpanded?: boolean = false;
  @Input() contentLoaded?: boolean = true;
  @Input() triggerReset?: boolean = false;
  @Input() triggerResize?: boolean = false;

  @Output() toggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  content: HTMLElement;
  contentHeight: number;
  expanded: boolean;
  detectChanges: boolean;
  setHeightOnWindowResize: any;

  constructor(@Inject(DOCUMENT) document) {

  }

  ngOnInit() {
    this.expanded = false;
    this.detectChanges = false;
    this.heapId = this.heapId || this.id;
  }

  ngOnChanges() {
    // reinitialize content height after service responds
    if ((this.detectChanges && this.contentLoaded) || this.triggerResize) {
      this.resize();
    } 

    // reset accordion on policy selection
    if (this.triggerReset && this.content) {
      this.expanded = this.initializeExpanded;
      if (this.expanded) {
        this.expandSection(this.content);
      } else {
        this.collapseSection(this.content);
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.expanded = this.initializeExpanded;
      this.detectChanges = true;
      setTimeout(() => {
        this.content = document.getElementById(this.id);
        this.contentHeight = this.content.scrollHeight;
        this.content.style.height = 0 + 'px';

        if (this.expanded) {
          this.expandSection(this.content);
        }
      });
    });
  }

  @HostListener('window:resize')
  onResize() {
    // resize the accordion at the end of the event
    clearTimeout(this.setHeightOnWindowResize);
    this.setHeightOnWindowResize = setTimeout(() => {
      this.resize();
    }, 500);
   
  }

  resize() {
    setTimeout(() => {
      if (this.content && this.content.children.length) {
        this.contentHeight = this.content.children.item(0).scrollHeight + 32;
        this.expandSection(this.content);
      }
    });
  }

  toggleAccordion() {
    this.toggled.emit(!this.expanded);

    if (!this.expanded) {
      this.expanded = true;
      setTimeout(() => {
        this.contentHeight = this.content.scrollHeight;
        this.expandSection(this.content);
      });
    } else {
      this.collapseSection(this.content);
      setTimeout(() => {
        this.expanded = false;
      }, 500);
    }
  }

  collapseSection(element: HTMLElement) {
    // temporarily disable all css transitions
    const elementTransition = element.style.transition;
    element.style.transition = '';

    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we 
    // aren't transitioning out of 'auto'
    requestAnimationFrame(function () {
      element.style.transition = elementTransition;

      // on the next frame (as soon as the previous style change has taken effect),
      // have the element transition to height: 0
      requestAnimationFrame(function () {
        element.style.height = 0 + 'px';
      });
    });
  }

  expandSection(element: HTMLElement) {
    // have the element transition to the height of its inner content
    element.style.height = this.contentHeight + 'px';

    // when the next css transition finishes (which should be the one we just triggered)
    function myFunc() {
      // remove this event listener so it only gets triggered once
      element.removeEventListener('transitionend', myFunc);
    }

    // when the next css transition finishes (which should be the one we just triggered)
    element.addEventListener('transitionend', myFunc);
  }

}


@Component({
  selector: 'app-login-accordion',
  templateUrl: './templates/login/login-accordion.component.html',
  styleUrls: ['./templates/login/login-accordion.component.scss']
})
export class LoginAccordionComponent extends AccordionComponent {

}
