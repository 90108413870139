//Most common invalid reasons
export const policyInvalidReason = {
  0: 'UndefinedError',
  1: 'NameMismatch',
  2: 'AddressMismatch',
  8: 'DOBMismatch',
  262144: 'AddressNotFound',
  524288: 'InvalidCustomerRole',
  1048576: 'LastNameMismatch',
  33554432: 'InterestNotFound'  //policy doesn't exist
}
export default policyInvalidReason;
