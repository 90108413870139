import { Component, OnInit, HostListener } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
// import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import SearchMethods from 'src/app/utilities/methods/search.methods';
import { Search, SearchResults, FaqSearch } from 'src/app/utilities/models/search';
import Endpoints from 'src/app/utilities/constants/endpoints';
import { SlackService } from 'src/app/utilities/services/slack-service/slack.service';
import { Features } from 'src/app/utilities/models/features';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/utilities/services/analytics-service/analytics.service';
import { Faq } from 'src/app/utilities/constants/faqs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit {
  searchControl = new UntypedFormControl();
  filteredResults: Observable<SearchResults>;
  pageResults: Observable<Search[]>;
  faqResults: Observable<FaqSearch[]>;
  faqPath = Endpoints.url.faq;
  input: string;
  results: SearchResults = null;
  features: Features;
  isSearchEnabled: boolean = false;
  streets: string[] = ['Champs-Élysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue'];

  constructor(private slackService: SlackService,
    private router: Router,
    private analyticsService: AnalyticsService) { 
      router.events.subscribe(val => {
        this.toggleSearchDisplay();
      });
     }

  ngOnInit() {
    this.toggleSearchDisplay();

    this.pageResults = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value, 'page'))
    );

    this.faqResults = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value, 'faq'))
    );
    
    this.input = '';
  }

  onChange(input: string) {
    this.input = input;
  }

  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: any) {
    if (event.key === "Enter") {
      if (this.input && this.input.length) {
        //this.sendToSlack(this.input, this.results.pageResults.length + this.results.faqResults.length, 'Search Input Enter with key: ' + event.path[0].value);
        // this.router.navigate([this.faqPath], {queryParams: {open: event.path[0].value}});
        if (this.results.faqResults.length === 0) {
          this.router.navigate([this.faqPath], { queryParams: { open: '' } });
        }
        else {
          this.router.navigate([this.faqPath], { queryParams: { open: this.results.faqResults[0].keyParam } });
        }
        this.resetSearch();
      }
    }
  }

  toggleSearchDisplay() {
    if (~this.router.url.indexOf('/faq')) {
      this.isSearchEnabled = false;
    } else {
      this.isSearchEnabled = true;
    }
  }

  resetSearch() {
    this.searchControl.reset();
    this.input = '';
  }

  private _filter(value: string, source?: string): any {
    this.results = SearchMethods.search(value);
    switch (source) {
      case 'page':
        return this.results.pageResults;
      case 'faq':
      return this.results.faqResults;
    }
  }

  onSearchClick() {
    if (this.input && this.input.length && this.results.faqResults && this.results.faqResults.length) {
      //this.sendToSlack(this.input, this.results.pageResults.length + this.results.faqResults.length, 'Search Icon Click getting first result: ' + this.results.faqResults[0].title);
      if (this.results.faqResults.length === 0) {
        this.router.navigate([this.faqPath], { queryParams: { open: '' } });
      }
      else {
        this.router.navigate([this.faqPath], { queryParams: { open: this.results.faqResults[0].keyParam } });
      }
      this.resetSearch();
    }
  }
}
