import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService, GetTokenSilentlyOptions, LogoutOptions } from '@auth0/auth0-angular';
import { AuthUser } from 'src/app/utilities/models/authUser';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { RoutingService } from 'src/app/utilities/services/routing-service/routing.service';
import { environment } from 'src/environments/environment';
import Endpoints from 'src/app/utilities/constants/endpoints';

@Injectable({
  providedIn: 'root'
})
export class AuthUserService {
  authUser: AuthUser;
  postProcessStatus: number;
  url = Endpoints.url;

  constructor(
    private auth: AuthService,
    private commonService: CommonService,
    private routingService: RoutingService
  ) {
    this.auth.user$.subscribe(profile => {
      if (this.commonService.isMobileApp()) {
        const mobileIdToken = sessionStorage.getItem('MOBILE_AUTH0_TOKEN');
        if (mobileIdToken) {
          const helper = new JwtHelperService();
          
          const decodedToken = helper.decodeToken(mobileIdToken);
          this.authUser = new AuthUser(decodedToken);
          this.postProcessStatus = 200;
        }
      } else if (profile) {
        this.authUser = new AuthUser(profile);
        this.postProcessStatus = +profile["https://safeco.com/postProcess/status"];
      } else {
        console.log('user is not logged in');
      }
    });
  }

  fetchUpdatedToken(errorHeapId: string, path: string): void {
    const tokenErrorHeapId: string = `${errorHeapId}|tokenUpdateFailure`;
    let accessTokenOptions: GetTokenSilentlyOptions = {
      cacheMode: 'off'
    };
    this.auth.getAccessTokenSilently(accessTokenOptions).subscribe(
      () => // Succcessfully updated token
      () => this.commonService.setServiceFailureAlert(tokenErrorHeapId, path)
    );
  }

  logout() {
    if (this.commonService.isMobileApp()) {
      // mobile app looks for this to logout of the app
      this.routingService.navigateTo(this.url.mobileLogin);
    } else {
      let logoutOptions: LogoutOptions = {
        clientId: environment.auth0ClientId,
        logoutParams: {
          returnTo: environment.auth0ReturnTo
        }
      };
      this.auth.logout(logoutOptions);
    }
  }

  handleRedirectCallback() {
    this.auth.handleRedirectCallback(environment.auth0RedirectUri);
  }
  
}