import { inject, NgModule } from '@angular/core';
import { provideHttpClient } from '@angular/common/http';
import { provideApollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { environment } from 'src/environments/environment';
 
const appTroveUri = environment.appTroveGraphQL;

@NgModule({
  providers: [
    provideHttpClient(),
    provideApollo(() => {
      const httpLink = inject(HttpLink);
 
      return {
        link: httpLink.create({ uri: appTroveUri }),
        cache: new InMemoryCache(),
        // other options...
      };
    }),
  ]
})

export class GraphQLModule {}