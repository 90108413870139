import { Component, Input, AfterContentChecked } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent extends InputComponent implements AfterContentChecked {
  @Input() default?: string;
  @Input() options: Array<any>;
  @Input() useSmallBottomMargin?: boolean = false;

  ngAfterContentChecked() {
    this.setSelectADAValue();
  }

  setSelectADAValue(): void {
    if (this.value) {
      const selectedOption: any = this.options.find((option: any) => option.value === this.ADAValue);

      if (selectedOption) {
        this.ADAValue = selectedOption.key;
      }
    } else {
      this.ADAValue = 'None selected';
    }

    if (this.isDisabled || this.isDisabled === '') {
      this.ADAValue = this.value ? `${this.value} disabled` : 'Disabled';
    }
  }
}
