import { Location } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import Endpoints from 'src/app/utilities/constants/endpoints';
import { environment as env } from 'src/environments/environment';
import { DocumentService } from '../document-service/document.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  complexUrl = Endpoints.complexUrl;

  constructor(
    private activatedRoute: ActivatedRoute,
    private documentService: DocumentService,
    private location: Location,
    private router: Router,
    public ngZone: NgZone,
  ) { }

  routeTo(link: string, navExtras?: NavigationExtras) {
    this.router.navigate([link], navExtras);
  }

  navigateBack() {
    this.location.back();
  }

  updateParams(params: Object) {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: params,
        queryParamsHandling: 'merge',
        replaceUrl: true
      }
    );
  }

  getParams(queryParam?: string) {
    if (queryParam) {
      return this.activatedRoute.snapshot.queryParamMap.get(queryParam);
    }
    return this.activatedRoute.snapshot.queryParams;
  }

  async openDocument(link: string, policyNumber: string) {
    this.documentService.trackTempIdClickAnalytics(policyNumber);
    window.open(env.baseHref + link.substr(1, link.length), '_blank', 'noopener');
  }

  openInNewWindow(link: string) {
    let openLink = link;
    // for non-local environments where we haven't already prepended the domain for links, prepend it
    if (!env.local && !~openLink.indexOf(env.domain)) {
      openLink = env.domain.substr(0, env.domain.length - 1) + openLink;
    }
    window.open(openLink, '_blank');
  }

  navigateTo(link: string, tabParam?: string) {
    const url = link + ((tabParam) ? '?tab=' + tabParam : '');
    this.ngZone.run(() => location.href = url);
  }

}
