<div class="wayfinder-container">
  <hr class="wayfinder-border" />
  <div class="wayfinder-content">
    <div class="progress-circle">
      <svg class="progress-circle__svg" viewBox="0 0 64 64">
        <circle id="whole-fill" class="progress-circle__stroke" r="32px" cx="50%" cy="50%"></circle>
        <circle #progressFill id="progress-fill" class="progress-circle__stroke" r="32px" cx="50%" cy="50%"></circle>
        <circle id="inner-outline" class="progress-circle__outline_stroke" r="28px" cx="50%" cy="50%"></circle>
        <circle id="outer-outline" class="progress-circle__outline_stroke" r="32px" cx="50%" cy="50%"></circle>
      </svg>
      <span class="wayfinder-label">
        <b>{{currentScreen}} of {{numScreens}}</b>
      </span>
    </div>


    <div *ngIf="nodes && nodes.length" class="wayfinder-text">
      <h3><b>{{nodes[currentScreen - 1].title}}</b></h3>
      <h4 *ngIf="nodes[currentScreen - 1].next">
        Next: {{nodes[currentScreen - 1].next}}
      </h4>
    </div>
  </div>
  <hr class="wayfinder-border" />
</div>