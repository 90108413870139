import { Component, OnInit, Input } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { IndicatorPages } from 'src/app/utilities/constants/statusIndicator';
import { Cookies } from 'src/app/utilities/services/cookies-service/cookies.service';
import Policy from 'src/app/utilities/models/policy';

@Component({
  selector: 'app-renewal-flag',
  templateUrl: './renewal-flag.component.html',
  styleUrls: ['./renewal-flag.component.scss']
})
export class RenewalFlagComponent implements OnInit {
  @Input() type: string = '';
  @Input() renewalPolicy?: Policy;

  imgLoc: string = env.imgLoc;
  renewalFlagText: string = '';
  renewalEffectiveDate: string = ''

  constructor(
    private cookies: Cookies
  ) { }

  ngOnInit() {
    this.setRenewalFlagText();
  }

  ngOnChanges() {
    this.setRenewalFlagText();
  }

  setRenewalFlagText() {
    switch (this.type) {
      case IndicatorPages.ACCOUNT_SELECT:
        this.renewalFlagText = 'Renewal';
        break;
      case IndicatorPages.POLICY_SUMMARY:
      case IndicatorPages.LANDING_POLICY_TILE:
      case IndicatorPages.BILLING_SUMMARY:
        this.renewalFlagText = this.getFlagTextWithDate();
        break;
      case IndicatorPages.DOC_CENTER_ID_CARD:
        this.renewalFlagText = 'Your updated ID cards are here';
        break;
      case IndicatorPages.DOC_CENTER_RENEWAL_DOC:
        this.renewalFlagText = 'Your renewal documents are here';
        break;
      default:
        this.renewalFlagText = 'Renewing soon';
        break;
    }
  }

  getFlagTextWithDate(): string {
    let flagText = 'Renewing soon';

    if (this.renewalPolicy) {
      if (this.renewalPolicy.isRenewedToday) {
        flagText = 'Renews today';
      } else {
        const renewalCookie = this.cookies.getRenewalCookie()

        this.renewalEffectiveDate = (renewalCookie && this.renewalPolicy.isMarketingRenewal) ? this.renewalPolicy.marketingRenewalEffectiveDate : this.renewalPolicy.renewalEffectiveDate;
        const formattedTermBeginDate = new Date(this.renewalEffectiveDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });

        if ((renewalCookie && this.renewalPolicy.isMarketingPreRenewal) || this.renewalPolicy.isPreRenewal) {
          flagText = 'Renews ' + formattedTermBeginDate;
        } else if ((renewalCookie && this.renewalPolicy.isMarketingPostRenewal) || this.renewalPolicy.isPostRenewal) {
          flagText = 'Renewed ' + formattedTermBeginDate;
        }
      }
    }


    return flagText;
  }

  getRenewalCSS(): string {
    let classes = this.isRightAligned() ? 'renewal-right-align' : 'renewal-left-align';

    if (this.isAccountSelect()) {
      classes += ' renewal-account-select';
    }

    if (this.isLandingTile()) {
      classes += ' renewal-landing';
    }

    if (this.isDocCenter()) {
      classes += ' renewal-docs';
    }

    if (this.isBillingSummary()) {
      classes += ' renewal-bill';
    }

    return classes;
  }

  isRightAligned(): boolean {
    let isRightAligned: boolean = false;

    switch (this.type) {
      case IndicatorPages.DOC_CENTER_ID_CARD:
      case IndicatorPages.DOC_CENTER_RENEWAL_DOC:
        isRightAligned = true;
        break;
      default:
        isRightAligned = false;
        break;
    }

    return isRightAligned;
  }

  isAccountSelect(): boolean {
    return this.type === IndicatorPages.ACCOUNT_SELECT;
  }

  isOnPolicySummary(): boolean {
    return this.type === IndicatorPages.POLICY_SUMMARY;
  }

  isLandingTile(): boolean {
    return this.type === IndicatorPages.LANDING_POLICY_TILE;
  }

  isDocCenter(): boolean {
    return (this.type === IndicatorPages.DOC_CENTER_ID_CARD || this.type === IndicatorPages.DOC_CENTER_RENEWAL_DOC);
  }

  isBillingSummary(): boolean {
    return this.type === IndicatorPages.BILLING_SUMMARY;
  }
}
