import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import Endpoints from 'src/app/utilities/constants/endpoints';

@Component({
    selector: 'app-account-terms-and-conditions',
    templateUrl: './account-terms-and-conditions.component.html',
    styleUrls: ['./account-terms-and-conditions.component.scss']
})
export class AccountTermsAndConditionsComponent implements OnInit {
    @Input() pageText: string;
    termsConditionsUrl: string;

    constructor() { }
    
    ngOnInit() {
        this.termsConditionsUrl = Endpoints.url.termsAndConditions;
    }
}