import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';

import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { AppState, AuthService, RedirectLoginOptions } from '@auth0/auth0-angular';


// SDK AuthGuard will determine if user is logged in. If not, redirect to Auth0 login page
@Injectable({
  providedIn: 'root'
})
export class Auth0GuardService  { 

  constructor(
    private auth0: AuthService,
    private commonService: CommonService
  ) { }
 
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.commonService.isMobileApp()) {
      return of(true);
    } else {
      this.auth0.isAuthenticated$.subscribe(isAuth => {
        if(!isAuth) {
          let options: RedirectLoginOptions<AppState> = {
            authorizationParams: {
              redirect_uri: environment.auth0RedirectUri + window.location.search
            },
            appState: { target: state.url }
          }
          window.localStorage.setItem('deepLink', state.url.split("?")[0]);
          window.localStorage.setItem('deepLinkParams', (state.url.split('?')[1]) ? state.url.split('?')[1] : '');
          this.auth0.loginWithRedirect(options);
        }
      });
  
      return this.auth0.isAuthenticated$;
    }
  }

}
