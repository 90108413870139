export enum PAYMENTS_STATUS {
    APPROVED = 0,
    HAS_AT_LEAST_ONE_RTB_PENDING = 10,
    FAILED_SAVE_CC = 11,
    DECLINED = 20,
    FAILED = 40,
    FAILED_RTB = 41,
    ERROR = 60,
    HYDRA_PAYMENT_ERROR = 70,
    REALTIME_PAYMENT_ERROR = 71,
    HAS_AT_LEAST_ONE_HYDRA_ERROR = 72,
    HAS_AT_LEAST_ONE_RTB_ERROR = 73,
}

//replaced payment_status when refactored completely.
export enum PAYMENTS_STATUS_DESCRIPTION {
  DECLINED = 'Declined',
}

export enum HYDRA_PAYMENT_STATUS_CODES {
    insufficientFunds = "D0001",
    limitExceeded = "D0002",
    cardholderActivityLimitExceeded = "D0003",
    expiredCard = "D0004",
    invalidCreditCardNumber = "D0005",
    invalidExpirationDate = "D0006",
    declinedCVV = "D0010"
}

export const HYDRA_DECLINED_MESSAGE: any = [
  { key: HYDRA_PAYMENT_STATUS_CODES.insufficientFunds, value: 'Insufficient fund' },
  { key: HYDRA_PAYMENT_STATUS_CODES.limitExceeded, value: 'Limit exceeded' },
  { key: HYDRA_PAYMENT_STATUS_CODES.cardholderActivityLimitExceeded, value: `Cardholder's Activity Limit Exceeded` },
  { key: HYDRA_PAYMENT_STATUS_CODES.expiredCard, value: 'Expired Card' },
  { key: HYDRA_PAYMENT_STATUS_CODES.invalidCreditCardNumber, value: 'Invalid Credit Card Number' },
  { key: HYDRA_PAYMENT_STATUS_CODES.invalidExpirationDate, value: 'Invalid Expiration Date' },
  { key: HYDRA_PAYMENT_STATUS_CODES.declinedCVV, value: 'Card Issuer Declined CVV' }
];
