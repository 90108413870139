export class FieldValidation {
    required: boolean;
    requiredMsg?: string;
    validations: Validations[];
    hasError: boolean;
    forceValidation?: boolean;
    forceError?: boolean;
    forceErrorMessage?: string;
    originalValue?: any;

    constructor(required?: boolean) {
        this.required = (required) ? true : false;
        this.validations = [];
        this.hasError = this.required;
        this.forceValidation = false;
        this.forceError = false;
    }
}

export class Validations {
    message?: string;
    pattern?: string;
    uniqueValueRule?: Function;
    validationRule?: Function;
    matchesValueById?: string;
}

export const ValidationRegex = {
    empty: '^(\\S+)$', // no spaces allowed
    allButEmpty: '[^ ]',
    oneLetterAndEmptyStringOnly: '^(^$)|[a-zA-Z]+$',
    password: '^(?=.*\\d+)(?=.*[a-zA-Z]+)(?!.*[ ]).{8,32}$',
    policyNumber: '^([a-zA-Z]{1}[0-9]{1,8}|[a-zA-Z]{2}[0-9]{1,7})$',
    address: '^[A-Za-z0-9 -]*$',
    addressOption2: '^[A-Za-z0-9 -.,\'#/]*$',
    alphaNumeric: '^[A-Za-z0-9]+$',
    alphaOnly: '^[A-Za-z]+$',
    alphaAndEmptyStringOnly: '^[a-zA-Z]+([\\s][a-zA-Z]+)*$',
    driverLicense: '^[A-Za-z0-9*]+$',
    name: '^[a-zA-Z \'-]+$', // alpha, single quote, and dash allowed
    minLengthName: (minLength: number) => `^([a-zA-Z \'-]{${minLength},})$`, // eslint-disable-line no-useless-escape
    numericOnly: '^[0-9]+$',
    numericAndEmptyStringOnly: '^(^$)|[0-9]+$',
    daysOfMonth: '^(0[1-9]|1[0-9]|2[0-9]|3[01])$',
    dateOfBirth: '^(0[1-9]|1[0-2])\/(0[1-9]|1\\d|2\\d|3[01])\/(19|20)\\d{2}$', // eslint-disable-line no-useless-escape
    email: '^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    maxLengthNumeric: (length: number) => `^([0-9]{1,${length}})$`,
    maxMinLengthNumeric: (min: number, max: number) => `^([0-9]{${min},${max}})$`,
    maxLengthNumericNonZero: (length: number) => `^([1-9]([0-9]{0,${length - 1}}))$`,
    verificationCode: '^$|^([a-zA-Z \'-.@]{1,4}[0-9]+)$',
    landlord: '^[A-Za-z0-9&., \'-/]+$',
    loanNumber: '^[ A-Za-z0-9\-]*$', // eslint-disable-line no-useless-escape
    lastFourvin: '^$|^([a-zA-Z0-9]{4})$',
    lowMilePolicy: '^$|^([a-zA-Z]{1}[0-9]{1,8}|[a-zA-Z]{2}[0-9]{1,7})$',
    city: '^([ a-zA-Z.\'-])+$',
    nocomma: '^[^,]+$',
    zipCode: '^\\d{5}$|^\\d{5}-\\d{4}$',
    date: '^(0\\d{1}|1[0-2])/([0-2]\\d{1}|3[0-1])/(20)\\d{2}$',
    phone: '^(\\+0?1\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$',
    loginUsername: '^((?!%).)*$',
    minLengthAlpha: (minLength: number) => `^([A-Za-z]{${minLength},})$`,
    maxLengthAlpha: (length: number) => `^([A-Za-z]{1,${length}})$`,
    minMaxLengthAlpha: (min: number, max: number) => `^([A-Za-z]{${min},${max}})$`,
    minLengthAny: (minLength: number) => `^(.{${minLength},})$`,
    minMaxLengthAny: (min: number, max: number) => `^(.{${min},${max}})$`,
    // keep for reference 
    // usernameNonEmail: '^[a-zA-Z0-9!_{}#.@@\'\-]+$',
    usernameNonEmail: '^[a-zA-Z0-9!_#\-]+$', // eslint-disable-line no-useless-escape
    nospace: '^[^\\s]+$', // eslint-disable-line no-useless-escape
    simpleDate: `^(0[1-9]|1[0-2])\/(0[1-9]|1\\d|2\\d|3[01])\/\\d{4}$`, // eslint-disable-line no-useless-escape
    maskedDate: `^((0[1-9]|1[0-2])\/(0[1-9]|1\\d|2\\d|3[01])\/\\d{4})|(\\*{2}\/\\*{2}\/\\d{4})$`, // eslint-disable-line no-useless-escape
    vin: `^((?![iIoOqQ])[a-zA-Z0-9]){17}$`,
    year: `^$|^((19|20)[0-9]{2})$`,
    numDaysAWeek: `^([1-7])$`,
    ISODate: `^((19|20)\\d{2})-\(0[1-9]|1[0-2])-\(0[1-9]|1\\d|2\\d|3[01])\(.*)$` // eslint-disable-line no-useless-escape
    //mileageOneWay:`^([1-9][0-9]{0,2}|1[0-8][0-9][0-9]|1[0-9][0-2][0-5])$`
};

export function addLeadingZeroToValueRule(value: string) {
    const numValue: number = parseInt(value);

    if (value && value.length < 2 && value.indexOf('0') !== 0 && !isNaN(numValue)) {
        value = `0${value}`;
    }
    return value;
}

export function trimValueRule(value: string) {
    if (value.length > 0) {
        return value.trim();
    }
}

export function trimDashRule(value: string) {
    if (value && value.includes('-')) {
        const split = value.split('-');
        if (split[1] === '') {
         value = value.slice(0, -1);
        }
    }
    return value;
}

export function IsValidAuth0UserId(userId: string) {   
            let isValid: boolean;           
            const AUTH0_ID_PATTERN = new RegExp('^(.{0,5})([a-fA-F0-9]{24})$');        
            isValid = AUTH0_ID_PATTERN.test(userId) && userId.length === 24;
            return isValid;
}