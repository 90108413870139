<div class="cross-sell-component">
    <div class="curve"></div>
    <div class="header">
        <span class="cross-sell-icon">
            <app-icon name="{{ offerLob }}" size="64" color="dark" circleColor="null"></app-icon>
        </span>
    </div>
    <div class="cross-sell-savings">{{ offerHeader }}</div>
    <div class=cross-sell-body>
        {{ offerBody }}
    </div>
    <span *ngIf="offerLoaded">
        <a class="sco--customer sco-link sco-textLink sco--standalone" (click)="openCrossSellModal()" (keyup.enter)="openCrossSellModal()" data-heap-id="LP_infoTile_btn_getCrossSell" tabindex="0">
            Find out if you can save</a>
    </span>

</div>