import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, NoPreloading } from '@angular/router';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

import { PageMissingComponent } from './modules/error/components/pageMissing/pageMissing.component';
import { CallbackComponent } from './modules/onload/callback/callback.component';
import { Auth0GuardService } from './authentication/guards/auth-guard.service';

import {
  BillingAccessGuardService,
  DocCenterAccessGuardService,
  FaqAccessGuardService,
  HomepageAccessGuardService,
  LoginAccessGuardService,
  LowMileageAccessGuardService,
  PaperlessAccessGuardService,
  DeactivateGuardService
} from './utilities/guards/access-guard.service';


const routes: Routes = [
  {
    path: '', canActivate: [Auth0GuardService], component: CallbackComponent, data: { title: 'loading' } 
  },
  { 
    path: 'homepage', canActivate: [Auth0GuardService, HomepageAccessGuardService], loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'account', loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'billing', loadChildren: () => import('./modules/billing/billing.module').then(m => m.BillingModule)
  },
  {
    path: 'documents', canActivate: [Auth0GuardService, DocCenterAccessGuardService], loadChildren: () => import('./modules/documents/documents.module').then(m => m.DocumentsModule)
  },
  {
    path: 'esign', canActivate: [Auth0GuardService], loadChildren: () => import('./modules/esign/esign.module').then(m => m.EsignModule)
  },
  {
    path: 'lowmileage', canActivate: [LowMileageAccessGuardService], loadChildren: () => import('./modules/lowmileage/lowmileage.module').then(m => m.LowmileageModule)
  },
  {
    path: 'communication', canActivate: [Auth0GuardService, PaperlessAccessGuardService], loadChildren: () => import('./modules/communication/communication.module').then(m => m.CommunicationModule)
  },
  {
    path: 'policy', canActivate: [Auth0GuardService], loadChildren: () => import('./modules/policy/policy.module').then(m => m.PolicyModule)
  },
  {
    path: 'profile', canActivate: [Auth0GuardService], loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
  },
  { 
    path: 'faq',  loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule) 
  },

  { path: 'pagemissing', component: PageMissingComponent, data: { title: 'Page missing' } },

  /* Temporary redirects */
  { path: 'paperlessoptions', redirectTo: 'communication' },
  { path: 'policy/add', redirectTo: 'homepage' }, // add policy is post MVP; redirect to homepage for now
  /* End temporary redirects */

  { path: 'account/login', redirectTo: 'homepage' }, // for users who bookmarked old login page
  { path: '**', redirectTo: 'pagemissing' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: NoPreloading
})],
  exports: [RouterModule],
  providers: [
    BillingAccessGuardService,
    FaqAccessGuardService,
    HomepageAccessGuardService,
    LoginAccessGuardService,
    PaperlessAccessGuardService,
    DeactivateGuardService,
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ]
})
export class AppRoutingModule { }
