<div class="new-business-component">
    <div class="curve"></div>
    <div class="header">
        <span class="sco-heading sco--level3 sco--regular">New auto policy, new ID card</span>
    </div>
    <span class="content">Don't drive without it!</span>
    <button class="sco-button sco--outline" data-heap-id="LP_infoTile_btn_getIDcard" (click)="getTempIDLink()">Get ID card</button>
</div>
<div class="new-business-loading-card" *ngIf="infoCardDisplayed === 'policyLoading'">
    <div class="spinner-card">
        <app-spinner [spinnerLabel]="'Getting your documents'"></app-spinner>
    </div>
</div>