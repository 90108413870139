import { Color } from 'src/app/utilities/constants/colorNames';

export enum AlertType {
    POSITIVE = 'positive',
    INFORMATIVE = 'informative',
    CAUTION = 'caution',
    NEGATIVE = 'negative',
    NEUTRAL = 'neutral',    // LMDS-compatible only
    ALERT = 'alert'         // custom type of error alert for CAM, not offical LMDS type
}

export class Alert {
    type: AlertType;
    class?: string;
    title?: string;
    noTitleCase?: boolean;
    messages: string[];
    heapId?: string;
    dismissable?: boolean;
    inline?: boolean;
    icon: {
        name: string;
        color: string;
    };
    highlightType?: string;

    constructor(type: AlertType, message?: string, heapId?: string) {
        this.type = type;
        this.title = this.setTitle(type);
        this.messages = (message) ? [message] : [];
        this.heapId = heapId || '';
        this.dismissable = false;
        this.inline = true;
        this.icon = this.setIcon(type);
        this.highlightType = this.setHighlightType(type);
    }

    setIcon(type: AlertType) {
        const icon = { name: '', color: 'inverse' };

        switch (type) {
            case AlertType.ALERT:
                icon.name = 'warning';
                break;
            case AlertType.NEGATIVE:
                icon.name = 'errorAlert';
                break;
            case AlertType.CAUTION:
                icon.name = 'warning';
                break;
            case AlertType.POSITIVE:
                icon.name = 'successAlert';
                break;
            case AlertType.INFORMATIVE:
                icon.name = 'info';
                break;
        }

        return icon;
    }

    setTitle(type: AlertType) {
        let title = "";

        switch (type) {
            case AlertType.ALERT:
            case AlertType.NEUTRAL:
                title = 'Alert';
                break;
            case AlertType.NEGATIVE:
                title = 'Error';
                break;
            case AlertType.CAUTION:
                title = 'Warning';
                break;
            case AlertType.POSITIVE:
                title = 'Success';
                break;
            case AlertType.INFORMATIVE:
                title = 'Information';
                break;
            default:
                break;
        }

        title += ". ";

        return title;
    }

    setHighlightType(type: AlertType) {
        this.highlightType = '';

        switch (type) {
            case AlertType.NEUTRAL:
                this.highlightType = Color.DEFAULT;
                break;
            case AlertType.ALERT:
            case AlertType.NEGATIVE:
                this.highlightType = Color.NEGATIVE;
                break;
            case AlertType.CAUTION:
                this.highlightType = Color.CAUTION;
                break;
            case AlertType.POSITIVE:
                this.highlightType = Color.POSITIVE;
                break;
            case AlertType.INFORMATIVE:
                this.highlightType = Color.INFORMATIVE;
                break;
            default:
                break;
        }

        return this.highlightType;
    }
}
