<label id="{{id}}-label" class="sco-label sco-label-select" for="{{id}}"
  [ngClass]="{'is-focused': focus, 'is-populated': populated, 'is-error sco--highlighted': error, 'is-disabled': isDisabled !== null, 'isOptional': !(fieldValidation && fieldValidation.required), 'useSmallBottomMargin': useSmallBottomMargin}">
  <span class="sco-label-visual" aria-hidden="true">{{ label }}</span>
  <span class="sco-label-a11y">{{ label }}</span>
  <select class="sco-input sco-input-select" id="{{id}}" name="{{name}}" [attr.aria-label]="name" role="listbox" [attr.data-value]="ADAValue"
    (change)="onChange($event.target.value)" (blur)="onBlur()" (focus)="focus = true" (focusout)="focus = false" [value]="value"
    [attr.disabled]="isDisabled" [attr.aria-required]="fieldValidation && fieldValidation.required" tabindex="0">
    <option value="" selected disabled hidden>{{default || ''}}</option>
    <option *ngFor="let option of options" [value]="option.value" [attr.selected]="value === option.value ? '' : null" role="option"
      [attr.data-value]="option.key">
      {{ option.key }}
    </option>
  </select>
  <div class="sco-messageGroup" *ngIf="error">
    <div id="select-error">
      <span class="sco-message sco-message--error">{{ validationMsg }}</span>
    </div>
  </div>
  <div class="optional" *ngIf="showOptional && !(fieldValidation && fieldValidation.required)">
    <span class="sco-message">Optional</span>
  </div>
</label>