// as of 9/1/23, LMDS color names
export enum Color {
    DEFAULT = 'default',
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
    TEAL = 'teal',
    CAUTION = 'caution',
    INFORMATIVE = 'informative',
    DISABLED = 'disabled',
    INVERSE = 'inverse'
}

// deprecated color names
export enum LegacyColor {
    DARK = 'dark',
    ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = 'success',
    ACTIVE = 'active'
}