
export class PaperlessPolicy {
    agencyEmailAddress: string;   
    agencyName: string;
    agencyPhoneNumber: string;
    companyName: string;
    insuredFirstName: string;
    isPaperless: boolean;
    lineOfBusiness: string;
    paperlessEmailAddress: string;
    policyNumber: string; 
    status: Status;
}

export class Status { 
    errorMessage: string;
    isSuccessful: boolean;
    statusCode: number;
}
