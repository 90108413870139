export const SMSDisplayPaths: string[] = [
    '/documents'
];

export const OptInStatus = {
    OPT_IN: 'in',
    OPT_OUT: 'out',
    PENDING: 'pending',
    VERBAL: 'verbal'
}

export const OptInType = {
    BILLING: 'service-safeco-global',
    WEATHER: 'weather-alert'
}

export const sendMessageFormat = {
    SMS: 'sms',
    EMAIL: 'email'
}