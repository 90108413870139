import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toggle-slider',
  templateUrl: './toggle-slider.component.html',
  styleUrls: ['./toggle-slider.component.scss']
})
export class ToggleSliderComponent implements OnChanges {

  @Input() heapId: string = '';
  @Input() id: string = '';
  @Input() isDisabled: boolean = false;
  @Input() isToggledOn: boolean;
  @Input() label: string = '';
  @Input() name: string = '';
  @Input() showToggleStateText: boolean = true;
  @Input() hideMargins: boolean = false;

  @Output() handleToggle = new EventEmitter<boolean>();

  clicks: number = 0;
  toggleStateText: string;
  toggleStateTextOptions: { ON: string, OFF: string } = {
    ON: 'On',
    OFF: 'Off'
  };

  constructor() {}

  ngOnChanges() {
    this.setToggleStateText();
  }

  setToggleStateText(): void {
    if (this.showToggleStateText) {
      this.toggleStateText = this.isToggledOn ? this.toggleStateTextOptions.ON : this.toggleStateTextOptions.OFF;
    }
  }

  toggleOnClick(): void {
    if (!this.isDisabled) {
      this.isToggledOn = !this.isToggledOn;
      this.setToggleStateText();
      this.handleToggle.emit(this.isToggledOn);
    }
  }

  preventDefault(event: Event): void {
    event.preventDefault();
  }
}
