import { Component, HostListener, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { DatepickerRange } from 'src/app/utilities/constants/datepickerRanges';
import DatePickerMethods, { DatePickerRangeMethods }from 'src/app/utilities/methods/datepicker.methods';
import { InputComponent } from '../input/input.component';
import DateMethods from 'src/app/utilities/methods/date.methods';

@Component({
  selector: 'app-datepicker-input',
  templateUrl: './datepicker-input.component.html',
  styleUrls: ['./datepicker-input.component.scss']
})
export class DatepickerInputComponent extends InputComponent implements OnInit {
  @Input() isDisabled: boolean = false;
  @Input() labelA11y: string;
  @Input() validDateRange: string = DatepickerRange.THIRTY_DAYS;
  @Input() validateDateRangeMessage?: string = '';
  @Input() maxValidDate?: Date;
  @Output() datePickerChange = new EventEmitter();

  datePickerValue: string;
  highlightColor: string = '';
  isDateDisabled: any;
  LABEL_SUFFIX: string = "(MM/DD/YYYY)";
  validateDateRange: Function;

  validationMessages: any = {
    AFTER_TODAY_EXCLUSIVE: 'Date should be after today',
    AFTER_TODAY_INCLUSIVE: 'Date should be today or later',
    FUTURE_THIRTY_INCLUSIVE: 'Please select a date within the next 30 days',
    FUTURE_THIRTY_EXCLUSIVE: 'Date should be within 30 days after today',
    PAST_THIRTY_INCLUSIVE: 'Date should be within the past 30 days',
    PAST_THIRTY_EXCLUSIVE: 'Date should be 30 days before today',
    THIRTY_DAYS: 'Date should be within 30 days of today',
    CUSTOM: 'Date should be within the given range'
  }

  ngOnInit(): void {
    this.datePickerValue = DatePickerMethods.removeTimestampFromISODate(new Date(this.value).toISOString());
    this.initializeDateValidation();
    this.isDateDisabled = (date: Date) => !this.isWithinDatepickerRange(date);
    this.setLabel();
  }

  @HostListener('focusout', ['$event'])
  onFocusOut($event) {
    if (DateMethods.isValidDateFormat(this.value)) {
      this.value = DatePickerMethods.formatToSimpleDateFromISO(this.datePickerValue);
    } 

    this.onDateBlur(this.value);
    this.valueChange.emit(this.value);
  }

  initializeDateValidation(): void {
    switch (this.validDateRange) {
      case DatepickerRange.AFTER_TODAY_INCLUSIVE:
        this.validateDateRange = DatePickerMethods.isDateAfterTodayInclusive;
        this.validateDateRangeMessage = this.validateDateRangeMessage ? this.validateDateRangeMessage : this.validationMessages.AFTER_TODAY_INCLUSIVE;
        break;
      case DatepickerRange.AFTER_TODAY_EXCLUSIVE:
        this.validateDateRange = DatePickerMethods.isDateAfterTodayExclusive;
        this.validateDateRangeMessage = this.validateDateRangeMessage ? this.validateDateRangeMessage : this.validationMessages.AFTER_TODAY_EXCLUSIVE;
        break;
      case DatepickerRange.THIRTY_DAYS:
        this.validateDateRange = DatePickerMethods.isWithinThirtyDays;
        this.validateDateRangeMessage = this.validateDateRangeMessage ? this.validateDateRangeMessage : this.validationMessages.THIRTY_DAYS;
        break;
      case DatepickerRange.THIRTY_DAYS_FUTURE:
        this.validateDateRange = DatePickerMethods.isWithinFutureThirtyDaysInclusive;
        this.validateDateRangeMessage = this.validateDateRangeMessage ? this.validateDateRangeMessage : this.validationMessages.FUTURE_THIRTY_INCLUSIVE;
        break;
      case DatepickerRange.THIRTY_DAYS_PAST:
        this.validateDateRange = DatePickerMethods.isWithinPastThirtyDaysInclusive;
        this.validateDateRangeMessage = this.validateDateRangeMessage ? this.validateDateRangeMessage : this.validationMessages.PAST_THIRTY_INCLUSIVE;
        break;
      case DatepickerRange.CUSTOM:
        this.validateDateRange = DatePickerMethods.isBetweenTodayAndDate;
        this.validateDateRangeMessage = this.validateDateRangeMessage ? this.validateDateRangeMessage : this.validationMessages.CUSTOM;
        break;
      default:
        break;
    }
  }

  isWithinDatepickerRange(date: Date): boolean {
    // set blackout dates for calendar popup
    let isWithinRange = false;

    switch (this.validDateRange) {
      case DatepickerRange.AFTER_TODAY_INCLUSIVE:
        isWithinRange = DatePickerRangeMethods.isTodayAndFutureDate(date);
        break;
      case DatepickerRange.AFTER_TODAY_EXCLUSIVE:
        isWithinRange = DatePickerRangeMethods.isAfterToday(date);
        break;
      case DatepickerRange.THIRTY_DAYS:
        isWithinRange = DatePickerRangeMethods.isWithinThirtyDays(date);
        break;
      case DatepickerRange.THIRTY_DAYS_FUTURE:
        isWithinRange = DatePickerRangeMethods.isWithinFutureThirtyDaysInclusive(date);
        break;
      case DatepickerRange.THIRTY_DAYS_PAST:
        isWithinRange = DatePickerRangeMethods.isWithinPastThirtyDays(date);
        break;
      case DatepickerRange.CUSTOM:
        isWithinRange = DatePickerRangeMethods.isWithinGivenDateRangeInclusive(date, this.maxValidDate);
        break;
      default:
        break;
    }

    return isWithinRange;
  }

  setLabel(): void {
    if (this.label) {
      this.label += " " + this.LABEL_SUFFIX;
    } else {
      this.label = this.LABEL_SUFFIX;
    }
  }

  onDateBlur(value: string) {
    if (this.helpText) {
      this.blnhelp = false;
    }
    if (this.helpTextMulti !== undefined && this.helpTextMulti.length > 0) {
      this.blnHelpMulti = false;
    }

    if (this.fieldValidation) {
      this.error = this.checkForValidationsErrors(value, true);
      this.fieldValidation.hasError = this.error;
      this.highlightColor = this.error ? 'error' : '';
      this.fieldValidationChange.emit(this.fieldValidation);
      this.fieldBlur.emit(true);
    }
  }

  onDateChange(inputValue: string) {
    // change event output from input event emitter
    this.value = inputValue;
    this.error = false;
    this.highlightColor = '';
    this.populated = (this.value && this.value.length > 0);
    this.getADAValue();
    this.valueChange.emit(this.value);
    this.fieldBlur.emit(false);

    if (this.fieldValidation) {
      this.fieldValidation.forceError = false;

      if (!this.checkForValidationsErrors(this.value, false)) {
        this.fieldValidation.hasError = false;
        this.fieldValidationChange.emit(this.fieldValidation);
      }
    }
  }

  onDatePickerChange($event) {
    // change event output from LMDS datePickerChange event emitter
    this.datePickerValue = $event.target.value;

    if (this.value !== DatePickerMethods.formatToSimpleDateFromISO(this.datePickerValue)) {
      this.value = DatePickerMethods.formatToSimpleDateFromISO(this.datePickerValue);
      this.datePickerChange.emit(this.value);
      this.valueChange.emit(this.value);
    }
  }

  onDatePickerBlur($event) {
    // placeholder for any blur events
    // only fires after complete date is entered
  }

  onDatePickerFocus($event) {
    // placeholder for any focus events
  }

  checkForValidationsErrors(value: string, validateDateRange?: boolean) {
    let hasError: boolean = super.checkForValidationsErrors(value);

    if (!hasError && validateDateRange) {
      if (this.maxValidDate) {
        hasError = !this.validateDateRange(value, this.maxValidDate.toISOString());
      } else {
        hasError = !this.validateDateRange(value);
      }
     
      if (hasError) {
        this.validationMsg = this.validateDateRangeMessage;
        return true;
      }
    }

    return hasError;
  }
}