import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpResponse, HttpInterceptor, HttpHandler } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RequestCache } from './request-cache.service';
// import { MockService } from '../services/mock-service/mock.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  testHarness: string = '';
  httpEventObs: Observable<HttpEvent<any>> = null;

  constructor(private cache: RequestCache) {}
  // constructor(private cache: RequestCache, private mockService: MockService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let cachedResponse = this.cache.get(req);

    // const mock =  sessionStorage.getItem('mock');
    // if (mock) {
    //   const mockResp = this.mockService.getMockResponses(req);
    //   cachedResponse = (mockResp) ? mockResp : cachedResponse;
    // }
    
    return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next, this.cache);
  }

  private sendRequest( req: HttpRequest<any>, next: HttpHandler, cache: RequestCache): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          cache.put(req, event);
        }
      })
    );
  }
  
}
