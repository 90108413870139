import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { InstrumentType } from 'src/app/utilities/constants/paymentInstrument';
import CAMStorage from 'src/app/utilities/constants/CAMStorage';

import { Alert } from 'src/app/utilities/models/alert';
import {
  EFTInstrument,
  EFTInstrumentDetailsRs,
  InitiateInstrument,
  InstrumentDetailsEvent,
  InstrumentDetailsStorage
} from 'src/app/utilities/models/payments';

import { PaymentsService } from 'src/app/utilities/services/payments-service/payments.service';

import PaymentsMethods from 'src/app/utilities/methods/payments.methods';
import { AnalyticsService } from 'src/app/utilities/services/analytics-service/analytics.service';
import { ModalService } from 'src/app/utilities/services/modal-service/modal.service';
import { ModalNames } from 'src/app/utilities/constants/modalNames';
import { AccountOptionsPaymentMethod } from 'src/app/utilities/models/billingAccountOptions';
import AutoPayMethods from '../../../../utilities/methods/autopay.methods';
import { AutoPayService } from '../../../../utilities/services/autopay-service/autopay.service';
import { AutoPayRs } from '../../../../utilities/models/autoPay';
import Billing from 'src/app/utilities/models/billing';


@Component({
  selector: 'app-eft-input',
  templateUrl: './eft-input.component.html',
  styleUrls: ['./eft-input.component.scss']
})
export class EftInputComponent implements OnInit {

  constructor(
    private paymentsService: PaymentsService,
    private analyticsService: AnalyticsService,
    private modalService: ModalService,
    private autoPayService: AutoPayService
  ) {}

  @Input() isGuestPayment?: boolean = false;
  @Input() isREFT?: boolean = false;
  @Input() accountOptions?: AccountOptionsPaymentMethod = null;
  @Input() selectedBillingAccount?: Billing = null;
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() authorizeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendAlert: EventEmitter<any> = new EventEmitter<any>();

  alerts: Alert[] = [];
  EFTDetails: EFTInstrumentDetailsRs;
  EFTDisplay: string;
  iFrameUrl: string;
  instrumentDetailsStorage: InstrumentDetailsStorage;
  instrumentIdCode: string;
  instrumentType = InstrumentType;
  isEftDeclined: boolean = false;
  isNewEFTAccountSelected: boolean = false;
  isSessionEFTAccountSelected: boolean = false;
  nextButtonId: string = '';
  nextButtonText: string = '';
  showCAMControls: boolean = false;
  showEFTSelection: boolean = false;
  showIFrameApp: boolean = false;
  showSpinner: boolean = true;
  showSubmitDisclaimer: boolean = false;

  ngOnInit() {
    this.initializeEftInput();
  }

  async ngDoCheck() {
    if (CAMStorage.getItemAsBool(CAMStorage.storageKeys.getInstrumentDetails)) {
      CAMStorage.removeItemInStorage(CAMStorage.storageKeys.getInstrumentDetails);

      if (this.isREFT) {
        this.enrollEftToAutopay();
        this.analyticsService.trackAnalytics("MMA_btn_HydraREFT_Submit");
      } else {
        this.analyticsService.trackAnalytics("MMA_btn_MAP_EFT_PaymentMethodSubmit");
        this.verifyInstrument();
      }
    }
  }

  async initializeEftInput(): Promise<void> {
    this.instrumentDetailsStorage = CAMStorage.getItemInStorage(CAMStorage.storageKeys.instrumentDetailsStorage, true);

    if (this.instrumentDetailsStorage) {
      if (this.instrumentDetailsStorage.eftInstrumentDetailsResponse && this.instrumentDetailsStorage.eftInstrumentDetailsResponse.instrument) {
       this.setExistingSessionEFTDisplay();
      } else {
        this.isNewEFTAccountSelected = true;
        await this.getHydraEFTIFrame(this.instrumentDetailsStorage.billingAccountNumber);
      }
      this.setButtonAttributes();
      this.showSpinner = false;
    }
  }

  setExistingSessionEFTDisplay(): void {
    this.EFTDetails = this.instrumentDetailsStorage.eftInstrumentDetailsResponse;

    const eftInstrument: EFTInstrument = this.EFTDetails.instrument;
    this.EFTDisplay = `${eftInstrument.bankAccountType} ${eftInstrument.maskedInstrument}`;
    
    this.showEFTSelection = true;
    this.isSessionEFTAccountSelected = true;
    this.showCAMControls = true;
  }

  async getHydraEFTIFrame(billingAccountNumber: string): Promise<void> {
    this.iFrameUrl = '';
    this.showCAMControls = false;
    this.showIFrameApp = true;

    try {
      const response = await this.paymentsService.initiatePaymentInstrument(InstrumentType.EFT, billingAccountNumber, !this.isGuestPayment);

      if (response) {
        this.setIFrameAppValues(response);
      }
    } catch (error) {
      console.log(error);
      this.setHydraEFTIFrameError();
      this.cancelEvent.emit(true);    // reset payment method display
    }
  }

  private setButtonAttributes(): void {
    if (this.isGuestPayment) {
      this.nextButtonText = 'Next';
      this.nextButtonId = 'PWOL_eftNext'

    } else {
      this.nextButtonText = 'Continue';
      this.nextButtonId = 'MAP_eftContinue'
    }
  }

  private setIFrameAppValues(response: InitiateInstrument): void {
    if (response && response.instrumentHostedPageUrl) {
      this.iFrameUrl = response.instrumentHostedPageUrl;
      this.instrumentIdCode = response.instrumentIdCode;

      setTimeout(() => {
        this.showCAMControls = true;
      }, 750);
    }
  }

  setHydraEFTIFrameError() {
    this.showIFrameApp = false;
    this.sendAlert.emit(PaymentsMethods.makeErrorAlertMessage());
  }

  async getInstrumentDetails(instrumentIdCode: string): Promise<EFTInstrumentDetailsRs> {
    try {
      const request = PaymentsMethods.buildInstrumentIdRq(instrumentIdCode);
      let response: EFTInstrumentDetailsRs = await this.paymentsService.getPaymentInstrumentDetailsEFT(request, !this.isGuestPayment);

      if (response && response.status) {
        this.isEftDeclined = false;
        return response;
      }
    } catch (error) {
      console.log(error.error);
      this.isEftDeclined = true;
      this.displayInstrumentDetailsErrorAlert(error.error);
    } 
  }

  async verifyInstrument(): Promise<void> {
    this.showIFrameApp = false;
    this.showCAMControls = false;
    this.showSpinner = true;

    this.EFTDetails = await this.getInstrumentDetails(this.instrumentIdCode);

    if (this.isEftDeclined) {
      this.getHydraEFTIFrame(this.instrumentDetailsStorage.billingAccountNumber);
    } else {
      this.sendEFTDetails(this.EFTDetails);
    }

    this.showSpinner = false;
  }

  selectSessionEFTInstrument(): void {
    this.isSessionEFTAccountSelected = true;
    this.isNewEFTAccountSelected = false;
    this.sendAlert.emit();

    this.showCAMControls = true;
    this.showIFrameApp = false;
  }

  selectNewEFTInstrument(): void {
    this.isNewEFTAccountSelected = true;
    this.isSessionEFTAccountSelected = false;
    this.sendAlert.emit();

    this.getHydraEFTIFrame(this.instrumentDetailsStorage.billingAccountNumber);
  }

  sendEFTDetails(eftDetails: EFTInstrumentDetailsRs): void {
    if (eftDetails) {
      const eventResponse: InstrumentDetailsEvent = new InstrumentDetailsEvent();
      eventResponse.instrumentDetailsStorage = this.instrumentDetailsStorage;
      eventResponse.instrumentDetailsStorage.eftInstrumentDetailsResponse = this.EFTDetails;
      eventResponse.instrumentDetailsStorage.cardInstrumentDetailsResponse = null;
      this.sendAlert.emit();
      this.authorizeEvent.emit(eventResponse);
    }
  }

  displayInstrumentDetailsErrorAlert(additionalDetails: string): void {
    const alert = PaymentsMethods.buildInstrumentDetailsErrorAlert(additionalDetails);

    if (alert) {
      this.sendAlert.emit(alert);
    }
  }

  async enrollEftToAutopay(): Promise<void> {
    this.showIFrameApp = false;
    this.showCAMControls = false;
    this.showSpinner = true;
    this.sendAlert.emit();      // clear existing errors on submit

    let result = await this.callToEFTAutoPay(this.instrumentIdCode);

    if (this.isEftDeclined) {
      this.initializeEftInput();
    } else {
      this.authorizeEvent.emit(result);
    }

    this.showSpinner = false;
  }

  async callToEFTAutoPay(instrumentIdCode: string): Promise<AutoPayRs> {
    try {
      this.isEftDeclined = false;
      const eftAutoPayRq = AutoPayMethods.buildAutoPayEnrollRequest(instrumentIdCode, JSON.parse(JSON.stringify(this.selectedBillingAccount)));
      let response: AutoPayRs = await this.autoPayService.enrollEftToAutoPay(eftAutoPayRq);

      return response;
    } catch (error) {
      const errorRs = error.error;
      console.log(errorRs);

      if (AutoPayMethods.isInstrumentDetailsError(errorRs.error)) {
        this.isEftDeclined = true;

        this.displayInstrumentDetailsErrorAlert(errorRs.error.instrumentErrorCode);
      }

      return errorRs;
    }
  }

  continueEFT(): void {
    this.sendEFTDetails(this.EFTDetails);
  }

  cancelEFT(): void {
    // cancel button action
    if (this.showEFTSelection) {
      if (this.showIFrameApp) {
        this.cancelEvent.emit(true);      // reset payment method display
      } else {
        this.cancelEvent.emit(false);     // navigate backwards
      }
    } else {
      this.cancelEvent.emit(false);       // navigate backwards
    }
  }


  openRoutingAccountNumberModal(): void {
    this.modalService.openModal(ModalNames.ROUTING_ACCT_NUMBER);
    this.modalService.setModalLoaded();
  }

  openEFTAuthorizationModal(): void {
    this.modalService.openModal(ModalNames.EFT_AUTHORIZATION);
    this.modalService.setModalLoaded();
  }
}
