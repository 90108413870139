import { Component, OnInit, Input, Output, EventEmitter, DoCheck } from '@angular/core';
import { FieldValidation } from 'src/app/utilities/models/fieldValidation';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit, DoCheck {
  @Input() id: string;
  @Input() name: string;
  @Input() label: string;
  @Input() value: string;
  @Input() type?: string = 'text';
  @Input() maxLength: string;
  @Output() valueChange = new EventEmitter();
  @Input() fieldValidation?: FieldValidation;
  @Output() fieldValidationChange = new EventEmitter();
  @Input() isDisabled: any;
  @Input() helpText?: string;
  @Input() helpTextMulti?: string[];
  @Input() helpTextStyle?: string;
  @Output() fieldBlur = new EventEmitter();
  @Output() fieldFocus = new EventEmitter();
  @Input() autocapitalize?: string;
  @Input() showOptional?: boolean = true;
  @Input() useSmallBottomMargin?: boolean = false;

  ADAValue: string;
  focus = false;
  populated = false;
  error = false;
  validationMsg: string;
  blnhelp = false;
  blnHelpMulti = false;

  constructor() { }

  ngOnInit() {
    this.populated = (typeof this.value === 'number' || (this.value && this.value.length > 0));

    if (this.populated) {
      this.onBlur();
    }
  }

  ngDoCheck() {
    // Allows isDisabled to be true/false and then sets it to HTML formatting
    this.isDisabled = this.isDisabled || this.isDisabled === '' ? '' : null;

    this.getADAValue();

    // checking if the page component called for hasValidationErrors to show missing input
    if (this.fieldValidation && (this.fieldValidation.forceValidation || this.fieldValidation.forceError)) {
      this.checkHelpTextAndFieldValidation();
      this.fieldValidation.forceValidation = false;
    } 

    this.populated = (typeof this.value === 'number' || (this.value && this.value.length > 0));
  }

  onChange(value: string) {
    this.error = false;
    this.value = value;
    this.populated = (typeof this.value === 'number' || (this.value && this.value.length > 0));
    this.valueChange.emit(this.value);
    this.getADAValue();

    if (this.fieldValidation) {
      this.fieldValidation.forceError = false;

      if (!this.checkForValidationsErrors(this.value)) {
        this.fieldValidation.hasError = false;
        this.fieldValidationChange.emit(this.fieldValidation);
      }
    }
  }

  onBlur() {
    this.checkHelpTextAndFieldValidation();

    if (this.fieldValidation) {
      this.fieldBlur.emit();
    }
  }

  checkHelpTextAndFieldValidation() {
    if (this.helpText) {
      this.blnhelp = false;
    }
    if (this.helpTextMulti !== undefined && this.helpTextMulti.length > 0) {
      this.blnHelpMulti = false;
    }
    if (this.fieldValidation) {
      this.error = this.checkForValidationsErrors(this.value);
      this.fieldValidation.hasError = this.error;
      this.fieldValidationChange.emit(this.fieldValidation);
    }
  }

  onFocus() {
    if (this.helpText) {
      this.error = false;
      this.blnhelp = true;
      this.blnHelpMulti = false;
    } else if (this.helpTextMulti !== undefined && this.helpTextMulti.length > 0) {
      this.error = false;
      this.blnhelp = false;
      this.blnHelpMulti = true;
    }

    this.fieldFocus.emit();
  }

  onKeyPress(event: KeyboardEvent): boolean {
    const numberType = 'NUMBER';
    const charCodes = {
      plus: 43,
      dash: 45,
      period: 46,
      letterE: 101
    };

    if (this.type && this.type.toUpperCase() === numberType && event && event.charCode) {
      switch (event.charCode) {
        case charCodes.plus:
        case charCodes.dash:
        case charCodes.period:
        case charCodes.letterE:
          return false;
        default:
          return true;
      }
    }
  }
  
  getADAValue(): void {
    this.ADAValue = '';

    if (this.value) {
      this.ADAValue = this.value;
    } else {
      this.ADAValue = 'Empty';
    }

    if (this.isDisabled || this.isDisabled === '') {
      this.ADAValue = this.value ? `${this.value} disabled` : 'Disabled';
    }
  }

  checkForValidationsErrors(value: string) {
    let hasError: boolean = false;

    if (this.fieldValidation.forceError) {
      this.validationMsg = this.fieldValidation.forceErrorMessage || '';
      return true;
    }

    if (this.fieldValidation.required && !(value.length || typeof value === 'number')) {
      this.validationMsg = (this.fieldValidation.requiredMsg) ? this.fieldValidation.requiredMsg : 'Required';
      return true;
    }

    if (this.fieldValidation.originalValue && value === this.fieldValidation.originalValue) {
      return false;
    }

    if (this.fieldValidation.validations.length) {
      this.fieldValidation.validations.some(validation => {
        if (validation.uniqueValueRule) {
          value = validation.uniqueValueRule(value);
        }

        if (validation.pattern) {
          const regex = new RegExp(validation.pattern);
          hasError = !(regex.test(value));
          if (hasError) {
            this.validationMsg = (validation.message) ? validation.message : 'Incorrect format';
            return true;
          }
        }

        if (validation.validationRule) {
          hasError = !validation.validationRule(value);
          if (hasError) {
            this.validationMsg = (validation.message) ? validation.message : 'Incorrect format';
            return true;
          }
        }

        if(validation.matchesValueById) {
          const matchesInput = (<HTMLInputElement>document.getElementById(validation.matchesValueById + 'Input')); // access embedded input id, not app-input component id
          if (matchesInput) {
            let matchesValue = matchesInput.value;
            hasError = !(this.value === matchesValue);
            if (hasError) {
              this.validationMsg = (validation.message) ? validation.message : 'Entries do not match';
              return hasError;
            }
          }
        }
      });
    }
    
    return hasError;
  }

}
