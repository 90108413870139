import Endpoints from "../constants/endpoints";
import { Alert, AlertType } from "../models/alert";
import { BillingPaymentAccount, ScheduleMakePaymentAccountResponse } from "../models/billingPaymentAccount";
import { InstrumentDetailsStorage, ScheduleMakePaymentRs, ScheduledPaymentDetails, ScheduledPaymentAccount, CancelScheduledPaymentsRq, ScheduledPayment, ScheduleMakePaymentRq, PaymentDetailsInfo } from "../models/payments";

import { SCHEDULE_MAKE_PAYMENT_STATUS, SCHEDULE_MAKE_PAYMENT_STATUS_CODES } from "../constants/payment";
import Billing from "../models/billing";
import { CurrencyPipe, DatePipe } from "@angular/common";
import DatePickerMethods from "./datepicker.methods";
import DateMethods from "./date.methods";
import PolicyMethods from "./policy.methods";
import PaymentsMethods from "./payments.methods";

export default class ScheduledPaymentsMethods {
  private static currencyPipe: CurrencyPipe = new CurrencyPipe('en-US');
  private static datePipe: DatePipe = new DatePipe('en-US');

  public static allowNavToScheduleMakePaymentConfirmation(statusCode: number): boolean {
    let navToConfirmation: boolean = false;

    switch (statusCode) {
      case SCHEDULE_MAKE_PAYMENT_STATUS_CODES.success:
      case SCHEDULE_MAKE_PAYMENT_STATUS_CODES.partialFailure:
        navToConfirmation = true;
        break;
      default:
        break;
    }

    return navToConfirmation;
  }

  public static createScheduleMakePaymentsRq(instrumentDetailsStorage: InstrumentDetailsStorage, scheduleDate: string): ScheduleMakePaymentRq {
    let scheduleMakePaymentRq: ScheduleMakePaymentRq = new ScheduleMakePaymentRq();

    if (instrumentDetailsStorage.cardInstrumentDetailsResponse) {
      scheduleMakePaymentRq.instrument = PaymentsMethods.buildCCInstrumentFromDetails(instrumentDetailsStorage.cardInstrumentDetailsResponse, instrumentDetailsStorage.storeInstrumentForReuse, instrumentDetailsStorage.customerName);
    }
    scheduleMakePaymentRq.paymentDetails = this.buildPaymentDetailsForScheduleMakePayment(instrumentDetailsStorage.selectedAccounts, scheduleDate);

    return scheduleMakePaymentRq;
  }

  public static createCancelScheduledPaymentAlert(isSuccess: boolean, billingAccount: string, currentScheduledPayment: ScheduledPaymentDetails): Alert {
    const alert = isSuccess ? new Alert(AlertType.POSITIVE) : new Alert(AlertType.NEGATIVE);
    let message = 'Oh dear, something went wrong. Please try again.';
    let heapId = 'MMA-View-Notification|CancelScheduledPayment|Error';

    if (billingAccount && billingAccount.length && currentScheduledPayment && currentScheduledPayment.scheduledDate)
    {
      const scheduledDateString = this.datePipe.transform(currentScheduledPayment.scheduledDate, 'shortDate');
      const amount = this.currencyPipe.transform(currentScheduledPayment.amount, 'USD');

      if (isSuccess) {
        message = `Your scheduled payment for <b>${amount}</b> on <b>${scheduledDateString}</b> has been canceled.`;
        heapId = 'MMA-View-Notification|CancelScheduledPayment|Success';
      } else {
        message = `There was a problem canceling your scheduled payment of <b>${amount}</b> on <b>${scheduledDateString}</b>. Please try again.`
      }
    }
    
    alert.messages = [message];
    alert.heapId = heapId;

    return alert;
  }

  public static createErrorAlertForScheduleMakePaymentSubmit(responseStatusDescription: string): Alert {

    const alert: Alert = new Alert(AlertType.NEGATIVE);
    const linkHeapIDStart: string = 'PaymentReview_acts_link_error_';
    const reviewInformationMsg: string = `{ "text" : "review your information", "url" : "${Endpoints.url.paymentMethod}", "heapId" : "${linkHeapIDStart}payment_method" }`;
    let message: string = 'Oh dear, something went wrong. Please '.concat(reviewInformationMsg, ' and try again.');

    alert.messages = [message];
    const heapIdPage = 'ScheduleMakePaymentReview';
    alert.heapId = `MMA-View-NotificationsPayment|${heapIdPage}|${responseStatusDescription}`;

    return alert;
  }

  public static createErrorAlertForGetScheduleMakePayments(responseStatusDescription: string): Alert {

    const alert: Alert = new Alert(AlertType.NEGATIVE);
    let message: string = 'If a payment has been scheduled, please check back later for more details';
    alert.messages = [message];
    const heapIdPage = 'GetScheduleMakePayments';
    alert.heapId = `MMA-View-NotificationSystemError|${heapIdPage}|${responseStatusDescription}`;
    return alert;
  }

  public static translateScheduleMakePaymentsResponse(instrumentDetailsStorage: InstrumentDetailsStorage, scheduledPaymentResult: ScheduledPaymentAccount[]): InstrumentDetailsStorage {

    instrumentDetailsStorage.selectedAccounts.forEach((selectedAccount: BillingPaymentAccount) => {
      if (scheduledPaymentResult && scheduledPaymentResult.length) {
        const scheduledPayment: ScheduledPaymentDetails =
          this.getScheduleMakePaymentForSelectedAccount(selectedAccount, scheduledPaymentResult); 

        this.buildScheduleMakePaymentAccountResponse(selectedAccount, scheduledPayment);
      }
    });

    instrumentDetailsStorage.selectedAccounts.sort((a, b) => 
      PaymentsMethods.sortPaymentAccountsByStatusCodeDesc(parseFloat(a.scheduleMakePaymentResponse?.statusCode), parseFloat(b.scheduleMakePaymentResponse?.statusCode)));

    return instrumentDetailsStorage;
  }

  private static buildPaymentDetailsForScheduleMakePayment(selectedAccounts: BillingPaymentAccount[], scheduleDate: string) {
    const paymentDetailsInfo: PaymentDetailsInfo[] = [];

    if (selectedAccounts && selectedAccounts.length > 0) {
      selectedAccounts.forEach((selectedAccount: BillingPaymentAccount) => {
        const paymentDetailsItem: PaymentDetailsInfo = new PaymentDetailsInfo();
        paymentDetailsItem.amount = PaymentsMethods.getAmountToPay(selectedAccount);
        paymentDetailsItem.billingAccountNumber = selectedAccount.billingAccountNumber;
        paymentDetailsItem.scheduledDate = scheduleDate;
        paymentDetailsInfo.push(paymentDetailsItem);
      });
    }

    return paymentDetailsInfo;
  }


  private static getScheduleMakePaymentForSelectedAccount(selectedAccount: BillingPaymentAccount, scheduledPaymentResults: ScheduledPaymentAccount[]): ScheduledPaymentDetails {
    let result: ScheduledPaymentDetails = null;
    const matchingScheduledPayment = scheduledPaymentResults.find((payment: ScheduledPaymentAccount) =>
      payment.accountNumber === selectedAccount.billingAccountNumber
    );

    if (matchingScheduledPayment && matchingScheduledPayment.scheduledPayments && matchingScheduledPayment.scheduledPayments.length) {
      result = matchingScheduledPayment.scheduledPayments[0];
    }

    return result;
  }

  private static sortScheduledPaymentsByDateDesc(scheduledPayments: ScheduledPaymentDetails[]): ScheduledPaymentDetails[] {
    if (scheduledPayments && scheduledPayments.length) {
      return scheduledPayments.sort((a,b) => DateMethods.sortByDateStringDesc(a.scheduledDate,b.scheduledDate))
    }
  }

  private static buildScheduleMakePaymentAccountResponse(selectedAccount: BillingPaymentAccount, scheduledPayment: ScheduledPaymentDetails) {
    const scheduleMakePaymentAccountResponse: ScheduleMakePaymentAccountResponse = new ScheduleMakePaymentAccountResponse();

    if (scheduledPayment) {
      scheduleMakePaymentAccountResponse.statusCode = scheduledPayment.scheduledStatus;
      scheduleMakePaymentAccountResponse.statusMessage = scheduledPayment.scheduledStatusDesc;
      scheduleMakePaymentAccountResponse.paymentConfirmationNumber = scheduledPayment.clientPaymentId;

      selectedAccount.paymentConfirmationNumber = scheduledPayment.clientPaymentId;
      selectedAccount.scheduleMakePaymentResponse = scheduleMakePaymentAccountResponse;
    } else {
      scheduleMakePaymentAccountResponse.statusCode = SCHEDULE_MAKE_PAYMENT_STATUS.failed;
    }

    selectedAccount.scheduleMakePaymentResponse = scheduleMakePaymentAccountResponse;
  }

  private static hasInProcessScheduledPayment(scheduledPayments: ScheduledPaymentDetails[]): boolean {
    let showMessage: boolean = false;

    if (scheduledPayments && scheduledPayments.length && scheduledPayments.some(scheduledPayment => this.hasScheduledOrSubmittedPayment(scheduledPayment.scheduledStatus))) {
      showMessage = true;
    }

    return showMessage;
  }

  public static isSchedulePaymentEligible(selectedAccount: BillingPaymentAccount): boolean {
    let isAccountEligible: boolean = false;
    
    if (selectedAccount.isRegularPay && !selectedAccount.isPastDue && !this.hasInProcessScheduledPayment(selectedAccount.scheduledMakePayments)) {
      if (!selectedAccount.nextPaymentDate) {
        if (!selectedAccount.nextBillDate || this.hasFutureNextBillDate(selectedAccount)) {
          isAccountEligible = true;
        }
      } else if (this.hasFutureNextPaymentDate(selectedAccount)) {
        isAccountEligible = true;
      }
    }

    return isAccountEligible;
  }

  public static hasFutureNextPaymentDate(selectedAccount: BillingPaymentAccount): boolean {
    let hasFutureNextPaymentDate: boolean = false;

    if (selectedAccount && selectedAccount.nextPaymentDate && DatePickerMethods.isDateAfterTodayExclusive(selectedAccount.nextPaymentDate)) {
      hasFutureNextPaymentDate = true;
    }

    return hasFutureNextPaymentDate;
  }

  public static hasFutureNextBillDate(selectedAccount: BillingPaymentAccount): boolean {
    let hasFutureNextBillDate: boolean = false;

    if (selectedAccount && selectedAccount.nextBillDate && DatePickerMethods.isDateAfterTodayExclusive(selectedAccount.nextBillDate)) {
      hasFutureNextBillDate = true;
    }

    return hasFutureNextBillDate;
  }

  private static hasScheduledOrSubmittedPayment(status: string): boolean {
    let hasScheduledOrSubmittedPayment: boolean = false;

    switch (status) {
      case SCHEDULE_MAKE_PAYMENT_STATUS.scheduled:
      case SCHEDULE_MAKE_PAYMENT_STATUS.submitted:
        hasScheduledOrSubmittedPayment = true;
        break;
      default:
        break;
    }

    return hasScheduledOrSubmittedPayment;
  }

  public static isPaymentScheduledStatus(status: string): boolean {
    let isScheduledStatus: boolean = false;

    if (status && status === SCHEDULE_MAKE_PAYMENT_STATUS.scheduled) {
      isScheduledStatus = true;
    }

    return isScheduledStatus;
  }

  public static isPaymentSubmittedStatus(status: string): boolean {
    let isSubmittedStatus: boolean = false;

    if (status && status === SCHEDULE_MAKE_PAYMENT_STATUS.submitted) {
      isSubmittedStatus = true;
    }

    return isSubmittedStatus;
  }

  private static hasScheduledPaymentForDisplay(scheduledPayment: ScheduledPaymentDetails, currentAmountDue: number, lastPaymentDate: Date): boolean {
    let hasScheduledPaymentForDisplay: boolean = false;

    if (scheduledPayment) {
      hasScheduledPaymentForDisplay = this.hasScheduledOrSubmittedPayment(scheduledPayment.scheduledStatus) || 
                                      this.isFailedPaymentEligibleForDisplay(scheduledPayment, currentAmountDue, lastPaymentDate)
    }

    return hasScheduledPaymentForDisplay;
  }

  private static isFailedPaymentEligibleForDisplay(scheduledPayment: ScheduledPaymentDetails, currentAmountDue: number, lastPaymentDate: Date) : boolean {
    let isEligibleForDisplay: boolean = false;

    if (scheduledPayment && scheduledPayment.scheduledPaymentInfo && scheduledPayment.scheduledPaymentInfo.isPaymentProcessFailed) {
      isEligibleForDisplay = currentAmountDue > 0 && !this.hasPaymentAfterScheduledDate(scheduledPayment, lastPaymentDate);
    }

    return isEligibleForDisplay;
  }

  private static hasPaymentAfterScheduledDate(scheduledPayment: ScheduledPaymentDetails, lastPaymentDate: Date): boolean {
    let isPaymentAfterScheduledDate: boolean = false;
    const formattedDateString: string = lastPaymentDate != null && !DateMethods.isDateMinValue(lastPaymentDate) ? DateMethods.formatDateString(new Date(lastPaymentDate)) : null;

    if (formattedDateString) {
        if (DateMethods.checkAfterDate(formattedDateString, scheduledPayment.scheduledDate)) {
        isPaymentAfterScheduledDate = true;
      }
    }

    return isPaymentAfterScheduledDate;
  }

  public static getScheduledPaymentMessage(scheduledPayment: ScheduledPaymentDetails, currentAmountDue: number, lastPaymentDate: Date): string {
    let message: string = '';

    if (scheduledPayment && this.hasScheduledPaymentForDisplay(scheduledPayment, currentAmountDue, lastPaymentDate)) {
      if (scheduledPayment.scheduledPaymentInfo && scheduledPayment.scheduledPaymentInfo.isPaymentProcessFailed) {
        message = this.getFailedStatusMessage(scheduledPayment);
      } else if (this.isPaymentScheduledStatus(scheduledPayment.scheduledStatus)) {
        message = this.getScheduledStatusMessage(scheduledPayment);
      } else if (this.isPaymentSubmittedStatus(scheduledPayment.scheduledStatus)) {
        message = this.getSubmittedStatusMessage(scheduledPayment);
      }
    }
    
    return message;
  }

  private static getScheduledStatusMessage(scheduledPayment: ScheduledPaymentDetails) {
    let scheduledMessage = "";

    if (scheduledPayment && scheduledPayment.amount && scheduledPayment.scheduledDate) {
      scheduledMessage = `A payment of ${this.currencyPipe.transform(scheduledPayment.amount)} is scheduled for ${this.datePipe.transform(scheduledPayment.scheduledDate, 'M/d/yyyy')}.`;
    }

    return scheduledMessage;
  }

  private static getSubmittedStatusMessage(scheduledPayment: ScheduledPaymentDetails) {
    let processingMessage = "";

    if (scheduledPayment && scheduledPayment.amount) {
      processingMessage = `We're processing your scheduled payment of ${this.currencyPipe.transform(scheduledPayment.amount)}. It could take up to 24 hours for the payment to be visible in your online account.`;
    }

    return processingMessage;
  }

  private static getFailedStatusMessage(scheduledPayment: ScheduledPaymentDetails) {
    let failedMessage = "";

    if (scheduledPayment && scheduledPayment.amount) {
      failedMessage = `We were not able to process your scheduled payment of ${this.currencyPipe.transform(scheduledPayment.amount)} on ${this.datePipe.transform(scheduledPayment.scheduledDate, 'shortDate')}.`
      if (window.location.href.indexOf(Endpoints.url.payment) > -1) {
        failedMessage += ' Please select this account and pay now.'
      } else {
        failedMessage += ' Please pay now.';
      }
    }

    return failedMessage;
  }

  public static setCurrentScheduledPaymentsForBillingAccounts(scheduledPaymentAccounts: ScheduledPaymentAccount[], billingAccounts: Billing[]): Billing[] {
    if (billingAccounts && billingAccounts.length) {
      //reset
      billingAccounts.forEach(ba => {ba.currentScheduledPayment = null; ba.scheduledMakePayments = null;});

      if (scheduledPaymentAccounts && scheduledPaymentAccounts.length) {
        scheduledPaymentAccounts.forEach((account: ScheduledPaymentAccount) => {
          const billingAccount: Billing = billingAccounts.find(nba => nba.number === account.accountNumber);
  
          if (billingAccount) {
            let currentScheduledPayment = new ScheduledPaymentDetails();
            currentScheduledPayment = account.scheduledPayments[0];
            currentScheduledPayment.schedulePaymentMessage = this.getScheduledPaymentMessage(currentScheduledPayment, billingAccount.amountDue, billingAccount.lastPaymentDate);
            billingAccount.currentScheduledPayment = currentScheduledPayment; 
            billingAccount.scheduledMakePayments = account.scheduledPayments;
          }
        });
      }
    }
    
    return billingAccounts;
  }

  public static buildCancelScheduledPaymentsRq(scheduledPaymentDetails : ScheduledPaymentDetails): CancelScheduledPaymentsRq {
    let cancelScheduledPaymentsRq = new CancelScheduledPaymentsRq();
    let scheduledPayment = new ScheduledPayment();

    scheduledPayment.amount = scheduledPaymentDetails.amount.toString();
    scheduledPayment.clientPaymentId = scheduledPaymentDetails.clientPaymentId;
    scheduledPayment.scheduledDate = scheduledPaymentDetails.scheduledDate;

    cancelScheduledPaymentsRq.scheduledPayments = [scheduledPayment];

    return cancelScheduledPaymentsRq;
  }

  public static getProcessedScheduledPaymentDetails(scheduledPaymentAccount: ScheduledPaymentAccount): ScheduledPaymentDetails[] {
    let scheduledPaymentDetails: ScheduledPaymentDetails[] = [];

    if (scheduledPaymentAccount && scheduledPaymentAccount.scheduledPayments && scheduledPaymentAccount.scheduledPayments.length) {
      scheduledPaymentDetails = scheduledPaymentAccount.scheduledPayments.filter(a => a.scheduledStatus === SCHEDULE_MAKE_PAYMENT_STATUS.success);
    }

    return scheduledPaymentDetails;
  }
    
  public static getScheduledPaymentAccounts(scheduledMakePayment: ScheduleMakePaymentRs): ScheduledPaymentAccount[] {
    let scheduledPaymentAccounts: ScheduledPaymentAccount[] = [];
    if (scheduledMakePayment.scheduledPaymentAccounts && scheduledMakePayment.scheduledPaymentAccounts.length) {
      scheduledMakePayment.scheduledPaymentAccounts.forEach((account) => {
        if (account.scheduledPayments && account.scheduledPayments.length) {
          account.scheduledPayments = this.sortScheduledPaymentsByDateDesc(account.scheduledPayments);
          scheduledPaymentAccounts.push(account);
        }
      });
    } 

    return scheduledPaymentAccounts;
  }

  public static hasClientPaymentId(scheduledPaymentAccount: ScheduledPaymentAccount, clientPaymentId: string) : boolean {
    let hasClientPaymentId = false;

    if(scheduledPaymentAccount) {
      hasClientPaymentId = scheduledPaymentAccount.scheduledPayments.some(sp => sp.clientPaymentId === clientPaymentId);
    }

    return hasClientPaymentId;
  }
}
