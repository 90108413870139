export default class CrossSellOffer {
    agency: CrossSellAgency;
    crossSellPolicies: CrossSellPolicy[];
    customer: CrossSellCustomer;
    dispositions: CrossSellDisposition[];
    lobOffer: string;
    offerAvailable: boolean;
    opportunityId: number;
    savings: number;
    sessionReferenceId: string;
    sourcePolicyNumber: string;
    sourceLob: string;
    sourceLobId: number;
    statusCode: number;
    statusDescription: string;
    user: string;
}

export class CrossSellDisposition {
    dispositionId: number;
    opportunityId: number;
}

export class CrossSellPolicy {
    lineOfBusiness: string;
    policyNumber: string;
    ratingState: string;
}

export class CrossSellAgency {
    agencyName: string;
    agencyPhone: string;
    agencyEmail: string;
    goldAgency: boolean;
    goldPlusAgency: boolean;    
}

export class CrossSellCustomer {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
}

export enum DISPOSITION_EVENT {
    offerClicked = 5,
    offerLobAlreadyExists = 6,
    offerGenricOverride = 7,
    offerDisplayed = 8
    //offerNewBusinessOverride = 9,
    //offerRenewalOverride = 10,
}
