import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnChanges {

  @Input() tabs: any[];
  @Input() tabLabel: string;
  @Input() width?: string = 'fill'; // auto, fill, fixed
  @Input() selectedIndex?: number;
  @Output() selectedTab = new EventEmitter();

  constructor() { }

  ngOnChanges() {
    if (this.selectedIndex > 0) {
      this.setSelected(this.selectedIndex);
    } else {
      this.setSelected(0);
    }
  }

  setSelected = function (selectedIx: number) {
    let selected = false;
    for (let i = 0; i < this.tabs.length; i++) {
      selected = (i === selectedIx);
      this.tabs[i].previouslySelected = this.tabs[i].selected;
      this.tabs[i].selected = selected;
      if (selected) {
        this.selectedTab.emit(this.tabs[i]);
      }
    }
  };

}
