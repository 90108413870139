import { Component, OnInit} from '@angular/core';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { Features } from 'src/app/utilities/models/features';
import Endpoints from 'src/app/utilities/constants/endpoints';

@Component({
    selector: 'app-pagemissing',
    templateUrl: './pageMissing.component.html',
    styleUrls: ['./pageMissing.component.scss']
})

export class PageMissingComponent implements OnInit {

    url = Endpoints.url;
    
    constructor(
        private commonService: CommonService
    ) {}

    ngOnInit() {
        
    }

}
