<label id="{{id}}-label" class="sco-label sco-label-textArea" for="{{id}}"
  [ngClass]="{'is-focused':focus, 'is-populated':populated, 'is-error':error, 'is-disabled': isDisabled !== null}">
  <span *ngIf="!hideHelperText" class="sco-label-visual small-helper-text" aria-hidden="true">{{label}}</span>
  <span *ngIf="!hideHelperText" class="sco-label-a11y">{{label}}</span>
  <textarea rows="10" cols="10" id="{{id}}" placeholder="{{placeholder}}" name="{{name}}" class="sco-input sco-input--textArea"
    [ngStyle]="{'resize': resize ? 'vertical' : 'none'}" (input)="onChange($event.target.value)" (blur)="onBlur()"
    class="sco-input sco-input-textInput" (focus)="focus=true;onFocus();" (focusout)="focus=false" [value]="value"
    [attr.data-value]="ADAValue" [attr.disabled]="isDisabled" tabindex="0" role="input" (keypress)="onKeyPress($event)"
    [attr.maxlength]="maxLength"></textarea>
  <div class="below-input-content" [ngClass]="{'has-error': error}">
    <div class="sco-messageGroup" *ngIf="error">
      <div id="text-error">
        <span class="sco-message sco-message--error">{{validationMsg}}</span>
      </div>
    </div>
    <div *ngIf="maxLength" id="character-count">{{ value.length }} / {{maxLength}} </div>
  </div>
</label>