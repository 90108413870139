
export const Platform = {
    DOCUSIGN: 'D',
    ONESPAN: 'V'
};

export const EsignStatus = {
    PENDING: 'Pending',
}

export const NeedToSignStatus = {
    ACTIVATED: 'activated',
    CREATED: 'created',
    IN_PROGRESS: 'in_progress',
    SENT: 'sent'
}

export const EsignPolicySignerStatus = {
    EMAIL_BOUNCE: 'email_bounce',
    IN_PROGRESS: 'in_progress',
    OPEN: 'open',
    SENT: 'sent'
}

export const ValidEsignPolicyTypes = {
    FIRE: "Fire",
    BOAT: "Boat",
    AUTO: "Auto",
    EARTHQUAKE: "Earthquake",
    HOME: "Home",
    MOTORCYCLE: "Motorcycle",
    RV: "RV",
    RENTERS: "Renters",
    UMBRELLA: "Umbrella"
}

export const StatusPageMessages = {
    AUTO_CONTENT_TITLE: 'ID cards a tap away in the Safeco mobile app',
    AUTO_LINK_SECTION_TITLE: 'Access your policy any time with the new Safeco mobile app. It\'s the easiest way to get your ID cards and manage your policy.',
    NON_AUTO_CONTENT_TITLE: 'Your policy information a tap away in the Safeco mobile app',
    NON_AUTO_LINK_SECTION_TITLE: 'Access your policy any time with the new Safeco mobile app. It\'s the easiest way to manage your policy.',
    MORE_POLICIES_TO_SIGN_ALERT: 'Policy signed &mdash; way to go! Now, please sign the other one(s) below.',
    SOME_POLICIES_CANNOT_LOAD_ALERT: 'Just so you know, we can’t load all of your policies right now, but you can still sign the one(s) below.',
    ALL_POLICIES_CANNOT_LOAD_ALERT: 'Just a reminder, you still need to sign these policies, but we can\'t load them for you right now:',
    CHECK_BACK_LATER_ALERT: 'Please check back later to finish signing',
    EXPLORE_ACCOUNT_MESSAGE: 'Now go explore your account.',
    ESIGN_PAGE_TITLE: 'Sign your policy documents',
    STATUS_PAGE_TITLE: 'Your policy is ready to sign'
}
