const NotificationMessageId = {
  NEW_BUSINESS: 999,
  MTP_BILLED_TO_USER: 1000,
  PAYMENT_DUE: 1001,
  CANCELED_ACCOUNT_W_BALANCE: 1002,
  REG_PAY_PAST_DUE: 1003,
  POST_MRK_DATE_OVERDUE: 1009,
  RCC_DECLINED: 1013,
  PAYMENT_PENDING: 1004,
  RTB_PAYMENT_SUCCESS: 1005,
  RTB_PAYMENT_PENDING: 1015,
  MTP_POLICY_CHANGE_BALANCE: 2003,
  PAYROLL_DEDUCTION_ALL_SET:2001,
  MTP_ALL_SET:2002,
  RCC_All_SET:2004,
  EFT_All_SET:2005,
  PAID_IN_FULL:2006
};

export default NotificationMessageId;