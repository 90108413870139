
export const BillingAccountStatuses = {
    ACTIVE: 'ACTIVE',
    BALANCE_DUE: 'BALANCE DUE',
    INACTIVE: 'INACTIVE',
    NOT_FOUND: 'NOTFOUND',
    WARNING: 'WARNING',
    CANC_PENDING: 'CANC PENDING',
    ACTIVE_W_REIN: 'ACTIVE ACCOUNT W REIN PENDING',
    INACTIVE_W_REIN: 'INACTIVE ACCOUNT W REIN PENDING',
    ACTIVE_W_REAC: 'ACTIVE ACCOUNT W REAC PENDING',
    INACTIVE_W_REAC: 'INACTIVE ACCOUNT W REAC PENDING'
}