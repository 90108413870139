
import { BillingAccountStatuses } from 'src/app/utilities/constants/billingAccountStatuses';
import { getLOBNameByNumber, isGenericNickname, LOBName } from 'src/app/utilities/constants/linesOfBusiness';
import { PaymentMethods } from 'src/app/utilities/constants/paymentMethods';

import { Alert, AlertType } from 'src/app/utilities/models/alert';
import Billing from 'src/app/utilities/models/billing';
import Policy from 'src/app/utilities/models/policy';

import PolicyMethods from 'src/app/utilities/methods/policy.methods';
import * as LOBUtility from 'src/app/utilities/constants/linesOfBusiness'
import { BillingPaymentAccount } from '../models/billingPaymentAccount';
export default class BillingMethods {

  // Used to search the billing account for the first policy number it finds
  public static getPolicyNumberFromBillingAccount(billingAccount: Billing): string {
    let policyNumber =  billingAccount.policyNumber || '';

    if (!policyNumber) {
      const policyWithNumber = billingAccount.policyList.find((policy: Policy) => !!policy.number);

      if (policyWithNumber) {
        policyNumber = policyWithNumber.number;
      }
    }

    return policyNumber;
  }

  public static getUserPolicyListFromBillingAccounts(billingAccounts: Billing[]): string[] {
    const policyList: string[] = [];
    try {
      billingAccounts.forEach((account) => {
        policyList.push(this.getPolicyNumberFromBillingAccount(account));
      });
    } catch (e) {
      // continue regardless of error
    }

    return policyList;
  }

  public static setEffectiveRecentlyDate(accounts: Billing[], policies: Policy[]): Billing[] {
    const effectiveRange: number = 5;

    accounts.forEach((account: Billing) => {
      account.isAccountEffectiveDateRecent = false;

      if (this.isAccountNotFound(account)) {
        const policyNumber: string = this.getPolicyNumberFromBillingAccount(account);
        const accountPolicy: Policy = PolicyMethods.getPolicyByNumber(policyNumber, policies);

        if (accountPolicy) {
          // account.policyList = [accountPolicy];

          if (accountPolicy.termBeginDate) {
            const todaysDate: Date = new Date();
            const dateCriteriaEndDate: Date = new Date(accountPolicy.termBeginDate);
            dateCriteriaEndDate.setDate(dateCriteriaEndDate.getDate() + effectiveRange);

            if (todaysDate <= dateCriteriaEndDate) {
              account.isAccountEffectiveDateRecent = true;
            }
          }
        }
      }
    });

    return accounts;
  }

  public static getBillingAccountByPolicyNumber(accounts: Billing[], policyNumber: string): Billing {
    if (accounts && accounts.length && policyNumber) {
      return accounts.find((billing: Billing) => billing.policyList.find((policy: Policy) => policy.number === policyNumber));
    }
  }

  // Filters out all billing accounts that:
  //  do not have an account status of "NOT_FOUND"
  //  are not inactive or canceled with a 0 total balance remaining amount
  // Will return an empty array if no billing accounts are considered active
  public static filterOutInactiveAccounts(billingAccounts: Billing[]): Billing[] {
    let activeBillingAccounts: Billing[] = [];

    if (billingAccounts && billingAccounts.length) {
      activeBillingAccounts = billingAccounts.filter((account: Billing) => {
        return (
          account.accountStatus &&
          account.accountStatus.toUpperCase() !== BillingAccountStatuses.NOT_FOUND &&
          (!account.isInactiveOrCanceledAccount || account.isInactiveOrCanceledAccount && account.totalBalanceRemainingAmount > 0)
        );
      });
    }

    return activeBillingAccounts;
  }

  public static getNonCanceledAccountsWithFoundPolicies(accounts: Billing[]): Billing[] {
    let nonCanceledAccountsWithFoundPolicies: Billing[] = [];

    if (accounts && accounts.length) {
      nonCanceledAccountsWithFoundPolicies = accounts.filter((account: Billing) => (!account.isInactiveOrCanceledAccount || this.isCanceledWithBalance(account)) && account.isPolicyFound);
    }

    return nonCanceledAccountsWithFoundPolicies;
  }

  public static getInactiveWithNoBalanceAccounts(accounts: Billing[]): Billing[] {
    let inactiveAccountsWithNoBalance: Billing[] = [];

    if (accounts && accounts.length) {
      inactiveAccountsWithNoBalance = accounts.filter((account: Billing) => (account.isInactiveOrCanceledAccount && account.totalBalanceRemainingAmount <= 0))
    }

    return inactiveAccountsWithNoBalance;
  }

  public static filterOutThirdPartyPay(billingAccounts: Billing[]): Billing[] {
    let nonMTPAccounts: Billing[] = [];

    if (billingAccounts && billingAccounts.length) {
      nonMTPAccounts = billingAccounts.filter((account: Billing) => this.isDirectPay(account));
    }

    return nonMTPAccounts;
  }

  public static filterOutNonRecurringPay(billingAccounts: Billing[]): Billing[] {
    let recurringAccounts: Billing[] = [];

    if (billingAccounts && billingAccounts.length) {
      recurringAccounts = billingAccounts.filter((account: Billing) => this.isAutoPay(account));
    }

    return recurringAccounts;
  }

  public static isRegularPay(account: Billing) {
    const payType: string = this.getPaymentMethod(account);

    switch (payType) {
      case PaymentMethods.REGULAR:
        return true;
      default:
        return false;
    }
  }

  public static isAnyAccountRegularPay(accounts: Billing[]): boolean {
    let isAnyAccountRegularPay: boolean = false;

    if (accounts && accounts.length && accounts.some(account => this.isRegularPay(account))) {
      isAnyAccountRegularPay = true;
    }

    return isAnyAccountRegularPay;
  }

  public static isAnyAccountDirectPay(accounts: Billing[]): boolean {
    let isAnyAccountDirectPay = false;

    if (accounts && accounts.length) {
      isAnyAccountDirectPay = accounts.some(account => this.isDirectPay(account));
    }

    return isAnyAccountDirectPay;
  }

  public static isAutoPay(account: Billing): boolean {
    const payType: string = this.getPaymentMethod(account);

    switch (payType) {
      case PaymentMethods.RECURRING_CREDIT_CARD:
      case PaymentMethods.AUTOMATIC_DEDUCTION:
        return true;
      default:
        return false;
    }
  }

  public static isDirectPay(account: Billing): boolean {
    const payType: string = this.getPaymentMethod(account);

    switch (payType) {
      case PaymentMethods.RECURRING_CREDIT_CARD:
      case PaymentMethods.AUTOMATIC_DEDUCTION:
      case PaymentMethods.REGULAR:
        return true;
      default:
        return false;
    }
  }

  public static isThirdPartyPay(account: Billing): boolean {
    if (account.isPayrollDeduction || account.isPayByMortgagee) {
      return true;
    }

    return false;
  }

  public static isCanceledWithBalance(account: Billing): boolean {
    return (
      account.isInactiveOrCanceledAccount && account.totalBalanceRemainingAmount > 0
    );
  }

  public static isActive(account: Billing): boolean {
    return (
      (!account.isInactiveOrCanceledAccount && this.isAccountFound(account))
    );
  }

  public static isAccountRecent(account: Billing): boolean {
    return (
      account.isAccountEffectiveDateRecent
    );
  }

  public static isAccountNotFound(account: Billing) {
    if (account.accountStatus && account.accountStatus.toUpperCase() === BillingAccountStatuses.NOT_FOUND) {
      return true;
    } else {
      return false;
    }
  }

  public static isAccountFound(account: Billing) {
    if (account.accountStatus && account.accountStatus.toUpperCase() !== BillingAccountStatuses.NOT_FOUND) {
      return true;
    } else {
      return false;
    }
  }

  public static getPaymentMethod(account: Billing): string {
    const payType: string = account.paymentMethod ? account.paymentMethod.toUpperCase() : '';

    return payType;
  }

  public static getTotalBalanceToDisplay(account: Billing): number {
    const totalBalanceToDisplay: number = account.isRenewal ? account.totalBalanceInRenewal : account.totalBalanceRemainingAmount;
    return totalBalanceToDisplay;
  }

  public static formatPolicyNumber(policyNumber: string) {
    if (~policyNumber.indexOf('nbsp;*')) {
      policyNumber = policyNumber.replace('&', '').replace('nbsp;*', '');
    }
    return policyNumber;
  }

  public static createAccountNotFoundAlert(account?: Billing): Alert {
    const alert: Alert = new Alert(AlertType.NEGATIVE);
    const message: string = this.configureNotFoundMessage(account);

    alert.messages = [message];
    return alert;
  }

  private static configureNotFoundMessage(account?: Billing): string {
    let errorMessageText: string = `Sorry, we can't load your`;
    let policyIdentifier: string = '';

    if (account && account.policyList && account.policyList.length) {
      const policy: Policy = account.policyList[0];

      if (policy) {
        if (policy.nickName && !isGenericNickname(policy)) {
          policyIdentifier = policy.nickName;
        } else if (account.policyList[0].nickName && !isGenericNickname(account.policyList[0])) {
          policyIdentifier = account.policyList[0].nickName;
        } else if (policy.number) {
          policyIdentifier = policy.number;
        }
      }
    }

    if (policyIdentifier) {
      errorMessageText += ` ${policyIdentifier}`;
    }

    errorMessageText += ' billing info right now. Please check back soon.';

    return errorMessageText;
  }

  public static isMortgageeAccountPayable(account: Billing, renewalPremium: number) {
    let isAccountPayable: boolean = false;

    if (account && account.isPayByMortgagee) {
      let isBalancePayable: boolean = false;
      const amountToPay: number = this.getAmountToPay(account);

      const isInactiveWithBalance = account.isInactiveOrCanceledAccount && amountToPay;

      if (account.isRenewal) {
        if (renewalPremium && (amountToPay !== renewalPremium)) {
          isBalancePayable = true;
        } else if (!renewalPremium && amountToPay) {
          // renewalPremium unavailable
          isBalancePayable = true;
        }
      }

      if (!account.isRenewal && amountToPay) {
        isBalancePayable = true;
      }

      if (!isInactiveWithBalance && isBalancePayable) {
        isAccountPayable = true;
      }
    }

    return isAccountPayable;
  }

  public static removeCanceledPoliciesFromBillingAccounts(accounts: Billing[]) {
    if (accounts && accounts.length) {
      accounts.forEach((ba) => {
        if (ba.policyList && ba.policyList.length) {
          ba.policyList = this.getActiveOrCanceledWithBalancePolicies(ba);
        }
      });
    }

    return accounts;
  }

  public static getActiveOrCanceledWithBalancePolicies(account: Billing): Policy[] {
    let policies: Policy[] = [];

    if (account && account.policyList) {
      policies = account.policyList.filter(policy => PolicyMethods.IsPolicyActiveOrCanceledWithBalance(policy));
    }

    return policies;
  }

  public static getRenewalPolicyForBillingAccount(account: Billing, renewalPolicies: Policy[]) {
    if (account && renewalPolicies && renewalPolicies.length) {
      return renewalPolicies.find((renewalPolicy) => renewalPolicy.number === account.policyNumber);
    }
  }

  public static getAmountToPay(account: Billing) {
    let amountToPay: number = 0;

    if (account) {
      if (account.amountDue) {
        amountToPay = account.amountDue;
      } else if (account.totalBalanceRemainingAmount) {
        amountToPay = account.totalBalanceRemainingAmount;
      }
    }

    return amountToPay;
  }

  public static async validatePolicyListInRenewal(policies: Policy[], billingAccounts: Billing[]) {
    if (billingAccounts && billingAccounts.length > 1) {
      billingAccounts.filter((ba) => ba.isRenewal).forEach((rba) => {
        rba.policyList.forEach((baPolicy) => {
          if (baPolicy.isRenewal && baPolicy.isPostRenewal) {
            const policyTest = policies.find((pol) => !!~baPolicy.number.indexOf(pol.number));
            if (policyTest) {
              const inceptionDate = new Date(policyTest.inceptionDate);
              const today = new Date();
              inceptionDate.setDate(inceptionDate.getDate() + 10);
              if (inceptionDate > today) {
                baPolicy.isPostRenewal = false;
              }
            }
          }
        });
      });
    }
  }

  // Check billing accounts for any home or auto policy and retrieved the nickname 
  // from policy instead.
  public static verifiedAndUpdateBillingNicknames(billingAccounts: Billing[], policies: Policy[]): void {
    if (billingAccounts && billingAccounts.length && policies && policies.length) {
      billingAccounts.forEach((account: Billing) => {
        account.policyList.forEach((policy: Policy) => {
          if (policy.number) {
            switch (LOBUtility.getLOBGroupType(policy.lineOfBusiness)) {
              case LOBUtility.LOBName.auto:
              case LOBUtility.LOBName.home:
                policy.nickName = this.getNicknamesFromPolicy(policy, policies);
                break;
              case LOBUtility.LOBName.umbrella:
                policy.nickName = 'Umbrella';
                break;
              default:
                policy.nickName = policy.number;
                break;
            }
          }
        });
      });
    }
  }

  // Get the nickname from policy or return the policynumber as the nickname if no matches
  private static getNicknamesFromPolicy(policy: Policy, policies: Policy[]): string {
    let verifiedNickname = policy.number;

    let matchingPolicy = policies.find(pol => this.isSamePolicyNumbers(policy.number, pol.number));
    if (matchingPolicy) {
      verifiedNickname = matchingPolicy.nickName;
    }

    return verifiedNickname;
  }

  public static isSamePolicyNumbers(policyNumber1: string, policyNumber2: string): boolean {
    let isSamePolicyNumber = false;

    if (policyNumber1 != null && policyNumber2 != null) {
      policyNumber1 = this.addLeadingZeroesToComparePolicy(this.formatPolicyNumber(policyNumber1));
      policyNumber2 = this.addLeadingZeroesToComparePolicy(this.formatPolicyNumber(policyNumber2));

      if (policyNumber1 === policyNumber2) {
        isSamePolicyNumber = true;
      }
    }

    return isSamePolicyNumber;
  }

  // takes in a policynumber and return an 7 digit policy number padded with zeroes if necessary.
  private static addLeadingZeroesToComparePolicy(policyNumber: string): string {
    if (policyNumber.length) {
      policyNumber = policyNumber.toUpperCase();
      const polNumberPart = policyNumber.match(/\d+/g);
      const alphaPart = policyNumber.substr(0, policyNumber.length - polNumberPart.toString().length);
      let result = parseInt(polNumberPart.toString());

      if (!isNaN(result) && result.toString().length < 7) {
        policyNumber = alphaPart + result.toString().padStart(7, '0');
      }
    }
    return policyNumber;
  }

  public static isLOBRenewalBillingEligible(LOBNum: number): boolean {
    const LOB: string = LOBNum ? getLOBNameByNumber(LOBNum).toUpperCase() : '';

    switch (LOB) {
      case LOBName.auto:
      case LOBName.home:
        return true;
      default:
        return false;
    }
  }

  public static getPolicyNumbersStringFromPaymentAccounts(billingPaymentAccounts: BillingPaymentAccount[], isAuth: boolean): string {
    let paymentAccountPoliciesString = "";

    if (billingPaymentAccounts && billingPaymentAccounts.length) {
      const policyStrings: string[] = billingPaymentAccounts.map(account => isAuth ? this.getPolicyNumbersStringFromBillingPolicies(account.policies) : this.getPolicyNumbersStringFromBillingPolicies(account.searchPolicies));
      paymentAccountPoliciesString = policyStrings.join(",");
    }

    return paymentAccountPoliciesString;
  }

  public static getPolicyNumbersStringFromBillingPolicies(billingAccountPolicies: any[]): string {
    let billingAccountPoliciesString = "";

    if (billingAccountPolicies && billingAccountPolicies.length) {
      if (typeof billingAccountPolicies[0] === 'object') {      // if Policy[] passed in, filter and concatenate policy numbers
        
        //if authenticated, then filter.
        if (billingAccountPolicies[0] instanceof Policy) {
          billingAccountPolicies = billingAccountPolicies.filter(policy => PolicyMethods.IsPolicyActiveOrCanceledWithBalance(policy));
        }
        
        billingAccountPoliciesString = PolicyMethods.buildPolicyCommaDelimitedString(billingAccountPolicies);
      } else if (typeof billingAccountPolicies[0] === 'string') {      // if string[] passed in, concatenate
        billingAccountPoliciesString = billingAccountPolicies.join(",");
      }
    }

    return billingAccountPoliciesString;
  }

  public static getFirstPolicyStateFromBillingPolicies(billingAccountPolicies: Policy[]): string {
    let policyState: string = "";

    if (billingAccountPolicies && billingAccountPolicies.length) {
      policyState = billingAccountPolicies[0].ratingStateAlpha;
    }

    return policyState;
  }
}
