import { Component, Input, Output, EventEmitter, DoCheck, ElementRef  } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent extends InputComponent {
  @Input() type?: string = 'password';
  @Input() useToggle?: boolean;
  @Input() maxLength: string;

  showVisibilityToggle = true;
  toggleText = 'Show';

  showHide() {
    if (this.toggleText === 'Show') {
      this.type = 'text';
      this.toggleText = 'Hide';
    } else {
      this.type = 'password';
      this.toggleText = 'Show';
    }
  }

  onEnterPress(event) {
    if (event.key === 'Enter') {
      this.showHide();
    }
  }

  /*
  // use these if you want the Show/Hide text to only appear when input has focus
  // add to input in html: (focus)="onFocus()" (focusout)="onFocusOut()"
  // doesn't play well with clicking the Show/Hide text, need to fix
  onFocus() {
    if (this.useToggle) {
      this.showVisibilityToggle = true;
    }
  }
  onFocusOut() {
    if (this.useToggle) {
      this.showVisibilityToggle = false;
    }
  }*/
  
}
