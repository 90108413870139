import { Component, OnInit, ViewChild, ElementRef, HostListener, Inject, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { environment as env } from '../../../../../environments/environment';
import Endpoints from 'src/app/utilities/constants/endpoints';
import { PolicyService } from '../../../../utilities/services/policy-service/policy.service';
import { RoutingService } from 'src/app/utilities/services/routing-service/routing.service';
import { BillingService } from 'src/app/utilities/services/billing-service/billing.service';

import { isVehicularLOB } from '../../../../utilities/constants/linesOfBusiness';
import Policy from '../../../../utilities/models/policy';
import BillingAccount from '../../../../utilities/models/billing';
import CAMStorage from 'src/app/utilities/constants/CAMStorage';
import { PolicyChangeTypes, PolicyChangeViews, TypeParamSessionStorageKeys } from 'src/app/utilities/constants/policyChange';
import { ChatService } from 'src/app/utilities/services/chat-service/chat.service';
import { AccountService } from 'src/app/utilities/services/account-service/account.service';

class MenuItemState {
  name: string;
  expanded: boolean;
  preserve: boolean;

  constructor(name: string) {
    this.name = name;
    this.expanded = false;
    this.preserve = false;
  }
}

@Component({
  selector: 'app-megamenu',
  templateUrl: './megamenu.component.html',
  styleUrls: ['./megamenu.component.scss']
})

export class MegaMenuComponent implements OnInit {
  checkIfVehicularLOB: any = isVehicularLOB;
  imgLoc: string = env.imgLoc;
  mobileMenuToggle: boolean;
  showMenu: boolean;
  activePolicies: Policy[] = [];
  billingAccounts: BillingAccount[] = [];
  url = Endpoints.url;
  complexUrl = Endpoints.complexUrl;
  mapPolicyNumber: string = '';
  document: Document;
  @ViewChild('menuClose', { static: true }) menuClose: ElementRef;
  fileClaimURL: string = '';
  viewClaimsURL: string = '';
  isMobileApp: boolean = false;
  isMobileDevice: boolean = false;

  menuItems: MenuItemState[] = [
    new MenuItemState('policy'),
    new MenuItemState('billing'),
    new MenuItemState('claims'),
    new MenuItemState('profile'),
  ];

  closeDropdown: any;

  //auth0
  customerName: string;

  constructor(
    private commonService: CommonService,
    private policyService: PolicyService,
    private router: Router,
    protected routingService: RoutingService,
    private billingService: BillingService,
    private chatService: ChatService,
    private renderer: Renderer2,
    private accountService: AccountService,
    @Inject(DOCUMENT) document
  ) {
    this.document = document;
    this.renderer.listen('body', 'click', (e: Event) => {
      const target = (e.target as Element);
      if (target.className && (typeof target.className) === 'string' && target.className.indexOf('dropdown-menu') === -1 && target.className.indexOf('dropdown-toggle') === -1) {
        this.closeTabs(true);
      }
    });
  }

  ngOnInit() {
    this.isMobileDevice = this.commonService.isMobileDevice();
    this.mobileMenuToggle = false;
    // this.billingCallFinished = false;
    this.showConfig();
    this.getAccountInfo();
    this.collapseMenu();
    // this.getBillingAccounts();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.keyCode === 27 && this.mobileMenuToggle) {
      this.mobileMenuToggle = false;
      this.closeFocus();
    }
  }

  navigateTo(link: string) {
    this.routingService.navigateTo(link);
  }

  setMenuFocusClose() {
    this.menuClose.nativeElement.focus();
  }

  closeFocus(): void {
    const calledFromElement: HTMLElement = this.document.getElementById('mobile_nav_menu');
    if (calledFromElement) {
      calledFromElement.focus();
    }
  }

  async getAccountInfo() {
    try {
      await this.policyService.getPolicies();
      this.activePolicies = this.policyService.policies.filter(policy => !policy.isCanceled);
      this.activePolicies = this.activePolicies.filter(policy => policy.policyResponseStatus.isSuccessful);
    } catch (error) {
      console.log(error);
    }
  }

  async showConfig() {
    try {
      this.setCustomerName();
      this.setClaimsLinks();
    } catch (error) {
      console.log(error);
    }
  }

  setCustomerName() {
    const authUser = this.accountService.getAuthUser();

    if (authUser && authUser.name) {
      this.customerName = authUser.name;
    }
  }

  collapseMenu() {
    Array.from(document.querySelectorAll('.dropdown-menu a')).forEach((link) => {
      link.addEventListener('click', () => {
        this.mobileMenuToggle = false;
      });
    });
  }

  showDropdownMenu(name: string, preventHover: boolean, stayOpen?: boolean) {
    clearTimeout(this.closeDropdown);

    if (!preventHover) {
      const tab = this.menuItems.find((tab) => tab.name === name);

      this.clearMenuPreservation(tab.name);
      this.closeTabs();
      tab.expanded = !tab.expanded;
      if (tab.expanded) {
        tab.preserve = stayOpen || false;
      }
    }
  }
  closeTabsTimeout() {
    this.closeDropdown = setTimeout(() => {
      this.closeTabs();
    }, 1250);
  }

  closeTabs(forceClose?: boolean) {
    this.menuItems.forEach((tab) => {
      if (!tab.preserve || forceClose) {
        tab.expanded = false;
      }
      tab.preserve = false;
    });
  }

  clearMenuPreservation(selectedTab: string) {
    this.menuItems.forEach((tab) => {
      if (tab.name !== selectedTab) {
        tab.preserve = false;
      }
    });
  }

  async getBillingAccounts() {
    try {
      await this.billingService.getBilling();
      this.billingAccounts = this.billingService.billingAccounts;
      this.mapPolicyNumber = this.billingService.mapPolicyNumber;
    } catch (error) {
      console.log(error);
    }
    finally {
      // this.billingCallFinished = true;
    }
  }

  setActiveTab() {
    const url = window.location.href.toLowerCase();
    if (url.indexOf('changeemail') !== -1 || url.indexOf('changepassword') !== -1 || url.indexOf('communication') !== -1) {
      return 'profile';
    } else if (url.indexOf('documents') !== -1) {
      return 'documents';
    } else if (url.indexOf('policy') !== -1 || url.indexOf('makepayment') !== -1 || url.indexOf('lowmileage') !== -1) {
      return 'policies';
    } else if (url.indexOf('billing') !== -1) {
      return 'billing';
    } else {
      return 'homepage';
    }
  }

  clearSession(sessionVar: string) {
    CAMStorage.removeItemInStorage(sessionVar + '-policy');
  }

  // Used for changing policy docs via mega menu on the documents page
  handlePolicyTabDocsNavigation(policyNumber: string) {
    const url = decodeURI(this.complexUrl.getPolicyDocuments(policyNumber));
    const navFrom = window.location.href;

    this.router.navigateByUrl(url);
    if (navFrom.indexOf('documents') !== -1) {
      window.location.reload();
    }
  }

  navigateToPolicyChange() {
    this.mobileMenuToggle = false;
    this.clearSession(this.url.policyEdit);
  }

  navigateToUpdateAddress() {
    if (this.activePolicies && this.activePolicies.length) {
      this.mobileMenuToggle = false;

      const typeParamInfo: any = {};
      typeParamInfo[TypeParamSessionStorageKeys.HAS_TYPE_PARAM] = true;
      typeParamInfo[TypeParamSessionStorageKeys.SOURCE_URL] = this.router.url;
      CAMStorage.setItemInStorage(CAMStorage.storageKeys.policyEditTypeParamInfo, typeParamInfo);

      this.routingService.routeTo(this.complexUrl.getPolicyEdit(this.activePolicies[0].number), {
        queryParams: { type: PolicyChangeTypes.ADDRESS, view: PolicyChangeViews.UPDATE_ADDRESS }
      });
    }
  }

  setClaimsLinks() {
    this.fileClaimURL = this.url.fileClaim + this.chatService.getSessionContext();
    this.viewClaimsURL = this.url.viewClaims;
  }

}

