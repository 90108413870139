import PolicyDocument, { DocumentPolicies } from 'src/app/utilities/models/policyDocument';

export const PolicyDocumentDescriptions = {
    ENDORSEMENT: 'ENDORSEMENT',
    NEW_BUSINESS: 'NEW BUSINESS',
    RENEWAL: 'RENEWAL'
};

export function setPolicyDocumentDescription(policyDocument: DocumentPolicies): string {
    let description = 'Policy documents';

    if (policyDocument) {
        switch (policyDocument.docTypeDescription) {
            case PolicyDocumentDescriptions.ENDORSEMENT:
                description = 'Policy change';
                break;
            case PolicyDocumentDescriptions.NEW_BUSINESS:
                description = 'New policy package';
                break;
            case PolicyDocumentDescriptions.RENEWAL:
                description = 'Renewal package';
                break;
            default:
                description = 'Policy documents';
                break;
        }
    }
    
    return description;
}

export function checkPolicyDocumentAvailability(policyDocuments: PolicyDocument): boolean {
    return (policyDocuments && policyDocuments.policies && policyDocuments.policies.length > 0);
}
