import { Injectable } from '@angular/core';
import RenewalDiscount from '../../models/renewalDiscount';
import RenewalPolicySummary from 'src/app/utilities/models/renewalPolicySummary';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import Endpoints from '../../constants/endpoints';
import { share } from 'rxjs/operators';
import { AnalyticsService } from '../analytics-service/analytics.service';
import Policy from 'src/app/utilities/models/policy';

@Injectable({
    providedIn: 'root'
})
export class DiscountsService {

    discountsObs: Observable<HttpResponse<RenewalDiscountsResponse>> = null;
    renewalPolSummObs: Observable<HttpResponse<RenewalPolicySummaryResponse>> = null;
    discounts: RenewalDiscount[];
    renewalPolicySummary: RenewalPolicySummary;

    constructor (
        private httpClient: HttpClient,
        private analyticsService: AnalyticsService
        // private location: Location
    ) {}
    
    getRenewalDiscounts(policyNumber: string): Promise<RenewalDiscount[]> {
        // const path = this.location.path();
        const errorHeapId = 'MMA-View-NotificationSystemError:GetRenewalDiscounts';
        this.discountsObs = this.httpClient.get<RenewalDiscountsResponse>(Endpoints.api.getRenewalDiscounts(policyNumber), 
            { withCredentials: true, observe: 'response' })
            .pipe(share());

        return new Promise((resolve, reject) => {
            this.discountsObs.subscribe({
                next: async (response: HttpResponse<RenewalDiscountsResponse>) => {
                    if (response.body.status.code === 0) {
                        this.discounts = response.body.result;
                        resolve(this.discounts);
                    } else {
                        this.analyticsService.trackAnalytics(errorHeapId);
                        reject({ error: 'getRenewalDiscounts: status.code - ' + response.body.status.code });
                    }
                },
                error: (error) => {
                    // this.commonService.setServiceFailureAlert(errorHeapId, path);
                    reject({ error: error });
                }
            });
        });
    }

    getRenewalPolicySummary(policyNumber: string): Promise<RenewalPolicySummary> {
        // const path = this.location.path();
        const errorHeapId = 'MMA-View-NotificationSystemError:GetRenewalPolicySummary';
        this.renewalPolSummObs = this.httpClient.get<RenewalPolicySummaryResponse>(Endpoints.api.getRenewalPolicySummary(policyNumber), 
            { withCredentials: true, observe: 'response' })
            .pipe(share());

        return new Promise((resolve, reject) => {
            this.renewalPolSummObs.subscribe({
                next: async (response: HttpResponse<RenewalPolicySummaryResponse>) => {
                    if (response.body.status.code === 0) {
                        this.renewalPolicySummary = response.body.result;
                        resolve(this.renewalPolicySummary);
                    } else {
                        this.analyticsService.trackAnalytics(errorHeapId);
                        reject({ error: 'getRenewalPolicySummary: status.code - ' + response.body.status.code });
                    }
                },
                error: (error) => {
                    // this.commonService.setServiceFailureAlert(errorHeapId, path);
                    reject({ error: error });
                }
            });
        });
    }

    async getRenewalPolicySummaries(policies: Policy[]): Promise<Policy[]> {
        for (const policy of policies) {
            if (policy.number && policy.isRenewal) {
                try {
                    const response = await this.getRenewalPolicySummary(policy.number);
                    if (response && response[0]) {
                        policy.renewalPolicySummary = response[0];
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }

        return policies;
    }
}

class RenewalDiscountsResponse {
    result: RenewalDiscount[];
    status: {
        code: number;
    };
}

class RenewalPolicySummaryResponse {
    result: RenewalPolicySummary;
    status: {
        code: number;
    };
}
