import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { share } from 'rxjs/operators';
import CrossSellOffer, { CrossSellPolicy, CrossSellAgency, CrossSellDisposition, CrossSellCustomer } from 'src/app/utilities/models/crossSellOffer';
import Endpoints from 'src/app/utilities/constants/endpoints';
import { RequestCache } from 'src/app/utilities/interceptors/request-cache.service';
import PolicyMethods from '../../methods/policy.methods';
import { PolicyService } from '../policy-service/policy.service';
import Policy from '../../models/policy';
import CAMStorage from '../../constants/CAMStorage';
import Agency from '../../models/agency';
import { AccountService } from '../account-service/account.service';


@Injectable({
  providedIn: 'root'
})
export class CrossSellService {
  crossSellOfferObs: Observable<CrossSellOfferResponse> = null;
  crossSellOfferRs: CrossSellOffer;
  crossSellDispositionObs: Observable<CrossSellOfferResponse> = null;
  disposition: CrossSellDisposition;
  dispositionOffer: CrossSellOffer;
  dispositionRs: CrossSellOffer;
  policies: Policy[] = [];
  primaryAgency: Agency;
  offer: CrossSellOffer;
  offerSavings: number;
  offerLob: string;
  offerStorage: CrossSellOffer;


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    }),
    withCredentials: true
  };

  httpOptionsSimple = {
    withCredentials: true
  };


  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private location: Location,
    private requestCache: RequestCache,
    private policyService: PolicyService,
    private accountService: AccountService
  ) { }

  getCrossSellOffer(xSellRq: CrossSellOffer): Promise<CrossSellOffer> {
    const errorHeapId = 'MMA-View-NotificationSystemError|GetCrossSellOffer';
    const path = this.location.path();
  
    this.setCrossSellServiceObservable(xSellRq);

    return new Promise((resolve, reject) => {
      this.crossSellOfferObs.subscribe({
        next: (response: CrossSellOfferResponse) => {
          if (response && response.result && response.status.code === 0) {
            const crossSellInfo: CrossSellOffer = response.result;
            resolve(crossSellInfo);
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject({ error: 'Non-zero status code' });
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  setCrossSellServiceObservable(offerRequest: CrossSellOffer) {
    if (this.crossSellOfferObs === null) {
      this.crossSellOfferObs = this.http.post<CrossSellOfferResponse>(Endpoints.api.getCrossSellOffers, offerRequest, this.httpOptionsSimple).pipe(share());
    }
  }

  postCrossSellDisposition(xSellRq: CrossSellOffer): Promise<CrossSellOffer> {
    const errorHeapId = 'MMA-View-NotificationSystemError|PostCrossSellOffer';
    const path = this.location.path();

    this.setCrossSellDispositionObservable(xSellRq);

    return new Promise((resolve, reject) => {
      this.crossSellDispositionObs.subscribe({
        next: (response: CrossSellOfferResponse) => {
          if (response && response.result && response.status.code === 0) {
            const crossSellInfo: CrossSellOffer = response.result;
            resolve(crossSellInfo);
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject({ error: 'Non-zero status code' });
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  setCrossSellDispositionObservable(offerRequest: CrossSellOffer) {
    this.crossSellDispositionObs = this.http.post<CrossSellOfferResponse>(Endpoints.api.postCrossSellDisposition, offerRequest, this.httpOptionsSimple);
  }


  async getCrossSellOfferInfo() {
    const allPolicies = await this.policyService.getAllPolicies();
    const authUser = this.accountService.getAuthUser();
    this.policies = this.policyService.filterExistingPolicies(allPolicies.policies);
    const xSellPolicyArray: CrossSellPolicy[] = PolicyMethods.buildCrossSellPolicies(this.policies);
    const xSellRq: CrossSellOffer = new CrossSellOffer();
    xSellRq.user = authUser.userId;
    xSellRq.crossSellPolicies = xSellPolicyArray;
    this.crossSellOfferRs = xSellRq;
    // only make the call if we have a policy to send
    if (xSellPolicyArray && xSellPolicyArray.length > 0) {
      try {
        this.crossSellOfferRs = await this.getCrossSellOffer(xSellRq);
      } catch (e) {
        // continue regardless of error
      }
      // put all data needed for the offer in storage
      if (this.crossSellOfferRs) {
        CAMStorage.removeItemInStorage(CAMStorage.storageKeys.crossSellStorage);
        this.primaryAgency = await this.policyService.getPrimaryAgency();
        this.offer = this.crossSellOfferRs;
        this.offer.agency = new CrossSellAgency();
        this.offer.customer = new CrossSellCustomer();
        if (this.primaryAgency) {
          this.offer.agency.agencyName = this.primaryAgency.agencyName;
          this.offer.agency.agencyPhone = this.primaryAgency.phoneNumber;
          this.offer.agency.agencyEmail = this.primaryAgency.emailAddress;
          this.offer.agency.goldAgency = PolicyMethods.isGoldAgency(this.primaryAgency);
          this.offer.agency.goldPlusAgency = PolicyMethods.isGoldPlusAgency(this.primaryAgency);
        }
        this.offerSavings = this.offer.savings;
        if (this.offer.lobOffer) {
          this.offerLob = this.offer.lobOffer.toLowerCase();
        }
        if (authUser) {
          this.offer.user = authUser.userId;
          this.offer.customer.email = authUser.email;
          this.offer.customer.firstName = authUser.firstName;
          this.offer.customer.lastName = authUser.lastName;
          this.offer.customer.phone = ''; // TODO where do we get new phone number info?
        }
        this.offerStorage = this.offer;
        CAMStorage.setItemInStorage(CAMStorage.storageKeys.crossSellStorage, this.offerStorage);
      }
    }
    return this.crossSellOfferRs;
  }

  async sendCrossSellOfferDisposition(dispositionOffer: CrossSellOffer, dispositionEvent: number) {
    this.requestCache.cacheBust(Endpoints.api.postCrossSellDisposition);
    this.disposition = new CrossSellDisposition();
    this.disposition.dispositionId = dispositionEvent;
    this.disposition.opportunityId = dispositionOffer.opportunityId;
    dispositionOffer.dispositions = [];
    dispositionOffer.dispositions.push(this.disposition);
    this.dispositionRs = await this.postCrossSellDisposition(dispositionOffer);
   }
}

class CrossSellOfferResponse {
  result: CrossSellOffer;
  status: {
    code: number;
  };
}

