import BillingInfo from 'src/app/utilities/models/billingInfo';
import Driver from 'src/app/utilities/models/driver';
import Dwelling from 'src/app/utilities/models/dwelling';
import HomeData from 'src/app/utilities/models/homeData';
import LoanProvider from 'src/app/utilities/models/loanProvider';
import PolicyHolder from 'src/app/utilities/models/policyHolder';
import PolicyResponseStatus from 'src/app/utilities/models/policyResponseStatus';
import SppItem from 'src/app/utilities/models/sppitem';
import TemporaryIDCards from 'src/app/utilities/models/temporaryIdCards';
import Vehicle from 'src/app/utilities/models/vehicle';
import RenewalPolicySummary from './renewalPolicySummary';
import Endorsement from './endorsement';

export default class Policy {
    // renewalIDStartDate: string;
    address1: string;
    address2: string;
    annualPremium: number;
    balanceAmount: number;
    billingInfo: BillingInfo;
    billingPlan: string;
    billingPlanCode: string;
    canceledEffectiveDate: string;
    city: string;
    claimsHref: string;
    companyName: string;
    documentEffectiveDate: string;
    drivers: Driver[];
    dwellings: Dwelling[];
    effectiveDate: string;
    electronicSignatureRequired: string;
    endorsements: Endorsement[];
    eSignUrl: string;
    homeData: HomeData;
    inceptionDate: string;
    inceptionEffectiveDate: string;
    isActivePolicy: boolean;
    isBillingRenewal: boolean;
    isCanceled: boolean;
    isCanceledEffective: boolean;
    isDocumentRenewal: boolean;
    isMailingAndLocationEqual: string;
    isMarketingPostRenewal: boolean;
    isMarketingPreRenewal: boolean;
    isMarketingRenewal: boolean;
    isPaperlessPolicy: string;
    isPayByMortgagee: boolean;
    isPolicyNumberUpdated: boolean;
    isPostRenewal: boolean;
    isPreRenewal: boolean;
    isRecentEndorsement: boolean;
    isRenewal: boolean;
    isRenewedToday: boolean;
    isCondo: boolean;
    isRenter: boolean;
    isPolicyTransferredFromLibertyMutual: boolean;
    isPolicyTransferredFromStateAuto: boolean;
    lastProcessedEndorsementDate: string;
    lineOfBusiness: number;
    loanProviders: LoanProvider[];
    marketingRenewalEffectiveDate: string;
    namedInsuredPolicyHolder: string;
    nickName: string;
    number: string;
    paperlessEmail: string;
    paymentAccount: string;
    paymentMethod: string;
    policyResponseStatus: PolicyResponseStatus;
    policyType: string;
    primaryPolicyHolder: PolicyHolder;
    profilePolicyId: string;
    profilePolicyState: number;
    ratingState: number;
    ratingStateAlpha: string;
    renewalEffectiveDate: string;
    renewalPolicySummary: RenewalPolicySummary;
    scheduledProperty: SppItem[];
    state: string;
    statusCode: string;
    temporaryIDCards: TemporaryIDCards[];
    term: string;
    termBeginDate: string;
    termEndDate: string;
    termType: string;
    userProfilePolicyValidation: number;
    vehicles: Vehicle[];
    zipcode: string;
}
