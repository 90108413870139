<div class="info-card-component" [ngSwitch]="infoCardDisplayed">
    <info-card-renewal *ngSwitchCase="infoCardType.renewal"></info-card-renewal>
    <info-card-autopay-signup *ngSwitchCase="infoCardType.autopaySignup"></info-card-autopay-signup>
    <info-card-billing-sms *ngSwitchCase="infoCardType.billingSms"></info-card-billing-sms>
    <info-card-paperless-billing *ngSwitchCase="infoCardType.paperlessBilling"></info-card-paperless-billing>
    <info-card-multi-policy-discount *ngSwitchCase="infoCardType.multiPolicyDiscount"></info-card-multi-policy-discount>
    <info-card-new-business *ngSwitchCase="infoCardType.newBusiness"></info-card-new-business>
    <info-card-cross-sell *ngSwitchCase="infoCardType.crossSell"></info-card-cross-sell>
    <info-card-mobile-app-promotion *ngSwitchCase="infoCardType.mobileAppPromotion"></info-card-mobile-app-promotion>
    <info-card-customer-appreciation *ngSwitchDefault></info-card-customer-appreciation>
</div>
