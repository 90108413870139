import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share, shareReplay } from 'rxjs/operators';
import { Location } from '@angular/common';

import { PolicyAPICodes } from 'src/app/utilities/constants/APICodes';
import { translateLOBtoProductType } from 'src/app/utilities/constants/linesOfBusiness';
import Endpoints from 'src/app/utilities/constants/endpoints';
import policyInvalidReason from 'src/app/utilities/constants/policyInvalidReason';

import { MasterPartyModel } from 'src/app/utilities/models/masterParty';
import Agency from 'src/app/utilities/models/agency';
import Billing from 'src/app/utilities/models/billing';
import DocumentRequest from 'src/app/utilities/models/documentRequest';
import EsignPolicies from 'src/app/utilities/models/esignPolicies';
import Policies from 'src/app/utilities/models/policies';
import Policy from 'src/app/utilities/models/policy';
import PolicyDocument from 'src/app/utilities/models/policyDocument';

import { AnalyticsService } from 'src/app/utilities/services/analytics-service/analytics.service';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { DiscountsService } from 'src/app/utilities/services/discounts-service/discounts.service';
import { RequestCache } from 'src/app/utilities/interceptors/request-cache.service';

import PolicyMethods from 'src/app/utilities/methods/policy.methods';
import OverrideExperience from '../../models/overrideExperience';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class PolicyService {

  allPolicies: Policies = null;
  allPoliciesStatus: any = null;
  esignPolicies: EsignPolicies = null;
  masterParty: MasterPartyModel = null;
  masterPartyObs: Observable<CDIPoliciesResponse> = null;
  policies: Policy[] = [];
  policiesStatus: any = null;
  policy: Policy = null;
  policyCallFailed: boolean = false;
  policyClearCacheObs: Observable<PoliciesResponse> = null;
  policyDocuments: PolicyDocument = null;
  policyNumber: string;
  policyObs: Observable<PoliciesResponse> = null;
  policyProfileStatusChecked: boolean = false;
  policyProfileStatusCheckObs: Observable<boolean> = null;
  policySingleCallFailed: boolean = false;
  policyVerified: boolean = false;
  policyVerifyObs: Observable<boolean> = null;
  primaryAgency: Agency = null;
  override: OverrideExperience;

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private location: Location,
    private requestCache: RequestCache,
    private analyticsService: AnalyticsService,
    private discountsService: DiscountsService,
    private route: ActivatedRoute
  ) {
    this.policyObs = this.http.get<PoliciesResponse>(Endpoints.api.getPolicies, { withCredentials: true }).pipe(share());
    this.policyClearCacheObs = this.http.get<PoliciesResponse>(Endpoints.api.getPolicies + '?clearcache', { withCredentials: true }).pipe(share());
  }

  // Name: setPoliciesResponse
  // Purpose: Sets response and policies from api/policy call to app memory objects
  // Params: none
  getPolicies(bustCache?: boolean): Promise<Policy[]> {
    const errorHeapId = 'MMA-View-NotificationSystemError|GetPolicies';
    const path = this.location.path();
    let policyObs = this.policyObs;

    if (bustCache) {
      policyObs = this.policyClearCacheObs;
    } else {
      policyObs = this.policyObs;
    }

    return new Promise((resolve, reject) => {
      policyObs.subscribe({
        next: (response: PoliciesResponse) => {
          if (response && response.status) {
            if (response.status.code === PolicyAPICodes.success) {
              this.policies = response.result.policies;
              resolve(this.policies);
            } else {
              this.policyCallFailed = true;
              this.policiesStatus = response.status;
              this.requestCache.cacheBust(Endpoints.api.getPolicies);

              if (response.status.code === PolicyAPICodes.mainframeIssue) {
                this.commonService.setMissingInfoServiceFailureAlert(errorHeapId, path);
              } else {
                this.commonService.setServiceFailureAlert(errorHeapId, path);
              }

              reject();
            }
          } else {
            this.requestCache.cacheBust(Endpoints.api.getPolicies);
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject();
          }
        },
        error: (error) => {
          this.policyCallFailed = true;
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  getAllPolicies(): Promise<Policies> {
    const errorHeapId = 'MMA-View-NotificationSystemError|GetAllPolicies';
    const path = this.location.path();

    return new Promise((resolve, reject) => {
      this.policyObs.subscribe({
        next: (response: PoliciesResponse) => {
          if (response && response.status) {
            if (response.status.code === PolicyAPICodes.success) {
              this.allPolicies = response.result;
              resolve(this.allPolicies);
            } else {
              this.policyCallFailed = true;
              this.allPoliciesStatus = response.status;
              this.requestCache.cacheBust(Endpoints.api.getPolicies);

              if (response.status.code === PolicyAPICodes.mainframeIssue) {
                this.commonService.setMissingInfoServiceFailureAlert(errorHeapId, path);
              } else {
                this.commonService.setServiceFailureAlert(errorHeapId, path);
              }

              reject();
            }
          } else {
            this.requestCache.cacheBust(Endpoints.api.getPolicies);
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject();
          }
        },
        error: (error) => {
          this.policyCallFailed = true;
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  async clearPolicyCache(): Promise<void> {
    this.requestCache.cacheBust(Endpoints.api.getPolicies);
    this.requestCache.cacheBust(`${Endpoints.api.getPolicies}?clearcache`);

    try {
      const policies: Policy[] = await this.getPolicies(true);
    } catch (err) {
      // do nothing
    }
  }

  // Returns policies that are within the renewal date range
  async getRenewalPolicies(getRenewalPolicySummaries?: boolean): Promise<Policy[]> {
    try {
      const allPolicies = await this.getPolicies();
      let renewalPolicies = PolicyMethods.getRenewalViewPolicies(allPolicies);

      if (getRenewalPolicySummaries) {
        renewalPolicies = await this.discountsService.getRenewalPolicySummaries(renewalPolicies);
      }

      return renewalPolicies;
    } catch (error) {
      console.log(error);
    }
  }

  // Returns policies that are within the marketing renewal date range
  async getMarketingRenewalPolicies(): Promise<Policy[]> {
    try {
      const allPolicies = await this.getPolicies();
      let marketingRenewalPolicies = PolicyMethods.getMarketingRenewalPolicies(allPolicies);

      return marketingRenewalPolicies;
    } catch (error) {
      console.log(error);
    }
  }

  async getBillingRenewalPolicies(getRenewalPolicySummaries?: boolean): Promise<Policy[]> {
    try {
      const allPolicies = await this.getPolicies();
      let billingRenewalPolicies = PolicyMethods.getBillingRenewalPolicies(allPolicies);

      if (getRenewalPolicySummaries) {
        billingRenewalPolicies = await this.discountsService.getRenewalPolicySummaries(billingRenewalPolicies);
      }

      return billingRenewalPolicies;
    } catch (error) {
      console.log(error);
    }
  }


  // Returns policies that are in renewal by the amount of days that was passed in for the renewal cookie
  async getRenewalPoliciesForCookie(renewalPeriod: number, currentDate?: Date) {
    let allPolicies = await this.getPolicies();
    allPolicies = this.filterExistingPolicies(allPolicies).filter(policy => !policy.isCanceled);
    const renewalPolicies = [];
    if (!currentDate) {
      currentDate = new Date();
    }

    allPolicies.forEach(policy => {
      const termBeginDate = new Date(policy.termBeginDate);
      const termEndDate = new Date(policy.termEndDate);
      let renewalDate;

      if (termEndDate > currentDate && currentDate > termBeginDate) {
        renewalDate = new Date(termEndDate);
        renewalDate.setDate(termEndDate.getDate() - renewalPeriod);
      } else if (currentDate < termBeginDate && policy.inceptionDate !== policy.termBeginDate) {
        renewalDate = new Date(termBeginDate);
        renewalDate.setDate(termBeginDate.getDate() - renewalPeriod);
      }

      if (currentDate >= renewalDate) {
        renewalPolicies.push(policy);
      }
    });

    return renewalPolicies;
  }

  formatDate(theDate: Date, days: number): Date { // move to common file later
    theDate.setTime(theDate.getTime() + days * 24 * 60 * 60 * 1000);
    return theDate;
  }

  // Returns policies that are in renewal based on src query param and number of days before or after renewal
  async getSrcRenewalPolicies(renewalPeriod: number, daysAfterRenewal: number, currentDate: Date) {
    let allPolicies = await this.getPolicies();
    allPolicies = this.filterExistingPolicies(allPolicies).filter(policy => !policy.isCanceled);
    const srcPolicies = [];

    allPolicies.forEach(policy => {
      const termBeginDate = new Date(policy.termBeginDate);
      const termEndDate = new Date(policy.termEndDate);
      let display = false;
      currentDate = this.formatDate(currentDate, 0);

      if (PolicyMethods.isRenewalView(policy)) {
        display = true;
      }

      // if (currentDate < this.formatDate(termBeginDate, daysAfterRenewal)) { // policy renewed within past x days (daysAfterRenewal)
      //   display = true;
      // } else if (currentDate > (this.formatDate(termEndDate, -renewalPeriod))) { // policy renews on future date minus x days (renewalPeriod)
      //   display = true;
      // } else if ((currentDate === this.formatDate(termBeginDate, 0)) && (policy.inceptionDate !== policy.termBeginDate)) {  // policy renews today
      //   display = true;
      // }

      if (display === true) {
        srcPolicies.push(policy);
      }
    });
    return srcPolicies;
  }

  getPrimaryAgency(): Promise<Agency> {
    const errorHeapId = 'MMA-View-NotificationSystemError|GetPolicies';
    const path = this.location.path();

    return new Promise((resolve, reject) => {
      this.policyObs.subscribe({
        next: (response: PoliciesResponse) => {
          if (response.status.code === PolicyAPICodes.success) {
            this.primaryAgency = response.result.primaryAgency;
            this.override = new OverrideExperience(this.route);
            if (this.override.agentType) {
              this.primaryAgency.agentType = this.override.agentType;
            }
            resolve(this.primaryAgency);
          } else {
            this.requestCache.cacheBust(Endpoints.api.getPolicies);
            reject();
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  // possible values of agentType: GPL, GLD, AGT, GXH, CSU
  async isGoldAgency(): Promise<string> {
    let isGoldAgency = 'N';
    const primaryAgency: Agency = await this.getPrimaryAgency();
    if (primaryAgency && primaryAgency.agentType) {
      const goldAgentTypes = ['GLD'];
      isGoldAgency = ~goldAgentTypes.indexOf(primaryAgency.agentType) ? 'Y' : 'N';
    }
    return isGoldAgency;
  }

  async isGoldPlusAgency(): Promise<string> {
    let isGoldPlusAgency = 'N';
    const primaryAgency: Agency = await this.getPrimaryAgency();
    if (primaryAgency && primaryAgency.agentType) {
      const goldPlusAgentTypes = ['GPL'];
      isGoldPlusAgency = ~goldPlusAgentTypes.indexOf(primaryAgency.agentType) ? 'Y' : 'N';
    }
    return isGoldPlusAgency;
  }

  async isGoldPlusOrGoldAgency(): Promise<boolean> {
    return await this.isGoldAgency() === 'Y' || await this.isGoldPlusAgency() === 'Y';
  }

  isMainframeIssue(): boolean {
    let isMainframeIssue: boolean = false;

    if (this.policiesStatus && this.policiesStatus.code === PolicyAPICodes.mainframeIssue) {
      isMainframeIssue = true;
    }

    if (this.allPoliciesStatus && this.allPoliciesStatus.code === PolicyAPICodes.mainframeIssue) {
      isMainframeIssue = true;
    }

    return isMainframeIssue;
  }

  // Since the termEndDate resets once a policy is renewed, the policy document effectiveDate will be set to the termEndDate when the current date is within 30 days of the termEndDate, and will be set to the termBeginDate once it renews
  private setDocumentRenewalEffectiveDate() {
    const docsPolicy: Policy = this.policies.find((policy) => this.policyDocuments.policyNumber === policy.number);
    if (docsPolicy) {
      const currentDate = new Date();
      const termEndDate = new Date(docsPolicy.termEndDate);
      termEndDate.setDate(termEndDate.getDate() - 30);
      if (currentDate >= termEndDate) {
        this.policyDocuments.effectiveDate = docsPolicy.termEndDate;
      } else {
        this.policyDocuments.effectiveDate = docsPolicy.termBeginDate;
      }
    }
  }

  getCDIPolicies(policyNumber: string): Promise<MasterPartyModel> {
    this.masterPartyObs = this.http.get<CDIPoliciesResponse>(Endpoints.api.getCDIPolicies(policyNumber), { withCredentials: true }).pipe(share());

    return new Promise((resolve, reject) => {
      this.masterPartyObs.subscribe({
        next: async (response: CDIPoliciesResponse) => {
          if (response.status.code === 0) {
            this.masterParty = response.result;
            if (this.masterParty && this.masterParty.masterParties) {
              let policyCount = 0;
              let policyInfo = '';
              let policyList = '';
              try {
                const policies = await this.getPolicies();
                policies.forEach(policy => {
                  policyList += policy.number + ', ';
                });
                this.masterParty.masterParties.forEach(party => {
                  if (party.policies) {
                    party.policies.forEach(policy => {
                      const polnum = policy.number;
                      if (policyList.indexOf(polnum) === -1 && policyCount < 10) { // proceed if policy not already in account
                        policyCount += 1;
                        const both = polnum + '-' + translateLOBtoProductType(policy.lineOfBusiness.toString());
                        if (policyInfo.indexOf(polnum) === -1) {
                          policyInfo += both + ',';
                        }
                      }
                    });
                  }
                });
                this.masterParty.policyInfo = policyInfo;
              } catch {
                console.log();
              }
            }
            resolve(this.masterParty);
          } else {
            this.analyticsService.trackAnalytics('MMA-View-NotificationSystemError|GetCDIPolies');
            reject({ error: 'getCDIPolicies: status.code - ' + response.status.code });
          }
        },
        error: (error) => reject({ error: error })
      });
    });
  }

  getPolicy(): Promise<Policy> {
    const errorHeapId = 'MMA-View-NotificationSystemError|GetPolicy';
    const path = this.location.path();

    return new Promise((resolve, reject) => {
      this.policyObs.subscribe({
        next: (response: PoliciesResponse) => {
          if (response && response.status && response.status.code === 0) {
            const policy = response.result.policies[0];
            resolve(policy);
          } else {
            this.policyCallFailed = true;
            this.commonService.setServiceFailureAlert(errorHeapId, path);
          }
        },
        error: (error) => {
          this.policyCallFailed = true;
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  getPolicyByNumber(policyNumber: string): Policy {
    return this.policies.find((policy: Policy) => policyNumber === policy.number);
  }

  async setNotFoundAccountByPolicy(billingAccount: Billing): Promise<Billing> {
    const effectiveRange: number = 5;
    billingAccount.isAccountEffectiveDateRecent = false;

    try {
      const policies: Policy[] = await this.getPolicies();

      if (policies && policies.length > 0) {
        const searchPolicy: Policy = this.getPolicyByNumber(billingAccount.number);

        if (searchPolicy) {
          // billingAccount.policyList = [searchPolicy];

          if (searchPolicy.termBeginDate) {
            const todaysDate: Date = new Date();
            const dateCriteriaEndDate: Date = new Date(searchPolicy.termBeginDate);
            dateCriteriaEndDate.setDate(dateCriteriaEndDate.getDate() + effectiveRange);

            if (todaysDate <= dateCriteriaEndDate) {
              billingAccount.isAccountEffectiveDateRecent = true;
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }

    return billingAccount;
  }

  // Helper function that returns array of policy that exist
  filterExistingPolicies(policies: Policy[]): Policy[] {
    return policies.filter(policy => policy.policyResponseStatus.isSuccessful);
  }


  isEligibleForPaperlessEnrollment(paperless: string): boolean {
    let isEligible = false;
    if ((paperless !== undefined && paperless !== null) && paperless === 'N') { // N means eligible for paperless but not enrolled
      isEligible = true;
    }
    return isEligible;
  }

  hasPaperlessStatus(policy: Policy): boolean {
    let hasPaperlessStatus = false;

    if (policy.isPaperlessPolicy !== undefined && policy.isPaperlessPolicy !== null) {
      hasPaperlessStatus = true;
    }

    return hasPaperlessStatus;
  }

  isEnrolledForPaperless(policies: Policy[]): boolean {
    let isEnrolled = false;

    if(policies.some(policy => this.hasPaperlessStatus(policy) && policy.isPaperlessPolicy === "Y")) {
      isEnrolled = true;
    }

    return isEnrolled;
  }


  getPolicyMismatchTypeName(mismatchType: number): Promise<string> {
    return new Promise((resolve) => {
      let name = policyInvalidReason[mismatchType];
      if (!name) {
        name = '';
      }
      resolve(name);
    });
  }
}

class PoliciesResponse {
  result: Policies;
  status: {
    code: number;
  };
}

class EsignPoliciesResponse {
  result: EsignPolicies;
  status: {
    code: number;
  };
}

class CDIPoliciesResponse {
  result: MasterPartyModel;
  status: {
    code: number;
  };
}

class PolicyDocumentResponse {
  result: PolicyDocument;
  status: {
    code: number;
  };
}
