<div class="mobile-app-promo-component">
    <div class="mobile-app-promo-desktop-view">
        <div class="mobile-promo-header-container">
            <div class="curve"></div>
            <div class="mobile-promo-header">
                <span class="sco-heading sco--level2 sco--regular">Get the mobile app</span>
            </div>
        </div>
        <div class="mobile-promo-content">
            <span>Whenever you need your insurance, just tap. <a
                    href="https://click.safins.co/UQHn/hx6e6ijl" target="_blank"
                    [attr.data-heap-id]="desktopHeapId" role="link"
                    aria-label="Learn how to download the Safeco mobile app"
                    class="sco-link sco-textLink sco--standalone">Learn how to download</a></span>
        </div>
    </div>

    <div class="mobile-app-promo-mobile-view">
        <div class="mobile-promo-header-container">
            <div class="curve"></div>
            <div class="phone-img-container">
                <img ngSrc="{{ imgLoc }}/mobile/phone_app_illus.svg" alt="Mobile app promotion image" width="174" height="214"/>
            </div>
        </div>
        <div class="mobile-promo-content">
            <div class="content">
                <h3 class="content-header" *ngIf="isAuto">Get your ID cards fast &#8212; use the app</h3>
                <h3 class="content-header" *ngIf="!isAuto">Love when insurance is easy? Try our app!</h3>
                <ul class="content-list">
                    <li *ngIf="isApple">Log in with FaceID or TouchID</li>
                    <li *ngIf="isAndroid">Log in with fingerprint or facial recognition</li>
                    <li *ngIf="isAuto">Tap for your ID cards</li>
                    <li *ngIf="isAuto">Plus, manage policies, pay bills and more</li>
                    <li *ngIf="!isAuto">Easily manage policies, make a payment and more</li>
                </ul>
            </div>
            <div class="app-store-button">
                <a *ngIf="isAndroid" [href]="googleAppStoreLink" target="_blank">
                    <img ngSrc="{{ imgLoc }}/mobile/google_play_badge.png" width="118" height="38"
                        [attr.data-heap-id]="mobileAndroidHeapId" role="link"
                        aria-label="Get the Safeco mobile app on Google play" alt="Get it on Google play" />
                </a>
                <a *ngIf="isApple" [href]="appleAppStoreLink" target="_blank">
                    <img ngSrc="{{ imgLoc }}/mobile/app_store_button.svg" width="118" height="38"
                        [attr.data-heap-id]="mobileAppleHeapId" role="link"
                        aria-label="Download the Safeco mobile app on the Apple store"
                        alt="Download on the Apple store" />
                </a>
                <a *ngIf="!isAndroid && !isApple"
                    href="https://click.safins.co/UQHn/hx6e6ijl" target="_blank"
                    class="sco-link sco-textLink sco--standalone"
                    [attr.data-heap-id]="desktopHeapId" role="link"
                    aria-label="Learn how to download the Safeco mobile app">Learn how to download</a>
            </div>
        </div>
    </div>
</div>
