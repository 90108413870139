import { Component, OnInit, Input, DoCheck, IterableDiffers, IterableDiffer } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, filter } from 'rxjs';
import { Alert } from 'src/app/utilities/models/alert';
import Endpoints from 'src/app/utilities/constants/endpoints';
import { AnalyticsService } from 'src/app/utilities/services/analytics-service/analytics.service';
import { Features } from "src/app/utilities/models/features";


@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
  private alertQueue: BehaviorSubject<Alert[]>;
  alerts: Alert[];
  imgLoc = env.imgLoc;
  url = Endpoints.url;

  constructor(
    private router: Router,
    private commonService: CommonService
  ) { }

  async ngOnInit() {
    this.alertQueue = this.commonService.getAlertQueue();
    this.alerts = this.alertQueue.value;
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
      ).subscribe((event: NavigationStart) => {
        this.commonService.popAlerts();
    });
  }
}


@Component({
  selector: 'page-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class PageAlertsComponent implements DoCheck {
  @Input() alerts: Alert[];
  imgLoc = env.imgLoc;
  url = Endpoints.url;
  iterableDiffer: IterableDiffer<Alert>;
  features: Features = new Features();

  constructor(
    private router: Router,
    private iterableDiffers: IterableDiffers,
    private commonService: CommonService,
    private analyticsService: AnalyticsService) {
    this.iterableDiffer = this.iterableDiffers.find([]).create(null);
  }

  ngDoCheck() {
    const changes = this.iterableDiffer.diff(this.alerts);
    if (changes) {
      changes.forEachAddedItem((alert) => {
        if (alert.item.heapId) {
          this.analyticsService.trackAnalytics(alert.item.heapId);
        }
      });
    }
  }

  dismissAlert(index: number) {
    this.alerts.splice(index, 1);
  }
}
