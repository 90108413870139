const Browsers = {
    IE: 'IE',
    CHROME: 'CHROME',
    FIREFOX: 'FIREFOX',
    SAFARI: 'SAFARI',
    OPERA: 'OPERA',
    NOT_FOUND: 'BROWSER NOT SUPPORTED'
}

export default Browsers;
