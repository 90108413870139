export const PaymentMethods = {
  AUTOMATIC_DEDUCTION: 'AUTOMATIC DEDUCTION',
  MORTGAGEE_PAY: 'MORTGAGEE PAY',
  PAYROLL_DEDUCTION: 'PAYROLL DEDUCTION',
  RECURRING_CREDIT_CARD: 'RECURRING CREDIT CARD',
  REGULAR: 'REGULAR'
};

export const PaymentMethodDisplay = {
  AUTOMATIC_BANK_WITHDRAWAL: 'Automatic (bank withdrawal)',
  AUTOMATIC_CREDIT_CARD: 'Automatic (recurring credit card)',
  MANUAL: 'Manual',
  MORTGAGEE_PAY: 'Mortgagee pay',
  PAYROLL_DEDUCTION: 'Payroll deduction',
};
