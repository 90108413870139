import { Alert } from "./alert";
import { ApiResponseStatus } from "./apiResponseStatus";
import { Result } from "./apiResult";
import { BillingPaymentAccount } from "./billingPaymentAccount";
import { CustomerSearchRequest } from "./customerSearch";
import { AccountsWithAmountToPay } from "./paymentInfo";

export class InitiateInstrumentRq {
    billingAccountNumber: string;
    clientId: string;
    clientProfileId: string;
    name: string;
    storeInstrumentForReuse: boolean;
    instrumentType: string;
    details: any;
}

export class InitiateInstrument {
    instrumentId: string;
    instrumentIdCode: string;
    instrumentHostedPage: string;
    instrumentHostedPageUrl: string;
}

export class InstrumentIdRq {
    instrumentIdCode: string;
    paymentSource: string;
}

export class InstrumentDetailsStorage {
    amount: number;
    billingAccountNumber: string;
    cCProfileAccounts: BillingPaymentAccount[];
    customerName: string;
    emailAddress: string;
    getPaymentInfoPolicyNumber: string;
    getPaymentInfoStatusCode: number;
    cardInstrumentDetailsResponse: CreditCardInstrumentDetailsRs;
    eftInstrumentDetailsResponse: EFTInstrumentDetailsRs;
    isCard: boolean;
    isNewSavedCard: boolean;
    isUpdatedSavedCard: boolean;
    makeCreditCardPaymentsRs: BaseMakePaymentsRs;
    makeEFTPaymentsRs: MakeEFTPaymentsRs;
    pageEdit: boolean;
    paymentDate: Date;
    paymentMethodOutput: string;
    scheduleMakePaymentRs: ScheduleMakePaymentRs;
    requestGuid: string;
    selectedAccounts: BillingPaymentAccount[];
    storeInstrumentForReuse: boolean;
    hasPaymentechProfile: boolean;
    isPageEditRequest: boolean;
    isSchedulePayment: boolean;
    isMakePayment: boolean;
    isAccountSchedulePaymentEligible: boolean;
    withdrawalDate: Date;
}

export class SavedInstrument {
    code: string;
    paymentSource: string;
}

// ----------------- newly refactored classes -----------------
export class MakePaymentsRq {
    accountsWithAmountToPay: AccountsWithAmountToPay[];
    instrument: Instrument;
    requestGuid: string;
}

export class MakePaymentsPWOLRq extends MakePaymentsRq {
    customerSearchRequest: CustomerSearchRequest;
    emailAddress: string;
}


export class BaseMakePaymentsRs extends Result {       // base for CC or EFT; todo: from name after CC has been refactored to use this
    paymentResults: MakePaymentRs[];
    totalAmountPaid: number;
    requestGuid: string;
}

export class MakePaymentRs {
    amountPaid: number;
    paymentDate: Date;
    confirmationNumber: string;
    billingAccountNumber: string;
    paymentStatus: number;
    paymentStatusDetails: string;
    paymentAdditionalDetails: string;
}

export class MakeEFTPaymentRs extends MakePaymentRs {
    withdrawalDate: Date;
}

export class MakeEFTPaymentsRs extends BaseMakePaymentsRs {
    paymentResults: MakeEFTPaymentRs[];
}

export class Instrument {
    instrumentIdCode: string;
    maskedInstrument: string;
    instrumentHolderName: string;
}

export class EFTInstrument extends Instrument {
    bankAccountType: string;
    bankRoutingNumber: string;
    paymentAccountToken: string;
}

export class CreditCardInstrument extends Instrument {
    cardBrand: string;
    cardExpirationMonth: string;
    cardExpirationYear: string;
    storeInstrumentForReuse: boolean;
}

export class EFTInstrumentDetailsRs extends Result {
    instrument: EFTInstrument;
}

export class CreditCardInstrumentDetailsRs extends Result {
    instrument: CreditCardInstrument;
}

// --------------------------------------------------------------------

export class PaymentResponseStatus {
    code: number;
    description: string;
    hydraCode: string;
}

export class InstrumentDetailsEvent {
    instrumentDetailsStorage: InstrumentDetailsStorage;
}

export class PendingPaymentStorageAccounts {
    billingAccountNumber: string;
    amountPaid: number;
}

export class DeleteCreditCardProfileRq {
    accountNumbers: string[];
    requestGuid: string;
}

export class DeleteCreditCardProfileRs {
    accountNumber: string[];
    status: ApiResponseStatus;
}

export class SaveCreditCardProfileRq {
    accountNumbers: string[];
    creditCardProfileToSave: CreditCardInstrument;
    emailAddress: string;
    requestGuid: string;
}

export class SaveCreditCardProfileRs {
    saveCreditCardProfile: CreditCardAccount;
    accountNumbers: string[];
}

export class CreditCardAccount {
    instrumentIdCode: string;
    lastFourDigits: string;
    expirationDate: string;
    cardType: string;
    paymentSource: string;
    firstName: string;
    lastName: string;
    middleInitial: string;
    emailAddress: string;
    userId: string;
    zipCode: string;
    message: any;
}

export class ScheduleMakePaymentRq {
    instrument: CreditCardInstrument;
    paymentDetails: PaymentDetailsInfo[];
}

export class CancelScheduledPaymentsRq {
    scheduledPayments: ScheduledPayment[];
}

export class CancelScheduledPaymentAlert {
    success: boolean;
    alert: Alert;
}

export class PaymentDetailsInfo {
    amount: string;
    scheduledDate: string;
    billingAccountNumber: string;
}

export class ScheduledPayment {
    amount: string;
    scheduledDate: string;
    clientPaymentId: string;
}

export class ScheduleMakePaymentRs {
    detail: string;
    status: number;
    additionalDetails: string
    scheduledPaymentAccounts: ScheduledPaymentAccount[];
    correlationId: string;
    description: string;
}

export class ScheduledPaymentAccount {
    accountNumber: string;
    billingRefId: string;
    scheduledPayments: ScheduledPaymentDetails[];
}

export class ScheduledPaymentDetails {
    clientPaymentId: string;
    amount: number;
    scheduledDate: string;
    scheduledStatus: string;
    schedulePaymentMessage: string;
    scheduledPaymentInfo: ScheduledPaymentInfo
    billingServiceCode: string;
    scheduledStatusDesc: string;
    errors: SchedulePaymentError[];
}

export class ScheduledPaymentInfo {
    isPaymentProcessFailed: boolean
    isCancelable: boolean
}

export class SchedulePaymentError {
    description: string;
    additionalDetails: string;
    target: string;
}
