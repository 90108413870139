<div class="paperless-billing-component">
    <div class="curve"></div>
    <div class="header">
        <img ngSrc="{{imgLoc}}/info-card/infocard_Paperless_Icon.svg" alt="Go paperless" width="70" height="73"/>
        <span class="sco-heading sco--level3 sco--regular">Ditch the paper</span>
    </div>
    <span class="content">Join the millions of Safeco customers who get their documents online.</span>
    <button class="sco-button sco--outline" 
        data-heap-id="LP_infoTile_paperless_btn_goPaperless" routerLink="{{url.paperlessOptions}}" [queryParams]="url.paperlessOptionsParams">Go paperless</button>
</div>
