import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { PolicyService } from 'src/app/utilities/services/policy-service/policy.service';
import { Features } from 'src/app/utilities/models/features';
import { SMSDisplayPaths } from 'src/app/utilities/constants/sms';

@Injectable({
  providedIn: 'root'
})
export class SMSService {

  private displaySMSSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  displaySMSObs: Observable<boolean> = this.displaySMSSource.asObservable();
  isSMSEligible: boolean;
  
  constructor(
    private commonService: CommonService,
    private policyService: PolicyService
  ) { }

  async initializeSMSDisplay(url: string) {
    try {
      const features: Features = await this.commonService.getFeatures();
    
      if (features && features.smsLaunched) {
        const isSMSState: boolean = await this.isSMSStateAvailable(features.smsLaunchedStates);
        const isGold: boolean = await this.policyService.isGoldPlusOrGoldAgency();

        if (isSMSState && isGold) {
          this.isSMSEligible = true;
        }

        this.setSMSLinkDisplay(url);
      }
    } catch (error) {
      console.log(error);
    }
    
  }

  setSMSLinkDisplay(url: string) {
    const isSMSPath: boolean = this.isSMSDisplayPath(url);
    let displaySMS: boolean = false;

    if (this.isSMSEligible && isSMSPath) {
      displaySMS = true;
    } 

    this.displaySMSSource.next(displaySMS);
  }

  private isSMSDisplayPath(url: string): boolean {
    let isSMSPath: boolean = false;

    if (SMSDisplayPaths.some((path) => path === url)) {
      isSMSPath = true;
    } 

    return isSMSPath;
  }

  private async isSMSStateAvailable(launchedStates: string[]) {
    const allPolicies = await this.policyService.getPolicies();
    let isSMSState: boolean = false;

    if (allPolicies && allPolicies.length) {
      allPolicies.forEach((policy) => {
        if (launchedStates.some((state) => state === policy.ratingStateAlpha)) {
          isSMSState = true;
        }
      });
    }

    return isSMSState;
  }
}
