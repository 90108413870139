import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AnalyticsApplicationNames, AnalyticsEventTypes, AnalyticsTransactions, ConfirmServiceType, ContentType, ToolName } from 'src/app/utilities/constants/analytics';

import { ModalNames } from 'src/app/utilities/constants/modalNames';
import AnalyticsMethods from 'src/app/utilities/methods/analytics.methods';
import BillingMethods from 'src/app/utilities/methods/billing.methods';
import PaymentsMethods from 'src/app/utilities/methods/payments.methods';
import PolicyMethods from 'src/app/utilities/methods/policy.methods';
import ScheduledPaymentsMethods from 'src/app/utilities/methods/scheduledPayments.methods';
import { ServiceTransactionAnalytics } from 'src/app/utilities/models/analytics';

import { Features } from 'src/app/utilities/models/features';
import { CancelScheduledPaymentAlert, CancelScheduledPaymentsRq, ScheduledPayment, ScheduledPaymentDetails } from 'src/app/utilities/models/payments';
import Policy from 'src/app/utilities/models/policy';

import { AnalyticsService } from 'src/app/utilities/services/analytics-service/analytics.service';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { ModalService } from 'src/app/utilities/services/modal-service/modal.service';
import { PaymentsService } from 'src/app/utilities/services/payments-service/payments.service';

@Component({
  selector: 'app-scheduled-payment-message',
  templateUrl: './scheduled-payment-message.component.html',
  styleUrls: ['./scheduled-payment-message.component.scss']
})
export class ScheduledPaymentMessageComponent implements OnInit {
  @Input() currentScheduledPayment: ScheduledPaymentDetails;
  @Input() billingAccount: string;
  @Input() billingAccountPolicies: Policy[] | string[];
  @Input() parentHeapId: string = ''; // Parent component heap identifier
  @Input() policyStates: string[];

  @Output() canceledSAPAlertEmitter: EventEmitter<CancelScheduledPaymentAlert> = new EventEmitter<CancelScheduledPaymentAlert>();

  hasCancelSAPCallBeenCalled = false;
  isCancelable: boolean = false;
  isCancelScheduledPaymentLaunched: boolean = false;
  isFailed: boolean = false;
  cancelLinkHeapId: string = '';
  schedulePaymentMessage: string = '';
  billingAccountPoliciesString: string;
  
  cancelScheduledPaymentSubscription: Subscription;
  hasCancelSAPCallSubscription: Subscription;

  constructor(
    private analyticsService: AnalyticsService,
    private paymentsService: PaymentsService,
    private commonService: CommonService,
    private modalService: ModalService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getFeatures();
    this.setCurrentScheduledPayment();
  }

  ngOnDestroy() {    
    this.unsubscribeModalServiceObs();
  }

  async getFeatures(): Promise<void> {
    const features: Features = await this.commonService.getFeatures();

    if (features) {
      this.isCancelScheduledPaymentLaunched = features.cancelScheduledPaymentLaunched;
    }
  }

  setCurrentScheduledPayment(): void {
    if (this.currentScheduledPayment) {
      this.cancelLinkHeapId = `${this.parentHeapId}_SAP_Cancel`;
      this.isCancelable = this.currentScheduledPayment.scheduledPaymentInfo?.isCancelable;
      this.isFailed = this.currentScheduledPayment.scheduledPaymentInfo?.isPaymentProcessFailed;
      this.schedulePaymentMessage = this.currentScheduledPayment.schedulePaymentMessage;
      this.billingAccountPoliciesString = BillingMethods.getPolicyNumbersStringFromBillingPolicies(this.billingAccountPolicies);
    }
  }
  
  async setModalServiceObs(): Promise<void> {
    this.hasCancelSAPCallSubscription = this.modalService.hasCancelSAPCallObs.subscribe(
      hasCancelSAPBeenCalled => { this.hasCancelSAPCallBeenCalled = hasCancelSAPBeenCalled; }
    );

    this.cancelScheduledPaymentSubscription = this.modalService.isCancelSAPSuccessObs.subscribe(
      async isCancelSAPSuccess => {
        if (this.hasCancelSAPCallBeenCalled) {
          const cancelScheduledPaymentAlert = this.buildCancelScheduledPaymentAlert(isCancelSAPSuccess);
          
          if  (isCancelSAPSuccess) {
            this.currentScheduledPayment = null;
            this.trackCancelSAPAnalytics(AnalyticsApplicationNames.CANCEL_SAP, AnalyticsEventTypes.COMPLETE);
          }

          this.canceledSAPAlertEmitter.emit(cancelScheduledPaymentAlert);
        } 

        this.unsubscribeModalServiceObs();
      }
    );
  }

  unsubscribeModalServiceObs() : void {
    if (this.cancelScheduledPaymentSubscription && !this.cancelScheduledPaymentSubscription.closed) {
      this.cancelScheduledPaymentSubscription.unsubscribe();
    }

    if (this.hasCancelSAPCallSubscription && !this.hasCancelSAPCallSubscription.closed) {
      this.hasCancelSAPCallSubscription.unsubscribe();
    }
  }

  buildCancelScheduledPaymentAlert(isCancelSAPSuccess: boolean): CancelScheduledPaymentAlert {
    const cancelScheduledPaymentAlert = new CancelScheduledPaymentAlert();
    cancelScheduledPaymentAlert.success = isCancelSAPSuccess;
    cancelScheduledPaymentAlert.alert = ScheduledPaymentsMethods.createCancelScheduledPaymentAlert(isCancelSAPSuccess, this.billingAccount, this.currentScheduledPayment);

    return cancelScheduledPaymentAlert;
  }

  async openCancelSchedulePaymentModal(): Promise<void> {
    try {
      this.analyticsService.trackServiceAnalytics(ToolName.CANCEL_SCHEDULED_PAYMENT, ConfirmServiceType.START, this.billingAccountPoliciesString);

      await this.setModalServiceObs();
      this.modalService.openModal(ModalNames.CANCEL_SCHEDULE_PAYMENT);
      this.modalService.setScheduledPaymentToCancel(this.billingAccount, ScheduledPaymentsMethods.buildCancelScheduledPaymentsRq(this.currentScheduledPayment), this.billingAccountPoliciesString);
      this.modalService.setModalLoaded();
      
      this.trackCancelSAPAnalytics(AnalyticsApplicationNames.CANCEL_SAP, AnalyticsEventTypes.START);
    } catch (err) {
      console.log(err);
    }
  }

  trackCancelSAPAnalytics(analyticsApplicationName: string, analyticsEventType: string): void {
    const cancelSAPAnalytics: ServiceTransactionAnalytics = AnalyticsMethods.buildServiceTransactionAnalytics(this.billingAccountPolicies, analyticsApplicationName, 
      AnalyticsTransactions.PAYMENT, "", analyticsEventType, this.policyStates); //applicationDetails is empty for cancel SAP

     // starts tracked via view (modal shown), completes tracked via link (success alert)
    if (analyticsEventType === AnalyticsEventTypes.START) {
      this.analyticsService.trackServiceTransactionViewAnalytics(cancelSAPAnalytics);
    } else {
      this.analyticsService.trackServiceTransactionLinkAnalytics(cancelSAPAnalytics);
    }
  }
}
