export class Features {
    accountAnalyticsRefactorLaunched: boolean;
    addDriverLaunched: boolean;
    addLandlordLaunched: boolean;
    addVehicleLaunched: boolean;
    addVehicleLaunchedStates: string[];
    appConfigAlertLaunched: boolean;
    appConfigAlertMessage: string;
    appConfigAlertPage: string;
    appConfigAlertType: string;
    autopayExtraPaymentLaunched: boolean;
    billInfoForCreditCardLaunched: boolean;
    billingDetailsLaunched: boolean;
    billingHistoryLaunched: boolean;
    billingHref: string;
    billingRedesignLaunched: boolean;
    billingSMSAlertsLaunched: boolean;
    camApiEmailBasicLaunched: boolean;
    canceledPolicyDocsLaunched: boolean;
    cancelScheduledPaymentLaunched: boolean;
    caPaperlessLaunched: boolean; // leaving in here so it can be reused for CAPaperless re-enrollment modal                                                                                                                                                        
    cdiAddPolicyLaunched: boolean;
    changeDriverLaunched: boolean;
    chatApiToken: string;
    chatLaunched: boolean;
    chatLaunchedStates: string[];
    chatUrl: string;
    omniChatLaunched: boolean;
    checkForBusinessUser: boolean;
    claimsHref: string;
    creditCardPaymentLaunched: boolean;
    creditCardProfilesLaunched: boolean;
    creditCardZipCodeLaunched: boolean;
    crossSellLaunched: boolean;
    datadogRUMLaunched: boolean;
    datadogFeedbackLaunched: boolean;
    discountsNotificationLaunched: boolean;
    docsAnalyticsLaunched: boolean;
    documentCenterLaunched: boolean;
    editAddressLaunched: boolean;
    editNameLaunched: boolean;
    eftPaymentLaunched: boolean;
    eServiceLaunched: string;
    faqLaunched: boolean;
    homepageLaunched: boolean;
    hydraRecurringCreditCardLaunched: boolean;
    imageCaptureLaunched: boolean;
    inflationMessagingInfoCardLaunched: boolean;
    inflationMessagingLaunched: boolean;
    infoCardRenewalLaunched: boolean;
    infoCardWaterProtectionLaunched: boolean;
    landingHref: string;
    lowMileageAngularLaunched: boolean;
    maintenanceAlertLaunched: boolean;
    managePaymentMethodRedesignLaunched: boolean;
    miniappUrl: string;
    mobilePdfShareLaunched: boolean;
    nebulaDocsHubRedesignLaunched: boolean;
    nebulaSafecoBillLaunched: boolean;
    nebulaSafecoPolicyLaunched: boolean;
    opcStartAnalyticsLaunched: boolean;
    opcVerifyAnalyticsLaunched: boolean;
    opcCompleteAnalyticsLaunched: boolean;
    opcTempIdCardLaunched: boolean;
    paperlessPolicyLaunched: boolean;
    paperlessRedesignLaunched: boolean;
    paperlessUIUpdateLaunched: boolean;
    paymentUpdateLaunched: boolean;
    pingFEServers: boolean;
    policySummaryUpdateLaunched: boolean;
    postponePaymentLaunched: boolean;
    preferencesLaunched: boolean;
    pwolccLaunched: boolean;
    pwoleftLaunched: boolean;
    pwolPaymentAmountsLaunched: boolean;
    rememberMeCookieName: string;
    removeDriverLaunched: boolean;
    removeLoanProviderLaunched: boolean;
    removeVehicleLaunched: boolean;
    replaceVehicleLaunchedStates: string[];
    renewalAllLOBsLaunched: boolean;
    renewalBillingLaunched: boolean;
    renewalDocsLaunched: boolean;
    renewalPolicySummaryLaunched: boolean;
    replaceVehicleLaunched: boolean;
    sarLinkLaunched: boolean;
    schedulePaymentLaunched: boolean;
    searchLaunched: boolean;
    selfServicePaperlessLaunched: boolean;
    slackFeedbackWidgetLaunched: boolean;
    slackRefactorLaunched: boolean;
    smsCTAModalLaunched: boolean;
    smsLaunched: boolean;
    smsLaunchedStates: string[];
    smsPhoneNumber: string;
    smsUrl: string;
    stateAutoLaunched: boolean;
    updateLoanProviderLaunched: boolean;
    cpraChoiceLaunched: boolean;
    lmdsAlertUpdateLaunched: boolean;
    ccPaymentEndpointRefactorLaunched: boolean;
    hydraRecurringEFTLaunched: boolean;
    hydraRecurringAutoPayV2Launched: boolean;
    reviewAutopayUnenrollLaunched: boolean;
    vaHomeownersDocsLaunched: boolean;

    // New Features
    experiences: Experience[]
    esign_status_enabled: boolean;
    sms_mercury_enabled: boolean;
    preferences_enabled: boolean;
    party_enabled: boolean;
    singleCaMsg_enabled: boolean;
    claims_enabled: boolean;
    email_customer_enabled: boolean;
}

class Experience {
   name: string;
   descritpion?: string;
   group: 'T1' | 'T2' | 'C1'
   samples?: number[];
}
