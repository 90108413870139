import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  @Input() color?: string = 'navy';          // navy, black, inverse
  @Input() isVertical?: boolean = false;     // vertical orientation; default is horizontal

  viewBox: string = '0 0 224 54';
  orientation: string = 'horizontal';

  constructor() { }

  ngOnInit() {
   this.setLogoProps();
  }

  setLogoProps() {
    const verticalLogoProps = {
      viewBox: '0 0 112 76',
      orientation: 'vertical'
    };

    if (this.isVertical) {
      this.viewBox = verticalLogoProps.viewBox;
      this.orientation = verticalLogoProps.orientation;
    }
  }

}