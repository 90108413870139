<div class="endorsement-status" [ngClass]="{'pending-vehicles': showPendingEndorsement}">
  <span *ngIf="showProcessedEndorsement" class="endorsement-content">   
    <app-icon name="successAlert" color='success' size="16"></app-icon>
    <div class="endorsement-text">Updated {{lastProcessedEndorsementDate | date: 'MMM d'}}</div>
  </span>

  <span *ngIf="showPendingEndorsement" class="endorsement-content">   
    <app-icon name="time" color='dark' size="16"></app-icon>
    <div class="endorsement-text">{{buildPendingEndorsementForDisplay()}}</div>
  </span>
</div>