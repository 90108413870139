import { Component, Input, AfterContentChecked } from '@angular/core';
import { InputComponent } from '../input/input.component';

/* Component uses ngx-mask
https://www.npmjs.com/package/ngx-mask/v/7.3.6

Mask pattern code and meaning:
  0 : digits (like 0 to 9)
  9 : digits (like 0 to 9), but optional
  A : letters (upper or lowercase) and digits
  S : only letters (upper or lowercase)
     
Examples:
  00-00-0000 : 12-34-5678
  AAAA : 2F6g
  SSSS : asDF
*/

@Component({
  selector: 'app-mask-input',
  templateUrl: './mask-input.component.html',
  styleUrls: ['./mask-input.component.scss']
})
export class MaskInputComponent extends InputComponent {
  @Input() maskPattern: string;
  @Input() helpText?: string;
  @Input() helpTextMulti?: string[];
}
