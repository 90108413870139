<lmds-date-picker [(value)]="datePickerValue" [labelVisual]="label" [disabled]="isDisabled"
    [disableDates]="isDateDisabled" [highlightType]="highlightColor" [inputId]="id"
    [labelA11y]="labelA11y" (datePickerFocus)="onDatePickerFocus($event)" (datePickerBlur)="onDatePickerBlur($event)"
    (datePickerChange)="onDatePickerChange($event)" (input)="onDateChange($event.target.value)" [required]="true">
</lmds-date-picker>
<div class="below-input-content" [ngClass]="{'has-error': error}">
    <div class="error-msg sco-messageGroup" *ngIf="error">
        <div id="text-error">
            <app-icon [name]="'errorAlert'" [color]="'error'"></app-icon>
            <span class="validation-message sco-message sco-message--error">{{validationMsg}}</span>
        </div>
    </div>
</div>