
export class Search {
    id: number;
    title: string;
    pageUrl: string;
    faq?: FaqSearch[];
    keywords: string[];
    needsValidPolicy: true;
    needsValidBilling: false;
    countOfHits: number;
}

export class FaqSearch {
    title: string;
    url?: string;
    keyParam: string;
    countOfHits: number;

    constructor(title: string, key: string) {
        this.title = title;
        this.keyParam = key;
        this.countOfHits = 1;
    }
}

export class SearchResults {
    pageResults: Search[];
    faqResults: FaqSearch[];

    constructor() {
        this.pageResults = [];
        this.faqResults = [];
    }
}