export default class PaperlessBilling {
    accountNumber: string;
    contextGuid: string;
    emailCurrent: string;
    emailNew: string;
    emailOld: string;
    enrollmentCode: string;
    enrollmentFlag: string;
    isGet: boolean;
    isPaperless: boolean;
    isSuccessful: boolean;
    policyNumbers: string[];
    requestGuid: string;
    statusCode: number;
    statusDescription: string;
    userId: string;
}