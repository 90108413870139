export default class Preferences {
    id: string;
    userId: string;
    state: string;
    paperlessDismissedDate: string;
    smsOptInDismissedDate: string;
    isPaperlessEnrollLastLogin: boolean;
    crossSellCount: number;
    crossSellDate: string;
}
