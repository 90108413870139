import CAMStorage from "../constants/CAMStorage";
import { getLOBGroupType, LOB } from "../constants/linesOfBusiness";
import { PhoneNumbers } from "../constants/phoneNumbers";
import {
    DefaultCollisionCoverage,
    DefaultComprehensiveCoverage,
    DefaultReimbursementCoverage,
    LossOfUseCoverageValues,
    PolicyChangeVehicleOwnershipTypes,
    PolicyChangeVehicleRequiredCoverageTypes
} from "../constants/policyChange";

import Agency from "../models/agency";
import { coverageKeys } from 'src/app/utilities/constants/coverages';
import { EmailRequest } from "../models/email";
import GarageLocation from "../models/garageLocation";
import Policy from "../models/policy";
import PolicyCoverage, { PolicyCoverageValue } from "../models/policyCoverage";
import Vehicle from "../models/vehicle";
import { EmailAttachment, EmailTemplate, EmailTemplateData } from 'src/app/utilities/models/email';
import PolicyMethods from "./policy.methods";
import LoanProvider from "../models/loanProvider";

export default class PolicyChangeMethods {

    public static buildBasicEmail(policy: Policy, agency: Agency, changeType: string, firstName: string, emailAddress: string): EmailTemplate {
        let customerEmail: EmailTemplate = new EmailTemplate(emailAddress);
        customerEmail.emailTemplateData.toEmailAddress = emailAddress;
        customerEmail.emailTemplateData.lineOfBusiness = getLOBGroupType(policy.lineOfBusiness);
        customerEmail.emailTemplateData.policyNumber = policy.number;
        customerEmail.emailTemplateData.firstName = firstName;
        customerEmail.emailTemplateData.changeType = changeType;
        customerEmail.emailTemplateData.agencyName = agency.agencyName;
        customerEmail.emailTemplateData.agencyPhone = this.getAgencyPhoneNumber(agency);
        customerEmail.emailTemplateData.agencyEmail = agency.emailAddress;
        customerEmail.emailTemplateData.isGoldAgency = PolicyMethods.isGoldPlusOrGoldAgency(agency);

        return customerEmail;
    }

    private static getAgencyPhoneNumber(agency: Agency): string {
        let agencyPhoneNumber = agency.phoneNumber;

        if (PolicyMethods.isGoldAgency(agency)) {
            agencyPhoneNumber = PhoneNumbers.GOLD_AGENCY_PHONE_NUMBER;
        }

        if (PolicyMethods.isGoldPlusAgency(agency)) {
            agencyPhoneNumber = PhoneNumbers.GOLD_PLUS_AGENCY_PHONE_NUMBER;
        }

        return agencyPhoneNumber;
    }

    public static hasPendingEndorsement(policy: Policy): boolean {
        let hasPendingStatus = false;

        if (policy) {
            const policyNumber = policy.number;
            const pendingVehicles = CAMStorage.getItemInStorage(CAMStorage.storageKeys.pendingVehicles, true);
            const pendingLandlords = CAMStorage.getItemInStorage(CAMStorage.storageKeys.pendingLandlords, true);

            switch (policy.lineOfBusiness) {
                case LOB.Home:
                    if (pendingLandlords && pendingLandlords.length > 0) {
                        hasPendingStatus = pendingLandlords.some((pending) => policyNumber === pending.policyNumber);
                    }
                    break;
                case LOB.Auto:
                    if (pendingVehicles && pendingVehicles.length > 0) {
                        hasPendingStatus = pendingVehicles.some((pending) => policyNumber === pending.policyNumber);
                    }
                    break;
                default:
                    break;
            }
        }


        return hasPendingStatus;
    }

    public static getMajorityGarageLocation(policy: Policy, policyGarageLocations: GarageLocation[]): GarageLocation {
        const garageLocationMap = this.configureGarageLocationMap(policyGarageLocations);
        const mostCommonGarageLocation = this.getMostCommonGarageLocation(garageLocationMap);
        let majorityGarageLocation: GarageLocation = mostCommonGarageLocation.location;

        // If there are more than 1 garage location, then find the most common one 
        // If no majority locaiton, use the one that matches with dwelling
        if (garageLocationMap.size > 1) {
            if (mostCommonGarageLocation.count > policyGarageLocations.length / 2) {
                majorityGarageLocation = mostCommonGarageLocation.location;
            } else if (this.isAnyGarageLocationSameAsDwelling(policy, policyGarageLocations)) {
                majorityGarageLocation = policy.dwellings[0];
            }
        }

        return majorityGarageLocation;
    }

    public static isLeasedOrFinanced(financingSelection: string) {
        let isLeasedOrFinanced: boolean = false;

        switch (financingSelection) {
            case PolicyChangeVehicleOwnershipTypes.LEASED:
            case PolicyChangeVehicleOwnershipTypes.FINANCED:
                isLeasedOrFinanced = true;
                break;
            default:
                break;
        }

        return isLeasedOrFinanced;
    }

    public static isStateCA(policy: Policy): boolean {
        const CA_STATE: string = 'CA';
        let isStateCA = false;

        if (policy && policy.ratingStateAlpha && policy.ratingStateAlpha.trim() === CA_STATE) {
            isStateCA = true;
        }

        return isStateCA
    }

    private static configureGarageLocationMap(policyGarageLocations: GarageLocation[]): Map<string, CommonGarageLocation> {
        const garageLocationsMap = new Map();

        policyGarageLocations.forEach((garageLocation) => {
            const _key = JSON.stringify(garageLocation);

            if (garageLocationsMap.has(_key)) {
                const _count = garageLocationsMap.get(_key).count;
                garageLocationsMap.set(_key, { location: garageLocation, count: _count + 1 });
            } else {
                garageLocationsMap.set(_key, { location: garageLocation, count: 1 });
            }
        });

        return garageLocationsMap;
    }

    private static getMostCommonGarageLocation(garageLocationMap: Map<string, CommonGarageLocation>): CommonGarageLocation {
        let mostCommonGarageLocation = new CommonGarageLocation();

        const _sortedGarageLocationMap = new Map([...garageLocationMap].sort((a, b) =>
            b[1].count - a[1].count
        ));

        const _keys = _sortedGarageLocationMap.keys();
        mostCommonGarageLocation = _sortedGarageLocationMap.get(_keys.next().value);

        return mostCommonGarageLocation;
    }

    public static isAnyGarageLocationSameAsDwelling(policy: Policy, policyGarageLocations: GarageLocation[]): boolean {
        let isMatchingLocation = false;

        if (policy && policy.dwellings && policy.dwellings.length) {
            isMatchingLocation = policyGarageLocations.some((garageLocation) => garageLocation === policy.dwellings[0]);
        }

        return isMatchingLocation;
    }

    public static filterNewVehicleReplacementCoverage(viewablePolicyCoverages: PolicyCoverage[]): PolicyCoverage[] {

        let newVehicleReplacement = viewablePolicyCoverages.find((coverage: PolicyCoverage) => coverage.key === coverageKeys.RPLVEH);
        if (newVehicleReplacement) {
            viewablePolicyCoverages = viewablePolicyCoverages.filter(item => item.key !== newVehicleReplacement.key);
        }

        return viewablePolicyCoverages;
    }

    public static setNewVehicleCoverages(policy: Policy): PolicyCoverage[] {
        // Copys the coverage list from the vehicle with the most of the 
        // 3 required coverages (COLL, COMP, LOU) and the most coverages
        // in general
        const requiredCoverages: string[] = [
            PolicyChangeVehicleRequiredCoverageTypes.COLLISION,
            PolicyChangeVehicleRequiredCoverageTypes.COMPREHENSIVE,
            PolicyChangeVehicleRequiredCoverageTypes.LOSS_OF_USE
        ];

        //Sorting by vehicle with the most coverages to least
        const vehicles: Vehicle[] = [...policy.vehicles.sort((a: Vehicle, b: Vehicle) => b.coverages.length - a.coverages.length)];

        let newCoverages: PolicyCoverage[] = [];
        let reqCoverageCount: number = 0;

        vehicles.every((vehicle: Vehicle) => {
            const coverages: PolicyCoverage[] = vehicle.coverages;
            const vehReqCoverages = coverages.filter(cov => cov.isEnabled && requiredCoverages.includes(cov.key));

            if (vehReqCoverages.length === 3) {
                newCoverages = [...coverages];
                reqCoverageCount = vehReqCoverages.length;

                return false;
            } else if (vehReqCoverages.length > reqCoverageCount) {
                newCoverages = [...coverages];
                reqCoverageCount = vehReqCoverages.length;
            }

            return true;
        });

        // For vehicle with the most required coverages but not all
        // Use default coverage for any missing required
        if (reqCoverageCount < 3) {
            const hasCollision: boolean = this.hasCollision(newCoverages);
            const hasComprehensive: boolean = this.hasComprehensive(newCoverages);
            const hasReimbursement: boolean = this.hasReiumbursement(newCoverages);

            if (!hasCollision) {
                newCoverages.push(DefaultCollisionCoverage);
            }

            if (!hasComprehensive) {
                newCoverages.push(DefaultComprehensiveCoverage);
            }

            if (!hasReimbursement) {
                newCoverages.push(this.getDefaultReimbursementCoverage(policy));
            }
        }

        return newCoverages;
    }

    public static isAdditionalCoveragesAdded(newCoverages: PolicyCoverage[], policy: Policy): boolean {
        // If all vehicles coverages are less than newCoverages than new coverages has added coverages
        const vehicles: Vehicle[] = policy.vehicles;
        const vehWithLessCoverages: Vehicle[] = vehicles.filter((vehicle: Vehicle) => {
            const vehicleCoverages: PolicyCoverage[] = vehicle.coverages.filter(cov => cov.isEnabled);
            const isNewCoveragesGreater: boolean = !!(newCoverages.length > vehicleCoverages.length);

            return isNewCoveragesGreater;
        });
        const isAdditionalCoveragesAdded: boolean = !!(vehWithLessCoverages.length === vehicles.length);

        return isAdditionalCoveragesAdded;
    }

    private static hasCollision(coverages: PolicyCoverage[]): boolean {
        const hasCollision: boolean = coverages.some(coverage => coverage.key === PolicyChangeVehicleRequiredCoverageTypes.COLLISION);

        return hasCollision;
    }

    private static hasComprehensive(coverages: PolicyCoverage[]): boolean {
        const hasComprehensive: boolean = coverages.some(coverage => coverage.key === PolicyChangeVehicleRequiredCoverageTypes.COMPREHENSIVE);

        return hasComprehensive;
    }

    private static hasReiumbursement(coverages: PolicyCoverage[]): boolean {
        const hasReiumbursement: boolean = coverages.some(coverage => coverage.key === PolicyChangeVehicleRequiredCoverageTypes.LOSS_OF_USE);

        return hasReiumbursement;
    }

    protected static getDefaultReimbursementCoverage(policy: Policy): PolicyCoverage {
        const defaultReimbursementCoverage: PolicyCoverage = DefaultReimbursementCoverage;
        defaultReimbursementCoverage.coverageValues = [];

        let coverageValuePerDay: PolicyCoverageValue;
        let coverageValuePerAccident: PolicyCoverageValue;

        switch (policy.ratingStateAlpha) {
            case 'GA':
                coverageValuePerDay = { limit: '$35', unit: LossOfUseCoverageValues.PER_DAY };
                coverageValuePerAccident = { limit: '$1050', unit: LossOfUseCoverageValues.PER_ACCIDENT };
                break;
            case 'NC':
                coverageValuePerDay = { limit: '$15', unit: LossOfUseCoverageValues.PER_DAY };
                coverageValuePerAccident = { limit: '$450', unit: LossOfUseCoverageValues.PER_ACCIDENT };
                break;
            case 'AL':
            case 'IN':
            case 'MI':
            case 'MN':
            case 'OK':
            case 'PA':
            case 'WA':
            case 'WI':
                coverageValuePerDay = { limit: '$22', unit: LossOfUseCoverageValues.PER_DAY };
                coverageValuePerAccident = { limit: '$660', unit: LossOfUseCoverageValues.PER_ACCIDENT };
                break;
            default:
                coverageValuePerDay = { limit: '$25', unit: LossOfUseCoverageValues.PER_DAY };
                coverageValuePerAccident = { limit: '$750', unit: LossOfUseCoverageValues.PER_ACCIDENT };
                break;
        }

        defaultReimbursementCoverage.coverageValues.push(coverageValuePerDay, coverageValuePerAccident);

        return defaultReimbursementCoverage;
    }

    public static buildLenderOptions(policy: Policy): LoanProvider[] {
        let lenderOptions: LoanProvider[] = [];

        if (policy && policy.loanProviders && policy.loanProviders.length) {
            const pendingLenderRemovals: any[] = CAMStorage.getItemInStorage(CAMStorage.storageKeys.pendingLenderRemoval, true);

            if (pendingLenderRemovals && pendingLenderRemovals.length) {
                policy.loanProviders.forEach(loanProvider => {
                    let pendingLenderRemoval = pendingLenderRemovals.find(pending => (pending.policyNumber === policy.number) && (pending.loanProviderID === loanProvider.loanProviderID));

                    // if the lender does not have pending removal request, allow it to be selected for removal or update
                    if (!pendingLenderRemoval) {
                        lenderOptions.push(loanProvider);
                    }
                });
            } else {
                lenderOptions = policy.loanProviders;
            }
        }

        return lenderOptions;
    }

}

export class CommonGarageLocation {
    location: GarageLocation;
    count: number;
}