import { CreditCardInfo } from './creditCard';
import EFTInfo from './eftInfo';
import Billing from './billing';

export default class PaymentInfo {
    policyNumber: string;
    billingAccountNumber: string;
    nicknames: string[];
    contextGuid: string;
    requestGuid: string;
    currentInstallment: number;
    outstandingBalance: number;
    nextInstallment: number;
    creditCardData: CreditCardInfo;
    recurrCreditCardData: CreditCardInfo;
    recurrEFTData: EFTInfo;
    accountActiveStatus: number;
    mortgageeToPay: boolean;
    billingNotes: string;
    paymentMethod: string;
    currentBillExists: string;
    nextBillExists: string;
    monthlyDueDate: string;
    amountDueDate: string;
    amountDueDateText: string;
    paymentMethodText: string;
    hasMultipleAccountsWithBalance: boolean;
    amountDueAccounts: Billing[];
}

export class PaymentInfoRequest {
    policyNumber: string;
}

export class CreditCardPaymentsRequest {
    accountsWithAmountToPay: AccountsWithAmountToPay[];
    billingZipCode: string;
    cardBrand: string;
    cardNumberMasked: string;
    cardType: string;
    ccProfileExists: boolean;
    contextGuid: string;
    emailAddress: string;
    emailNotification: boolean;
    expirationMonth: string;
    expirationYear: string;
    financialAccountId: string;
    firstName: string;
    lastName: string;
    middleInitial: string;
    policyGuid: string;
    requestGuid: string;
    saveAccount: boolean;
}

export class AccountsWithAmountToPay {
    amountToPay: string;
    billingAccountNumber: string;
    policyNumber: string;
}

export class CreditCardPaymentsResponse {
    creditCardMultipleAccountsResults: CreditCardPaymentResponse[];
    requestGuid: string;
    statusCode: number;
    statusMessage: string;
}

export class CreditCardPaymentResponse {
    amountPaid: number;
    billingAccountNumber: string;
    confirmationNumber: string;
    contextGuid: string;
    datePosted: Date;
    financialAccountId: string;
    paymentConfirmationNumber: string;
    policyNumber: string;
    profileChangeMessage: string;
    requestGuid: string;
    statusCode: number;
    statusMessage: string;
    transReferenceNumber: string;
    validateContents: boolean;
}

export class BankWithdrawalPaymentsRequest {
    nameOnBankAccount: string;
    bankAccountNumber: string;
    routingNumber: string;
    eftAccountsWithAmountToPay: AccountsWithAmountToPay[];
    contextGuid: string;
    requestGuid: string;
}

export class BankWithdrawalPaymentsResponse {
    statusCode: number;
    statusMessage: string;
    eftMultipleAccountsResult: BankWithdrawalPaymentResponse[];
    requestGuid: string;
}

export class BankWithdrawalPaymentResponse {
    amountPaid: number;
    paymentDate: Date;
    withdrawalDate: Date;
    statusCode: number;
    status: boolean;
    statusMessage: string;
    confirmationNumber: string;
    tokenizedProcessingResult: TokenizedPaymentResponse;
}

export class TokenizedPaymentResponse {
    billingAccountNumber: string;
    statusCode: number;
    statusMessage: string;
    maskedBankAccountNumber: string;
    financialAccountId: string;
    tokenizedBankAccountNumber: string;
}

class ContextAndRequestGuid {
    requestGuid: string;
    contextGuid: string;
}

export class CreditCardDeleteProfileRequest {
    billingAccountNumber: string;
    emailAddress: string;
    contextAndRequest: ContextAndRequestGuid = new ContextAndRequestGuid();
    policyNumber: string;
}

export class DeleteCcProfileResponse {
    statusCode: number;
    statusMessage: string;
}
