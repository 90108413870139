<div class="multi-policy-discount-component">
    <div class="curve"></div>
    <div class="header">
        <img ngSrc="{{imgLoc}}/info-card/infocard_Saving_Icon.svg" alt="Savings" width="64" height="76"/>
        <span class="sco-heading sco--level3 sco--regular">Saving looks good on you</span>
    </div>
    <span class="content">See all your discounts in your policy declaration.</span>
    <a class="sco-link sco-textLink sco--standalone" 
    data-heap-id="LP_infoTile_multiPolicyDiscount_txt_documentCenter" routerLink="{{url.documents}}">Document center</a>
</div>
