import { Component, OnInit, Input } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'app-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.scss']
})
export class TextareaInputComponent extends InputComponent implements OnInit {
  @Input() id: string;
  @Input() label: string;
  @Input() value: string;
  @Input() hideHelperText?: boolean = false;
  @Input() placeholder?: string = '';
  @Input() resize?: boolean = false;

  ngOnInit() {
  }

}
