import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { PostponePaymentModalInfo } from 'src/app/utilities/models/modal';
import { CancelScheduledPaymentsRq } from 'src/app/utilities/models/payments';
import PolicyDocument from 'src/app/utilities/models/policyDocument';
import { ModalNames } from '../../constants/modalNames';
import { BookTransferInfo } from '../../models/bookTransfer';
import { ReviewBillingSchedule } from '../../models/autoPay';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private canDeactivateSource: Subject<boolean> = new Subject<boolean>();
  private isModalOpenSource: Subject<any> = new Subject<OpenModal>();
  private isModalLoadedSource: Subject<boolean> = new Subject<boolean>();
  private policyDocumentsLoadedSource: Subject<PolicyDocument> = new Subject<PolicyDocument>();
  private unenrollAutopaySource: Subject<boolean> = new Subject<boolean>();
  
  canDeactivateObs: Observable<boolean> = this.canDeactivateSource.asObservable();
  isModalOpenObs: Observable<OpenModal> = this.isModalOpenSource.asObservable(); 
  isModalLoadedObs: Observable<boolean> = this.isModalLoadedSource.asObservable();
  policyDocumentsObs: Observable<PolicyDocument> = this.policyDocumentsLoadedSource.asObservable();
  unenrollAutopayObs: Observable<boolean> = this.unenrollAutopaySource.asObservable();
  
  //scheduled payments obs.  @todo: do not follow this pattern -- new pattern is being worked on
  private hasCancelScheduledPaymentSource: Subject<boolean> = new Subject<boolean>();
  private isCancelScheduledPaymentSuccessSource: Subject<boolean> = new Subject<boolean>();
  hasCancelSAPCallObs: Observable<boolean> = this.hasCancelScheduledPaymentSource.asObservable();
  isCancelSAPSuccessObs: Observable<boolean> = this.isCancelScheduledPaymentSuccessSource.asObservable();

  autoPaySuccessMessage: string = '';
  bookTransferInfo: BookTransferInfo;
  cancelScheduledPaymentAccountNumber: string;
  cancelScheduledPaymentPolicies: string;
  canDeactivate: boolean = false;
  hasCancelSAPBeenCalled: boolean = false;
  isBillingAvailable = false;
  isCancelSAPSuccess: boolean = false;
  isContactingAgent: boolean = false;
  isModalLoaded: boolean = false;
  modalName: string = '';
  paperlessModalViewed: boolean = false;
  paymentSettingsSuccessMessages: string[] = [];
  policyDocuments: PolicyDocument = null;
  postponePaymentSuccessInfo: PostponePaymentModalInfo = null;
  reviewAutopayUnenrollSchedule: ReviewBillingSchedule[];
  scheduledPaymentToCancel: CancelScheduledPaymentsRq;
  smsOptInModalViewed: boolean = false;
  sourceComponent: string = '';
  unenrollAutopay: boolean = false;
  verifyPolicyNumber: string = '';

  openModal(modalName: string, sourceComponent?: string): void {
    this.modalName = modalName;
    this.sourceComponent = sourceComponent;
    // need to check in app component - if modal is paperless terms & conditions are loaded then the modal can be opened
    let isPaperlessModal = (modalName === ModalNames.PAPERLESS || modalName === ModalNames.PAPERLESS_CA || modalName === ModalNames.PAPERLESS_CA_REENROLL) ? true : false;
    this.isModalOpenSource.next({ openModal: true, isModalPaperless: isPaperlessModal });
  }

  closeModal(): void {
    this.isModalOpenSource.next({ openModal: false });
    this.unsetModalProperties();
  }

  contactAgent(action: boolean): void {
    this.isContactingAgent = action;
  }

  getModalName(): string {
    return this.modalName;
  }

  getSourceComponent(): string {
    return this.sourceComponent;
  }

  // called from modal component when buttons are clicked set to true
  setCanDeactivate(canDeactivate: boolean): void {
    this.canDeactivate = canDeactivate;
    this.canDeactivateSource.next(canDeactivate);
  }

  setModalLoaded(): void {
    this.isModalLoadedSource.next(true);
    this.isModalLoaded = true;
  }

  setHasCancelScheduledPaymentBeenCall(): void {
    this.hasCancelScheduledPaymentSource.next(true);
    this.hasCancelSAPBeenCalled = true;
  }

  setCancelScheduledPaymentSuccess(isSuccess: boolean): void {
    this.isCancelScheduledPaymentSuccessSource.next(isSuccess);
    this.isCancelSAPSuccess = isSuccess;
  }

  setPolicyDocuments(documents: PolicyDocument): void {
    this.policyDocumentsLoadedSource.next(documents);
    this.policyDocuments = documents;
  }

  setAutopaySuccessInfo(autoPaySuccessMessage: string, bookTransferInfo: BookTransferInfo): void {
    this.autoPaySuccessMessage = autoPaySuccessMessage;
    this.bookTransferInfo = bookTransferInfo;
  }

  setPaymentSettingsSuccessMessage(paymentSettingsSuccessMessages: string[], bookTransferInfo: BookTransferInfo): void {
    this.paymentSettingsSuccessMessages = paymentSettingsSuccessMessages;
    this.bookTransferInfo = bookTransferInfo;
  }

  setPostponePaymentSuccessInfo(postponePaymentSuccessInfo: PostponePaymentModalInfo, bookTransferInfo: BookTransferInfo): void {
    this.postponePaymentSuccessInfo = postponePaymentSuccessInfo;
    this.bookTransferInfo = bookTransferInfo;
  }

  setVerifyPolicyNumber(verifyPolicyNumber: string): void {
    this.verifyPolicyNumber = verifyPolicyNumber;
  }

  setBillingAvailableFlag(billingFlag: boolean) {
    this.isBillingAvailable = billingFlag;
  }

  setPaperlessModalViewed(viewed: boolean) {
    this.paperlessModalViewed = viewed;
  }

  setSmsModalViewed(viewed: boolean) {
    this.smsOptInModalViewed = viewed;
  }

  setScheduledPaymentToCancel(cancelScheduledPaymentAccountNumber: string , scheduledPaymentToCancel: CancelScheduledPaymentsRq, cancelScheduledPaymentPolicies: string): void {
    this.scheduledPaymentToCancel = scheduledPaymentToCancel;
    this.cancelScheduledPaymentAccountNumber = cancelScheduledPaymentAccountNumber;
    this.cancelScheduledPaymentPolicies = cancelScheduledPaymentPolicies;
  }

  setReviewAutopayUnenrollSchedule(autopayUnenrollSchedule: ReviewBillingSchedule[]): void {
    this.reviewAutopayUnenrollSchedule = autopayUnenrollSchedule;
  }

  setUnenrollAutopayFlag(unenrollAutopay: boolean) {
    this.unenrollAutopaySource.next(unenrollAutopay);
    this.unenrollAutopay = unenrollAutopay;
  }

  unsetModalProperties(): void {
    this.modalName = '';
    this.isContactingAgent = false;
    this.isModalLoadedSource.next(false);
    this.isModalLoaded = false;
    this.isBillingAvailable = false;
    this.policyDocumentsLoadedSource.next(null);
    this.policyDocuments = null;
    this.verifyPolicyNumber = null;
     this.unsetUnenrollAutopayProperties();
    this.unsetCancelScheduledPaymentsProperties();
  }

  unsetUnenrollAutopayProperties(): void {
    this.reviewAutopayUnenrollSchedule = null;
    this.unenrollAutopaySource.next(null);
    this.unenrollAutopay = false;
  }

  //reset cancel scheduled payments modal properties
  unsetCancelScheduledPaymentsProperties(): void {
    this.hasCancelScheduledPaymentSource.next(false);
    this.hasCancelSAPBeenCalled = false;
    this.isCancelScheduledPaymentSuccessSource.next(false);
    this.isCancelSAPSuccess = false;
    this.scheduledPaymentToCancel = null;
    this.cancelScheduledPaymentAccountNumber = '';
    this.cancelScheduledPaymentPolicies = '';
  }
}

export class OpenModal {
  openModal: boolean;
  isModalPaperless?: boolean;
}
