import { addLeadingZeroToValueRule, ValidationRegex } from "../models/fieldValidation";
import DateMethods from "./date.methods";

export default class DatePickerMethods {
    public static isWithinPastThirtyDaysExclusive(value: string) {
        let isWithinPastThirtyDays: boolean = false;

        if (value && DateMethods.isValidDateFormat(value)) {
            let selectedDate = new Date(value);
            let today = new Date();

            let diff = (selectedDate.setHours(0, 0, 0, 0) - today.setHours(0, 0, 0, 0)) / (1000 * 60 * 60 * 24);

            if ((diff > -31) && (diff < 0)) {
                isWithinPastThirtyDays = true
            }
        }

        return isWithinPastThirtyDays;
    }

    public static isWithinPastThirtyDaysInclusive(value: string) {
        let isWithinPastThirtyOrToday: boolean = false;

        if (value && DateMethods.isValidDateFormat(value)) {
            const isToday: boolean = DatePickerMethods.isSameDate(new Date(value), new Date());
            const isWithinPastThirtyDays: boolean = DatePickerMethods.isWithinPastThirtyDaysExclusive(value);

            isWithinPastThirtyOrToday = isToday || isWithinPastThirtyDays;
        }

        return isWithinPastThirtyOrToday;
    }

    public static isWithinFutureThirtyDaysExclusive(value: string) {
        let isWithinFutureThirtyDays: boolean = false;

        if (value && DateMethods.isValidDateFormat(value)) {
            let selectedDate = new Date(value);
            let today = new Date();

            let diff = (selectedDate.setHours(0, 0, 0, 0) - today.setHours(0, 0, 0, 0)) / (1000 * 60 * 60 * 24);

            if ((diff > 0) && (diff < 31)) {
                isWithinFutureThirtyDays = true
            }
        }

        return isWithinFutureThirtyDays;
    }

    public static isWithinFutureThirtyDaysInclusive(value: string) {
        let isWithinFutureThirtyOrToday: boolean = false;

        if (value && DateMethods.isValidDateFormat(value)) {
            const isToday: boolean = DatePickerMethods.isSameDate(new Date(value), new Date());
            const isWithinFutureThirtyDays: boolean = DatePickerMethods.isWithinFutureThirtyDaysExclusive(value);

            isWithinFutureThirtyOrToday = isToday || isWithinFutureThirtyDays;
        }

        return isWithinFutureThirtyOrToday;
    }

    public static isWithinThirtyDays(value: string): boolean {
        let isWithinThirtyDays: boolean = false;

        if (value && DateMethods.isValidDateFormat(value)) {
            const isToday: boolean = DatePickerMethods.isSameDate(new Date(value), new Date());
            const isWithinFutureThirtyDays: boolean = DatePickerMethods.isWithinFutureThirtyDaysExclusive(value);
            const isWithinPastThirtyDays: boolean = DatePickerMethods.isWithinPastThirtyDaysExclusive(value);

            if (isToday || isWithinFutureThirtyDays || isWithinPastThirtyDays) {
                isWithinThirtyDays = true;
            }
        }

        return isWithinThirtyDays;
    }

    public static isDateAfterTodayExclusive(value: string): boolean {
        let isAfterToday: boolean = false;

        if (value && DateMethods.isValidDateFormat(value)) {
            let selectedDate = new Date(value);
            let today = new Date();

            if (selectedDate.setHours(0, 0, 0, 0) > today.setHours(0, 0, 0, 0)) {
                isAfterToday = true;
            }
        }

        return isAfterToday;
    }

    public static isDateAfterTodayInclusive(value: string) {
        let isTodayOrAfter: boolean = false;

        if (value && DateMethods.isValidDateFormat(value)) {
            const isAfterToday: boolean = DatePickerMethods.isDateAfterTodayExclusive(value);
            const isToday: boolean = DatePickerMethods.isSameDate(new Date(value), new Date());

            isTodayOrAfter = isToday || isAfterToday;
        }

        return isTodayOrAfter;
    }
    
    public static isBetweenTodayAndDate(value: string, maxValue: string) {
        let isBetweenTodayAndDate: boolean = false;

        if (value && maxValue && DateMethods.isValidDateFormat(value) && DateMethods.isValidDateFormat(maxValue)) {
            let maxDate = new Date(maxValue);
            let selectedDate = new Date(value);

            if (DatePickerMethods.isDateAfterTodayInclusive(value) && (selectedDate.setHours(0, 0, 0, 0) <= maxDate.setHours(0, 0, 0, 0))) {
                isBetweenTodayAndDate = true;
            }
        }

        return isBetweenTodayAndDate;
    }

    public static isSameDate(inputDate1: Date, inputDate2: Date) {
        if (inputDate1 && inputDate2) {
            return inputDate1.setHours(0, 0, 0, 0) === inputDate2.setHours(0, 0, 0, 0);
        }
    }

    public static removeTimestampFromISODate(value: string) {
        if (value && value.includes('T')) {
            const split = value.split('T');
            value = split[0];
        }
    
        return value;
    }
    
    public static formatToSimpleDateFromISO(inputDateString: string) {
        if (inputDateString && DateMethods.isValidISODateFormat(inputDateString)) {
            const split = inputDateString.split('-');

            if (split && split.length === 3) {
                let month = addLeadingZeroToValueRule(split[1]);
                let date = addLeadingZeroToValueRule(split[2]);
                let year = split[0];

                return month + "/" + date + "/" + year;
            }
        }
        return '';
    }
}

// used to set blackout dates on calendar for datepicker component
export class DatePickerRangeMethods {
    public static isAfterToday(date: Date): boolean {
        let isAfterToday = false;
        let today = new Date();

        if (date > today) {
            isAfterToday = true;
        }

        return isAfterToday;
    }

    public static isTodayAndFutureDate(date: Date): boolean {
        let isTodayAndFuture = false;
        const today = new Date();

        if (DatePickerMethods.isSameDate(date, today) || this.isAfterToday(date)) {
            isTodayAndFuture = true;
        }

        return isTodayAndFuture;
    }

    public static isWithinPastThirtyDays(date: Date): boolean {
        let isWithinPastThirtyDays = false;

        let today = new Date();
        let pastThirty = new Date(new Date().setDate(today.getDate() - 30));

        if (date > pastThirty) {
            isWithinPastThirtyDays = true;
        }

        return isWithinPastThirtyDays;
    }

    public static isWithinFutureThirtyDaysExclusive(date: Date): boolean {
        let isWithinFutureThirtyDays = false;

        let today = new Date();
        let futureThirty = new Date(new Date().setDate(today.getDate() + 30));

        if (date < futureThirty && date > today) {
            isWithinFutureThirtyDays = true;
        }

        return isWithinFutureThirtyDays;
    }

    public static isWithinFutureThirtyDaysInclusive(date: Date): boolean {
        let isWithinFutureThirtyDays = false;

        let today = new Date();

        if (DatePickerMethods.isSameDate(date, today) || this.isWithinFutureThirtyDaysExclusive(date)) {
            isWithinFutureThirtyDays = true;
        }

        return isWithinFutureThirtyDays;
    }

    public static isWithinThirtyDays(date: Date): boolean {
        let isWithinThirtyDays = false;

        if (this.isWithinPastThirtyDays(date) && this.isWithinFutureThirtyDaysExclusive(date)) {
            isWithinThirtyDays = true;
        }

        return isWithinThirtyDays;
    }

    public static isWithinGivenDateRangeInclusive(date: Date, maxDate: Date) {
        let isWithinGivenDateRange = false;

        if (this.isTodayAndFutureDate(date) && (date <= maxDate)) {
            isWithinGivenDateRange = true;
        }

        return isWithinGivenDateRange;
    }
}