<div class="way-finder" role="region" attr.aria-label="Visual indicator of {{steps}}-step process">
  <div class="header-progress-container">
    <ol class="header-progress-list">
      <li class="header-progress-item {{node.status}}" *ngFor="let node of nodes" attr.aria-label="{{node.ariaText}}">
        <img src="{{imgLoc}}/icons/{{node.content}}_icon.svg" *ngIf="!isNumber(node.content)" attr.aria-hidden="true" alt="{{node.content}} icon"/>
        <span *ngIf="isNumber(node.content)" attr.aria-hidden="true">{{node.content}}</span>
        {{node.label}}
        <span class="path"  attr.aria-hidden="true"></span>
      </li>
    </ol>
  </div>
</div>
