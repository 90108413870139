export const DocumentTypeDescriptions = {      // match documentTypeDescription from documents API
    NEW_BUSINESS: 'NEW POLICY',
    RENEWAL: 'RENEWAL',
    ENDORSEMENT: 'POLICY CHANGE',
    REINSTATEMENT: 'REINSTATEMENT',
    REACTIVATION_NOTICE: 'REACTIVATION NOTICE' // Same as reinstatement
};

export const DocumentCodesMeta = {      // for Nebula
    ID_CARDS: 'PM7',
    NON_PAY_CANCELLATION: 'IA',
    RENEWAL: 'PM5',
    PROOF_OF_INSURANCE: ''              // fill in PM code for POI 
};

export const DocumentTypesMeta = {
    POLICY: 'POLICY',
    BILLING: 'BILLING'
};
