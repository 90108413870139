<div class="scheduled-payment" *ngIf="currentScheduledPayment && schedulePaymentMessage" [ngClass]="{'failed-payment' : isFailed, 'large' : parentHeapId === 'MAP'}">
    <div *ngIf="isFailed; else notificationIcon">
        <app-icon name="info" color="error"></app-icon>
    </div>
    <ng-template #notificationIcon>
        <div>
            <app-icon name="info" color="informative"></app-icon>
        </div>
    </ng-template>
    <div class="scheduled-payment-content">
        <b class="scheduled-make-payments-message">
            <span>{{schedulePaymentMessage}} </span>
        </b>
        <span>
            <a *ngIf="isCancelScheduledPaymentLaunched && isCancelable" 
                class="sco-link sco-textLink sco--standalone schedule-make-payment-cancel" [id]="cancelLinkHeapId"
                (click)="openCancelSchedulePaymentModal()" [routerLink]="[]">
                Cancel
            </a>
        </span>
    </div>
</div>