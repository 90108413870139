import { addLeadingZeroToValueRule, ValidationRegex, Validations } from "src/app/utilities/models/fieldValidation";
import { FieldValidation } from 'src/app/utilities/models/fieldValidation';

export default class DateMethods {
    private static MINIMUM_AGE: number = 15;
    private static MAXIMUM_AGE: number = 130;

    public static dateFields: { dateOfBirth: string, licenseIssuedDate: string, effectiveDate: string, purchasedDate: string, plateReturnedDate: string, accidentDate: string } = {
        dateOfBirth: 'Date of birth',
        licenseIssuedDate: 'License issued date',
        effectiveDate: 'Effective date',
        purchasedDate: 'Purchased date',
        plateReturnedDate: 'Plate returned date',
        accidentDate: 'Accident date'
    };

    public static isDateMinValue(date: Date): boolean {
        const dateMinValue: string = '0001-01-01T00:00:00';

        return (!date || date.toString() === dateMinValue);
    }

    public static validateDate(value: string): boolean {
        if (value && value.length === 10 && value.split("/").length === 3) {
            const months = {
                january: '01',
                february: '02',
                march: '03',
                april: '04',
                may: '05',
                june: '06',
                july: '07',
                august: '08',
                september: '09',
                october: '10',
                november: '11',
                december: '12'
            };

            let month = value.split("/")[0];
            let day = value.split("/")[1];

            if (+day < 1) {
                return false;
            }

            switch (month) {
                case months.april:
                case months.june:
                case months.september:
                case months.november:
                    if (+day > 30) {
                        return false;
                    }
                    break;
                case months.february:
                    if (+day > 29) {
                        return false;
                    }
                    break;
                default:

                    return true;
            }

            return true;
        }
    }

    // checkFuture optional boolean - determines if we check 30 days into the future, 30 days into the past, or both directions if omitted
    public static checkDateWithin30Days(value: string, checkFuture?: boolean) {
        if (value && value.length === 10) {
            let selectedDate = new Date(value);
            let today = new Date();

            let diff = (selectedDate.setHours(0, 0, 0, 0) - today.setHours(0, 0, 0, 0)) / (1000 * 60 * 60 * 24);

            if (checkFuture === true) {
                return (diff >= 0) && (diff <= 30);
            } else if (checkFuture === false) {
                return (diff >= -30) && (diff <= 0);
            } else {
                return Math.abs(diff) <= 30;
            }
        }
    }

     // check more than 39 days into the past
     public static isDateWithin39DaysBeforeToday(value: string) {
        let isValidDate = false;
        if (value && value.length === 10) {
            let selectedDate = new Date(value);
            let today = new Date();

            let diff = (selectedDate.setHours(0, 0, 0, 0) - today.setHours(0, 0, 0, 0)) / (1000 * 60 * 60 * 24);            
            isValidDate =  (diff >= -39) && (diff <= 0);            
        }
        return isValidDate;
    }

    public static checkDateAfterToday(value: string) {
        if (value && value.length === 10) {
            let selectedDate = new Date(value);
            let today = new Date();

            return selectedDate.setHours(0, 0, 0, 0) > today.setHours(0, 0, 0, 0);
        }
    }

    public static checkDateBeforeToday(value: string) {
        if (value && value.length === 10) {
            let selectedDate = new Date(value);
            let today = new Date();

            return selectedDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0);
        }
    }

    public static checkAfterDate(inputDate1: string, inputDate2: string, inclusive?: boolean) {
        if (inputDate1 && inputDate2 && inputDate1.length === 10 && inputDate2.length === 10) {
            let firstDate = new Date(inputDate1);
            let secondDate = new Date(inputDate2);

            if (inclusive && inclusive === true) {
                return firstDate.setHours(0, 0, 0, 0) >= secondDate.setHours(0, 0, 0, 0);
            }

            return firstDate.setHours(0, 0, 0, 0) > secondDate.setHours(0, 0, 0, 0);
        }
    }

    public static checkBeforeDate(inputDate1: string, inputDate2: string, inclusive?: boolean) {
        if (inputDate1 && inputDate2 && inputDate1.length === 10 && inputDate2.length === 10) {
            let firstDate = new Date(inputDate1);
            let secondDate = new Date(inputDate2);

            if (inclusive && inclusive === true) {
                return firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0);
            }
            
            return firstDate.setHours(0, 0, 0, 0) < secondDate.setHours(0, 0, 0, 0);
        }
    }

    public static sortByDateStringDesc(inputDate1: string, inputDate2: string) {
        if (inputDate1 && inputDate2 && this.isValidDateFormat(inputDate1) && this.isValidDateFormat(inputDate2)) {
            if(this.checkAfterDate(inputDate1,inputDate2)) {
                return -1;
            }

            if(this.checkBeforeDate(inputDate1,inputDate2)) {
                return 1;
            }

            if(this.isSameDateFromString(inputDate1,inputDate2)) {
                return 0;
            }
        }

    }

    public static isBetweenTodayAndFutureDateInclusive(inputDate: string, futureDate: string) {
        if (inputDate && futureDate) {
            const isAfterTodayInclusive: boolean = this.checkAfterDate(inputDate, this.formatDateString(new Date()), true);
            const isBeforeFutureDateInclusive: boolean = this.checkBeforeDate(inputDate, futureDate, true);

            return (isAfterTodayInclusive && isBeforeFutureDateInclusive);
        }
    }

    public static oneDayAfter(input: string): string {
        if (input && input.length === 10) {
            let inputDate = new Date(input);
            inputDate.setDate(inputDate.getDate() + 1);
            return this.formatDateString(inputDate);
        }
        return '';
    }

    public static addDaysToDate(input: string, numDays: number) {
        if (input && input.length === 10) {
            let inputDate = new Date(input);
            inputDate.setDate(inputDate.getDate() + numDays);
            return this.formatDateString(inputDate);
        }
    }

    // MM/DD/YYYY
    public static formatDateString(inputDate: Date): string {
        if (inputDate) {
            let month = addLeadingZeroToValueRule(inputDate.getMonth() + 1 + "");
            let date = addLeadingZeroToValueRule(inputDate.getDate() + "");
            return month + "/" + date + "/" + inputDate.getFullYear();
        }
        return '';
    }
    // YYYY-MM-DD
    public static formatDateStringYearMonthDay(inputDate: Date): string {
      if (inputDate) {
        let month = addLeadingZeroToValueRule(inputDate.getMonth() + 1 + "");
        let date = addLeadingZeroToValueRule(inputDate.getDate() + "");
        return inputDate.getFullYear() + "-" + month + "-" + date;
      }
      return '';
    }

    // **/**/YYYY
    public static formatMaskedDateString(inputDate: Date): string {
        if (inputDate) {
            return "**/**/" + inputDate.getFullYear();
        }
        return '';
    }

    public static formatDate(inputDate: string): string {
        if (inputDate) {
            if (inputDate.length === 2 && !~inputDate.indexOf("/")) {
                inputDate += "/";
            }

            if (inputDate.length === 3 && !~inputDate.indexOf("/")) {
                inputDate = inputDate.slice(0, 2) + "/" + inputDate.slice(2);
            }

            if (inputDate.length === 5 && inputDate.split("/").length !== 3) {
                inputDate += "/";
            }

            if (inputDate.length === 6 && inputDate.split("/").length !== 3) {
                inputDate = inputDate.slice(0, 5) + "/" + inputDate.slice(5);
            }
        }

        return inputDate;
    }

    public static restrictSlashOnInput(event: KeyboardEvent): void {
        const pattern: RegExp = /[/\\]/;

        if (pattern.test(event.key)) {
            event.preventDefault();
        }
    }

    public static formatOrdinalDate(dayOfMonth: string): string {
        // check and remove leading zero
        if (dayOfMonth && dayOfMonth.length === 2 && dayOfMonth.indexOf('0') === 0) {
            dayOfMonth = dayOfMonth.replace(/^0+/, '');
        }

        switch (dayOfMonth) {
            case '1':
            case '21':
            case '31':
                dayOfMonth += 'st';
                break;
            case '2':
            case '22':
                dayOfMonth += 'nd';
                break;
            case '3':
            case '23':
                dayOfMonth += 'rd';
                break;
            default:
                dayOfMonth += 'th';
                break;
        }

        return dayOfMonth;
    }

    public static formatShortYear(year: string): string {
        let shortYear = year;
        if (year && year.length === 4) {
            shortYear = year.substring(2);
        }

        return shortYear;
    }

    public static isSameDateFromString(inputDate1: string, inputDate2: string) {
        if (inputDate1 && inputDate2 && inputDate1.length === 10 && inputDate2.length === 10) {
            let firstDate = new Date(inputDate1);
            let secondDate = new Date(inputDate2);

            return firstDate.setHours(0, 0, 0, 0) === secondDate.setHours(0, 0, 0, 0);
        }
    }

    public static isWithinYearsFromDate(input: string, yearsFromDate: number) {
        const today = new Date();
        const inputDate = new Date(input);
        let isYearsFromDate = false;
        let years = (today.getFullYear() - inputDate.getFullYear());

        if (today.getMonth() <= inputDate.getMonth() && today.getDate() < inputDate.getDate()) {
            years--;
        }

        isYearsFromDate = years < yearsFromDate;

        return isYearsFromDate;
    }

    public static isRequiredAgeValidate(birthOfDate: string, minimumAge: number, maximumAge?: number) {
        const birthDate = new Date(birthOfDate);
        const today = new Date();
        maximumAge = maximumAge ? maximumAge : this.MAXIMUM_AGE;
        let validBirthDate = false;
        let years = (today.getFullYear() - birthDate.getFullYear());

        if (today.getMonth() <= birthDate.getMonth() && today.getDate() < birthDate.getDate()) {
            years--;
        }

        validBirthDate = years >= minimumAge && years <= maximumAge;

        return validBirthDate;
    }

    public static validateDateOnBlur(field: string, date: string, validation: FieldValidation, isPastDateValid?: boolean): void {
        let forceError: boolean = false;
        let forceErrorMessage: string = '';

        if (date) {
            forceError = true;

            if (!this.validateDate(date) || !this.isDateFormatted(date, validation)) {
                forceErrorMessage = 'Enter a valid date';
            } else {
                switch (field) {
                    case this.dateFields.accidentDate:
                        forceErrorMessage = this.getAccidentDateErrorMessage(date);
                        break;
                    case this.dateFields.effectiveDate:
                        forceErrorMessage = this.getEffectiveDateErrorMessage(date, isPastDateValid);
                        break;
                    case this.dateFields.purchasedDate:
                        forceErrorMessage = this.getPurchasedDateErrorMessage(date);
                        break;
                    case this.dateFields.dateOfBirth:
                        forceErrorMessage = this.getDOBDateErrorMessage(date, field);
                        break;
                    case this.dateFields.licenseIssuedDate:
                        forceErrorMessage = this.getLicenseDateErrorMessage(date, field);
                        break;
                    case this.dateFields.plateReturnedDate:
                        forceErrorMessage = this.getPlateReturnedDateErrorMessage(date);
                        break;
                    default:
                        forceErrorMessage = '';
                }

                forceError = forceErrorMessage ? true : false;
            }
        }

        if (forceError) {
            validation.forceError = true;
            validation.forceErrorMessage = forceErrorMessage;
        }
    }

    private static isDateFormatted(date: string, validation: FieldValidation): boolean {
        let isDateFormatted: boolean = true;

        if (validation.hasError && validation.validations && validation.validations.length) {
            validation.validations.forEach((validation: Validations) => {
                if (validation.pattern) {
                    const regex: RegExp = new RegExp(validation.pattern);

                    if (!regex.test(date)) {
                        isDateFormatted = false;
                    }
                }
            });
        }

        return isDateFormatted;
    }

    private static getAccidentDateErrorMessage(date: string): string {
        let forceErrorMessage: string = '';

        if (this.checkDateAfterToday(date)) {
            forceErrorMessage = `Accident date can't be later than today`;
        } else if (!this.checkDateWithin30Days(date)) {
            forceErrorMessage = `Accident date must be within 30 days before today`;
        }

        return forceErrorMessage;
    }

    private static getEffectiveDateErrorMessage(date: string, isPastDateValid?: boolean): string {
        let forceErrorMessage: string = '';

        if (!isPastDateValid) {
            if (this.checkDateBeforeToday(date)) {
                forceErrorMessage = `Effective date must be today or later`;
                return forceErrorMessage;
            }
        }

        if (!this.checkDateWithin30Days(date, true)) {
            forceErrorMessage = `Effective date must be within 30 days after today`;
        }

        return forceErrorMessage;
    }

    private static getPurchasedDateErrorMessage(date: string): string {
        let forceErrorMessage: string = '';

        if (this.checkDateAfterToday(date)) {
            forceErrorMessage = `Date shouldn't be in the future`;            
        } 

       
        return forceErrorMessage;
    }

    private static getDOBDateErrorMessage(date: string, field: string): string {
        const hasDOBValidationError = this.checkDateAfterToday(date) || !(this.isRequiredAgeValidate(date, this.MINIMUM_AGE));
        let forceErrorMessage = hasDOBValidationError ? `Please enter a valid ${field.toLowerCase()}` : '';

        return forceErrorMessage;
    }

    private static getLicenseDateErrorMessage(date: string, field: string): string {
        let forceErrorMessage = this.checkDateAfterToday(date) ? `Please enter a valid ${field.toLowerCase()}` : '';

        return forceErrorMessage;
    }

    private static getPlateReturnedDateErrorMessage(date: string): string {
        let forceErrorMessage: string = '';

        if (this.checkDateAfterToday(date)) {
            forceErrorMessage = `Date shouldn't be in the future — return plates before removing vehicle`;
        } else if (!this.checkDateWithin30Days(date, false)) {
            forceErrorMessage = `Date should be within the past 30 days`;
        }

        return forceErrorMessage;
    }

    public static isValidDateFormat(inputString: string): boolean {
        let isValid = false;

        if (inputString) {
            const isValidSimpleDate: boolean = this.isValidSimpleDateFormat(inputString);
            const isValidISODateFormat: boolean = this.isValidISODateFormat(inputString);

            isValid = isValidSimpleDate || isValidISODateFormat;
        }

        return isValid;
    }

    public static isValidSimpleDateFormat(inputString: string): boolean {
        let isValid = false;

        const simpleDateRegex = new RegExp(ValidationRegex.simpleDate);

        if (inputString && simpleDateRegex.test(inputString)) {
            isValid = true;
        }

        return isValid;
    }

    public static isValidISODateFormat(inputString: string): boolean {
        let isValid = false;

        const isoDateRegex = new RegExp(ValidationRegex.ISODate);

        if (inputString && isoDateRegex.test(inputString)) {
            isValid = true;
        }

        return isValid;
    }

}
