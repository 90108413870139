import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import Endpoints from 'src/app/utilities/constants/endpoints';
import { PolicyService } from '../policy-service/policy.service';
import Policy from '../../models/policy';
import { RequestCache } from '../../interceptors/request-cache.service';
import { AnalyticsService } from '../analytics-service/analytics.service';
import { Features } from '../../models/features';
import { Observable } from 'rxjs';
import { datadogRum } from '@datadog/browser-rum';

@Injectable({
  providedIn: 'root'
})
export class SlackService {
  features: Features = new Features();
  httpOptions: any;
  pageName: string;
  policies: Policy[] = [];
  slackEndpoint: any;
  slackFeedbackObs: Observable<any> = null;
  userAgent: string;

  constructor(
    private commonService: CommonService,
    private policyService: PolicyService,
    private http: HttpClient,
    private router: Router,
    private requestCache: RequestCache,
    private analyticsService: AnalyticsService
  ) { }

  async initializeSlackService(): Promise<void> {
    this.slackEndpoint = Endpoints.api.postFeedback;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      }),
      withCredentials: true
    };
  }

  async sendFeedback(thumbsUp: boolean, message: string, policyNumber: string, additionalPageInfo: string, isDatadogFeedbackLaunched: boolean): Promise<any> {
    const errorHeapId = 'MMA-SystemError|sendFeedback';
    this.userAgent = await this.commonService.getUserAgent();

    if (policyNumber === undefined) {
      this.policies = await this.policyService.getPolicies();
    }

    this.pageName = this.router.url;

    const slackRq = this.buildFeedbackRq(thumbsUp, message, policyNumber, additionalPageInfo);
    this.slackFeedbackObs = this.http.post<any>(this.slackEndpoint, slackRq, this.httpOptions);

    if (isDatadogFeedbackLaunched) {
      this.sendFeedbackToDatadog(slackRq);
    }
    
    return new Promise((resolve, reject) => {
      this.slackFeedbackObs.subscribe({
        next: (response: any) => {
          if (response && this.isSuccessStatus(response)) {
            this.requestCache.cacheBust(this.slackEndpoint);
            resolve(true);
          } else {
            this.analyticsService.trackAnalytics(errorHeapId);
            resolve(true);
          }
        },
        error: (error) => {
          this.analyticsService.trackAnalytics(errorHeapId);
          reject({ error: error });
        }
      });
    });
  }

  private buildFeedbackRq(thumbsUp: boolean, message: string, policyNumber: string, additionalPageInfo: string): Feedback {
    const feedback: Feedback = new Feedback();

    feedback.comment = (message.trim()) ? message : 'no comment';
    feedback.page = this.pageName;
    feedback.thumbsUp = thumbsUp;
    feedback.userAgent = this.userAgent;
    feedback.policyNumber = this.policies.length > 0 ? /[\w]+/g.exec(this.policies[0].number)[0] : policyNumber;
    feedback.additionalPageInfo = additionalPageInfo;
    return feedback;
  }

  private isSuccessStatus(response: any): boolean {
    let isSuccess = false;

    if (response.status && response.status.code === 0) {
      isSuccess = true;
    }

    return isSuccess;
  }

  async sendExitSurveyRequest(exitText: string): Promise<any> {
    const errorHeapId = 'MMA-SystemError|ExitSurvey';
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
        }),
      withCredentials: false
    };
    this.userAgent = await this.commonService.getUserAgent();
    this.policies = await this.policyService.getPolicies();
    this.pageName = this.router.url;

    const slackRq = this.setExitSurveyRequest(exitText);

    return new Promise((resolve) => {
      this.http.post(this.slackEndpoint, slackRq, { ...httpOptions, responseType: 'text' }).subscribe({
        next: (response) => {
          if ('ok' === response.toLowerCase()) {
            this.requestCache.cacheBust(this.slackEndpoint);
            resolve(true);
          } else {
            this.analyticsService.trackAnalytics(errorHeapId);
            resolve(true);
          }
        },
        error: (error) => {
          this.analyticsService.trackAnalytics(errorHeapId);
          resolve(true);
        }
      });
    });
  }

  sendFeedbackToDatadog(feedback: Feedback): void {
    let feedbackCopy: Feedback = { ...feedback };
    feedbackCopy = this.formatPageAndSubview(feedbackCopy);

    datadogRum.addAction('customer-feedback', feedbackCopy);
  }

  formatPageAndSubview(feedback: Feedback): Feedback {
    const ANALYTICS_QUESTION_PARAM = '?analytics';
    const ANALYTICS_AMP_PARAM = '&amp;analytics';

    const page: string = feedback.page;
    const queryParamStartIndex: number = page.indexOf('?');
    let formattedPage: string = page;
    let subView: string = '';

    if (queryParamStartIndex >= 0) {
      if (page.includes(ANALYTICS_QUESTION_PARAM)) {
        formattedPage = page.substring(0, page.indexOf(ANALYTICS_QUESTION_PARAM));
      } else if (page.includes(ANALYTICS_AMP_PARAM)) {
        formattedPage = page.substring(0, page.indexOf(ANALYTICS_AMP_PARAM));
      }

      subView = formattedPage.substring(queryParamStartIndex);
      formattedPage = formattedPage.substring(0, queryParamStartIndex);
    }

    feedback.page = formattedPage;
    feedback.subView = subView;

    return feedback;
  }

  setExitSurveyRequest(exitText: string) {
    const rq = {
      attachments: [
        {
          text: (exitText.trim()) ? exitText : 'no comment',
          author_name: this.pageName,
          fields: [
            {
              title: 'Primary Policy#',
              value: /[\w]+/g.exec(this.policies[0].number)[0]
            }, {
              title: 'Metrics',
              value: 'Experience: ' + '\nDate: ' + new Date().toLocaleDateString() + '\nUser-Agent: ' + this.userAgent
            }
          ]
        }
      ]
    };

    return rq;
  }

}

class Feedback {
  page: string;
  comment: string;
  thumbsUp: boolean;
  userAgent: string;
  policyNumber: string;
  additionalPageInfo: string;
  subView: string;
}
