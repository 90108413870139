<div class="account-select-component">
  <div class="select-account" [ngClass]="{'acct-not-selected' : showSelector, 'acct-selected' : !showSelector, 'center-aligned' : isCenterAligned}">
    <div *ngIf="!supressSelector && selectAccounts.length">
      <h2 id="select-an-account" tabindex="-1" style="outline: none;" class="sco-heading sco--level6 sco--bold">Select a {{title}} to get started</h2>
      <div class="acct-container" *ngFor="let acct of selectAccounts" [ngClass]="{'center-aligned' : isCenterAligned}" tabindex="0"
        (click)="selectAccount(acct)" role="button" (keydown)="keyDownSelectAccount($event, acct)">
        <div class="acct-content">
          <div class="content-container">
            <div class="heading-container">
              <div class="heading">
                <span>{{title}} {{acct.number}}</span>
                <app-renewal-flag *ngIf="acct.isRenewalView" [type]="indicatorPages.ACCOUNT_SELECT"></app-renewal-flag>
              </div>
              <div class="policies" *ngFor="let policy of acct.policies">
                <app-icon name="{{policy.lob.toLowerCase()}}" [size]="'16'" circleColor="null"></app-icon>
                <span>{{policy.nickname}}
                  <div *ngIf="policy.isCanceled" class="policy-info">
                    <div *ngIf="!policy.isCanceledEffective && policy.canceledEffectiveDate; else canceled" class="policy-info-value">
                      (Canceled as of {{policy.canceledEffectiveDate | date:'mediumDate'}})
                    </div>
                    <ng-template #canceled>
                      <div class="policy-info-value">(Canceled)</div>
                    </ng-template>
                  </div>
                </span>
              </div>
            </div>
            <app-icon name="accordionCaretUp" size="16" color="teal" labelA11y="Caret right"></app-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="account-selected" [ngClass]="{'not-selected' : showSelector, 'selected' : !showSelector, 'center-aligned': isCenterAligned}">
    <div *ngIf="!supressSelectedAcct">
      <div class="account-display" *ngIf="selectedAccount && selectedAccount.number">
        <a (click)="toggleView()" *ngIf="!isSingleAccount" id="account-selector-link" name="Select another {{title}}" role="link" (keydown)="keyDownToggleView($event)"
          tabindex="0" [attr.data-heap-id]="'AccountSelect_acts_link_SelectAnother|' + type">
          <app-icon name="linkCaretLeft" color="active"></app-icon><span>Select another {{title}}</span>
        </a>
        <h2 class="heading">{{title}} {{selectedAccount.number}}</h2>
        <div class="policies" *ngFor="let policy of selectedAccount.policies">
          <app-icon name="{{policy.lob.toLowerCase()}}" [size]="'16'"></app-icon>
          <span>{{policy.nickname}}
            <div *ngIf="policy.isCanceled" class="policy-info">
              <div *ngIf="!policy.isCanceledEffective && policy.canceledEffectiveDate; else canceled" class="policy-info-value">
                (Canceled as of {{policy.canceledEffectiveDate | date:'mediumDate'}})
              </div>
              <ng-template #canceled>
                <div class="policy-info-value">(Canceled)</div>
              </ng-template>
            </div>
          </span>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</div>