import { Component, OnInit, Input } from "@angular/core";
import Endpoints from "src/app/utilities/constants/endpoints";
import { ModalService } from "src/app/utilities/services/modal-service/modal.service";
import { CommonService } from "src/app/utilities/services/common-service/common.service";
import { AnalyticsService } from "src/app/utilities/services/analytics-service/analytics.service";

@Component({
  selector: "app-link-paragraph",
  templateUrl: "./link-paragraph.component.html",
  styleUrls: ["./link-paragraph.component.scss"],
})
export class LinkParagraphComponent implements OnInit {
  @Input() text: string;
  splitText: string[] = [];
  splitLinkParagraph: LinkParagraph[] = []
  url = Endpoints.url;
  linkParagraphRegex: RegExp = /(\{.*?\})/g;
  unorderedListRegex: RegExp = /<ul>/g;

  variantType: any = {
    INLINE: 'inline',
    STANDALONE: 'standalone',
    NAVIGATIONAL: 'navigational',
    NAVIGATIONALHEADER: 'navigational-header'
  }

  constructor(
    private commonService: CommonService,
    private modalService: ModalService,
    private analyticsService: AnalyticsService
  ) {

  }

  async ngOnInit() {
    this.splitText = this.text.split(this.linkParagraphRegex);

    this.splitText.forEach((text) => {
      if (text.match(this.linkParagraphRegex)) {
        const parsedLink: LinkParagraph = JSON.parse(text);
        parsedLink.url = this.url[parsedLink.url] || parsedLink.url;
        parsedLink.queryParams = this.url[parsedLink.queryParams];
        parsedLink.variant = this.getVariant(parsedLink.cssClass);
        this.splitLinkParagraph.push(parsedLink);
      } else {
        this.splitLinkParagraph.push({ text: text });
      }
    });
  }

  openModal(modalId: string, heapId: string) {
    this.modalService.openModal(modalId);
    this.analyticsService.trackAnalytics(heapId);

  }

  openExternalUrl(externalUrl: string) {
    window.open(externalUrl);
  }

  trackUrlAnalytics(heapId: string) {
    this.analyticsService.trackAnalytics(heapId);
  }

  getVariant(cssClass: string) {
    let variant = "";
    switch (cssClass) {
      case "sco--standalone":
        variant = this.variantType.STANDALONE;
        break;
      case this.variantType.STANDALONE:
      case this.variantType.NAVIGATIONAL:
      case this.variantType.NAVIGATIONALHEADER:
      case this.variantType.INLINE:
        variant = cssClass;
        break;
      default:
        variant = this.variantType.INLINE;
        break;
    }
    return variant;
  }
}

class LinkParagraph {
  text: string;
  modal?: string;
  url?: string;
  queryParams?: string;
  externalUrl?: string;
  heapId?: string;
  cssClass?: string; 
  variant?: string;
}
