import { Component, Input, OnInit } from '@angular/core';
import { Color } from 'src/app/utilities/constants/colorNames';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {
  @Input() badgeText: string;
  @Input() highlightType: string;
  @Input() iconName?: string;
  @Input() isCompact?: boolean = false;
  @Input() showIcon?: boolean = true;

  iconColor: string;

  constructor() { }

  ngOnInit(): void {
    if (this.showIcon) {
      this.setIcon();
    }
  }

  setIcon(): void {
    let color = "";
    let icon = "";

    switch (this.highlightType) {
      case Color.POSITIVE:
        color = "success";
        icon = "successAlert";
        break;
      case Color.NEGATIVE:
        color = "error";
        icon = "errorAlert";
        break;
      case Color.INFORMATIVE:
        color = "active";
        icon = "info"
        break;
      case Color.CAUTION:
        color = "warning";
        icon = "warning";
        break;
      default:
        break;
    }

    this.iconName = this.iconName ? this.iconName : icon;
    this.iconColor = color;
  }
}
