import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { concatMap, from, mergeMap, Observable, of, switchMap, take } from 'rxjs';

import { environment } from 'src/environments/environment';

import Endpoints from '../constants/endpoints';

import { Features } from '../models/features';

import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { Cookies } from '../services/cookies-service/cookies.service';
import { RoutingService } from '../services/routing-service/routing.service';
import { HttpParams } from '@angular/common/http';

export interface ComponentCanDeactivate {
  canDeactivate: () => Observable<boolean> | boolean;
}

@Injectable()
export class DeactivateGuardService  {
  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> | boolean {
    return component.canDeactivate();
  }
}

@Injectable({
  providedIn: 'root'
})
export class BillingAccessGuardService  {
  features: Features = null;
  constructor(
    private router: Router,
    private commonService: CommonService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.features = await this.commonService.getFeatures();
    if (this.features.billingRedesignLaunched) {
      return true;
    } else {
      this.router.navigate(['pagemissing']);
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class FaqAccessGuardService  {
  features: Features = null;
  constructor(
    private router: Router,
    private commonService: CommonService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.features = await this.commonService.getFeatures();
    if (this.features.faqLaunched) {
      return true;
    } else {
      this.router.navigate(['pagemissing']);
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class HomepageAccessGuardService  {
  features: Features = null;
  constructor(
    private cookies: Cookies,
    private router: Router,
    private commonService: CommonService,
    private routingService: RoutingService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.features = await this.commonService.getFeatures();
    if (this.features.homepageLaunched) {
      return true;
    } else {
      this.routingService.routeTo(Endpoints.url.homepage);
      return false;
    }
  }
}


@Injectable({
  providedIn: 'root'
})
export class PaperlessAccessGuardService  {
  features: Features = null;
  constructor(
    private router: Router,
    private commonService: CommonService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.features = await this.commonService.getFeatures();
    if (this.features.paperlessRedesignLaunched) {
      return true;
    } else {
      this.router.navigate(['pagemissing']);
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class PolicyUpdateAccessGuardService  {
  features: Features = null;
  constructor(
    private router: Router,
    private commonService: CommonService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.features = await this.commonService.getFeatures();
    if (this.features.policySummaryUpdateLaunched) {
      return true;
    } else {
      this.router.navigate(['pagemissing']);
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class PaymentUpdateAccessGuardService  {
  features: Features = null;
  constructor(
    private router: Router,
    private commonService: CommonService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.features = await this.commonService.getFeatures();
    if (this.features.paymentUpdateLaunched) {
      return true;
    } else {
      this.router.navigate(['pagemissing']);
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class DocCenterAccessGuardService  {
  features: Features = null;
  constructor(
    private router: Router,
    private commonService: CommonService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.features = await this.commonService.getFeatures();
    if (this.features.documentCenterLaunched) {
      return true;
    } else {
      this.router.navigate(['pagemissing']);
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class LowMileageAccessGuardService  {
  features: Features = null;
  constructor(
    private router: Router,
    private commonService: CommonService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.features = await this.commonService.getFeatures();
    if (this.features.lowMileageAngularLaunched) {
      return true;
    } else {
      this.router.navigate(['pagemissing']);
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class RenewalDiscountsAccessGuardService  {
  features: Features = null;
  constructor(
    private router: Router,
    private commonService: CommonService,
    private routingService: RoutingService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.features = await this.commonService.getFeatures();
    if (this.features.discountsNotificationLaunched) {
      return true;
    } else {
      this.router.navigate(['pagemissing']);
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class PaymentSelectAccessGuardService  {

  features: Features = null;
  constructor(
    private router: Router,
    private commonService: CommonService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.features = await this.commonService.getFeatures();
    return true;
  }
}

// Redirect to homepage logic
@Injectable({
  providedIn: 'root'
})
export class Auth0AccessGuardService  {
  constructor(
    private router: Router,
    private commonService: CommonService,
    private routingService: RoutingService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
      return true;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LoginAccessGuardService  {
  features: Features = null;
  constructor(
    private cookies: Cookies,
    private router: Router,
    private commonService: CommonService,
    private routingService: RoutingService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.redirectToHome()) {
      return true;
    } else {
      return false;
    }
  }

  // Redirect to homepage if getMMAQParamCookie cookie is not set (the user accessed the login page without the ping parameters)
  redirectToHome(): boolean {
    const flag = this.cookies.getMMAQParamCookie();
    const destUrl = this.cookies.getMMADestUrlCookie();
    if (flag === '' && window.document.location.search === '') {
      this.cookies.clearCookie('MMAQParam');
      window.location.href = (destUrl === '' ? Endpoints.url.homepageUrl : destUrl);
      return true;
    } else if (window.location.href.indexOf('logout') > 1) {
      // Redirect to homepage if request was to logout page from an idle session (multiple tabs scenario) 
      window.location.href = Endpoints.url.homepageUrl;
      return true;
    } else {
      return false;
    }
  }
}
