<div class="autopay-signup-component">
    <div class="curve"></div>
    <div class="header">
        <img ngSrc="{{imgLoc}}/info-card/infocard_Autopay_Icon.svg" alt="autopay" width="60" height="52"/>
        <span class="sco-heading sco--level3 sco--regular">Autopay &#8212; it's so easy</span>
    </div>
    <span class="content">Sign up for recurring payments in less than 5 minutes.</span>
    <button class="sco-button sco--outline" 
        data-heap-id="LP_infoTile_autopay_btn_setupAutopay" routerLink="{{url.managePayments}}">Set up autopay</button>
</div>
