import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, timer, Subscription } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { RoutingService } from 'src/app/utilities/services/routing-service/routing.service';
import Endpoints from 'src/app/utilities/constants/endpoints';
import { environment as env } from 'src/environments/environment';
import { UserActivityService } from 'src/app/utilities/services/user-activity-service/user-activity.service';
import { Cookies } from 'src/app/utilities/services/cookies-service/cookies.service';
import { Features } from 'src/app/utilities/models/features';
import { AccountService } from 'src/app/utilities/services/account-service/account.service';

@Component({
  selector: 'idle-timer',
  templateUrl: './idle-timer.component.html',
  styleUrls: ['./idle-timer.component.scss']
})

export class IdleTimerComponent implements OnDestroy, OnInit {

  url = Endpoints.url;
  timeLeft = 0;
  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;
  features: Features = new Features();
  sessionTimeout = env.sessionDuration;
  idleStart: number;

  constructor(
    private cookies: Cookies,
    private userActivityService: UserActivityService,
    private routingService: RoutingService,
    private accountService: AccountService
  ) {

  }

  ngOnInit() {
    this.idleStart = Date.now();
    this.resetTimer();
    this.userActivityService.isUserActionObs.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      if (this.cookies.getMMAInactivityCookie() === 'Yes') {
        //Checking for this cookie before resetting the timer because a user might have hit the idle timer on a different tab
        this.sessionLogout();
      } else {
        this.resetTimer();
      }
    });
  }

  ngOnDestroy() {
    this.timerSubscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  resetTimer() {
    const interval = 1000;
    const ticks = timer(0, interval).pipe(take(this.sessionTimeout));
    this.timerSubscription = ticks.subscribe({
      next: (value) => {
        this.timeLeft = ((this.sessionTimeout - +value) * interval);
        if (Date.now() - this.idleStart > this.sessionTimeout * interval) {
          this.sessionLogout();
        }
      },
      error: (error) => {
        console.log(error)
      },
      complete: () => {
        this.sessionLogout()
      }
    });

    this.idleStart = Date.now();
  }

  sessionLogout() {
    // Logout once idle time reaches sessionDuration
      this.cookies.setMMAInactivityCookie('Yes');
      this.accountService.logout();
    }
}
