import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { OverlayModule } from '@angular/cdk/overlay';
import { OverlayContainer } from '@angular/cdk/overlay';

import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { AccountSelectComponent } from './components/account-select/account-select.component';
import { AccountTermsAndConditionsComponent } from './components/account-terms-and-conditions/account-terms-and-conditions.component';
import { ActionsMenuComponent } from './components/actions-menu/actions-menu.component';
import { AgentCardComponent } from './components/agent-card/agent-card.component';
import { AlertsComponent, PageAlertsComponent } from './components/alerts/alerts.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { CookieService } from 'ngx-cookie-service';
import { CustomTitleCasePipe } from 'src/app/utilities/pipes/custom-title-case/custom-title-case';
import { MaskInputComponent } from './components/mask-input/mask-input.component';
import { EftInputComponent } from './components/eft-input/eft-input.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { FooterComponent } from './components/footer/footer.component';
import { IconComponent, CreditCardIconComponent, SvgIconComponent } from './components/icon/icon.component';
import { IdleTimerComponent } from './components/idle-timer/idle-timer.component';
import {
  InfoCardAutoPaySignupComponent,
  InfoCardBillingSmsComponent,
  InfoCardComponent,
  InfoCardCustomerAppreciationComponent,
  InfoCardCrossSellComponent,
  InfoCardMultiPolicyDiscountComponent,
  InfoCardNewBusinessComponent,
  InfoCardPaperlessBillingComponent,
  InfoCardRenewalComponent,
  InfoCardMobileAppPromotionComponent,
} from './components/info-card/info-card.component';
import { InputComponent } from './components/input/input.component';
import { MegaMenuComponent } from './components/megamenu/megamenu.component';
import { AccordionComponent, LoginAccordionComponent } from './components/accordion/accordion.component';
import { AppOverlayContainer } from './custom-overlay-container';
import { CreditCardInputNewComponent } from './components/credit-card-input/credit-card-input.component';
import { EndorsementStatusComponent } from './components/endorsement-status/endorsement-status.component';
import { EsignNotificationComponent } from './components/esign-notification/esign-notification.component';
import { HeapIdDirective } from './directives/heap-id.directive';
import { LinkParagraphComponent } from './components/link-paragraph/link-paragraph.component';
import { LogoComponent } from './components/logo/logo.component';
import { MaterialModule } from './material-module';
import { MobileAppDownloadComponent } from './components/mobile-app-download/mobile-app-download.component';
import { ModalComponent } from './components/modal/modal.component';
import { PaperlessTermsContentComponent } from './components/paperless-terms-content/paperless-terms-content.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { PhoneNumberPipe } from 'src/app/utilities/pipes/phone-number/phone-number.pipe';
import { ProgressComponent } from './components/progress/progress.component';
import { RenewalFlagComponent } from './components/renewal-flag/renewal-flag.component';
import { SafePipe } from 'src/app/utilities/pipes/safe-html/safe-html.pipe';
import { ScheduledPaymentMessageComponent } from './components/scheduled-payment-message/scheduled-payment-message.component';
import { SearchComponent } from './components/search/search.component';
import { SelectComponent } from './components/select/select.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { StatusIndicatorComponent } from './components/status-indicator/status-indicator.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TextareaInputComponent } from './components/textarea-input/textarea-input.component';
import { ToggleSliderComponent } from './components/toggle-slider/toggle-slider.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { WayFinderComponent } from './components/way-finder/way-finder.component';

import { AlertModule } from '@lmig/lmds-angular-alert';
import { ButtonModule } from '@lmig/lmds-angular-button';
import { DatePickerModule } from '@lmig/lmds-angular-date-picker';
import { ToggleModule } from '@lmig/lmds-angular-toggle';
import { TypographyModule } from '@lmig/lmds-angular-typography';
import { GridModule } from '@lmig/lmds-angular-grid';

import { DatepickerInputComponent } from './components/datepicker-input/datepicker-input.component';
import { BadgeComponent } from './components/badge/badge.component';

import { IconModule } from '@lmig/lmds-angular-icon';
import { NotificationModule } from '@lmig/lmds-angular-notification';
import { LinkModule } from '@lmig/lmds-angular-link';
import { BadgeModule } from '@lmig/lmds-angular-badge';
import { PopoverModule } from '@lmig/lmds-angular-popover';
import { CreditCardIconsModule } from '@lmig/lmds-angular-credit-card-icons';
import { CustomScrollDirective } from './directives/custom-scroll/custom-scroll.directive';

@NgModule({
  imports: [
    AlertModule,
    ButtonModule,
    BadgeModule,
    CommonModule,
    CreditCardIconsModule,
    DatePickerModule,
    FormsModule,
    GridModule,
    IconModule,
    LinkModule,
    MaterialModule,
    NgOptimizedImage,
    NgxMaskDirective,
    NgxMaskPipe,
    NotificationModule,
    PopoverModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    RouterModule,
    ToggleModule,
    TypographyModule
  ],
  declarations: [
    AccordionComponent,
    AccountSelectComponent,
    AccountTermsAndConditionsComponent,
    ActionsMenuComponent,
    AgentCardComponent,
    AlertsComponent,
    BadgeComponent,
    ContactUsComponent,
    CreditCardIconComponent,
    CreditCardInputNewComponent,
    CustomScrollDirective,
    CustomTitleCasePipe,
    DatepickerInputComponent,
    EftInputComponent,
    EndorsementStatusComponent,
    EsignNotificationComponent,
    FeedbackComponent,
    FooterComponent,
    HeapIdDirective,
    IconComponent,
    IdleTimerComponent,
    InfoCardAutoPaySignupComponent,
    InfoCardBillingSmsComponent,
    InfoCardComponent,
    InfoCardCrossSellComponent,
    InfoCardCustomerAppreciationComponent,
    InfoCardMobileAppPromotionComponent,
    InfoCardMultiPolicyDiscountComponent,
    InfoCardNewBusinessComponent,
    InfoCardPaperlessBillingComponent,
    InfoCardRenewalComponent,
    InputComponent,
    LinkParagraphComponent,
    LoginAccordionComponent,
    LogoComponent,
    MaskInputComponent,
    MegaMenuComponent,
    MobileAppDownloadComponent,
    ModalComponent,
    PageAlertsComponent,
    PaperlessTermsContentComponent,
    PasswordInputComponent,
    PhoneNumberPipe,
    ProgressComponent,
    RenewalFlagComponent,
    SafePipe,
    ScheduledPaymentMessageComponent,
    SearchComponent,
    SelectComponent,
    SpinnerComponent,
    StatusIndicatorComponent,
    SvgIconComponent,
    TabsComponent,
    TextareaInputComponent,
    ToggleSliderComponent,
    TooltipComponent,
    WayFinderComponent
  ],
  exports: [
    AccordionComponent,
    AccountSelectComponent,
    AccountTermsAndConditionsComponent,
    ActionsMenuComponent,
    AgentCardComponent,
    AlertsComponent,
    AlertModule,
    BadgeComponent,
    BadgeModule,
    ButtonModule,
    ContactUsComponent,
    CreditCardIconComponent,
    CreditCardInputNewComponent,
    CreditCardIconsModule,
    CustomScrollDirective,
    CustomTitleCasePipe,
    DatepickerInputComponent,
    DatePickerModule,
    EftInputComponent,
    EndorsementStatusComponent,
    EsignNotificationComponent,
    FeedbackComponent,
    FooterComponent,
    FormsModule,
    GridModule,
    HeapIdDirective,
    IconComponent,
    IconModule,
    IdleTimerComponent,
    InfoCardComponent,
    InputComponent,
    LinkModule,
    LinkParagraphComponent,
    LoginAccordionComponent,
    LogoComponent,
    MaskInputComponent,
    MaterialModule,
    MegaMenuComponent,
    MobileAppDownloadComponent,
    ModalComponent,
    NotificationModule,
    OverlayModule,
    PageAlertsComponent,
    PaperlessTermsContentComponent,
    PasswordInputComponent,
    PhoneNumberPipe,
    PopoverModule,
    ProgressComponent,
    ReactiveFormsModule,
    RenewalFlagComponent,
    SafePipe,
    ScheduledPaymentMessageComponent,
    SearchComponent,
    SelectComponent,
    SpinnerComponent,
    StatusIndicatorComponent,
    SvgIconComponent,
    TabsComponent,
    TextareaInputComponent,
    ToggleModule,
    ToggleSliderComponent,
    TooltipComponent,
    TypographyModule,
    WayFinderComponent
  ],
  providers: [
    CookieService,
    PhoneNumberPipe,
    provideNgxMask(),
    { provide: OverlayContainer, useExisting: AppOverlayContainer },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }
  ]
})
export class SharedModule { }
