import { Component, Input, OnInit } from '@angular/core';

import { SlackService } from 'src/app/utilities/services/slack-service/slack.service';
import { environment as env } from 'src/environments/environment';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { Features } from 'src/app/utilities/models/features';

@Component({
  selector: 'app-slack-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  @Input() additionalPageInfo?: string;
  @Input() isAuthenticated?: boolean = true
  @Input() policyNumber?: string;
  
  badBtnState: string;
  feedbackSubmitted = false;
  goodBtnState: string;
  imgLoc = env.imgLoc;
  readyToSubmit = false;
  thumbsUp: boolean;
  isDatadogFeedbackLaunched: boolean = false;

  buttonStates = {
    SELECTED: 'selected',
    HOVER: 'hover',
    UNSELECTED: 'unselected'
  };

  feedbackBtns = {
    POSITIVE: 'good',
    NEGATIVE: 'bad'
  };

  constructor(
    private commonService: CommonService,
    private slackService: SlackService,
    private router: Router
  ) {
  }

  async ngOnInit() {
    this.thumbsUp = null;
    this.goodBtnState = this.buttonStates.UNSELECTED;
    this.badBtnState = this.buttonStates.UNSELECTED;

    await this.getFeatures();
    
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe(async (event: NavigationStart) => {
      this.clearForm();
      this.feedbackSubmitted = false;
    });
  }

  async getFeatures(): Promise<void> {
    try {
      const features: Features = await this.commonService.getFeatures();

      if (features) {
        this.isDatadogFeedbackLaunched = features.datadogFeedbackLaunched;
      }
    } catch (error) {
      // continue regardless of error
    }
  }

  async sendFeedback(feedback: string) {
    try {


      await this.slackService.sendFeedback(this.thumbsUp, feedback, this.policyNumber, this.additionalPageInfo, this.isDatadogFeedbackLaunched);
    } catch (error) {
      console.log(error);
    } finally {
      this.feedbackSubmitted = true;
    }
  }

  clearForm() {
    this.goodBtnState = this.buttonStates.UNSELECTED;
    this.badBtnState = this.buttonStates.UNSELECTED;
    this.thumbsUp = false;
    this.readyToSubmit = false;
  }

  toggleThumb(up?: boolean) {
    this.thumbsUp = (up) ? true : false;
  }

  getPlaceHolderText() {
    if (this.thumbsUp) {
      return 'Thanks! Tell us what you like.';
    } else {
      return 'Oh no! Tell us what could be better.';
    }
  }

  getNextBtnState(btn: string, nextAction: string) {
    const targetBtnState = btn === this.feedbackBtns.POSITIVE ? this.goodBtnState : this.badBtnState;

    if (targetBtnState !== this.buttonStates.SELECTED) {
      return nextAction;
    }

    return targetBtnState;
  }

  toggleView(btn: string) {
    const targetBtnState = (btn === this.feedbackBtns.POSITIVE) ? this.goodBtnState : this.badBtnState;
    const isSelected = (this.goodBtnState === this.buttonStates.SELECTED || this.badBtnState === this.buttonStates.SELECTED);

    if (isSelected) {
      this.goodBtnState = this.buttonStates.UNSELECTED;
      this.badBtnState = this.buttonStates.UNSELECTED;
    }

    switch (btn) {
      case 'good': {
        this.goodBtnState = (targetBtnState !== this.buttonStates.SELECTED) ? this.buttonStates.SELECTED : this.buttonStates.UNSELECTED;
        this.thumbsUp = (this.goodBtnState === this.buttonStates.SELECTED) ? true : false;
        break;
      }
      case 'bad': {
        this.badBtnState = (targetBtnState !== this.buttonStates.SELECTED) ? this.buttonStates.SELECTED : this.buttonStates.UNSELECTED;
        this.thumbsUp = (this.badBtnState === this.buttonStates.SELECTED) ? false : true;
        break;
      }
    }

    this.readyToSubmit = targetBtnState !== this.buttonStates.SELECTED ? true : false;
  }
}
