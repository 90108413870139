<nav id="menu" aria-label="Safeco Account Navigation Menu">

    <div class="safeco-mobile-header">
        <button id="mobile_nav_menu" type="button" class="navbar-toggle" aria-expanded="false"
            [attr.aria-label]="'menu'" (click)="mobileMenuToggle = true;">
            <app-icon name="menu" color="inverse" size="32"></app-icon>
        </button>
        <div class="mobile-tab">
            <span class="tab">{{setActiveTab() | titlecase}}</span>
            <span class="name" *ngIf="customerName">Hello {{customerName}}</span>
        </div>
    </div>

    <div id="mobile-menu" class="slide-out" [ngClass]="{'active': mobileMenuToggle}" role="none">
        <div class="menu-overlay" [ngClass]="{'active': mobileMenuToggle}" (click)="mobileMenuToggle = false;" role="none"></div>
        <ul id="safeco-nav" class="nav navbar-nav" role="menubar" aria-label="Navigation Menu">
            <div id="safeco-title" class="">
                <app-logo color="inverse"></app-logo>
                <button #menuClose id="menu_close" class="close-menu no-fill" aria-label="Close menu"
                    (click)="mobileMenuToggle = false; closeFocus();" role="button">
                    <app-icon name="close" color="inverse"></app-icon>
                </button>
            </div>
            <li id="mm-homepage" role="none" class="nav-item" [ngClass]="{active: setActiveTab() === 'homepage'}">
                <a id="mm-homepage-link" class="nav-link" role="menuitem" (click)="mobileMenuToggle = false;"
                    [routerLink]="url.homepage">Homepage</a>
            </li>
            <li id="mm-documents" role="none" class="nav-item" [ngClass]="{active: setActiveTab() === 'documents'}">
                <a id="mm-documents-link" class="nav-link" role="menuitem" (click)="mobileMenuToggle = false;"
                    [routerLink]="url.documents">Documents</a>
            </li>
            <li id="mm-policies" role="none" class="nav-item dropdown"
                [ngClass]="{active: setActiveTab() === 'policies'}">
                <a id="mm-policies-link" class="dropdown-toggle nav-link" role="menuitem" data-hover="dropdown" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" [routerLink]="[]" queryParamsHandling="preserve"
                    (click)="showDropdownMenu('policy', false, true)"
                    (mouseenter)="showDropdownMenu('policy', isMobileDevice)" (mouseleave)="closeTabsTimeout()">
                    Policies <app-icon class="caret-down" name="linkCaretDown" color="inverse" role="none"></app-icon>
                </a>
                <div class="dropdown-menu" [ngClass]="{ show: menuItems[0].expanded }"
                    (mouseenter)="showDropdownMenu('policy', isMobileDevice)" (mouseleave)="closeTabsTimeout()" role="none">
                    <div class="dropdown-menu-column" role="none">
                        <ul class="policy-action-list" role="menu" aria-label="Policy sub menu">
                            <li role="none">
                                <a id="myPoliciesLink" role="menuitem"
                                    (click)="mobileMenuToggle = false; clearSession(url.policyDetail);"
                                    [routerLink]="url.policyDetail" class="mm-title-link">My policies</a>
                            </li>
                            <li class="policy-action-list-item" role="none">
                                <a id="idCardsAndDocumentsLink" role="menuitem" class="mm-title-link"
                                    (click)="mobileMenuToggle = false; clearSession(url.documents);"
                                    [routerLink]="url.documents">ID cards and documents</a>
                            </li>
                            <li class="policy-action-list-item" role="none">
                                <a id="policyChangeLink" class="mm-title-link"
                                    (click)="mobileMenuToggle = false; navigateToPolicyChange()" role="menuitem"
                                    [routerLink]="url.policyEdit">Request a
                                    policy change</a>
                            </li>                        
                        </ul>
                    </div>
                </div>
            </li>
            <li id="mm-billing" role="none" class="nav-item dropdown"
                [ngClass]="{active: setActiveTab() === 'billing'}">
                <a id="mm-billing-link" class="dropdown-toggle nav-link" role="menuitem" data-hover="dropdown" data-toggle="dropdown"
                    [routerLink]="[]" aria-haspopup="true" aria-expanded="false" queryParamsHandling="preserve"
                    (click)="showDropdownMenu('billing', false, true)"
                    (mouseenter)="showDropdownMenu('billing', isMobileDevice)" (mouseleave)="closeTabsTimeout()">
                    Billing <app-icon class="caret-down" name="linkCaretDown" color="inverse"></app-icon>
                </a>
                <div class="dropdown-menu-right dropdown-menu" [ngClass]="{show: menuItems[1].expanded}"
                    (mouseenter)="showDropdownMenu('billing', isMobileDevice)" (mouseleave)="closeTabsTimeout()" role="none">
                    <div class="dropdown-menu-column" role="none">
                        <ul role="menu" aria-label="Billing sub menu">
                            <li role="none">
                                <a role="menuitem" [routerLink]="url.billingSummary" class="mm-title-link"
                                    (click)="mobileMenuToggle = false;">My billing</a>
                            </li>
                            <li role="none"><a role="menuitem" [routerLink]="url.payment" class="mm-title-link"
                                    (click)="mobileMenuToggle = false;">Make a payment</a></li>
                            <li role="none">
                                <a role="menuitem" [routerLink]="url.managePayments" class="mm-title-link"
                                    (click)="mobileMenuToggle = false;">Manage payment settings</a>
                            </li>
                            <li role="none">
                                <a role="menuitem" [routerLink]="url.billingHistory" [queryParams]="url.billingHistoryParams" class="mm-title-link"
                                    (click)="mobileMenuToggle = false;">View transaction history</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
            <li id="mm-claims" role="none" class="nav-item dropdown">
                <a id="mm-claims-link" class="dropdown-toggle nav-link" role="menuitem" data-hover="dropdown" data-toggle="dropdown"
                    [routerLink]="[]" aria-haspopup="true" aria-expanded="false" queryParamsHandling="preserve"
                    (click)="showDropdownMenu('claims', false, true)"
                    (mouseenter)="showDropdownMenu('claims', isMobileDevice)" (mouseleave)="closeTabsTimeout()">
                    Claims <app-icon class="caret-down" name="linkCaretDown" color="inverse" role="none"></app-icon>
                </a>
                <div class="dropdown-menu-right dropdown-menu" [ngClass]="{show: menuItems[2].expanded}"
                    (mouseenter)="showDropdownMenu('claims', isMobileDevice)" (mouseleave)="closeTabsTimeout()" role="none">
                    <div class="dropdown-menu-column" role="none">
                        <ul role="menu" aria-label="Claims sub menu">
                            <li role="none"><a role="menuitem" [routerLink]="[]" (click)="navigateTo(viewClaimsURL)">View
                                    my claims</a>
                            </li>
                            <li role="none"><a role="menuitem" [routerLink]="[]" (click)="navigateTo(fileClaimURL)">File a
                                    claim</a></li>
                        </ul>
                    </div>
                </div>
            </li>
            <li id="mm-profile" role="none" class="nav-item dropdown"
                [ngClass]="{active: setActiveTab() === 'profile'}">
                <a id="mm-profile-link" class="dropdown-toggle nav-link" role="menuitem" data-hover="dropdown" data-toggle="dropdown"
                    [routerLink]="[]" aria-haspopup="true" aria-expanded="false" queryParamsHandling="preserve"
                    (click)="showDropdownMenu('profile', false, true)"
                    (mouseenter)="showDropdownMenu('profile', isMobileDevice)" (mouseleave)="closeTabsTimeout()">
                    Profile <app-icon class="caret-down" name="linkCaretDown" color="inverse"></app-icon>
                </a>
                <div class="dropdown-menu-right dropdown-menu" [ngClass]="{show: menuItems[3].expanded}"
                    (mouseenter)="showDropdownMenu('profile', isMobileDevice)" (mouseleave)="closeTabsTimeout()" role="none">
                    <div class="dropdown-menu-column" role="none">
                        <ul role="menu" aria-label="Profile sub menu">
                            <li role="none">
                                <a id="updateContactInfoLink" role="menuitem" class="mm-title-link"
                                    (click)="mobileMenuToggle = false;" [routerLink]="url.changeEmail">Change email</a>
                            </li>
                            <li role="none" *ngIf="activePolicies.length">
                                <a id="updateAddrLink" role="menuitem" class="mm-title-link" [routerLink]="[]"
                                    (click)="mobileMenuToggle = false; navigateToUpdateAddress()">Update address</a>
                            </li>
                            <li role="none">
                                <a id="loginSettingsLink" role="menuitem" class="mm-title-link"
                                    (click)="mobileMenuToggle = false;" [routerLink]="url.changePassword">Change password</a>
                            </li>
                            <li role="none">
                                <a id="paperlessOptLink" role="menuitem" class="mm-title-link"
                                    (click)="mobileMenuToggle = false;" [routerLink]="url.paperlessOptions" 
                                    [queryParams]="url.paperlessOptionsParams">Paperless options</a>
                            </li>
                            <li role="none">
                                <a id="textAlertsLink" role="menuitem" class="mm-title-link" (click)="mobileMenuToggle = false;" 
                                [routerLink]="url.textAlerts" [queryParams]="url.textAlertsParams">Manage text alerts</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
            <div id="menu_hidden_end" class="menu-hidden-end" tabindex="0" role="none"
                style="opacity: 0; height: 0; position: fixed; top: -10px" (focus)="setMenuFocusClose()"></div>
        </ul>
    </div>
</nav>
