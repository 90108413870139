import Policy from './policy';
import EsignSession from './esignSession';
import { EsignSessionUrls } from './esignSessionUrls';

export default class EsignSessionPolicy {
    policyInfo: Policy;
    esignInfo: EsignSession;
    esignSession?: EsignSessionUrls;
    isValidLOBDisplay: boolean;
    isAutoPolicy: boolean;
    iconName: string;
    formattedNickName: string;
    esignSessionUrlFailed: boolean;
    cardPopover: EsignPolicyCardPopover;
    esignSessionFailed: boolean;

    constructor(policy: Policy, session?: EsignSession, urls?: EsignSessionUrls) {
        this.policyInfo = policy;
        if (session !== undefined) { 
            this.esignInfo = session;
            this.esignSession = urls;
        }

    }
}

export class EsignPolicyCardPopover {
    title: string;
    vehicleTitle: string;
    policyNumber: string;
    vehicleList: Array<string>;
    direction: string;
    isVehicleListPopulated: boolean;
}