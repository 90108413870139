<div class="shared-tooltip" title="" #sharedTooltip>
  <div class="tooltip-wrapper">
    <div class="tooltip-row">
      <span class="tooltip-text" #tooltipText>
        {{text}}
      </span>
      <div class="tooltip-content" [ngClass]="direction"
        [ngStyle]="{'width': overlayWidth + 'px', 'margin-left': shiftRight + 'px', 'margin-right': shiftLeft + 'px'}"
        *ngIf="isTooltipActive" tabindex="1" role="tooltip" id="extrainfo">
          <div *ngIf="name === 'vinTooltip'">
            Find your vehicle identification number (VIN) on:
            <ul>
              <li class="bullet-spacing">Your dashboard, near the windshield on the driver's side.</li>
              <li class="bullet-spacing">Frame of the driver's door, when the door is open.</li>
              <li class="bullet-spacing">Title or registration.</li>
            </ul>
            Your VIN should be 17 characters long and shouldn't contain the letters I, O or Q.
            <p class="line-spacing">We'll use your VIN to look up the year, make and model.</p>
          </div>
          <!-- <div *ngIf="name === 'ownerTooltip'">
            <b>Who is the owner?</b>
            <p>The person (or people) listed on the vehicle's title / registration. List only the first two people.</p>

            <b>What if I lease?</b>
            <p>Select the person leasing the vehicle (i.e., the lessee).</p>

            <b>What's a named insured?</b>
            <p>The person (or people) actually named on your policy - usually the person (or people) who purchases and manages the policy.</p>

            <b>One of the drivers on my policy owns the vehicle.</b>
            <p>That's fine, so long as one of your named insureds is also a registered owner. If not, please
              <a class="sco-link sco--standalone sco-textLink tooltip-link" role="link" tabindex="1" [routerLink]="[]" (click)="contactAgent(true)" 
                name="Contact your local Safeco agent for assistance" 
                id="AddVehicle_namedInsuredNotOwner"> contact your local Safeco agent for assistance
              </a>
            </p>

            <b>The owner isn't on my policy yet</b>
            <p>No worries. Since you need to add both a person and a vehicle to your policy, please
              <a class="sco-link sco--standalone sco-textLink tooltip-link" role="link" [routerLink]="[]" tabindex="1" (click)="contactAgent(true)" 
                name="Contact your local Safeco agent for assistance" 
                id="AddVehicle_addPersonAndVehicle"> contact your local Safeco agent for assistance
              </a>
            </p>
            <a class="sco-link tooltip-link" role="link" [routerLink]="[]" tabindex="1" (keydown.Tab)="onTab($event)" (focus)="toggleTooltip($event)"></a>
          </div> -->
          <div class="vehicle-usage" *ngIf="name === 'mileageEstimatorTooltip'">
            <b class="vehicle-usage-header">Mileage estimator</b>
            <p class="vehicle-usage-description">Use this conversion table to help estimate your annual mileage.</p>
            <div class="mileage-estimator-table">
              <table>
                <thead>
                  <tr *ngFor="let header of mileageEstimatorTableData.mileageEstimatorHeaders">
                    <th>{{header.daily}}</th>
                    <th>{{header.weekly}}</th>
                    <th>{{header.annual}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="cam-table-update" *ngFor="let row of mileageEstimatorTableData.mileageEstimatorData">
                    <td>{{row.daily}}</td>
                    <td>{{row.weekly}}</td>
                    <td>{{row.annual}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div *ngIf="name === 'businessUseTooltip'">
            Using your vehicle for business refers to travel needed
            for regular work responsibilities, such as driving to sales
            calls, traveling between job sites, contracting work, etc.
          </div>
      </div>
      <div class="arrow-button-group">
        <div class="tooltip-arrow" [ngClass]="direction" *ngIf="isTooltipActive"></div>
        <div class="icon-group" [ngClass]="{'ie-opened': isTooltipActive && isBrowserIE, 'ie-closed': !isTooltipActive && isBrowserIE}">
          <button class="tooltip-icon" [ngClass]="isTooltipActive ? 'opened' : 'closed'" (keydown.Tab)="onTab($event)"
            (click)="toggleTooltip($event)" id="{{id}}" aria-describedby="extrainfo">
              <div class="button-adjustment">
                <app-icon name="{{iconName}}" labelA11y="{{label}}" color='active' size="16"></app-icon>
              </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>