import { AutoPayError, AutoPayRqV1, AutoPayRq } from "../models/autoPay";
import Billing from "../models/billing";
import { AccountOptionsPaymentMethod } from "../models/billingAccountOptions";
import { BillingPolicies } from "../models/billingStatements";
import PaymentsMethods from "./payments.methods";

export default class AutoPayMethods {
    //@deprecated remove after  
    public static buildCreditCardAutoPayEnrollRequest(instrumentIdCode: string, accountOptions: AccountOptionsPaymentMethod): AutoPayRqV1 {
        const creditCardAutoPayRq = new AutoPayRqV1();
    
        creditCardAutoPayRq.instrumentIdRq = PaymentsMethods.buildInstrumentIdRq(instrumentIdCode);
        creditCardAutoPayRq.accountOptions = accountOptions;

        return creditCardAutoPayRq;
    }

    public static buildAutoPayEnrollRequest(instrumentIdCode: string, billingAccount: Billing): AutoPayRq {
        const autoPayRq = new AutoPayRq();
    
        autoPayRq.instrumentIdRq = PaymentsMethods.buildInstrumentIdRq(instrumentIdCode);
        autoPayRq.policyNumber = billingAccount.policyNumber;
        return autoPayRq;
    }

    public static isInstrumentDetailsError(instrumentPayError: AutoPayError): boolean {
        let isInstrumentError = false;
    
      if (instrumentPayError && instrumentPayError.instrumentErrorCode) {
          isInstrumentError = true;
        }
    
        return isInstrumentError;
    }

    
}
