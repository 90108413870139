import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {

  // Service to notify of user action for the Idle timer
  private userActionOccured: Subject<void> = new Subject();
  isUserActionObs: Observable<void> = this.userActionOccured.asObservable();

  notifyUserAction() {
    this.userActionOccured.next();
  }
}