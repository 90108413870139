import PolicyCoverage from '../models/policyCoverage';

// param values to be used for 'type' query param
export const PolicyChangeTypes = {
    PROOF_OF_INSURANCE: 'proofofinsurance',
    LOAN_PROVIDER: 'loanprovider',
    DEFAULT: 'default',
    VEHICLE: 'vehicle',
    DRIVER: 'driver',
    NAME: 'name',
    ADDRESS: 'address',
    LANDLORD: 'landlord',
    POLICY: 'Policy',
};

// param values to be used for 'mode' query param
export const PolicyChangeModes = {
    UPDATE: 'update',
    ADD: 'add',
    SEND: 'send',
    REMOVE: 'remove',
    CANCEL: 'cancel',
};

export const EmailTypes = {
    ADD_DRIVER: 'Add Driver',
    ADD_LANDLORD: 'Add Landlord',
    ADD_LENDER: 'Add Lender',
    ADD_VEHICLE: 'Add Vehicle',
    DOCUMENT_REQUEST: 'Document Request',
    REPLACE_VEHICLE: 'Replace Vehicle',
    REMOVE_DRIVER: 'Remove Driver',
    REMOVE_LENDER: 'Remove Lender',
    REMOVE_VEHICLE: 'Remove Vehicle',
    UPDATE_ADDRESS: 'Update Address',
    UPDATE_DRIVER: 'Update Driver',
    UPDATE_LENDER: 'Update Lender',
    UPDATE_NAME: 'Update Name',
    TEMP_ID: 'Temp Id',
    XSELL: "Cross Sell"
};

export const PendingEndorsementTypes = {
    ADD_LANDLORD: 'Add landlord',
    ADD_VEHICLE: 'Add Vehicle'
};

// param values to be used for 'view' query param
export const PolicyChangeViews = {
    FIND_VEHICLE: 'findvehicle',
    VEHICLE_DETAILS: 'vehicledetails',
    REGISTERED_OWNER: 'registeredowner',
    VEHICLE_USE: 'vehicleuse',
    VEHICLE_USE_DETAILS: 'vehicleusedetails',
    INTRODUCTION: 'introduction',
    WHAT_DO_YOU_WANT_TO_DO: 'whatdoyouwanttodo',
    FOR_WHICH_VEHICLE: 'forwhichvehicle',
    LENDER_FORM: 'lenderForm',
    LANDLORD_FORM: 'landlordForm',
    CONTACT_INFO: 'contactInformation',
    REVIEW: 'reviewInformation',
    SUBMITTED: 'requestSubmitted',
    REMOVE_VEHICLE_FORM: 'removeVehicleForm',
    REQUEST_UNAVAILABLE: 'requestUnavailable',
    WHICH_DRIVER: 'whichDriver',
    WHICH_LENDER: 'whichLender',
    SEND_PROOF_OF_INSURANCE_CONFIRM: 'sendProofOfInsuranceConfirm',
    UPDATE_ADDRESS: 'updateAddress',
    FORM: 'form' // generic view for lift 'n shifts if needed
};

export const PolicyChangeFlows = {
    ADD_DRIVER: 'AddDriver',
    ADD_LANDLORD: 'AddLandlord',
    ADD_LOAN_PROVIDER: 'AddLoanProvider',
    ADD_VEHICLE: 'AddVehicle',
    CANCEL_POLICY: 'CancelPolicy',
    REMOVE_DRIVER: 'RemoveDriver',
    REMOVE_VEHICLE: 'RemoveVehicle',
    REMOVE_LOAN_PROVIDER: 'RemoveLoanProvider',
    REPLACE_VEHICLE: 'ReplaceVehicle',
    UPDATE_ADDRESS: 'UpdateAddress',
    UPDATE_DRIVER: 'UpdateDriver',
    UPDATE_LANDLORD: 'UpdateLandlord',
    UPDATE_LOAN_PROVIDER: 'UpdateLoanProvider',
    UPDATE_NAME: 'UpdateName'
};

export const PolicyChangeTitles = {
    DEFAULT: 'OK, what\'s changing?',
    SEND_PROOF_OF_INSURANCE: 'Send proof of insurance',
    UPDATE_DWELLING_LOAN_PROVIDER: 'Change mortgage lender',
    ADD_DWELLING_LOAN_PROVIDER: 'Add mortgage lender',
    UPDATE_AUTO_LOAN_PROVIDER: 'Change auto lender',
    ADD_AUTO_LOAN_PROVIDER: 'Add auto lender',
    REMOVE_VEHICLE: 'Change vehicles',
    EDIT_DRIVER: 'Change drivers',
    ADD_LANDLORD: 'Add landlord'
};

// titles to be used for wizard screen 1 (introduction)
export const PolicyChangeIntroTitles = {
    PROOF_OF_INSURANCE: 'OK, first let\'s add your new lender to your policy',
    UPDATE_LOAN_PROVIDER: 'OK, let\'s replace your lender',
    ADD_LOAN_PROVIDER: 'OK, let\'s add your new lender',
    EDIT_LANDLORD: 'OK, let\'s change your landlord'
};

export const PolicyChangeContactPreferences = {
    EMAIL: 'email',
    PHONE: 'phone'
};

export const PolicyChangeVehicleBusinessUseTypes = {
    FULLTIME: 'Full-time',
    OCCASIONAL: 'Occasional',
    NONE: 'None',
    CONSULTING_OR_CONTRACTING: 'Consulting or Contracting',
    SALES: "Sales",
    TRAVEL: "Travel between job sites or locations",
    ERRANDS: "Errands",
    OTHER: "Something else",
};

export const PolicyChangeVehicleCommuteTypes = {
    WORK: 'To work',
    SCHOOL: 'To school',
    NONE: 'None'
};

export const PolicyChangeVehicleRequiredCoverageTypes = {
    COLLISION: 'COLL',
    COMPREHENSIVE: 'COMP',
    LOSS_OF_USE: 'RREIM'
};

export const DefaultCollisionCoverage: PolicyCoverage = {
    title: "Collision",
    coverageValues: [{limit: "$500", unit: "out of pocket"}],
    isDisplayed: true,
    isEnabled: true,
    isVisible: true,
    isSelected: true,
    key: "COLL",
    limitDed: "500",
    limitDedOptionalCode: "",
    limitDescription: "",
    originalLimitDed: "500",
    premium: "",
    premiumUnit: "",
    displayName: "",
    displayDescription: [],
    iconName: "",
    groupNumber: null
};

export const DefaultComprehensiveCoverage: PolicyCoverage = {
    title: "Comprehensive",
    coverageValues: [{limit: "$500", unit: "out of pocket"}],
    isDisplayed: true,
    isEnabled: true,
    isVisible: true,
    isSelected: true,
    key: "COMP",
    limitDed: "500",
    limitDedOptionalCode: "",
    limitDescription: "",
    originalLimitDed: "500",
    premium: "",
    premiumUnit: "",
    displayName: "",
    displayDescription: [],
    iconName: "",
    groupNumber: null
};

export const DefaultReimbursementCoverage: PolicyCoverage = {
    title: "Rental Car Reimbursement",
    coverageValues: [], // default coverageValues to be set later in add-vehicle.component.ts
    isDisplayed: true,
    isEnabled: true,
    isVisible: true,
    isSelected: true,
    key: "RREIM",
    limitDed: "$25/750",
    limitDedOptionalCode: "",
    limitDescription: "",
    originalLimitDed: "$25/750",
    premium: "",
    premiumUnit: "",
    displayName: "",
    displayDescription: [],
    iconName: "",
    groupNumber: null
};

export const LossOfUseCoverageValues = {
    PER_DAY: 'per day',
    PER_ACCIDENT: 'per accident'
};

export const PolicyChangeVehicleOwnershipTypes = {
    FINANCED: 'Financed',
    LEASED: 'Leased',
    OWNED: 'Owned'
};

export const FinancingSubtitle = {
    FINANCED: 'Loan provider',
    LEASED: 'Lease provider'
}

export const RemoveVehicleReasons = {
    SOLD: 'Sold',
    OTHER_POLICY: 'Insured on another policy',
    NOT_REGISTERED: 'Canceled registration',
    TOTAL_LOSS: 'Totaled',
    STORAGE: 'In storage',
    INOPERABLE: 'Inoperable',
    DRIVING_LESS: 'Driving less',
    OTHER: 'Other',
};

export const ChangeAddressOptions = {
    CORRECTION_ONLY: 'Correction only, same address',
    MAILING_ADDRESS_CHANGE: 'Mailing address change only',
    MOVED_TO_NEW_ADDRESS: 'Moved to a new address',
    MOVED_TO_NEW_STATE: 'Moved to a new state',
    OTHER: 'Other'
};

export const SpecialStates = {
    kentucky: {
        abbr: 'KY',
        name: 'KENTUCKY'
    },
    massachusetts: {
        abbr: 'MA',
        name: 'MASSACHUSETTS'
    },
    maryland: {
        abbr: 'MD',
        name: 'MARYLAND'
    },
    newJersey: {
        abbr: 'NJ',
        name: 'NEW JERSEY'
    },
    newYork: {
        abbr: 'NY',
        name: 'NEW YORK'
    },
    nortCarolina: {
        abbr: 'NC',
        name: 'NORTH CAROLINA'
    },
    washingtonDC: {
        abbr: 'DC',
        name: 'WASHINGTON D.C.'
    }
};

export const StateRules = new Map([
    ['KY', {
        state: 'Kentucky',
        link: 'https://drive.ky.gov/Pages/default.aspx',
        stateOffice: 'county clerk'
    }],
    ['MA', {
        state: 'Massachusetts',
        link: 'https://www.mass.gov/orgs/massachusetts-registry-of-motor-vehicles',
        stateOffice: 'RMV'
    }],
    ['MD', {
        state: 'Maryland',
        link: 'http://www.mva.maryland.gov/',
        stateOffice: 'MVA'
    }],
    ['NJ', {
        state: 'New Jersey',
        link: 'https://www.state.nj.us/mvc/',
        stateOffice: 'MVC'
    }],
    ['NY', {
        state: 'New York',
        link: 'https://dmv.ny.gov/',
        stateOffice: 'DMV'
    }],
    ['NC', {
        state: 'North Carolina',
        link: 'https://www.ncdot.gov/dmv/Pages/default.aspx',
        stateOffice: 'DMV'
    }],
    ['DC', {
        state: 'Washington D.C.',
        link: 'https://dmv.dc.gov/',
        stateOffice: 'DMV'
    }]
]);

export const ChangeNameReasons: Array<{ key: string, value: string }> = [
    { key: 'Name is misspelled or otherwise incorrect', value: 'name-correction' },
    { key: 'Legally changed name', value: 'legal-name-change' },
    { key: 'Other', value: 'other' }
];

export const FinancingOptions: Array<{ key: string, value: string }> = [
    { key: 'I have a loan', value: PolicyChangeVehicleOwnershipTypes.FINANCED },
    { key: 'I have a lease', value: PolicyChangeVehicleOwnershipTypes.LEASED },
    { key: 'None, I fully own this vehicle', value: PolicyChangeVehicleOwnershipTypes.OWNED }
];

export const CommuteOptions: Array<{ key: string, value: string }> = [
    { key: 'To work', value: PolicyChangeVehicleCommuteTypes.WORK },
    { key: 'To school', value: PolicyChangeVehicleCommuteTypes.SCHOOL },
    { key: 'None - this vehicle isn\'t used for commuting', value: PolicyChangeVehicleCommuteTypes.NONE }
];

export const BusinessUseOptions: Array<{ key: string, value: string }> = [
    { key: 'Occasional', value: PolicyChangeVehicleBusinessUseTypes.OCCASIONAL },
    { key: 'Full-time', value: PolicyChangeVehicleBusinessUseTypes.FULLTIME },
    { key: 'None', value: PolicyChangeVehicleBusinessUseTypes.NONE }
];

export const BusinessUseTypeOptions: Array<{ key: string, value: string }> = [
    { key: 'Consulting or Contracting', value: PolicyChangeVehicleBusinessUseTypes.CONSULTING_OR_CONTRACTING },
    { key: 'Sales', value: PolicyChangeVehicleBusinessUseTypes.SALES },
    { key: 'Travel between job sites or locations', value: PolicyChangeVehicleBusinessUseTypes.TRAVEL },
    { key: 'Errands', value: PolicyChangeVehicleBusinessUseTypes.ERRANDS },
    { key: 'Something else', value: PolicyChangeVehicleBusinessUseTypes.OTHER }
];

export const RemoveDriverReasons: Array<{ key: string, value: string }> = [
    { key: 'No longer in household', value: 'Driver no longer in the household' },
    { key: 'Deceased', value: 'Driver is deceased' },
    { key: 'Active military', value: 'Driver is active military' },
    { key: 'Surrendered license and no longer drives', value: 'Driver surrendered license and no longer drives' },
    { key: 'In household but has own insurance', value: 'In household but has own insurance' },
    { key: 'Other', value: 'other' },
];

export const PolicyHolderRelationships: { self: string, spouse: string, dependent: string, other: string } = {
    self: 'Self',
    spouse: 'Spouse',
    dependent: 'Dependent',
    other: 'Other'
};

export const YesNoValues: { YES: string, NO: string} = {
    YES: 'Yes',
    NO: 'No'
};

export const YesNoOptions: Array<{ key: string, value: string }> = [
    { key: YesNoValues.YES, value: YesNoValues.YES },
    { key: YesNoValues.NO, value: YesNoValues.NO }
];

export const Genders: { male: string, female: string } = {
    male: 'Male',
    female: 'Female'
};

export const MarriageStatuses: { married: string, single: string, divorced: string, widowed: string } = {
    married: 'Married',
    single: 'Single',
    divorced: 'Divorced',
    widowed: 'Widowed'
};

export const ModesOfCommuteOptions: Array<{ key: string, value: string }> = [
    { key: 'Drive a personal car', value: 'personal-car' },
    { key: 'Drive a corporate car', value: 'corporate-car' },
    { key: 'Carpool', value: 'carpool' },
    { key: 'Vanpool', value: 'vanpool' },
    { key: 'Mass transit', value: 'mass-transit' },
    { key: 'Bike/walk', value: 'bike-walk' },
    { key: 'Work at home', value: 'work-at-home' }
];

export const TravelDistanceExcludedModesOfCommute: { personalCar: string, carpool: string } = {
    personalCar: 'personal-car',
    carpool: 'carpool'
};

export const DriverBusinessOptions: Array<{ key: string, value: string }> = [
    { key: 'No business use', value: 'no-business-use' },
    { key: 'For sales or professional calls', value: 'sales-or-professional-calls' },
    { key: 'For delivery of goods or merchandise', value: 'delivery-goods-merchandise' },
    { key: 'To carry you and tools to job site', value: 'carry-to-job-site' }
];

export const TypeParamSessionStorageKeys = {
    HAS_TYPE_PARAM: 'hasTypeParam',
    SOURCE_URL: 'sourceUrl'
};

export const MAX_VEHICLES = 4;

export const MileageEstimatorTableData = {
    mileageEstimatorHeaders: [{daily: 'Daily mileage', weekly: 'Weekly mileage', annual: 'Annual mileage'}],
    mileageEstimatorData: [
        {daily: '6', weekly: '42', annual: '3000'},
        {daily: '14', weekly: '98', annual: '6000'},
        {daily: '22', weekly: '154', annual: '9000'},
        {daily: '31', weekly: '217', annual: '12000'},
        {daily: '39', weekly: '273', annual: '15000'},
        {daily: '47', weekly: '329', annual: '18000'},
        {daily: '55', weekly: '385', annual: '21000'}
    ]
}

export const PolicyChangeHeapEvents = {
    ADD_VEHICLE_DEADEND: 'MMA - Add Vehicle|Deadend',
    REPLACE_VEHICLE_DEADEND: 'MMA - Replace Vehicle|Deadend'
}

export const DriverRelationships = {
    INSURED: 'I',
    SPOUSE: 'S',
    DOMESTIC_PARTNER: 'D'
};

export const VinSubmitText = {
    ADD: 'Yes, add vehicle',
    REPLACE: 'Yes, replace vehicle'
}
