import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import Footer from 'src/app/utilities/models/footer';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { Features } from 'src/app/utilities/models/features';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  imgLoc: string = environment.imgLoc;
  footer: Footer = null;
  features: Features = null;
  isCpraChoiceLaunched: boolean = false;


  constructor(private commonService: CommonService) { }

  async ngOnInit() {
    try {
      this.footer = await this.commonService.getCamFooter();
      await this.getFeatures();
    } catch (e) {
      console.log(e);
    }
  }

  openTealiumUrl(funcName: string) {    
    const functionToUse = new Function(funcName)
    functionToUse();   
  }

  async getFeatures(): Promise<void> {
    try {
      this.features = await this.commonService.getFeatures();

      if (this.features) {
        this.isCpraChoiceLaunched = this.features.cpraChoiceLaunched;
      }

    } catch (err) {
      // continue regardless of error
    }
  }

}
