<div class="feedback-component">
  <div class="feedback-container">
    <div class="heading">Did you find what you were looking for?</div>
    <div>We would like to hear your feedback.</div>
    <div class="reactions">
      <button class="reaction-group" (mouseenter)="goodBtnState=(goodBtnState!=='selected')?'hover':'selected'" (mouseleave)="goodBtnState=(goodBtnState!=='selected')?'unselected':'selected'" (click)="!feedbackSubmitted && toggleView('good')" tabindex="0">
        <img ngSrc="{{imgLoc}}/slack/feedback/Feedback_good_{{goodBtnState}}.svg" alt="Smile" width="28" height="28"/>
        <a class="response">Yes</a>
      </button>
      <button class="reaction-group" (mouseenter)="badBtnState=(badBtnState!=='selected')?'hover':'selected'" (mouseleave)="badBtnState=(badBtnState!=='selected')?'unselected':'selected'" (click)="!feedbackSubmitted && toggleView('bad')" tabindex="0">
        <img ngSrc="{{imgLoc}}/slack/feedback/Feedback_bad_{{badBtnState}}.svg" alt="Frown" width="28" height="28"/>
        <a class="response">No</a>
      </button>
    </div>
  </div>
  <div class="feedback-container">
    <div class="submit-container" *ngIf="readyToSubmit && !feedbackSubmitted">
      <form (ngSubmit)="sendFeedback(textarea.value)">
        <textarea ref-textarea placeholder="{{getPlaceHolderText()}}" [attr.aria-label]="getPlaceHolderText()" maxlength="256" tabindex="0"></textarea>
        <div class="sco-button-group">
          <button class="sco-button sco--small sco--outline" type="reset" (click)="clearForm()" tabindex="0">Cancel</button>
          <button class="sco-button sco--small sco--fill" type="submit" tabindex="0">Submit</button>
        </div>
      </form>
    </div>
    <div class="success-icon-container" *ngIf="feedbackSubmitted && thumbsUp">
      <app-icon name='successAlert' color='success' size='32'></app-icon>
      <span>Thanks! We appreciate your feedback.</span>
    </div>
    <div class="issue-icon-container" *ngIf="feedbackSubmitted && !thumbsUp">
      <span class="contact-message">Sorry about that. If you're looking for direct assistance, please contact your agent.</span>
      <div *ngIf="isAuthenticated">
        <app-agent-card [hideAgentTitle]="true"></app-agent-card>
      </div>
    </div>
  </div>
</div>
