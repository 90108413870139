import { Component, OnInit, HostListener, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { environment as env } from 'src/environments/environment';

import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { Router } from '@angular/router';
import Endpoints from 'src/app/utilities/constants/endpoints';

@Component({
  selector: 'app-mobile-app-download',
  templateUrl: './mobile-app-download.component.html',
  styleUrls: ['./mobile-app-download.component.scss']
})
export class MobileAppDownloadComponent implements OnInit {

  @Input() sourceComponent: string = '';
  @Input() isMobileView: boolean = false;

  imgLoc: string = env.imgLoc;
  imgQrCode: string = "";
  isIOS: boolean = false;
  isAndroid: boolean = false;
  isMobileApp: boolean = false;

  constructor(
    private commonService: CommonService,
    private deviceService: DeviceDetectorService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.isMobileApp = this.commonService.isMobileApp();
    this.setDevice();
    this.setQrCodeImg();
  }

  setDevice(): void {
    const ANDROID = 'Android';
    const IOS = 'iOS';
    const deviceInfo = this.deviceService.getDeviceInfo().os;

    switch (deviceInfo) {
      case ANDROID:
        this.isAndroid = true;
        break;
      case IOS:
        this.isIOS = true;
        break;
      default:
        this.isAndroid = false;
        this.isIOS = false;
        break;
    }
  }

  setQrCodeImg(): void {
    const url = this.router.routerState.snapshot.url;
    const docHubQrImg = this.imgLoc.concat("/mobile/QR Code_MMA_Docs_Hub_Page_Deeplink.png");
    const paymentConfirmQrImg= this.imgLoc.concat("/mobile/QR Code_MMA_Payment_Confirmation_Page_Deeplink.png");

    switch(url) {
      case Endpoints.url.paymentConfirmation:
        this.imgQrCode = paymentConfirmQrImg;
        break;
      default:
        this.imgQrCode = docHubQrImg;
        break;
    }
  }
}
