import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'customTitleCase'
})

export class CustomTitleCasePipe implements PipeTransform {

    transform(title: string): string {
        return this.formatName(title);
    }

    // Capitalizes the first letter of each word
    // Add words to noCapitalizeList array to not capitalize
    // Capitalizes the first and last word
    formatName(title: string): string {
        const wordArray = title?.toLowerCase().split(' ');
        const noCapitalizeList = ['a', 'an', 'and', 'as', 'at', 'but', 'by', 'for', 'from', 'if', 'in', 'of', 'off', 'on', 'or', 'so', 'the', 'to', 'with'];
        const formattedName = [];

        for (let i = 0; i < wordArray.length; i++) {
            for (const noCapWord of noCapitalizeList) {
                // Checks for Mc
                if (wordArray[i].startsWith('mc')) {
                    formattedName[i] = 'Mc' + wordArray[i].charAt(2).toUpperCase() + wordArray[i].slice(3);
                    break;
                }
                // Checks for O'
                if (wordArray[i].startsWith('o\'')) {
                    formattedName[i] = 'O\'' + wordArray[i].charAt(2).toUpperCase() + wordArray[i].slice(3);
                    break;
                }

                if (i === 0 || i === wordArray.length - 1) {
                    formattedName[i] = wordArray[i].charAt(0).toUpperCase() + wordArray[i].slice(1);
                    break;
                }
                if (wordArray[i] === noCapWord) {
                    formattedName[i] = noCapWord;
                    break;
                } else {
                    formattedName[i] = wordArray[i].charAt(0).toUpperCase() + wordArray[i].slice(1);
                }
            }
        }
        return formattedName.join(' ');
    }
}




