import { Component, OnInit, Input } from '@angular/core';
import Agency from 'src/app/utilities/models/agency';

import { Cookies } from 'src/app/utilities/services/cookies-service/cookies.service';
import { PolicyService } from 'src/app/utilities/services/policy-service/policy.service';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { Features } from 'src/app/utilities/models/features';
import { SMSService } from 'src/app/utilities/services/sms-service/sms.service';
import { PhoneNumbers } from 'src/app/utilities/constants/phoneNumbers';

@Component({
  selector: 'app-agent-card',
  templateUrl: './agent-card.component.html',
  styleUrls: ['./agent-card.component.scss']
})
export class AgentCardComponent implements OnInit {

  @Input() hideAgentTitle: boolean = false;
  @Input() useYourAgentTitle: boolean = false;
  dataLoaded: boolean = false;
  displaySMS: boolean;
  features: Features;
  goldAgencyPhoneNumber = PhoneNumbers.GOLD_AGENCY_PHONE_NUMBER;
  goldAgencySMSLink: string = '';
  goldAgencySMSNumber: string = '';
  goldPlusAgencyPhoneNumber = PhoneNumbers.GOLD_PLUS_AGENCY_PHONE_NUMBER;
  isGoldAgency: boolean = false;
  isGoldPlusAgency: boolean = false;
  isMobileDevice: boolean = false;
  primaryAgency: Agency = null;

  constructor(
    private commonService: CommonService,
    private policyService: PolicyService,
    private smsService: SMSService,
    private cookies: Cookies,
  ) { }

  ngOnInit() {
    this.setSMSDisplay();
    this.getAgencyData();
  }

  async setSMSDisplay() {
    try {
      this.features = await this.commonService.getFeatures();
      if (this.features) {
        this.goldAgencySMSLink = this.features.smsUrl;
        this.goldAgencySMSNumber = this.features.smsPhoneNumber;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.smsService.displaySMSObs.subscribe(
        displaySMS => { this.displaySMS = displaySMS; }
      );
      this.isMobileDevice = this.commonService.isMobileDevice();
    }
  }

  async getAgencyData() {
    try {
      this.primaryAgency = await this.policyService.getPrimaryAgency();
    } catch (error) {
      console.log(error);
    } finally {
      this.dataLoaded = true;
      if (this.primaryAgency) {
        this.isGoldAgency = await this.policyService.isGoldAgency() === 'Y';
        this.isGoldPlusAgency = await this.policyService.isGoldPlusAgency() === 'Y';
        this.setAgentCookie();
      }
    }
  }

  setAgentCookie() {
    this.cookies.createMMAAgentInfoCookie(this.primaryAgency);
  }
}
