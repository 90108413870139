export default class EmailResponse {
     dateEmailSent: string;
     isSuccessful: boolean;
     requestGuid: string; 
}

export class EmailRequest {
     agentEmail: string;
     changeType: string;
     customerEmail: string;
     customerName: string;
     customerPhone: string;
     emailDestination: string;
     emailType: string;
     firstName: string;
     insuranceAgency: string;
     insuranceTelephone: string;
     isGoldOrGoldPlusAgency: boolean;
     lineOfBusiness: string;
     policyNumber: string;
     toEmailAddress: string;
 }

 export class EmailTemplate {
  templateId: string
  requestType: string;
  emailContactInfo : EmailContactInfo;
  emailFormData: EmailFormData[]; // salesforce key/value pair data
  emailTemplateData: EmailTemplateData; //ditto object data => converts to mjmlData string
  attachments: EmailAttachment[];
 
  constructor(email: string) {
    this.templateId = '';
    this.requestType = '';
    this.emailContactInfo = new EmailContactInfo(email);
    this.emailFormData = [];
    this.emailTemplateData = new EmailTemplateData();
    this.attachments = [];
  }
 }

 export class EmailAttachment {
   name: string;
   mimetype: string;
   file: string;

   constructor(name: string, mimetype: string, file: string) {
     this.name = name;
     this.mimetype = mimetype;
     this.file = file;
     }
 }

 class EmailContactInfo {
     to: string;

     constructor(email: string) {
          this.to = email;
     }
 }

 export class EmailTemplateData {
  agencyEmail: string;
  agencyName: string;
  agencyPhone: string;
  changeType: string;
  customerEmail: string;
  customerPhone: string;
  emailType: string;
  firstName: string;
  isGoldAgency: boolean;
  lineOfBusiness: string;
  name: string;
  policyNumber: string;
  toEmailAddress: string;

  constructor() {
     this.agencyEmail = '',
     this.agencyName = '',
     this.agencyPhone = '',
     this.changeType = '',
     this.customerEmail = '',
     this.name = '',
     this.customerPhone = '',
     this.emailType = '',
     this.firstName = '',
     this.isGoldAgency = false,
     this.lineOfBusiness = '',
     this.policyNumber = '',
     this.toEmailAddress = ''
   }
 }

 export class EmailFormData {
     name: string;
     value: string

     constructor(name: string, value: string) {
          this.name = name;
          this.value = value;
     }
 }