const coverageA = ['Your home protects you and your family. Now, you can return the favor. If your house is damaged by fire, theft, frozen pipes, and more, we’ll assist in your return to normalcy.',
    'This coverage relates specifically to anything built on your home’s structure and any structures connected to it - such as an attached garage, central A/C units, or roof.',
    'This coverage doesn’t extend to normal wear and tear, mold, insects, or some other specific types of damage. This coverage also does not cover damage from a flood. Please review your policy documents to understand exactly how your home is covered.'    
];

export const coverageA_Limited = 'Your home protects you and your family. Now, you can return the favor. If your house is damaged by theft, frozen pipes, and more, we’ll assist in your return to normalcy.'   
       
const coverageE = ['You can’t predict accidents, but you can always be prepared. If someone is injured in an accident in your home, we’ll defend any claims filed against you, and pay them up to the policy limits you set. We’ll even pay any necessary medical expenses up to the limit you specify.',
    'For additional information on the bodily injury and property damage limits that are included in your liability coverage, please refer to your policy documents.'
];

export const coverageKeys = {
    RPLVEH: 'RPLVEH'    
}

export const coverageMessages = {
    disclaimer: 'Please Note: These coverage definitions are intended only as guidelines. Review your policy for full terms and coverages.',
    autoIntro: 'These coverage descriptions are intended to be guidelines. For complete and accurate definitions of your coverages, please refer to your policy documents.',
    homeIntro: 'Coverages, deductibles and premiums displayed apply to the primary residence. All coverages may not be shown here. These coverage definitions are intended only as guidelines. Review your policy for full terms and coverages.',
    viewDocIntro: 'View your policy forms documents to see your coverage limits, deductibles, and premiums'
};

export const autoCoverageDisplay = {
    'ACCFOR': {
        name: 'Accident Forgiveness',
        description: ['Every Safeco Auto Insurance policy includes accident forgiveness after a set number of years of accident and violation-free driving.'],
        icon: 'accidentForgiveness',
        group: 1
    },
    'AVSNAP2': {
        name: 'Custom Parts Protection',
        description: ['This protects audio, visual or custom equipment that was not installed by the factory or dealership. Audio-visual systems must be permanently installed, like a stereo or DVD player. Custom equipment includes parts that change the appearance of your vehicle, like roll bars, lift kits, and custom wheels.'],
        icon: 'coverages',
        group: 1
    },
    'BI': {
        name: 'Other Driver\'s Well Being',
        description: ['Helps pay for bodily injury expenses, like hospital bills and medical care, that you may be held responsible to pay if you cause an accident that injures another driver or another vehicle\'s passengers.'],
        message: 'Bodily Injury (BI) coverage pays up to the specified limit for personal injuries caused in an auto accident. Limits represent thousands of dollars, and are expressed in a BI/BI format. For example, limits of 100/300 would pay up to $100,000 of Bodily Injury per person, up to $300,000 of total Bodily Injury per accident.',
        icon: 'bodilyInjury',
        group: 2
    },
    'COLL': {
        name: 'Damage from a Collision Incident',
        description: ['Collision coverage pays for damage to your vehicle when it collides with another vehicle or object (e.g., a tree, a guardrail).',
            'For example, if you hit another car, moving or parked, or you hit a tree that has fallen into the road, collision coverage will kick in to pay the cost of repairing or replacing your vehicle, after you pay your deductible.',
            'If you have comprehensive and collision coverage, your protection extends to when you are driving a rental car as well. Please review your policy documents for a complete understand of your coverage when you are driving a car other than your own.',
        ],
        message: 'Collision (COLL): In the event your vehicle is in a collision, your deductible is the amount you have agreed to pay out of pocket before insurance kicks in to pay the remaining cost of repairing or replacing your vehicle.',
        icon: 'collision',
        group: 1
    },
    'COMP': {
        name: 'Damage from a Non-Collision Incident',
        description: ['Comprehensive coverage pays for damages to your vehicle due to fire, hail, theft, vandalism, or impact with an animal.',
            'After certain events, comprehensive coverage kicks in to pay the cost, minus your deductible, of repairing or replacing your vehicle. The complete list of damage types that are covered by comprehensive are listed in your policy documents.',
            'If you have comprehensive and collision coverage, your protection extends to when you are driving a rental car as well. Please review your policy documents for a complete understand of your coverage when you are driving a car other than your own.'
        ],
        message: 'Comprehensive (COMP): In the event your vehicle is damaged by anything other than another vehicle, your deductible is the amount you have agreed to pay out of pocket before insurance kicks in to pay the remaining cost of repairing or replacing your vehicle.',
        icon: 'autoDamage',
        group: 1
    },
    'CSL': {
        name: 'Bodily Injury and Property',
        description: ['Combines bodily injury expenses and property damage under one total amount. If you are responsible for an accident, this helps pay for medical care and costs associated with repairing or replacing another person’s property.'],
        icon: 'bodilyInjury,propertyDamageLiability',
        group: 2
    },
    'DIMDED': {
        name: 'Diminishing Deductible',
        description: ['Each year you remain claim-free, we’ll reduce your collision deductible by $100 during the time of policy renewal (up to $500 or five years).'],
        icon: 'deductibleFund',
        group: 1
    },
    'EKLREP': {
        name: 'Electronic Key and Lock Replacement',
        description: ['If your electronic keys are lost or stolen, we’ll replace them – along with any remote control pads and locks – at no extra charge and with no deductible applied.'],
        icon: 'logIn',
        group: 1
    },
    'EAP': {
        name: 'Emergency Assistance Package',
        description: ['All the benefits of Roadside Assistance and $100 for expenses and up to $500 for meals, lodging, transportation if you’re involved in an accident more than 25 miles from home. Includes coverage for personal belongings damaged in an accident or stolen from your vehicle.'],
        icon: 'roadsideAssistancePlus',
        group: 1
    },
    'FAREIM': {
        name: 'First Aid Treatment',
        description: ['All Safeco auto policies include coverage for reimbursement of first aid administered to others after an accident.'],
        icon: 'medical',
        group: 2
    },
    'FLGLS': {
        name: 'Windshield Repair',
        description: [
            'Part of Comprehensive coverage, Vehicle Windshield Repair coverage pays to repair any damaged glass on your vehicle’s windshield, if necessary.',
            'For example, if you get a chip or crack in your windshield, this coverage will cover the cost to repair your windshield at no expense to you.'
        ],
        icon: 'glassDamage',
        group: 1
    },
    'LOANCV': {
        name: 'Loan and Lease Coverage',
        description: [
            'If your vehicle is totaled, and the vehicle’s value is lower than the amount remaining on your loan, Gap Coverage will cover the remaining amount you owe. Sometimes, this price difference can be steep, and you’ll want to avoid paying out of pocket.',
            'For example, let’s say you bought a vehicle for $20,000, and you have $15,000 in payments remaining. If the vehicle is totaled, the vehicle is now only worth $10,000. Gap Coverage would make sure you’re covered for that $5,000 difference between remaining payments and the vehicle’s actual value.',
            'This optional coverage must be purchased at the same time as your vehicle, and you must be the vehicle’s first owner.'
        ],
        icon: 'documentQuote',
        group: 1
    },
    'MED': {
        name: 'Medical Payments',
        description: [
            'After an accident, the last thing that should be on your mind is how you’ll pay for your injuries. This coverage pays for your initial medical expenses related to the accident, regardless of who is at fault. Passengers in your vehicle are also covered.',
            'Medical Payments covers everything from ambulance transportation to the hospital to emergency room care, as well as the initial hospital stay and other reasonable medical expenses.'
        ],
        icon: 'medicalExpenses',
        group: 2
    },
    'OPTBI': {
        name: 'Bodily Injury to Others',
        description: ['This type of coverage is specific to your rating state. Check your policy declarations for full coverage details.'
        ],
        icon: 'bodilyInjury',
        group: 2
    },
    'PD': {
        name: 'Other Vehicle\'s Damage',
        description: ['When you are found legally responsible for a vehicle accident, this coverage pays for costs associated with repairing or replacing another person\'s property.'],
        message: 'Property Damage (PD) coverage pays up to the specified limit for damage your vehicle causes to the property of others (e.g., a fence, a telephone pole, or another vehicle).',
        icon: 'propertyDamageLiability',
        group: 2
    },
    'PIP': {
        name: 'Personal Injury',
        description: [
            'Personal Injury Protection (PIP) pays for your medical bills as a result of a vehicle accident, up to the limits you choose.',
            'Regardless of who caused the accident, PIP kicks in to cover medical costs. PIP may also cover your lost wages if you are unable to work.',
            'PIP coverage changes from state-to-state, so your coverage is unique. For a full breakdown of what is included in your coverage please review your declarations page in the Full Policy Copy PDF.'
        ],
        icon: 'workersComp',
        group: 1
    },
    'PET': {
        name: 'Pet Coverage',
        description: ['We’ll help pay for veterinary expenses or death benefit if your cat or dog is injured or dies in a collision.'],
        icon: 'barkingDog',
        group: 1
    },
    'RPLVEH': {
        name: 'New Car Replacement',
        description: ['If you’re the original owner of your vehicle that is less than a year old and has been totaled or stolen, we will pay you the value of a comparable new vehicle.'],
        icon: 'newCarReplacement',
        group: 1
    },
    'RREIM': {
        name: 'Rental Car Reimbursement',
        description: [
            'If you file a comprehensive or collision claim, you are covered for Rental Car Reimbursement with this optional coverage. We will provide a rental vehicle if your vehicle needs repairs after an accident.',
            'Your rental is covered until your vehicle is repaired at one of our Guaranteed Repair Network facilities, or a shop of your choice. Daily limits apply, so you may not get the same type of vehicle you normally drive.'
        ],
        icon: 'rentalCarReimbursement',
        group: 1
    },
    'RDASST': {
        name: 'Roadside Assistance',
        description: ['If your vehicle breaks down, we won’t leave you stranded. Roadside assistance will tow your vehicle to the nearest qualified repair shop, change a flat tire, deliver gas and other essential fluids. We’ll also jumpstart your battery or send a locksmith if you’re locked out of your vehicle.'],
        icon: 'roadsideAssistance',
        group: 1
    },
    'UM': {
        name: 'Uninsured Motorist Coverage',
        description: [
            'Getting in an accident is no fun, and it can be worse if the other driver doesn’t have insurance. If you have uninsured motorist coverage (UM), you’re protected in an accident if the at-fault driver is uninsured.',
            'UM coverage changes from state-to-state, so your coverage is unique. For a full breakdown of what is included in your coverage see your Declarations Page.'
        ],
        icon: 'uninsured',
        group: 2
    },
    'UIM': {
        name: 'Underinsured Motorist Coverage',
        description: [
            'If the other driver does have insurance, but is underinsured and unable to cover the full cost of the damage, underinsured motorist coverage (UIM) will make sure the remaining balance is paid for, up to the limits you choose.',
            'UIM coverage changes from state-to-state, so your coverage is unique. For a full breakdown of what is included in your coverage see your Declarations Page.'
        ],
        icon: 'uninsured',
        group: 2
    }
};

export const homeCoverageDisplay = {
    'DEDUCT': {
        name: 'Deductible',
        description: ['Your deductible is the amount you\'re responsible for paying before your coverage kicks in.'],
        icon: 'deductible',
        group: 1
    },
    'DWELL': {
        name: 'Damage to Primary Home',
        description: coverageA,
        icon: 'homePhysicalDamage',
        group: 1
    },
    'FIREA': {
        name: 'Building Coverage',
        description: ['Your home protects you and your family. Now, you can return the favor. If your house is damaged by fire, theft, and more, we’ll assist in your return to normalcy. This coverage relates specifically to anything built on your home’s structure and any structures connected to it - such as an attached garage, central A/C units, or roof. This coverage doesn’t extend to normal wear and tear, mold, insects, or some other specific types of damage. This coverage also does not cover damage from a flood. Please review your policy documents to understand exactly how your home is covered.'],
        icon: 'homePhysicalDamage',
        group: 1
    },
    'BEQK': {
        name: 'Damage to Primary Home',
        description: coverageA,
        icon: 'homePhysicalDamage',
        group: 1
    },
    'OS': {
        name: 'Damage to Other Structures',
        description: ['Any structures on your property not attached to the home - such as a detached garage, shed, or fence - are automatically covered for 10% of your overall policy limit. For example, if you have a total policy limit of $100,000, other structures on your property would be covered up to $10,000. You are able to increase the limit of this coverage at any time.',
            'Please note, this does not cover those structures if they are used for business or as a rental property.',
            'Additionally, this coverage will not apply to normal wear and tear, mold, insects, or some other forms of damage. Please refer to your policy documents for an exact description of what’s covered.'
        ],
        icon: 'otherStructure',
        group: 1
    },
    'OSF': {
        name: 'Damage to Other Structures',
        description: ['Provides coverage for fences, patios, driveways, walkways and other structures not attached to the dwelling at your rental property location.'],
        icon: 'otherStructure',
        group: 1
    },
    'PP': {
        name: 'Damage to Personal Property',
        description: ['Home is where the heart is. It’s also where all your stuff is. If any of your personal possessions, like furniture, clothing, electronics, or appliances are damaged or stolen, we can often assist in your efforts to regain what’s been lost.',
            'We know that your possessions don’t always stay in one place, which is why your belongings are protected outside of your home. For example, items stolen from your car or boat would be protected under this coverage in most states. Please refer to your policy documents for an exact description of what is covered.',
            'It’s important to know that coverage limits may apply to certain valuable items, like jewelry, fine art, collectibles, cash, etc. If you have specific items in your home that you believe would require higher coverage limits, <a href="https://www.safeco.com/products/homeowners-insurance/coverage/valuable-articles" target="_blank">click here to learn more</a>.'
        ],
        icon: 'personalProperty',
        group: 1
    },
    'PPEQK': {
        name: 'Damage to Personal Property',
        description: ['Provides coverage for personal property owned or used by any insured.'],
        icon: 'personalProperty',
        group: 1
    },
    'FIREC': {
        name: 'Personal Item Coverage',
        description: ['This provides coverage for your personal belongings inside your property from damage and loss and will help you replace the items up to the defined dollar limit in your policy. This does not protect tenants’ belongings.'],
        icon: 'personalProperty',
        group: 1
    },
    'LOU': {
        name: 'Loss of Use Expenses',
        description: [
            'A helping hand when your home is uninhabitable due to a covered loss.',
            'If your living expenses increase as a result of having to stay somewhere else while your home is being repaired, this coverage will assist with maintaining a normal standard of living for a set period of time, according to your policy.',
            `Review your policy documents to better understand how you're covered.`
        ],
        icon: 'lossOfUse',
        group: 1
    },
    'LOUF': {
        name: 'Loss of Rental Income',
        description: ['If there’s a serious claim that makes it so your tenant cannot live in the building, this covers lost income from rent.'],
        icon: 'lossOfUse',
        group: 1
    },
    'PL': {
        name: 'Protection for Injury and Damage',
        description: coverageE,
        icon: 'bodilyInjury',
        group: 1
    },
    'LIAB': {
        name: 'Liability Coverage',
        description: ['You can’t predict accidents, but you can always be prepared. If someone is injured in an accident in your home, we’ll defend any claims filed against you, and pay them up to the policy limits you set. We’ll even pay any necessary medical expenses up to the limit you specify.',
            'For additional information on the bodily injury and property damage limits that are included in your liability coverage, please refer to your policy documents.'
        ],
        icon: 'bodilyInjury',
        group: 1
    },
    'MEDPM': {
        name: 'Well Being of Others',
        description: ['Provides coverage for the payment of necessary medical expenses of non-insureds incurred as a result of a covered accident causing bodily injury.'],
        icon: 'medical',
        group: 1
    }
};

export const scheduledPropertyCoverageDisplay = {
    name: 'Valuable Property with Replacement Cost',
    description: ['Here is the list of valuable items like jewelry, fine art, firearms and electronics that have additional protection with your policy. There is no deductible and the item is insured for its agreed upon value. Please review your policy declarations forms for reimbursement details when replacing, repairing, and dealing with pairs or sets of items.'],
    icon: 'valuables',
    group: 1
};
