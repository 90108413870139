<div class="renewal-component-swoosh"
  *ngIf="infoCardDisplayed !== 'renewalError' && infoCardDisplayed !== 'renewalDocLoading'">
  <div class="curve"></div>
  <div class="header">
    <span class="sco-heading sco--level3 sco--regular">Keeping you <strong>covered</strong></span>
  </div>
  <div *ngIf="!multiplePolicies">
    <span class="content">
      Your {{policyLOB}} policy automatically renews
      <strong *ngIf="renewalStartDate">{{renewalStartDate}}</strong>
      <span *ngIf="!renewalStartDate">soon</span>.
    </span>
    <button id="singleRenewal" class="sco-button sco--outline" data-heap-id="LP_INFOCARD_BTN_VIEWDOCUMENTS"
      (click)="getRenewalPolicyDocuments()">
      View documents
    </button>
  </div>
  <div *ngIf="multiplePolicies">
    <span class="content">You have policies that are about to renew.</span>
    <button id="multiRenewal" class="sco-button sco--outline" data-heap-id="LP_INFOCARD_BTN_GOTODOCUMENTS"
      routerLink="{{url.documents}}">
      Go to documents
    </button>
  </div>
</div>

<div class="error-card" *ngIf="infoCardDisplayed === 'renewalError'">
  <div class="curve"></div>
  <div class='error-header-container'>
    <app-icon name="errorAlert" size="32" color='error'></app-icon>
    <span class="error-header">Oops!</span>
  </div>
  <div>
    We can't get your renewal documents right now.
  </div>
  <div>
    Please check back later.
  </div>
</div>

<div class="renewal-loading-card" *ngIf="infoCardDisplayed === 'renewalDocLoading'">
  <div class="curve"></div>
  <div class="spinner-card">
    <app-spinner [spinnerLabel]="'Getting your documents'"></app-spinner>
  </div>
</div>