<div class="app-spinner" aria-busy="true" aria-live="polite" [attr.aria-label]="ariaLabel">
  <div class="spinner-blades">
      <div class="spinner-blade"></div>
      <div class="spinner-blade"></div>
      <div class="spinner-blade"></div>
      <div class="spinner-blade"></div>
      <div class="spinner-blade"></div>
      <div class="spinner-blade"></div>
      <div class="spinner-blade"></div>
      <div class="spinner-blade"></div>
  </div>
  <div class="spinner-label">{{spinnerLabel}}</div>
</div>
