import { gql } from 'apollo-angular';
import { DocumentsMetadataResponse, PolicyVariables } from 'src/app/utilities/models/documentMetadata';

export const GET_POLICY_DOCUMENTS = gql<DocumentsMetadataResponse, PolicyVariables> `query
  GetPolicyDocuments($policyNumbers: [String!]) {
    getDocumentMetadata (
      policies: $policyNumbers,
      clientType: SERVICE_CUSTOMER
      brand: SAFECO
      docCategory: Policy
    ){
    documentGroup {
      policyNumber
      hasPaperlessDocs
      primaryDocuments {
      billingAccountNumber
      lineOfBusiness
      policyEffectiveDate
      policyExpirationDate
      transactionEffectiveDate
      policyNumber
      creationDate
      docTypeCode
      jurisdiction
      effectiveDate
      displayName
      docTypeName
      objectId
      documentUID
      newDocument
      currentDocument
      contentUrl
      documentType
      receivedPassDate
      documentTypeDescription
      }
    }
  }
}`;