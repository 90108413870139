import { Directive, ElementRef, AfterViewInit } from '@angular/core';
import { AnalyticsService } from 'src/app/utilities/services/analytics-service/analytics.service';
import {environment as env} from 'src/environments/environment';

@Directive({
  selector: 'button , a' 
})
export class HeapIdDirective implements AfterViewInit {
  nativeElement: HTMLElement;

  constructor(
    { nativeElement }: ElementRef<HTMLElement>,
    private analyticsService: AnalyticsService
  ) { 
    this.nativeElement = nativeElement;
  }

  ngAfterViewInit() {
    const elementId = this.nativeElement.getAttribute('id') || '';
    let elementHeapId = this.nativeElement.getAttribute('data-heap-id') || '';

    if (elementHeapId) {
      if (elementHeapId !== 'custom') { // this is set for examples like the hompage get ids which already calls trackHeap dynamically 
        this.trackHeapListener(this.nativeElement, elementHeapId);
      }
    } else {
      elementHeapId = this.generateHeapId(this.nativeElement, elementId);
      this.trackHeapListener(this.nativeElement, elementHeapId);
    }
  }

  private generateHeapId(el: HTMLElement, id: string): string {
    let generatedId = '';
    const elementType = (el.nodeName === 'A') ? 'link' : 'btn';

    if (id) {
      generatedId = 'MMA_' + elementType + '_' + id; // if we want to add page location, we can do that too, but we need to make sure all app.html items are covered otherwise they'll get set to whatever page initially loaded
    } else {
      // Enforce either id or heap-id on all links and buttons 
      if (env.local) {
        // throw Error('No id or data-heap-id on element: ' + el);
      }
    }

    return generatedId;
  }

  // This method will prevent Heap from gathering click events, instead we will initiate the sending of ids
  // this will auto generate reports in heap with out the need for manual definitions
  private trackHeapListener(el: HTMLElement, heapId: string) {
    el.addEventListener('click', () => {
      this.analyticsService.trackAnalytics(heapId);
    });
    el.removeAttribute('data-heap-id'); // commening out so we can see in code and an alternate way to group click events
  }

}
