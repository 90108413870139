<label id="{{id}}-label" class="sco-label sco-label-password password-input" for="{{id}}"
  [ngClass]="{'is-focused':focus, 'is-populated':populated, 'is-error':error, 'is-disabled': isDisabled !== null, 'is-helpText': blnhelp || blnHelpMulti}">
  <span class="sco-label-visual" aria-hidden="true">{{label}}</span>
  <span class="sco-label-a11y">{{label}}</span>
  <input type="{{type}}" id="{{id}}" name="{{name}}" [attr.aria-label]="name" (input)="onChange($event.target.value)" (blur)="onBlur()"
    class="sco-input sco-input-password" (focus)="focus=true;onFocus();" (focusout)="focus=false" [attr.maxlength]="maxLength" [value]="value"
    [attr.disabled]="isDisabled" [attr.aria-required]="fieldValidation && fieldValidation.required" aria-describedby="multilineHelp" />
  <a (focus)="onFocus();" (blur)="onBlur()" (click)="showHide()" (keypress)="onEnterPress($event)" class="sco-input-visToggle"
    *ngIf="showVisibilityToggle" tabindex="0">{{toggleText}}</a>
  <div class="sco-messageGroup" *ngIf="error">
    <div id="text-error">
      <span class="sco-message sco-message--error">{{validationMsg}}</span>
    </div>
  </div>
  <div class="sco-messageGroup" *ngIf="blnhelp && !blnHelpMulti">
    <div id="text-help">
      <span class="sco-message">{{helpText}}</span>
    </div>
  </div>
  <div id="multilineHelp" class="multilineHelp" *ngIf="blnHelpMulti && !blnhelp">
    <ul class="sco-list sco-list--unordered">
      <li *ngFor="let text of helpTextMulti" class="sco-list-item">{{text}}</li>
    </ul>
  </div>
</label>