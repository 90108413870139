<div class="app-download-ad" [ngClass]="{ 'mobile-view': isMobileView }" *ngIf="!isMobileApp">
    <div class="content-container">
        <h3 class="sco--customer sco-heading sco--level4 sco--light">Download our app - the easiest way to manage your policy</h3>
        <div class="safeco-mobile-logo" *ngIf="isAndroid || isIOS">
            <img *ngIf="isAndroid" src="{{imgLoc}}/mobile/Safeco-GooglePlay-icon.svg" alt="Get it on Google play" />
            <img *ngIf="isIOS" src="{{imgLoc}}/mobile/Safeco-iOS-icon.svg" alt="Get it on Apple store" />
        </div>
        
        <div class="content">
            <h4 class="sco--customer sco-heading sco--level6 sco--bold sco--noMarginTop">Access policy documents</h4>
            <p>Easily view your documents and proof of insurance.</p>
        </div>
        <div class="content">
            <h4 class="sco--customer sco-heading sco--level6 sco--bold sco--noMarginTop">Pay and manage bills</h4>
            <p>Quickly pay bills, enroll in autopay, and update billing preferences.</p>
        </div>
        <div class="content">
            <h4 class="sco--customer sco-heading sco--level6 sco--bold sco--noMarginTop">File and track claims</h4>
            <p>File a claim in minutes and track its progress.</p>
        </div>
    </div>

    <div class="actions-container">
        <div class="desktop-container" *ngIf="!isAndroid && !isIOS" >
            <img ngSrc="{{imgQrCode}}" class="mobile-width" alt="Get it on Apple store"  width="144" height="144"/>
            <div class="qr-explanation">
                Open the camera on your phone, hold it up to the QR code, and tap the link to download.
            </div>
        </div>
        <div class="mobile-container" *ngIf="isAndroid || isIOS">
            <a href="https://play.google.com/store/apps/details?id=com.safecoinsurance.consumer&referrer=utm_source%3Dall%26utm_campaign%3DDMQRCodeTest_CM%26anid%3Dadmob"
                class="sco-link" role="link" name="Get it on Google play" *ngIf="isAndroid"
                [id]="sourceComponent + '_toGooglePlay'">
                <img ngSrc="{{imgLoc}}/mobile/google_play_badge.png" class="mobile-width" alt="Get it on Google play" width="144" height="43"/>
            </a>
            <a href="https://apps.apple.com/app/apple-store/id1191491672?mt=8/" *ngIf="isIOS"
                class="sco-link" role="link" name="Download on the Apple store" [id]="sourceComponent + '_toAppStore'">
                <img ngSrc="{{imgLoc}}/mobile/app_store_button.svg" class="mobile-width" alt="Download on the Apple store" width="144" height="50"/>
            </a>
        </div>
    </div>
</div>
