import Endpoints from 'src/app/utilities/constants/endpoints';

import { Search } from 'src/app/utilities/models/search';

const commonVehicleKeywords: string[] = [
    'autos',
    'bikes',
    'boats',
    'cars',
    'carts',
    'jet',
    'motorbikes',
    'motorcycles',
    'motors',
    'off',
    'recreation',
    'road',
    'rvs',
    'scooters',
    'skis',
    'vehicles',
    'watercrafts'
];

const commonClaimsKeywords: string[] = [
    'accidents',
    'claims',
    'closed',
    'damages',
    'disasters',
    'documentation',
    'eligible',
    'estimates',
    'faults',
    'glass',
    'guides',
    'information',
    'natural',
    'numbers',
    'opens',
    'past',
    'payments',
    'photos',
    'rates',
    'real',
    'recoveries',
    'recovery',
    'reimbursements',
    'rentals',
    'repairs',
    'reports',
    'review',
    'reviews',
    'shares',
    'shops',
    'time',
    'windshields'
];

export const SearchDefinitions: Search[] = [
    {
        id: 0,
        title: 'Proof of Insurance',
        pageUrl: Endpoints.url.documents,
        keywords: [
            'proofs',
            'insurances',
            'certificates',
            'proving',
            'documents',
            'policy',
            'policies'
        ],
        needsValidPolicy: true,
        needsValidBilling: false,
        countOfHits: 1
    }, {
        id: 1,
        title: 'Remove vehicles',
        pageUrl: Endpoints.url.policyEdit,
        keywords: [
            'remove',
            'cancel',
            'delete',
            'eliminate',
            'withdraw',
            ...commonVehicleKeywords
        ],
        needsValidPolicy: true,
        needsValidBilling: false,
        countOfHits: 1
    }, {
        id: 2,
        title: 'Remove drivers',
        pageUrl: Endpoints.url.policyEdit,
        keywords: [
            'remove',
            'drivers',
            'cancel',
            'delete',
            'withdraw',
            'eliminate',
            'ex',
            'husbands',
            'wifes',
            'kids',
            'daughters',
            'sons',
            'boyfriends',
            'girlfriends',
            'persons',
            'someones',
            'spouses'
        ],
        needsValidPolicy: true,
        needsValidBilling: false,
        countOfHits: 1
    },
    {
        id: 3,
        title: 'ID cards',
        pageUrl: Endpoints.url.documents,
        keywords: [
            'cards',
            'identification',
            'ids',
            'insurance',
            'proof',
            ...commonVehicleKeywords
        ],
        needsValidPolicy: true,
        needsValidBilling: false,
        countOfHits: 1
    },
    {
        id: 4,
        title: 'File a claim',
        pageUrl: Endpoints.url.fileClaim,
        keywords: [
            'files',
            ...commonClaimsKeywords
        ],
        needsValidPolicy: true,
        needsValidBilling: false,
        countOfHits: 1 
    },
    {
        id: 5,
        title: 'View my claims',
        pageUrl: Endpoints.url.viewClaims,
        keywords: [
            'sees',
            'statuses',
            'views',
            ...commonClaimsKeywords
        ],
        needsValidPolicy: true,
        needsValidBilling: false,
        countOfHits: 1
    }
];
