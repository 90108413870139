<!-- <div class="lmig-Toggle toggle-container" [ngClass]="{'no-margin': hideMargins}">
  <input type="checkbox" id="lmig-Toggle-{{ id }}" [name]="name" [attr.aria-label]="name"
    [value]="isToggledOn ? 'Toggled on' : 'Toggled off'" class="lmig-Toggle-input" aria-disabled="false"
    aria-hidden="true" attr.aria-labelledby="lmig-Toggle-{{ id }}-label" [checked]="checkedValue" tabindex="-1" />
  <label id="lmig-Toggle-{{ id }}-label" [ngClass]="{'no-margin': hideMargins}"
    class="lmig-Toggle-label toggle-label-container" for="lmig-Toggle-{{ id }}">
    <div class="lmig-Toggle-text">{{ label }}</div>
    <button attr.aria-labelledby="lmig-Toggle-{{ id }}-label" role="checkbox" [attr.aria--checked]="isToggledOn"
      type="button" class="lmig-Toggle-visual" (click)="toggleOnClick()" [attr.data-heap-id]="heapId"></button>
  </label>
  <label for="lmig-Toggle-{{ id }}" class="lmig-toggle-state toggle-state-label"
    *ngIf="showToggleStateText">{{ toggleStateText }}</label>
</div> -->

<div class="lmig-Toggle toggle-container" [ngClass]="{'no-margin': hideMargins}">
  <input type="checkbox" id="lmig-Toggle-{{ id }}" [name]="name" [value]="isToggledOn ? 'Toggled on' : 'Toggled off'"
    class="lmig-Toggle-input" [attr.aria-disabled]="isDisabled" aria-hidden="true" attr.aria-labelledby="lmig-Toggle-{{ id }}-label"
    [checked]="isToggledOn" tabindex="-1" (click)="preventDefault($event)" />
  <label id="lmig-Toggle-{{ id }}-label" class="lmig-Toggle-label" for="lmig-Toggle-{{ id }}"
    [ngClass]="{'no-margin': hideMargins}">
    <div class="lmig-Toggle-text">{{ label }}</div>
    <button attr.aria-label="{{name}} Toggled {{toggleStateText}}" attr.aria-labelledby="lmig-Toggle-{{ id }}-label" role="checkbox" 
      [attr.aria-checked]="isToggledOn" type="button" class="lmig-Toggle-visual" 
      (click)="toggleOnClick()" [attr.data-heap-id]="heapId"></button>
  </label>
  <label for="lmig-Toggle-{{ id }}" class="lmig-toggle-state toggle-state-label"
    *ngIf="showToggleStateText">{{ toggleStateText }}</label>
</div>