import { Component, OnInit } from '@angular/core';

import { PhoneNumbers } from 'src/app/utilities/constants/phoneNumbers';
import Endpoints from 'src/app/utilities/constants/endpoints';

import Agency from 'src/app/utilities/models/agency';
import Policy from 'src/app/utilities/models/policy';

import { Cookies } from 'src/app/utilities/services/cookies-service/cookies.service';
import { PolicyService } from 'src/app/utilities/services/policy-service/policy.service';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';

import PolicyMethods from 'src/app/utilities/methods/policy.methods';

@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})

export class ContactUsComponent implements OnInit {
  
  claimsExpanded: boolean = false;
  dataLoaded: boolean = false;
  goldAgencyPhoneNumber = PhoneNumbers.GOLD_AGENCY_PHONE_NUMBER;
  goldPlusAgencyPhoneNumber = PhoneNumbers.GOLD_PLUS_AGENCY_PHONE_NUMBER;
  isGoldAgency: boolean = false;
  isGoldPlusAgency: boolean = false;
  isRoadsideAsstAvailable : boolean = false;
  isRoadsideAsstSet: boolean = false;
  policiesBillingExpanded: boolean = false;
  primaryAgency: Agency = null;
  roadsideAssistanceUrl: string = Endpoints.url.roadsideAssistance;
  roadsideAssistExpanded: boolean = false;
  techSupportExpanded: boolean = false;
  url: any = Endpoints.url;

  constructor(
    private policyService: PolicyService,
    private cookies: Cookies,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.configureContactUs();
  }

  async configureContactUs(): Promise<void> {
    await this.getAgencyData();
    await this.checkForRoadsideAssistance();
    this.setRoadsideAssistanceUrl();
  }

  async getAgencyData(): Promise<void> {
    try {
      this.primaryAgency = await this.policyService.getPrimaryAgency();
    } catch (error) {
      console.log(error);
    } finally {
      this.dataLoaded = true;
      if (this.primaryAgency) {
        this.isGoldAgency = await this.policyService.isGoldAgency() === 'Y';
        this.isGoldPlusAgency = await this.policyService.isGoldPlusAgency() === 'Y';
      }
    }
  }

  async checkForRoadsideAssistance(): Promise<void> {
    try {
      const policies: Policy[] = await this.policyService.getPolicies();

      this.isRoadsideAsstAvailable = PolicyMethods.hasRoadsideAssistance(policies);
    } catch (ex) {
      this.isRoadsideAsstAvailable = false;
    } finally {
      this.isRoadsideAsstSet = true;
    }
  }

  setRoadsideAssistanceUrl(): void {
    const isMobileApp: boolean = this.commonService.isMobileApp();

    if (isMobileApp) {
      this.roadsideAssistanceUrl = Endpoints.url.roadsideAssistance
    }
  }

  onKeyPress(event) {
    const targetId = event.target.id;
    if (event.key === 'Enter') {
      if (targetId === 'policiesBillingHelp') {
        this.policiesBillingExpanded = !this.policiesBillingExpanded;
      }
      if (targetId === 'techSupportHelp') {
        this.techSupportExpanded = !this.techSupportExpanded;
      }
      if (targetId === 'claimsHelp') {
        this.claimsExpanded = !this.claimsExpanded;
      }
      if(targetId === 'roadsideAssistHelp') {
        this.roadsideAssistExpanded = !this.roadsideAssistExpanded;
      }
    }
  }
}
