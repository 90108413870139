import { Injectable } from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpHandler } from '@angular/common/http';

@Injectable()
export class AuthHttpInterceptorService implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const mobileAccessToken = sessionStorage.getItem('MOBILE_AUTH0_ACCESS_TOKEN');

    if (mobileAccessToken) {
      const headersConfig = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${mobileAccessToken}`
      };
      
      /* replicate request w/ injected token in authorization header */
      req = req.clone({
        setHeaders: headersConfig
      });           
    }
    
    return next.handle(req)
  }
}

/* This is to return an empty interceptor to app.module instead of the mobile interceptor above (satisfies conditional) */
@Injectable()
export class FauxInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req);
  } 
}
