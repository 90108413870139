import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phoneNumber'
})

export class PhoneNumberPipe implements PipeTransform {

    transform(phoneNumber: string): string {
        return this.formatNumber(phoneNumber);   
    }

    private formatNumber(phoneNumber: string): string {
        // Removes all non-digits
        phoneNumber = phoneNumber.replace(/[- )(]/g, '');
        
        // Adds dashes
        return (phoneNumber) ? '1-' + phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3, 6) + '-' + phoneNumber.slice(6, 10) : '';
    }

    formatPhoneNumber(phoneNumber: string): string {
        // Removes all non-digits
        phoneNumber = phoneNumber.replace(/[- )(]/g, '');
        
        // Adds dashes
        return (phoneNumber) ? phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3, 6) + '-' + phoneNumber.slice(6, 10) : '';
    }
}
