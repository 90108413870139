export const HeaderTypes: any = {
    ENDORSEMENT: 'ENDORSEMENT',
    RENEWAL: 'RENEWAL'
}

export const IndicatorPages: any = {
    LANDING_POLICY_TILE: 'landing-policy-tile',
    POLICY_SUMMARY: 'policy-summary',
    ACCOUNT_SELECT: 'account-select',
    BILLING_SUMMARY: 'billing-summary',
    DOC_CENTER_ID_CARD: 'doc-center-id-card',
    DOC_CENTER_RENEWAL_DOC: 'doc-center-renewal-doc'
}
