import PaperlessBilling from 'src/app/utilities/models/paperlessBilling';
import { PaperlessPolicy } from 'src/app/utilities/models/paperlessPolicy';

export class PaperlessDocuments {
    paperlessBilling: PaperlessBilling[];
    paperlessPolicies: PaperlessPolicy[];
    emailAddress: string;
    status: PaperlessDocumentsStatus;
    firstName?: string;
    lastName?: string;
    tandCsDateTime?: string;
    tandCStatusCode?: number;
}

export class PaperlessDocumentsStatus {
    isCompletelySuccessful: boolean;
    statusDescription: string;
    statusCode: number;
    billingResponsesInError: PaperlessBilling[];
    policyResponsesInError: PaperlessPolicy[];
}

export enum PaperlessDocumentStatusCodes {
    success = 0,
    failureInTAndCStorageRequest = 20,
    incompleteInformationInRequest = 30,
    failureInBillingUpdates = 40,
    failureInPolicyUpdates = 50,
    failureInBothUpdates = 60,
    systemFailure = 100,
    aggregateException = 110,
    exception = 500
}
