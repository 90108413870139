import { FaqConstants, Section, Topics } from 'src/app/utilities/constants/faqs';
import { SearchDefinitions } from 'src/app/utilities/constants/searchDefinitions';

import { SearchResults, Search, FaqSearch } from 'src/app/utilities/models/search';

export default class SearchMethods {

    public static search(search: string): SearchResults {
        const searchResults: SearchResults = new SearchResults();
        const searchParts: string[] = this.getSearchParts(search);

        searchParts.forEach((searchPart: string) => {
            searchResults.pageResults = this.searchPages(searchPart, searchResults.pageResults);
            searchResults.faqResults = this.searchFaq(searchPart, searchResults.faqResults);
        });

        return searchResults;
    }

    private static searchPages(searchPart: string, searchResults: Search[]): Search[] {
        let maxCount: number = searchResults.length ? searchResults[0].countOfHits : 1;

        SearchDefinitions.forEach((definition: Search) => {
            if (definition.keywords.some((keyword: string) => keyword.indexOf(searchPart) === 0)) {
                const matchingResult: Search = searchResults.find((result: Search) => result.id === definition.id);

                if (matchingResult) {
                    matchingResult.countOfHits++;
                    maxCount = (matchingResult.countOfHits > maxCount) ? matchingResult.countOfHits : maxCount;
                } else {
                    searchResults.push({ ...definition });
                }
            }
        });

        searchResults = this.formatSearchArray(searchResults);

        return searchResults;
    }

    private static searchFaq(searchPart: string, searchResults: FaqSearch[]): FaqSearch[] {
        const faqSections: Section[] = FaqConstants.sections;
        const faqSearches: FaqSearch[] = this.searchSections(searchPart, faqSections);
        let maxCount: number = searchResults.length ? searchResults[0].countOfHits : 1;

        faqSearches.forEach((faqSearch: FaqSearch) => {
            const matchingResult: FaqSearch = searchResults.find((result: FaqSearch) => result.title === faqSearch.title);

            if (matchingResult) {
                matchingResult.countOfHits++;
                maxCount = (matchingResult.countOfHits > maxCount) ? matchingResult.countOfHits : maxCount;
            } else {
                searchResults.push({ ...faqSearch });
            }
        });

        searchResults = this.formatSearchArray(searchResults);

        return searchResults;
    }

    private static searchSections(searchPart: string, sections: Section[]): FaqSearch[] {
        let faqSearchMatches: FaqSearch[] = [];

        sections.forEach((section: Section) => {
            const sectionTitle: string = this.formatText(section.title);

            if (sectionTitle.indexOf(searchPart) > -1) {
                faqSearchMatches.push(new FaqSearch(section.title, section.key));
            }

            // For searching topics and questions within sections/subsections
            if (section.topics.some((topic: Topics) => topic.question.indexOf(searchPart) > -1 || topic.answer.indexOf(searchPart) > -1)) {
                //Remove if statement when all questions/answers in FAQ have a key
                if(section.key === 'mostPopularTopics') {
                    faqSearchMatches = faqSearchMatches.concat(this.searchTopic(searchPart, section.topics));
                }   
            }

            if (section.subSections && section.subSections.length) {
                faqSearchMatches = faqSearchMatches.concat(this.searchSections(searchPart, section.subSections));
            }
        });

        return faqSearchMatches;
    }

    private static searchTopic(searchPart: string, topics: Topics[]): FaqSearch[] {
        let faqSearchMatches: FaqSearch[] = [];

        topics.forEach((topic: Topics) => {
            const topicQuestion: string = this.formatText(topic.question);
            const topicAnswer: string = this.formatText(topic.answer);

            if (topicQuestion.indexOf(searchPart) > -1 || topicAnswer.indexOf(searchPart) > -1) {
                faqSearchMatches.push(new FaqSearch(topic.question, topic.key));
            }
        });

        return faqSearchMatches;
    }

    private static formatText(text: string): string {
        if (text && text.length > 0) {
            text = text.replace(/[^a-zA-Z ]/g, ' ');
            text = text.toLowerCase(); 
            return text;  
        } else {
            return '';
        }
    }

    private static getSearchParts(search: string): string[] {
        search = this.formatText(search);

        let searchParts: string[] = search.split(' ').filter((word: string) => word.length > 1);
        searchParts = this.removeArticles(searchParts);

        return searchParts;
    }

    private static removeArticles(searchParts: string[]): string[] {
        const articles: string[] = ['of', 'the', 'from', 'to', 'a'];

        searchParts = searchParts.filter((searchPart: string) => !articles.some((article: string) => article === searchPart));

        return searchParts;
    }

    private static formatSearchArray(searchArray: { countOfHits: number }[]): any[] {
        let maxHitCount: number = 1;

        if (searchArray && searchArray.length) {
            searchArray = searchArray.sort((a: { countOfHits: number }, b: { countOfHits: number }) => {
                if (a.countOfHits < b.countOfHits) return -1;
                if (a.countOfHits > b.countOfHits) return 1;
                return 0;
            });
    
            maxHitCount = searchArray[0].countOfHits;
            searchArray = searchArray.filter((search: { countOfHits: number }) => search.countOfHits === maxHitCount);
    
            if (searchArray.length > 3) {
                searchArray = searchArray.splice(0, 3);
            }
        }
        
        return searchArray;
    }

    public static testSearch(): void {
        const searchTerm = 'proof of insurance';

        // const results: SearchResults = {
        //     pageResults: this.search(searchTerm),
        //     faqResults: this.searchFAQ(searchTerm)
        // };

        console.log(this.search(searchTerm));
    }
}
