import { CrossSellPolicy } from 'src/app/utilities/models/crossSellOffer';
import { LOBName, getLOBNameByNumber } from 'src/app/utilities/constants/linesOfBusiness';
import Agency from 'src/app/utilities/models/agency';
import DateMethods from 'src/app/utilities/methods/date.methods';
import Policy from 'src/app/utilities/models/policy';
import PolicyCoverage from 'src/app/utilities/models/policyCoverage';
import Vehicle from 'src/app/utilities/models/vehicle';
import { LOB } from 'src/app/utilities/constants/linesOfBusiness';
import EsignSessionPolicy from '../models/esignSessionPolicy';
import { DocumentGroup } from '../models/documentMetadata';

export default class PolicyMethods {

    // Filters out all policies that are canceled
    // Will return an empty array if no policies are active
    public static removeCanceledPolicies(policies: Policy[]): Policy[] {
        let activePolicies: Policy[] = [];

        if (policies && policies.length) {
            activePolicies = policies.filter((policy: Policy) => !policy.isCanceled);
        }

        return activePolicies;
    }

    public static removeCanceledPoliciesOverAyear(policies: Policy[]): Policy[] {
        let activeAndRecentlyCanceledPolicies: Policy[] = [];
        
        if (policies && policies.length) {
            activeAndRecentlyCanceledPolicies = policies.filter((policy: Policy) => 
                !this.isCanceledOverAYear(policy)
            );
        }

        return activeAndRecentlyCanceledPolicies;
    } 

    public static isCanceledOverAYear(policy: Policy) {
        let canceledOverAYear = false;

        if(policy.isCanceledEffective && !DateMethods.isWithinYearsFromDate(policy.canceledEffectiveDate, 1)) {
            canceledOverAYear = true;
        }

        return canceledOverAYear;
    }

    public static isPolicyTransferredFromStateAuto(policy: Policy): boolean {
        let isPolicyTransferredFromStateAuto = false;

        if(policy && policy.isPolicyTransferredFromStateAuto) {
            isPolicyTransferredFromStateAuto = true;
        }

        return isPolicyTransferredFromStateAuto;
    }

    public static isAnyPolicyTransferredFromStateAuto(policies: Policy[]): boolean {
        let isPolicyTransferredFromStateAuto = false;

        if(policies && policies.length) {
            isPolicyTransferredFromStateAuto = policies.some((policy: Policy) => this.isPolicyTransferredFromStateAuto(policy));
        }

        return isPolicyTransferredFromStateAuto;
    }

    public static removeCanceledEffectivePolicies(policies: Policy[]): Policy[] {
        let canceledPolicies: Policy[] = [];

        if (policies && policies.length) {
            canceledPolicies = policies.filter((policy: Policy) => !policy.isCanceledEffective);
        }

        return canceledPolicies;
    }

    public static removeUnsuccessfulPolicies(policies: Policy[]): Policy[] {
        let successfulPolicies: Policy[] = [];

        if (policies && policies.length) {
            successfulPolicies = policies.filter((policy: Policy) => policy.policyResponseStatus && policy.policyResponseStatus.isSuccessful);
        }

        return successfulPolicies;
    }

    public static getActivePolicies(policies: Policy[], isCanceledEffective?: boolean): Policy[] {
        let filteredPolicies: Policy[] = [];

        if (policies && policies.length) {
            filteredPolicies = isCanceledEffective ? this.removeCanceledEffectivePolicies(policies) : this.removeCanceledPolicies(policies);
            filteredPolicies = this.removeUnsuccessfulPolicies(filteredPolicies);
        }

        return filteredPolicies;
    }

    public static getCanceledPolicies(policies: Policy[]) {
        let canceledPolicies: Policy[] = [];

        if (policies && policies.length) {
            canceledPolicies = policies.filter((policy: Policy) => policy.isCanceled && policy.isCanceledEffective);
        }

        return canceledPolicies;
    }

    public static getRenewalViewPolicies(policies: Policy[]): Policy[] {
        let renewalViewPolicies: Policy[] = [];

        if (policies && policies.length) {
            renewalViewPolicies = this.removeUnsuccessfulPolicies(policies);
            renewalViewPolicies = renewalViewPolicies.filter((policy: Policy) => this.isRenewalView(policy));
        }

        return renewalViewPolicies;
    }

    public static isRenewalView(policy: Policy) {
        if (policy.isPreRenewal || policy.isRenewedToday || policy.isPostRenewal) {
            return true;
        }

        return false;
    }

    public static isAnyPolicyPreRenewal(policies: Policy[]) {
        return policies.some(policy => policy.isPreRenewal || policy.isRenewedToday);
    }

    public static isPolicyPreRenewal(policy: Policy) {
        if (policy.isPreRenewal || policy.isRenewedToday) { // is not isPostRenewal
            return true;
        }

        return false;
    }

    public static getPreRenewalPolicies(policies: Policy[]): Policy[] {
        let preRenewalPolicies: Policy[] = [];

        if (policies && policies.length) {
            preRenewalPolicies = policies.filter(policy => policy.isPreRenewal || policy.isRenewedToday);

        }
        return preRenewalPolicies;
    }

    public static getMarketingRenewalPolicies(policies: Policy[]): Policy[] {
        let marketingRenewalPolicies: Policy[] = [];

        if (policies && policies.length) {
            marketingRenewalPolicies = policies.filter(policy => policy.isMarketingRenewal);
        }

        return marketingRenewalPolicies;
    }

    public static getMarketingPreRenewalPolicies(policies: Policy[]): Policy[] {
        let marketingPreRenewalPolicies: Policy[] = [];

        if (policies && policies.length) {
            marketingPreRenewalPolicies = policies.filter(policy => policy.isMarketingPreRenewal || policy.isRenewedToday);

        }
        return marketingPreRenewalPolicies;
    }

    public static getBillingRenewalPolicies(policies: Policy[]): Policy[] {
        let billingRenewalPolicies: Policy[] = [];

        if (policies && policies.length) {
            billingRenewalPolicies = policies.filter(policy => policy.isBillingRenewal);
        }

        return billingRenewalPolicies;
    }

    public static getNonCompliantPaperlessPoliciesCA(policies: Policy[], policyDocs: DocumentGroup[]): Policy[] {
        let nonCompliantPolices: Policy[] = [];
    
        const activePolicies = PolicyMethods.getActivePolicies(policies);
        const caPolicies = activePolicies.filter((policy: Policy) => policy.ratingStateAlpha && policy.ratingStateAlpha === 'CA');
        
        // non compliant is when paperless status is Y and does not have paperless t&cs docs
        caPolicies.forEach(policy => {
            if (policyDocs.find((obj: DocumentGroup) => obj.policyNumber == policy.number && policy.isPaperlessPolicy === 'Y' && !obj.hasPaperlessDocs)) {
                nonCompliantPolices.push(policy);
            }
          });
          
        return nonCompliantPolices;
    }


    public static getPolicyByNumber(policyNumber: string, policies: Policy[]): Policy {
        let policyFound: Policy = policies.find((policy: Policy) => policyNumber === policy.number);

        return policyFound;
    }

    public static buildPolicyCommaDelimitedString(policies: Policy[]): string {
        let strPolicies = '';
        for (const policy of policies) {
            strPolicies += policy.number + ',';
        }
        strPolicies = strPolicies.substring(0, strPolicies.length - 1)
        return strPolicies;
    }

    public static buildPolicyStringArray(policies: Policy[]): string[] {
        const strPolicies = [];
        for (const policy of policies) {
            strPolicies.push(policy.number);
        }
        return strPolicies;
    }

    public static buildCrossSellPolicies(policies: Policy[]): CrossSellPolicy[] {
        const xSell: CrossSellPolicy[] = new Array<CrossSellPolicy>();
        for (const policy of policies) {
            if (!policy.isCanceled) {
                const xPolicy = new CrossSellPolicy();
                xPolicy.lineOfBusiness = policy.policyType;
                xPolicy.policyNumber = policy.number;
                xPolicy.ratingState = policy.ratingStateAlpha;
                xSell.push(xPolicy);
            }
        }
        return xSell;
    }

    //possible values of agentType: GPL, GLD, AGT. GXH, CSU
    public static isGoldAgency(agency: Agency) {
        let isGoldAgency: boolean = false;
        if (agency.agentType) {
            const goldAgentTypes = ['GLD'];
            isGoldAgency = ~goldAgentTypes.indexOf(agency.agentType) ? true : false;
        }
        return isGoldAgency;
    }

    public static isGoldPlusAgency(agency: Agency) {
        let isGoldPlusAgency: boolean = false;
        if (agency.agentType) {
            const goldPlusAgentTypes = ['GPL'];
            isGoldPlusAgency = ~goldPlusAgentTypes.indexOf(agency.agentType) ? true : false;
        }
        return isGoldPlusAgency;
    }

    public static isGoldPlusOrGoldAgency(agency: Agency) {
        return this.isGoldAgency(agency) || this.isGoldPlusAgency(agency);
    }

    public static isMultiVehiclePolicy(policy: Policy): boolean {
        let isMultiVehiclePolicy: boolean = false;

        if (policy && policy.vehicles && policy.vehicles.length) {
            isMultiVehiclePolicy = (policy.vehicles.length > 1);
        }

        return isMultiVehiclePolicy;
    }

    public static isSingleVehiclePolicy(policy: Policy): boolean {
        let isSingleVehiclePolicy: boolean = false;

        if (policy && policy.vehicles && policy.vehicles.length) {
            isSingleVehiclePolicy = (policy.vehicles.length === 1);
        }

        return isSingleVehiclePolicy;
    }

    public static displayRenewalDiscounts(LOBNum: number): boolean {
        const LOB: string = LOBNum ? getLOBNameByNumber(LOBNum).toUpperCase() : '';

        switch (LOB) {
            case LOBName.auto:
                // case LOBName.motorcycle:
                return true;
            default:
                return false;
        }
    }

    public static buildVehicleOptions(policy: Policy): Array<{ key: string, value: string }> {
        const vehicleOptions: Array<{ key: string, value: string }> = [];

        if (policy && policy.vehicles && policy.vehicles.length) {
            policy.vehicles.forEach((vehicle: Vehicle) => {
                if (vehicle.displayName && vehicle.vehicleId !== null && vehicle.vehicleId !== undefined) {
                    const vehicleOption: { key: string, value: string } = { key: vehicle.displayName, value: vehicle.vehicleId.toString() };

                    vehicleOptions.push(vehicleOption);
                }
            });
        }

        return vehicleOptions;
    }

    public static getPolicyDeductibleAsNumber(deductible: string): number {
        let deductibleAsNumber = Number.NaN;
        if (deductible) {
            //input will be a string in currency format (e.g. "$4,000")
            deductibleAsNumber = parseInt(deductible.replace(/[$,]+/g, ""));
        }
        return deductibleAsNumber;
    }

    public static hasRoadsideAssistance(policies: Policy[]): boolean {
        const DISABLED_VALUE: string = 'DIS';
        let hasRoadsideAssistance: boolean = false;

        if (policies && policies.length) {
            hasRoadsideAssistance = policies.some((policy: Policy) => {

                if (policy.vehicles && policy.vehicles.length) {
                    const vehicles: Vehicle[] = policy.vehicles;

                    return vehicles.some((vehicle: Vehicle) => {
                        if (vehicle.coverages && vehicle.coverages.length) {
                            const coverages: PolicyCoverage[] = vehicle.coverages;

                            return coverages.some((coverage: PolicyCoverage) => 
                                coverage.key === 'RDASST' &&
                                coverage.limitDed !== DISABLED_VALUE
                            );
                        }

                        return false;
                    });
                }

                return false;
            });
        }

        return hasRoadsideAssistance;
    }

    public static RemoveAsteriskFromPolicy(policyNumber: string): string {
        const CHAR_TO_REMOVE: string = '&nbsp;*';
        const formattedPolicy: string = policyNumber.replace(CHAR_TO_REMOVE, '');

        return formattedPolicy;
    }

    public static getPolicyNumbersStringFromPolicyList(policies: any[]): string {
        let policiesString = "";
  
        if (policies && policies.length) {
          if (typeof policies[0] === 'object') {      // if Policy[] passed in, filter and concatenate policy numbers
            
            if (policies[0] instanceof Policy) {
              policies = policies.filter(policy => PolicyMethods.IsPolicyActiveOrCanceledWithBalance(policy));
            }
  
            policiesString = PolicyMethods.buildPolicyCommaDelimitedString(policies);
          } else if (typeof policies[0] === 'string') {      // if string[] passed in, concatenate
            policiesString = policies.join(",");
          }
        }
  
        return policiesString;
    }
  
  
    public static getFirstPolicyStateFromPolicyList(policies: Policy[]): string {
        let policyState: string = "";
    
        if (policies && policies.length) {
          policyState = policies[0].ratingStateAlpha;
        }
    
        return policyState;
      }

    public static IsPolicyActiveOrCanceledWithBalance(policy: Policy): boolean {
        let isActiveOrCanceledWithBalance: boolean = false;

        if (policy) {
            isActiveOrCanceledWithBalance = policy.isActivePolicy || (policy.isCanceled && policy.balanceAmount > 0);
        }

        return isActiveOrCanceledWithBalance;
    }

    public static isAutoOrMotorcyclePolicy(lineOfBusiness: number) {
        let isAutoOrMotorcyclePolicy: boolean = false;
        if (lineOfBusiness) {
            isAutoOrMotorcyclePolicy = (lineOfBusiness == LOB.Auto || lineOfBusiness == LOB.Motorcycle);
        }
        return isAutoOrMotorcyclePolicy;
    }

    public static isSingleCAPolicy(policy: Policy): boolean {
        let isSingleCAPolicy: boolean = false;

        if (policy && policy.isPolicyTransferredFromLibertyMutual && this.isAutoOrMotorcyclePolicy(policy.lineOfBusiness) && (policy.ratingStateAlpha === 'CA')) {
            isSingleCAPolicy = true; 
        }

        return isSingleCAPolicy;
    }
}
