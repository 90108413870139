import Endpoints from 'src/app/utilities/constants/endpoints';

const roadsideClaimsUrl: string = Endpoints.url.roadsideAssistance;

export const FaqConstants: Faq = {
    sections: [
        {
            key: 'customerSupport',
            title: 'Customer support',
            icon: '',
            topics: [],
            subSections: [{
                    key: 'mostPopularTopics',
                    title: 'Most popular topics',
                    topics: [{
                            key: 'mostPopularRightTrack',
                            question: 'RightTrack® savings program',
                            answer: `
                                <strong>What’s RightTrack®?</strong><br>
                                    RightTrack® users save 5 to 30% off their auto premium by sharing their driving habits over a period of 90 days.  
                                    { "text": "Learn how it works", "externalUrl": "${Endpoints.url.rightTrack}", "heapId": "FAQ_link_rightTrack" }
                                <br><br>
                                
                                <strong>How do I enroll?</strong><br>
                                Please work with your agent to learn more about RightTrack®.`, 
                            hasPrimaryAgency: true
                        }, {
                            key: 'mostPopularLowMileageDiscount',
                            question: 'Low Mileage discount',
                            answer: `
                                { "text": "Enter my mileage to maintain my discount", "externalUrl": "${Endpoints.url.lowMileageLegacy}", "heapId": "FAQ_link_LowMileage", "cssClass": "sco--standalone" }<br><br>

                                <strong>What’s the Low Mileage discount?</strong><br>
                                When you drive less, you’re typically exposed to fewer risks and accidents. And you may be able to pay 
                                less for your auto insurance with this discount. 
                                <br><br>
                            
                                <strong>How do I qualify?</strong><br>
                                You may qualify if you walk, bike or take mass transit to work, if you work from home, or if your annual 
                                mileage is under a certain threshold. Contact your agent for more details.`, 
                            hasPrimaryAgency: true
                        }, {
                            key: 'mostPopularRoadsideAssistance',
                            question: 'Roadside Assistance',
                            answer: `
                                You have 24-Hour Roadside Assistance.<br><br>

                                <strong>Do you have a roadside emergency you need help with?</strong><br>
                                { "text": "Request Roadside Assistance for my vehicle", "externalUrl": "${roadsideClaimsUrl}", "heapId": "FAQ_link_RoadsideAssistance", "cssClass": "sco--standalone" }<br><br>

                                <strong>What’s Roadside Assistance?</strong><br>
                                A coverage you can use 24/7 for help with being locked out of your car, having a dead battery or a flat tire, 
                                running out of gas or other fluids, or needing a tow.<br>`
                        }, {
                            key: 'mostPopularNoRoadsideAssistance',
                            question: 'Roadside Assistance',
                            answer: `
                                You don't have Roadside Assistance.<br><br>

                                <strong>What’s Roadside Assistance?</strong><br>
                                A coverage you can add to your auto policy for 24/7 assistance with such issues as being locked out of your car, having a dead battery or 
                                a flat tire, running out of gas or other fluids, or needing a tow.<br><br>

                                <strong>How do I add Roadside Assistance to my policy?</strong><br>
                                Please work with your agent to add it to your policy.<br>`,
                            hasPrimaryAgency: true
                        }, {
                            key: 'mostPopularCancelMyPolicy',
                            question: 'Cancel my policy',
                            answer: 'Your agent can help you cancel your policy and, if needed, transition to new coverage.',
                            hasPrimaryAgency: true
                        }, {
                            key: 'mostPopularCustomerServiceWithRoadside',
                            question: 'Customer service',
                            answer: `
                                    Claims: 800-332-3226<br>
                                    { "text": "Request Roadside Assistance for my vehicle", "externalUrl": "${roadsideClaimsUrl}", "heapId": "FAQ_link_RoadsideAssistance", "cssClass": "sco--standalone" }<br>
                                    Website help: 888-458-2246<br>
                                    Policies and billing: Contact your agent`, 
                            hasPrimaryAgency: true
                        }, {
                            key: 'mostPopularCustomerServiceNoRoadside',
                            question: 'Customer service',
                            answer: `
                                    Claims: 800-332-3226<br>
                                    Website help: 888-458-2246<br>
                                    Policies and billing: Contact your agent`, 
                            hasPrimaryAgency: true
                        }, {
                            key: 'mostPopularPaperless',
                            question: 'Paperless',
                            answer: `
                                    { "text": "Manage paperless options", "url": "paperlessOptions", "heapId": "FAQ_link_ManagePaperless", "cssClass": "sco--standalone" }<br><br>
                                    <strong>Billing statements</strong>
                                    <ul>
                                    <li>Start paperless<br>
                                    Select the paperless toggle to On to receive electronic billing statements (PDFs) delivered through 
                                    your account. Then agree to the terms and conditions. You’ll no longer receive printed bills in 
                                    the mail.</li>
                                
                                    <li>Stop paperless<br>
                                    Select the paperless toggle to Off to receive printed billing statements delivered in the mail.</li>
                                    </ul>
                                    Your selection will apply to all your billing accounts.<br><br>

                                    <strong>Policy documents</strong>
                                    <ul>
                                    <li>Start paperless<br>
                                    Select the paperless toggle to On to receive electronic policy documents (PDFs) delivered through 
                                    your account. Then agree to the terms and conditions. You’ll no longer receive printed policy 
                                    documents in the mail.</li>
                                
                                    <li>Stop paperless<br>
                                    Select the paperless toggle to Off to receive printed policy documents delivered in the mail.</li>
                                    </ul>
                                    Your selection will apply to all your policies.`
                        }
                    ]
                }
            ]
        }, {
            key: 'documentsAndIdCards',
            title: 'Documents and ID cards',
            icon: 'documentMulti',
            topics: [{
                    question: '',
                    active: true,
                    answer: `{ "text": "View my documents", "url": "documents", "heapId": "FAQ_link_viewMyDocuments", "cssClass": "sco--standalone" }<br><br>
                            { "text": "Manage paperless options", "url": "paperlessOptions", "heapId": "FAQ_link_ManagePaperless", "cssClass": "sco--standalone" }`
                }, {
                    question: 'Where can I find my policy documents and ID cards?',
                    answer: `
                            Go to your { "text": "Homepage", "url": "homepage", "heapId": "FAQ_link_homepage" } and scroll down 
                            to find the policy you want documents or ID cards for. Then select the Policy documents or ID cards link 
                            associated with that policy.<br><br>

                            These are your most recent documents and ID cards. To see all documents available for a policy, select 
                            { "text": "Documents", "url": "documents", "heapId": "FAQ_link_viewMyDocuments" } from the main navigation. 
                            If prompted, select the policy you want documents for.<br><br>
                            
                            Feel free to save or print your documents.`
                }, {
                    question: 'How should I send documents or photos for my policy?',
                    answer: `
                            If requested, submit supporting documentation or photos and reference your policy or account number. 
                            Submitting documentation does not bind coverage.<br><br>
                                
                            Email: irisat@safeco.com<br>
                            Fax: 877-344-5107<br>
                            Please allow up to 24 hours for Safeco to confirm your documents were received.<br><br>
                                    
                            Mail:<br> 
                            Safeco Insurance<br> 
                            PO Box 515097<br> 
                            Los Angeles, CA 90051`
                }, {
                    question: 'How should I send documents or photos for my claim?',
                    answer: 'Talk to your Claims Representative about what documents and photos would be helpful for your claim, as well as how to submit them.'
                }, {
                    question: 'When should I sign my policy documents?',
                    answer: 'We recommend you sign your documents as soon as possible to make sure your coverage isn\'t canceled or interrupted.'
                }, {
                    question: 'Am I signed up for paper or paperless policy documents?',
                    answer: `
                            To check or update your paperless settings, go to the { "text": "Paperless options", "url": "paperlessOptions", "heapId": "FAQ_link_ManagePaperless" } 
                            page and scroll down to the Policy documents area.<br><br>
                            
                            Start paperless<br>
                            Select the paperless toggle to On to receive electronic policy documents (PDFs) delivered through 
                            your account. Then agree to the terms and conditions. You’ll no longer receive printed policy 
                            documents in the mail.<br><br>

                            Stop paperless<br>
                            Select the paperless toggle to Off to receive printed policy documents delivered in the mail.<br><br>

                            Your selection will apply to all your policies.`
                }, {
                    question: 'Am I signed up for paper or paperless billing statements?',
                    answer: `
                            To check or update your paperless settings, go to the { "text": "Paperless options", "url": "paperlessOptions", "heapId": "FAQ_link_ManagePaperless" } 
                            page and scroll down to the Billing statements area.<br><br>

                            Start paperless<br>
                            Select the paperless toggle to On to receive electronic billing statements (PDFs) delivered through 
                            your account. Then agree to the terms and conditions. You’ll no longer receive printed bills in 
                            the mail.<br><br>

                            Stop paperless<br>
                            Select the paperless toggle to Off to receive printed billing statements delivered in the mail.<br><br>
                            
                            Your selection will apply to all your billing accounts.`
                }
            ]
        }, {
            key: 'esign',
            title: 'Esign',
            icon: 'esign',
            topics: [{
                    question: 'Can I sign my policy documents electronically?',
                    answer: `
                            It depends on the type of documents you need to sign.<br><br>

                            Yes, esign is available: Documents for a newly purchased policy.<br>

                            Maybe, esign is sometimes available: Documents related to a policy 
                            change (endorsement) — check with your agent.<br>

                            No, esign isn’t available: Documents related to your renewal.`
                }, {
                    question: 'What does the esign process involve?',
                    answer: `
                            An email from Safeco will let you know your policy is ready to sign. Click Get Started 
                            from the email to log in to your account. This will take you directly into the esign process.<br><br>
                            
                            To sign, click or tap the Sign policy button below your name. On the next page, if prompted, 
                            click or tap Agree to confirm your identity. You’ll then be taken to your policy documents to esign.<br><br>
                            
                            Signing occurs by clicking or tapping. You don’t need to draw your signature with your mouse or 
                            your finger.<br><br> 

                            Once you finish signing all documents for a policy, you’ll either see another policy to sign or 
                            a confirmation that you’re done.`
                }, {
                    question: 'What if I receive a warning or error message while esigning?',
                    answer: `
                            Most of the time it means there’s a temporary problem on our end, in which case you can simply wait 
                            awhile and check back later. Don’t worry, we’ll send you a reminder email if you forget.<br><br> 

                            In some cases, you may need to verify your policy information before you can esign. This is usually 
                            due to a mismatch between the information in your account and the information in our system. If 
                            you’re asked to re-enter any policy information, be sure to enter it exactly as shown on your 
                            policy documents.`
                }, {
                    question: 'How can I access my documents after I’ve esigned?',
                    answer: `
                            Select { "text": "Documents", "url": "documents", "heapId": "FAQ_link_viewMyDocuments" } from the main navigation. 
                            If prompted, select the policy you esigned. You’ll see all documents available online for that policy. It may take a 
                            day or so for the documents you esigned to appear.<br><br>

                            If you don’t find what you’re looking for, please check with your local Safeco agent.<br>`, 
                    hasPrimaryAgency: true
                }, {
                    question: 'What should I do if esign just won’t work?',
                    answer: `
                            If a problem persists, you may want to call your local Safeco agent and request to sign your documents in person or 
                            by mail instead of online.`, 
                    hasPrimaryAgency: true
                }
            ]
        }, {
            key: 'payMyBill',
            title: 'Payments and billing',
            icon: 'savingsDiscount',
            topics: [{
                    question: '',
                    active: true,
                    answer: `{ "text": "Make a payment", "url": "payment", "heapId": "FAQ_link_makeAPayment", "cssClass": "sco--standalone" }<br><br>
                            { "text": "Enroll in or update autopay", "url": "managePayments", "heapId": "FAQ_link_paymentSetting", "cssClass": "sco--standalone" }`
                }
            ],
            subSections: [{
                    key: 'generalPaymentAndBillingQuestions',
                    title: 'General payment and billing questions',
                    topics: [{
                            question: 'How can I pay my bill?',
                            answer: `
                                    { "text": "Make a one-time payment online", "url": "payment", "heapId": "FAQ_link_makeAPayment", "cssClass": "sco--standalone" }<br><br>

                                    { "text": "Enroll in autopay", "url": "managePayments", "heapId": "FAQ_link_paymentSetting", "cssClass": "sco--standalone" }<br><br>

                                    By mail or through your financial institution<br>
                                    Use this address to mail a payment (write your policy number on the check) or set up payments 
                                    through your financial institution:<br><br>

                                    Safeco Insurance<br> 
                                    PO BOX 91016<br> 
                                    Chicago, IL 60680-1174`
                        }, {
                            question: 'Can I pay in multiple installments?',
                            answer: `
                                    Yes, you can typically choose to pay once, twice or four times per policy period, or monthly.<br><br>

                                    To see the options available to you, go to the { "text": "Payment settings page", "url": "managePayments", "heapId": "FAQ_link_paymentSetting" }. 
                                    If prompted, select a billing account. Then, select the Update when I pay button.`
                        }, {
                            question: 'How do I change how many payments I make per policy period?',
                            answer: `
                                    Go to the { "text": "Payment settings page", "url": "managePayments", "heapId": "FAQ_link_paymentSetting" }. 
                                    If prompted, select a billing account. Then, select the Update when I pay button and change how often you pay.<br><br>

                                    To pay less in fees, choose 1 or 2 payments per policy period.<br><br>
                                    
                                    Note, you’re unable to change how often you pay if your payments are made through your escrow account or paycheck.`
                        }, {
                            question: 'How can I avoid installment fees?',
                            answer: `
                                    Avoid installment fees by making 1 payment on your policy, paying in full.<br>
                                    { "text": "Change how often I pay", "url": "managePayments", "heapId": "FAQ_link_paymentSetting" }<br><br>
                                    (You typically pay the most in fees if you pay monthly.)<br><br>

                                    Reduce fees by enrolling in autopay with your bank account.<br>
                                    { "text": "Enroll in autopay", "url": "managePayments", "heapId": "FAQ_link_paymentSetting" }`
                        }, {
                            question: 'Can I change my due date?',
                            answer: `
                                    Yes, go to the { "text": "Payment settings page", "url": "managePayments", "heapId": "FAQ_link_paymentSetting" }. If 
                                    prompted, select a billing account. Then, select the Update when I pay button and enter a new day of the month 
                                    for your due date.<br><br>

                                    Your change will be effective for at least the next 6 months. You can only change your due date once 
                                    every 6 months.<br><br>
                                    
                                    Your new due date will take effect with your current or your next payment, depending on where you 
                                    are in the billing cycle. Be sure to check your Upcoming payments to see your upcoming due dates.<br><br>
                                    
                                    Note, you’re unable to change your due date if your payments are made through your escrow account 
                                    or paycheck.`
                        }, {
                            question: 'Am I signed up for paper or paperless billing statements?',
                            answer: `
                                    To check or update your paperless settings, go to the 
                                    { "text": "Paperless options", "url": "paperlessOptions", "heapId": "FAQ_link_ManagePaperless" } page 
                                    and scroll down to the Billing statements area.<br><br>

                                    Start paperless<br>
                                    Select the paperless toggle to On to receive electronic billing statements (PDFs) delivered 
                                    through your account. Then agree to the terms and conditions. You’ll no longer receive 
                                    printed bills in the mail.<br><br>

                                    Stop paperless<br>
                                    Select the paperless toggle to Off to receive printed billing statements delivered in the mail.<br><br>

                                    Your selection will apply to all your billing accounts.`
                        }, {
                            question: 'How do I know if my payment has been scheduled or processed?',
                            answer: 'Go to the { "text": "My billing page", "url": "billingSummary", "heapId": "FAQ_link_MyBilling" } and select the Transaction history tab.'

                        }, {
                            question: 'What’s the difference between my premium and my balance?',
                            answer: `
                                    Your premium is the price you agreed to pay for your policy when purchasing it. This stays the same 
                                    throughout your policy period unless you decide to change your policy. Any changes to your policy 
                                    could increase or decrease your premium for the remainder of the policy period.<br><br>

                                    Your balance is how much you have left to pay on your premium for the current policy period, plus 
                                    any fees you’ll incur based on how and when you pay. Your balance may also reflect any missed 
                                    payments or late fees.`
                        }, {
                            question: 'I paid for an extra month on my last bill. Why am I getting a bill this month?',
                            answer: `
                                    If the amount you paid covers the amount of your current and next bills, you shouldn’t 
                                    receive an additional bill. If you have any questions, please contact your local Safeco agent.<br>`, 
                            hasPrimaryAgency: true
                        }, {
                            question: 'My payment was returned. What happens now?',
                            answer: `
                                    You'll receive notice and be given additional days to pay. 
                                    You may also need to update your payment method. If you don’t pay your bill within the additional 
                                    days granted, your policy will be canceled.<br><br> 

                                    A fee of up to $25 may be charged for each returned payment.`
                        }
                    ]
                }, {
                    key: 'autoPay',
                    title: 'Autopay',
                    topics: [{
                            question: 'How do I enroll in automatically recurring payments?',
                            answer: `
                                    Go to the { "text": "Payment settings page", "url": "managePayments", "heapId": "FAQ_link_paymentSetting" }. 
                                    If prompted, select a billing account. Then, select the Enroll in autopay button.<br><br>

                                    To pay less in fees, use a bank account for your autopay (not a credit or debit card).`
                        }, {
                            question: 'Will my current bill be paid if I set up autopay today? ',
                            answer: 'If you\'re up to date on your payments and enroll in autopay, the current amount due will be paid automatically. If the bill is past due, the payment will not be made.'
                        }, {
                            question: 'How do I update my autopay information?',
                            answer: `
                                    Go to the { "text": "Payment settings page", "url": "managePayments", "heapId": "FAQ_link_paymentSetting" }. 
                                    If prompted, select a billing account. Then, select the Update my autopay button.<br><br>

                                    To pay less in fees, use a bank account for your autopay (not a credit or debit card).`
                        }, {
                            question: 'How do I stop automatic payments?',
                            answer: `
                                    Go to the { "text": "Payment settings page", "url": "managePayments", "heapId": "FAQ_link_paymentSetting" }. 
                                    If prompted, select a billing account. Then, select the Update my autopay button. Select Unenroll from 
                                    autopay and then the Unenroll button.<br><br>

                                    You’ll need to start making payments yourself and may pay more in fees.`
                        }, {
                            question: 'Can I change the day all my payments are charged or withdrawn?',
                            answer: `
                                    Yes, go to the { "text": "Payment settings page", "url": "managePayments", "heapId": "FAQ_link_paymentSetting" }. 
                                    If prompted, select a billing account. Then, select the Update when I pay button and enter a new day of the month 
                                    for your autopay charge or withdrawal.<br><br>

                                    Your change will be effective for at least the next 6 months. You can only change your autopay day 
                                    once every 6 months.<br><br> 

                                    Your new autopay day will take effect with your current or your next payment, depending on where 
                                    you are in the billing cycle. Be sure to check your Upcoming charges or Upcoming withdrawals to 
                                    see when your next payments will occur.`
                        }, {
                            question: 'What if I need a few extra days before my next payment is charged or withdrawn?',
                            answer: `
                                    Go to the { "text": "Payment settings page", "url": "managePayments", "heapId": "FAQ_link_paymentSetting" }. 
                                    If prompted, select a billing account. Then, select the Delay my next payment button and choose from the 
                                    available days.<br><br>

                                    This change will only apply once, to your very next payment. Once that payment is charged or withdrawn, your 
                                    autopay will resume on your usual day.`
                        }
                    ]
                }, {
                    key: 'payingOnline',
                    title: 'Paying online',
                    topics: [{
                            question: 'Which payment methods are accepted online?',
                            answer: 'You can { "text": "make a one-time payment", "url": "payment", "heapId": "FAQ_link_makeAPayment" } with a checking or savings account. Or, use a debit card, or one of the following credit cards: MasterCard, Visa, Discover or American Express.'
                        }, {
                            question: 'If I make a payment online, will I still receive a paper bill in the mail?',
                            answer: 'Yes, making a payment online doesn’t affect whether you receive paper or paperless bills. Go to the { "text": "Paperless options page", "url": "paperlessOptions", "heapId": "FAQ_link_ManagePaperless" } to check or change your paperless settings.'
                        }, {
                            question: 'How do I update the credit or debit card I saved to my account?',
                            answer: `
                                    If you use a saved credit or debit card to make one-time payments, you can update the card by using it 
                                    the next time you make a payment. Be sure to check the box to save the card for future payments. 
                                    This will override the card currently saved to your account.<br><br>

                                    If you need to update the card you use for automatic payments, go to the 
                                    { "text": "Payment settings page", "url": "managePayments", "heapId": "FAQ_link_paymentSetting" }.`
                        }, {
                            question: 'When will my online payment be processed?',
                            answer: 'Safeco considers your payment to have been received on the day it was made, charged or deducted. However, it may take up to 2 business days to process the payment and adjust your account / policy balance. Payments made after 5 p.m. Pacific will post to your account the following business day.'
                        }
                    ]
                }
            ]
        }, {
            key: 'policyInfoAndUpdates',
            title: 'Policy info and updates',
            icon: 'policy',
            topics: [{
                    question: '',
                    active: true,
                    answer: `
                            { "text": "View my policy information", "url": "policyDetail", "heapId": "FAQ_link_MyPolicy", "cssClass": "sco--standalone" }<br><br>
                            { "text": "View my policy documents", "url": "documents", "heapId": "FAQ_link_ViewMyDocuments", "cssClass": "sco--standalone" }`
                }
            ],
            subSections: [
                {
                    key: 'generalPolicyQuestions',
                    title: 'General policy questions',
                    topics: [{
                            question: 'How do I cancel my policy?',
                            answer: `
                                    Your agent can help you cancel your policy and, if needed, transition to new coverage.<br>`, 
                            hasPrimaryAgency: true
                        }, {
                            question: 'Where can I find my policy number?',
                            answer: `
                                    Find your policy number on any { "text": "policy document", "url": "documents", "heapId": "FAQ_link_MyDocuments" }, 
                                    including your declarations page or your bill. For auto policies, the number may also be on your insurance ID card.<br><br>

                                    Note that your bill will also list your billing account number, which is different from your policy number.`
                        }, {
                            question: 'Where can I find my policy documents and ID cards?',
                            answer: `
                                    Go to your { "text": "Homepage", "url": "homepage", "heapId": "FAQ_link_homepage" } and scroll down 
                                    to find the policy you want documents or ID cards for. Then select the Policy documents or ID cards 
                                    link associated with that policy.<br><br>

                                    These are your most recent documents and ID cards. To see all the documents available for a policy, 
                                    select { "text": "Documents", "url": "documents", "heapId": "FAQ_link_MyDocuments" } from the main 
                                    navigation. If prompted, select the policy you want documents for.<br><br>
                                    
                                    Feel free to save or print your documents.`
                        }, {
                            question: 'Will I receive paper or paperless policy documents?',
                            answer: `
                                    That’s up to you. To see or update your paperless settings, go to the { "text": "Paperless options page", "url": "paperlessOptions", "heapId": "FAQ_link_ManagePaperless" } 
                                    and scroll down to the Policy documents area.<br><br>
                                        
                                    Start paperless<br>
                                    Select the paperless toggle to On to receive electronic policy documents (PDFs) delivered 
                                    through your account. Then agree to the terms and conditions. You’ll no longer receive printed 
                                    policy documents in the mail.<br><br>

                                    Stop paperless<br>
                                    Select the paperless toggle to Off to receive printed policy documents delivered in the mail.<br><br>

                                    Your selection will apply to all your policies.`
                        }, {
                            question: 'What does my policy cover?',
                            answer: `
                                    { "text": "Go to your policy page", "url": "policyDetail", "heapId": "FAQ_link_MyPolicy" } to see a 
                                    summary of your coverages. For full coverage descriptions, refer to your policy documents.<br><br> 

                                    Note that policies and coverage levels differ from policyholder to policyholder.`
                        }, {
                            question: 'Is the policy information in my account up to date?',
                            answer: 'Yes. Although, if you recently requested a policy change, it may take up to 4 business days to process the change and update your account.'
                        }, {
                            question: 'How do I change or update my policy?',
                            answer: `
                                    You can start a policy change request from the { "text": "Homepage", "url": "homepage", "heapId": "FAQ_link_homepage" }. 
                                    Scroll down to find the policy you want to update and select the Request a policy change link 
                                    associated with that policy.<br><br>

                                    Once you click the link, you’ll see the types of changes you can request. If you don’t see the type of 
                                    change you’d like to make or you have any questions, contact your local Safeco agent.<br>`, 
                            hasPrimaryAgency: true
                        }, {
                            question: 'When does my policy expire?',
                            answer: 'On the last day of your policy period. You can see the start date and end date of your policy on your { "text": "Homepage", "url": "homepage", "heapId": "FAQ_link_homepage" } or { "text": "policy page", "url": "policyDetail", "heapId": "FAQ_link_MyPolicy" }, or check your { "text": "policy documents", "url": "documents", "heapId": "FAQ_link_MyDocuments" }.'
                        }
                    ]
                }, {
                    key: 'deductiblesAndRates',
                    title: 'Deductibles and rates',
                    topics: [{
                            question: 'Why do rates change over time?',
                            answer: 'There are many factors that can cause rates to change over time. Some are specific to you, such as being involved in an accident or receiving a speeding ticket. Others are more general, such as an increase of severe weather in your area.'
                        }, {
                            question: 'Is there anything I can do to lower my insurance costs?',
                            answer: `
                                    There are a few simple things you can do that may lower your costs.<br><br>

                                    { "text": "Pay your bill using autopay", "url": "managePayments", "heapId": "FAQ_link_paymentSetting" }, with 
                                    automatic withdrawals from your bank account (EFT).<br><br>

                                    { "text": "Pay for your policy in 1 or 2 payments", "url": "managePayments", "heapId": "FAQ_link_paymentSetting" } — when you pay monthly, you pay an installment fee each month.<br><br>
                                    
                                    Work with your agent to choose a higher deductible for your policy.<br>`, 
                            hasPrimaryAgency: true
                        }, {
                            question: 'What’s a deductible and how much is mine?',
                            answer: `
                                    You chose a deductible amount when purchasing your policy. It’s the amount you’ve agreed to pay out of 
                                    your own pocket toward any covered vehicle or home repair costs. After you pay your deductible, your 
                                    insurance carrier covers the remaining costs of any covered loss, as determined by your policy.<br><br>

                                    { "text": "Go to your policy page", "url": "policyDetail", "heapId": "FAQ_link_MyPolicy" } to see your 
                                    deductible. If prompted, select a policy first.`
                        }, {
                            question: 'How do I increase or decrease my deductible?',
                            answer: `
                                    In general, a lower deductible may increase the overall cost of your policy. A higher deductible may 
                                    lower your overall policy costs<br><br>

                                    Please work with your agent to change your deductible.<br>`, 
                            hasPrimaryAgency: true
                        }
                    ]
                }, {
                    key: 'autoPolicies',
                    title: 'Auto policies',
                    topics: [{
                            question: '',
                            active: true,
                            answer: `{ "text": "File an auto insurance claim", "externalUrl": "${Endpoints.url.fileClaim}", "heapId": "FAQ_link_FileClaims", "cssClass": "sco--standalone" }`
                        }, {
                            question: 'Does my coverage extend to a rental vehicle?',
                            answer: `
                                    If you have comprehensive and collision on your auto policy, these coverages will typically 
                                    extend to a vehicle you rent. This can vary by state. Please contact your local Safeco 
                                    agent for further details and to check if coverage applies in your state.<br>`, 
                            hasPrimaryAgency: true
                        }, {
                            question: 'Where can I find my ID cards and other documents?',
                            answer: `
                                    Go to your { "text": "Homepage", "url": "homepage", "heapId": "FAQ_link_homepage" } and scroll 
                                    down to find the policy you want ID cards or documents for. Then select the ID cards or Policy 
                                    documents link associated with that policy.<br><br>

                                    These are your most recent ID cards and documents. To see all the documents available for a policy, 
                                    select { "text": "Documents", "url": "documents", "heapId": "FAQ_link_ViewMyDocuments" } from the 
                                    main navigation. If prompted, select the policy you want documents for.<br><br>
                                    
                                    Feel free to save or print your documents. `
                        }, {
                            question: 'How do I enroll in or get help with RightTrack®?',
                            answer: `
                                    { "text": "Get help using RightTrack®", "externalUrl": "${Endpoints.url.rightTrack}", "heapId": "FAQ_link_rightTrack", "cssClass": "sco--standalone" }<br><br>
                                    
                                    Please contact your agent about possibly enrolling in RightTrack® and saving 5 to 30% on 
                                    your auto premium.<br>`, 
                            hasPrimaryAgency: true
                        }, {
                            question: 'Where do I enter my odometer reading for my Low Mileage discount?',
                            answer: `
                                    { "text": "Enter my mileage to maintain my discount", "externalUrl": "${Endpoints.url.lowMileageLegacy}", "heapId": "FAQ_link_LowMileage", "cssClass": "sco--standalone" }<br><br>
                                    
                                    If you have any trouble, please contact your local Safeco agent.<br>`, 
                            hasPrimaryAgency: true
                        }, {
                            question: 'How do I change or update my auto policy?',
                            answer: `
                                        You can start a policy change request from the { "text": "Homepage", "url": "homepage", "heapId": "FAQ_link_homepage" }. 
                                        Scroll down to find the policy you want to update and select the Request a policy change link 
                                        associated with that policy.<br><br> 

                                        You can request to:
                                        <br><br>
                                        Add, remove or replace a vehicle.<br><br>

                                        Add, remove or modify drivers and their information, including driver license numbers.<br><br>

                                        Update your lender or leasing company.<br><br>

                                        Update your or another policyholder’s name.<br><br>

                                        Update your address.<br><br>

                                        Please contact your local Safeco agent if:<br><br>

                                        You don’t see the change you’d like to make.<br><br>

                                        You need to add or remove coverages.<br><br>

                                        You’d like to add a motorcycle, off-road vehicle (snowmobile, ATV, four-wheeler, etc.), 
                                        motorhome or trailer to your auto policy.<br>`, 
                            hasPrimaryAgency: true
                        }, {
                            key: 'autoPolicyRoadsideAssistance',
                            question: 'Do I have Roadside Assistance?',
                            answer: `
                                    You have 24-Hour Roadside Assistance.<br><br>

                                    <strong>Do you have a roadside emergency you need help with?</strong><br>
                                    { "text": "Request Roadside Assistance for my vehicle", "externalUrl": "${roadsideClaimsUrl}", "heapId": "FAQ_link_LowMileage", "cssClass": "sco--standalone" }<br><br>

                                    <strong>What’s Roadside Assistance?</strong><br>
                                    A coverage you can use 24/7 for help with being locked out of your car, having a dead battery or a flat tire, 
                                    running out of gas or other fluids, or needing a tow.<br>`
                        }, {
                            key: 'autoPolicyNoRoadsideAssistance',
                            question: 'Do I have Roadside Assistance?',
                            answer: `
                                    You don't have Roadside Assistance.<br><br>

                                    <strong>What’s Roadside Assistance?</strong><br>
                                    A coverage you can add to your auto policy for 24/7 assistance with such issues as being locked out of your car, having a dead battery or 
                                    a flat tire, running out of gas or other fluids, or needing a tow.<br><br>

                                    <strong>How do I add Roadside Assistance to my policy?</strong><br>
                                    Please work with your agent to add it to your policy.<br>`, 
                            hasPrimaryAgency: true
                        }, {
                            question: 'Should I update my policy if my mileage or vehicle usage changes?',
                            answer: `
                                    Yes, contact your local Safeco agent to update your policy if:<br><br>

                                    Your annual mileage changes or you expect it to change significantly.<br><br>

                                    You begin using a personal vehicle for business purposes, or vice versa.<br>`, 
                            hasPrimaryAgency: true
                        }, {
                            question: 'What should I do if I buy or sell a vehicle?',
                            answer: `
                                    You can submit a request to add or remove a vehicle from your policy.<br><br>

                                    Go to your { "text": "Homepage", "url": "homepage", "heapId": "FAQ_link_homepage" } and 
                                    scroll down to find the vehicle’s policy. Then, select the Request a policy change link associated 
                                    with that policy`
                        }, {
                            question: 'What should I do if I\'m putting my vehicle in storage?',
                            answer: `
                                    Please work with your agent to update your policy.<br>`, 
                            hasPrimaryAgency: true
                        }

                    ]
                }, {
                    key: 'homePolicies',
                    title: 'Home policies',
                    topics: [{
                            question: '',
                            active: true,
                            answer: `{ "text" : "File a home, condo, renters or landlord insurance claim", "externalUrl" : "${Endpoints.url.fileClaim}", "heapId" : "FAQ_link_FileClaims" , "cssClass": "sco--standalone"}`
                        }, {
                            question: 'Where can I find my policy documents?',
                            answer: `Go to your { "text": "Homepage", "url": "homepage", "heapId": "FAQ_link_homepage" } and scroll
                            down to find the policy you want documents for. Then select the Policy documents link associated with that 
                            policy.<br><br>

                            These are your most recent documents. To see all the documents available for a policy, select 
                            { "text": "Documents", "url": "documents", "heapId": "FAQ_link_viewMyDocuments" } from the main navigation. 
                            If prompted, select the policy you want documents for.<br><br>
                            
                            Feel free to save or print your documents.`
                        }, {
                            question: 'How do I insure special items?',
                            answer: `
                                    Your policy includes a set amount of coverage for your personal items, such as furniture and clothing.<br><br>

                                    If you have especially valuable, rare or expensive items, such as jewelry, antiques, photography 
                                    equipment, etc., you can purchase separate coverage for these items. This is known as “scheduling” 
                                    items.<br><br>
                                    
                                    Scheduling an item provides higher limits and broader coverages than your policy would normally 
                                    provide for personal property.<br><br>
                                    
                                    Contact your local Safeco agent to schedule items.<br>`, 
                            hasPrimaryAgency: true
                        }, {
                            question: 'How do I change or update my home policy?',
                            answer: `
                                    You can start a policy change request from the { "text": "Homepage", "url": "homepage", "heapId": "FAQ_link_homepage" }. 
                                    Scroll down to find the policy you want to update and select the Request a policy change link associated 
                                    with that policy.<br><br>

                                    You can request to:<br><br>

                                    Update your lender or leasing company.<br><br>

                                    Update your or another policyholder’s name.<br><br>

                                    Update your address.<br><br>
                                          
                                    Please contact your local Safeco agent if:<br><br>

                                    You don’t see the change you’d like to make.<br><br>
                                    
                                    You need to add or remove coverages.`, 
                            hasPrimaryAgency: true
                        }, {
                            question: 'Should I update my policy if I renovate my home?',
                            answer: `
                                    Yes, if you make a home improvement change to your property, you should update your Coverage A - Dwelling 
                                    limit to reflect the updated value of your home. Some examples of home improvements include remodeling your 
                                    kitchen, installing a new floor or finishing your basement.<br><br>

                                    Contact your local Safeco agent to update your policy.`, 
                            hasPrimaryAgency: true
                        }
                    ]
                }

            ]
        }, {
            key: 'claims_faq',
            title: 'Claims',
            icon: 'claims',
            topics: [{
                    question: '',
                    active: true,
                    answer: `
                            { "text" : "File a claim", "externalUrl" : "${Endpoints.url.fileClaim}", "heapId" : "FAQ_link_FileClaims", "cssClass": "sco--standalone" }<br><br>
                            { "text" : "Track a claim", "externalUrl" : "${Endpoints.url.viewClaims}", "heapId" : "FAQ_link_ViewClaims", "cssClass": "sco--standalone" }`
                }
            ],
            subSections: [{
                    key: 'generalClaimQuestions',
                    title: 'General claim questions',
                    topics: [{
                            question: 'How can I see information and updates about my claim?',
                            answer: `
                                    Go to the { "text" : "My claims page", "externalUrl" : "${Endpoints.url.viewClaims}", "heapId" : "FAQ_link_ViewClaims" } to view the latest about your claim.<br><br>

                                    You can also get your claim number, look up your Claim Representative's contact information, share photos and documents, request a damage review or rental, and view completed estimates and payments.<br><br>
                                    
                                    For questions about a claim not shown in your account, please contact your Claims Representative.`
                        }, {
                            question: 'Will a claim affect how much I pay for my policy?',
                            answer: 'The rate you pay for your policy — your premium — won\'t change when your claim is first reported. It may change, however, when your policy is up for renewal. There are many factors that determine how much your policy costs, and a claim is only one factor.'
                        }
                    ]
                }, {
                    key: 'autoClaims',
                    title: 'Auto claims ',
                    topics: [
                        {
                            key: 'autoClaimsRoadsideAssistance',
                            question: '',
                            active: true,
                            answer: `
                                    { "text": "File an auto claim", "externalUrl": "${Endpoints.url.fileClaim}", "heapId": "FAQ_link_FileClaims", "cssClass": "sco--standalone" }<br><br>

                                    <strong>Do you have a roadside emergency you need help with?</strong><br>
                                    { "text": "Request Roadside Assistance for my vehicle", "externalUrl": "${roadsideClaimsUrl}", "heapId": "FAQ_link_RoadsideAssistance", "cssClass": "sco--standalone" }`,
                        }, {
                            key: 'autoClaimsNoRoadsideAssistance',
                            question: '',
                            active: true,
                            answer: `
                                    { "text": "File an auto claim", "externalUrl": "${Endpoints.url.fileClaim}", "heapId": "FAQ_link_FileClaims", "cssClass": "sco--standalone" }`
                        }, {
                            key: 'autoClaimsQuestionRoadside',
                            question: 'What should I do if I get into a car accident?',
                            answer: `
                                    Be mindful of your surroundings while collecting information for your claim.<br><br>
                                    
                                    Call the local police to file a report, if possible.<br><br>

                                    Exchange names, license plate numbers and insurance company names with the other driver(s) 
                                    involved.<br><br>

                                    Make a note of the date, time, location and other details of the accident.<br><br>

                                    Collect the names and contact information of any witnesses, if possible.<br><br>

                                    Take photos with your smartphone, or make a sketch of the accident scene.<br><br>
                                    
                                    Your Claims Representative will guide you on how to submit this and any other requested information.<br><br>
                                    
                                    When it’s safe to do so:<br><br>

                                    { "text": "Request Roadside Assistance for my vehicle", "externalUrl": "${roadsideClaimsUrl}", "heapId": "FAQ_link_RoadsideAssistance", "cssClass": "sco--standalone" }<br><br>

                                    { "text": "File a claim", "externalUrl": "${Endpoints.url.fileClaim}", "heapId": "FAQ_link_FileClaims" } from your account or mobile app. Or, call 800-332-3226.`
                        }, {
                            key: 'autoClaimsQuestionNoRoadside',
                            question: 'What should I do if I get into a car accident?',
                            answer: `
                                    Be mindful of your surroundings while collecting information for your claim.<br><br>
                                    
                                    Call the local police to file a report, if possible.<br><br>

                                    Exchange names, license plate numbers and insurance company names with the other driver(s) 
                                    involved.<br><br>

                                    Make a note of the date, time, location and other details of the accident.<br><br>

                                    Collect the names and contact information of any witnesses, if possible.<br><br>

                                    Take photos with your smartphone, or make a sketch of the accident scene.<br><br>
                                    
                                    Your Claims Representative will guide you on how to submit this and any other requested information.<br><br>
                                    
                                    When it’s safe to do so:<br><br>

                                    { "text": "File a claim", "externalUrl": "${Endpoints.url.fileClaim}", "heapId": "FAQ_link_FileClaims" } from your account or mobile app. Or, call 800-332-3226.`
                        }, {
                            question: 'Can I choose the auto shop for my claim repairs?',
                            answer: `
                                    Yes, you can take your vehicle to any repair shop. Or, choose one from our Guaranteed Repair Network 
                                    (GRN), a collection of shops we've vetted to ensure the highest quality service. It’s up to you.<br><br> 

                                    GRN is not available in all states and may vary by state. Check with your Claims Representative for 
                                    details on our Superior Service Program in Massachusetts and Rhode Island.`
                        }, {
                            question: 'Will you pay the auto shop directly for my claim repairs?',
                            answer: `
                                    Yes, we can pay the shop directly for any covered repairs, minus the amount of your deductible. You’ll 
                                    need to pay your deductible to the shop.<br><br> 

                                    Most shops will release your vehicle sooner if they know the payment is coming from an insurance company. 
                                    However, if you don’t want us to pay the shop directly, we'll issue the payment to you instead, minus 
                                    your deductible. If you have a loan, you and your lender(s) are both required to sign the check. 
                                    You’ll then need to pay the shop the full amount of your repairs.`
                        }, {
                            question: 'How do I file an auto glass claim?',
                            answer: `
                                    Safeco partners with Safelite® Solutions to manage windshield and auto glass claims.<br><br> 

                                    { "text": "File a claim", "externalUrl": "${Endpoints.url.fileClaim}", "heapId": "FAQ_link_FileClaims" } from your account or mobile app, or call Safelite at 800-332-3226.<br><br> 
                                    
                                    Glass coverage can vary from policy to policy and state to state. Review your policy documents or 
                                    check with your local Safeco agent for more information.`
                        }, {
                            question: 'Can I get a rental car while my vehicle’s being repaired?',
                            answer: `
                                    Yes, your Claims Representative can help you rent a vehicle, whether or not you have rental coverage on 
                                    your auto policy, or extend the rental if your repair takes longer than expected (within reason, and 
                                    within your coverage limits).<br><br>

                                    We partner with Enterprise Rent-a-Car to provide you with exceptional customer service at discounted 
                                    rates. Or, you can choose a different rental company.`
                        }, {
                            question: 'If I’m not at fault, who handles the claim and pays for the repair?',
                            answer: `
                                    You can file a claim with Safeco or with the at-fault party’s insurance carrier to see about getting 
                                    your vehicle repaired.<br><br>

                                    If you file with Safeco, you’ll need to pay your deductible. However, if our claim investigation 
                                    finds you're legally not at fault, we may attempt to recover all or part of the amount you paid for 
                                    your deductible from the responsible party.`
                        }, {
                            question: 'Why does my account show the Deductible Recovery process is still open?',
                            answer: 'If the Deductible Recovery section is still open after you\'re reimbursed for your deductible, it means we\'re still trying to recover other costs from the responsible party. These costs may be related to property damage, medical expenses, storage, towing or car rental.'
                        }
                    ]
                }, {
                    key: 'homeClaims',
                    title: 'Home claims',
                    topics: [{
                            question: '',
                            active: true,
                            answer: `{ "text" : "File a home, condo, renters or landlord claim", "externalUrl": "${Endpoints.url.fileClaim}", "heapId": "FAQ_link_FileClaims" } `
                        }, {
                            question: 'Can you view my property damage over a video call?',
                            answer: `
                                    Maybe, depending on the circumstances of your claim.<br><br>

                                    Once you’ve { "text": "filed your claim", "externalUrl": "${Endpoints.url.fileClaim}", "heapId": "FAQ_link_FileClaims" }, your Claims Representative will discuss whether a 
                                    RealTime Review® video call is an option for you. If so, you’ll need a smartphone or tablet capable of 
                                    video chat with Internet access, and either a FaceTime™ or Skype™ account.`
                        }

                    ]
                }
            ]
        }, {
            key: 'profile',
            title: 'Profile',
            icon: 'aboutMe',
            topics: [{
                    question: 'How can I delete my online account?',
                    answer: `Please call <a href="tel:+1-888-458-2246">888-458-2246</a> and follow the prompts to speak with someone about deleting your online account. <br><br>

                    What happens when you delete your account? 
                    <ul>
                    <li>You’ll lose online access to manage your account and access policy information.</li>                    
                    <li>Your policy won’t be deleted unless you cancel your policy.</li>                    
                    <li>Your personal information won’t be deleted.</li>
                    </ul>

                    Why? As a regulated industry, we are required to maintain your personal data in accordance with our retention schedule. To the extent that data may be deleted, you may make a request. 
                    You may have certain rights that allow you to learn more about our processing activities related to your personal data. <br><br>
                    
                    { "text": "Learn more about your personal information", "externalUrl": "${Endpoints.url.privacyWebForm}", "heapId": "FAQ_link_PrivacyWebForm" } `

                }
            ]
        }
    ]
};

export class Faq {
    sections: Section[];

    constructor() {
        this.sections = [];
    }
}

export class Section {
    key: string;
    title: string;
    icon?: string;
    active?: boolean;
    topics?: Topics[];
    subSections?: Section[];

    constructor() {
        this.subSections = [];
        this.topics = [];
    }
}

export class Topics {
    key?: string;
    question: string;
    answer: string;
    hasPrimaryAgency?: boolean;
    active?: boolean;
    isHidden?: boolean = false;
}
