import { Component, OnInit, Input } from '@angular/core';
import WayFinderNode from 'src/app/utilities/models/wayFinderNode';
import {environment as env} from 'src/environments/environment';

@Component({
  selector: 'app-way-finder',
  templateUrl: './way-finder.component.html',
  styleUrls: ['./way-finder.component.scss']
})
export class WayFinderComponent implements OnInit {
  @Input() nodes: WayFinderNode[];
  imgLoc = env.imgLoc;
  steps: string;

  constructor() { }

  ngOnInit() {
    this.steps = this.nodes.length.toString();
  }

  isNumber(val) {
    return typeof val === 'number';
  }

}
