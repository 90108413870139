<div id="contact-us">Contact us</div>
<div id="accordion" class="panel-group" aria-multiselectable="true">
    <div class="panel">
        <div class="contact-header" data-parent="#accordion" href="#policiesBilling" tabindex="0"
            (click)="policiesBillingExpanded = !policiesBillingExpanded" (keypress)="onKeyPress($event)" 
            id="policiesBillingHelp" [attr.aria-expanded]="policiesBillingExpanded" aria-controls="policiesBilling"
            [ngClass]="{'expanded': policiesBillingExpanded, 'collapsed': !policiesBillingExpanded}">
            <span class="header-caret">
                <app-icon name="accordionCaretDown" color="active"></app-icon>
            </span>
            <span>Policies & billing</span>
        </div>
        <div id="policiesBilling" class="contact-content" *ngIf='policiesBillingExpanded'>
            <app-spinner *ngIf='!dataLoaded'></app-spinner>
            <span *ngIf="isGoldAgency" class="phone" itemprop="telephone">
                <div><a href="tel:+{{ goldAgencyPhoneNumber | phoneNumber}}">{{ goldAgencyPhoneNumber | phoneNumber }}</a></div>
            </span>
            <span *ngIf="isGoldPlusAgency" class="phone" itemprop="telephone">
                <div><a href="tel:+{{ goldPlusAgencyPhoneNumber | phoneNumber }}">{{ goldPlusAgencyPhoneNumber | phoneNumber }}</a></div>
            </span>
            <span *ngIf="primaryAgency && primaryAgency.phoneNumber && !(isGoldPlusAgency || isGoldAgency)" class="phone" itemprop="telephone">
                <div><a href="tel:+{{ primaryAgency.phoneNumber | phoneNumber }}">{{ primaryAgency.phoneNumber | phoneNumber }}</a></div>
            </span>
            <span *ngIf="primaryAgency && primaryAgency.emailAddress" class="email" itemprop="email">
                <div><a href='mailto:{{ primaryAgency.emailAddress }}'>{{ primaryAgency.emailAddress.toLowerCase() }}</a></div>
            </span>
            <div>Reach out to your agent with changes or questions.</div>
        </div>

        <div class="contact-header" data-parent="#accordion" href="#techSupport" [attr.aria-expanded]="techSupportExpanded" tabindex="0"
            (click)="techSupportExpanded = !techSupportExpanded" (keypress)="onKeyPress($event)" 
            [ngClass]="{'expanded': techSupportExpanded, 'collapsed': !techSupportExpanded}"
            aria-controls="techSupport" id="techSupportHelp">
            <span class="header-caret">
                <app-icon name="accordionCaretDown" color="active"></app-icon>
            </span>
            <span>Safeco technical support</span>
        </div>
        <div id="techSupport" class="contact-content" *ngIf='techSupportExpanded'>
            <div><a href='tel:+1-888-458-2246'>1 888-458-2246</a></div>
            <div>Call us if you're having trouble with our site.</div>
        </div>

        <div class="contact-header" data-parent="#accordion" href="#claims" [attr.aria-expanded]="claimsExpanded" tabindex="0"
            (click)="claimsExpanded = !claimsExpanded" (keypress)="onKeyPress($event)" 
            [ngClass]="{'expanded': claimsExpanded, 'collapsed': !claimsExpanded}" 
            aria-controls="claims" id="claimsHelp">
            <span class="header-caret">
                <app-icon name="accordionCaretDown" color="active"></app-icon>
            </span>
            <span>Claims</span>
        </div>
        <div id="claims" class="contact-content" *ngIf="claimsExpanded">
            <div><a href='tel:+1-800-332-3226'>1 800-332-3226</a></div>
            <div>Available 24 hours a day.</div>
        </div>

        <div class="contact-header" data-parent="#accordion" href="#roadsideAssist" tabindex="0"
            (click)="roadsideAssistExpanded = !roadsideAssistExpanded" (keypress)="onKeyPress($event)"
            [ngClass]="{'expanded': roadsideAssistExpanded, 'collapsed': !roadsideAssistExpanded}" [attr.aria-expanded]="roadsideAssistExpanded"
            aria-controls="roadsideAssist" id="roadsideAssistHelp">
            <span class="header-caret">
                <app-icon name="accordionCaretDown" color="active"></app-icon>
            </span>
            <span>Roadside Assistance</span>
        </div>
        <div id="roadsideAssist" class="contact-content" *ngIf="roadsideAssistExpanded">
            <div id="statusPageSpinner" class="spinner-card" *ngIf="!isRoadsideAsstSet">
                <app-spinner></app-spinner>
            </div>
            <div *ngIf="isRoadsideAsstSet">
                <div class="roadside-assistance" *ngIf="isRoadsideAsstAvailable">
                    <div>Flat tire? Dead battery? Locked out?</div>
                    <a class="sco-link sco--inline" [href]="roadsideAssistanceUrl" target="_blank" rel="noopener noreferrer" role="link" 
                        name="Navigate to roadside assistance" id="roadside_assistance_link" 
                        data-heap-id="ContactUs_link_RoadsideAssistance">Request Roadside Assistance for my vehicle</a>
                </div>
                <div class="roadside-assistance" *ngIf="!isRoadsideAsstAvailable">
                    <div>You don't have Roadside Assistance.</div>
                    <div>Please work with your agent to add it to your policy.</div>
                    <div *ngIf="primaryAgency">
                        <div *ngIf="primaryAgency.agencyName">{{ primaryAgency.agencyName }}</div>
                        <div *ngIf="primaryAgency.phoneNumber"><a href="tel:+{{ primaryAgency.phoneNumber | phoneNumber }}">{{ primaryAgency.phoneNumber | phoneNumber }}</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>