import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})

export class SpinnerComponent implements OnInit {
  @Input() spinnerLabel = 'One moment...';
  ariaLabel: string = '';

  ngOnInit() {
    if (this.spinnerLabel === 'One moment...') {
      this.ariaLabel = 'Loading';
    } else {
      this.ariaLabel = this.spinnerLabel;
    }
  }
}
